import { TextField, Autocomplete, CircularProgress, FormControl, InputLabel, Select, MenuItem, createFilterOptions } from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';

import { Grid } from '@mui/material';
import Button from '../../../Components/Button';
import UserUnit from '../../../Services/Http/User';
import wrlog from '../../../Helper/functions/wrlog';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import Storage from '../../../Services/Storage';

interface FilterFormprops {
    closePopup?: any;
    setFilter: any;
    filter: any
}

/**
 * 
 * @returns Login Page
 */
const FilterForm = (props: FilterFormprops) => {

    const { closePopup, setFilter, filter } = props;

    wrlog("filter", filter)

    const [presetFilter, setPresetFilters] = useState({ ...filter });

    // useEffect(() => {
    //     setPresetFilters({ ...filter });
    // }, [filter]);

    const changeFilter = (key: string, value: string) => {
        let _presetFilter = { ...presetFilter };
        _presetFilter[key] = value;
        setPresetFilters(_presetFilter);
    }

    const applyFilter = () => {
        setFilter(presetFilter);
        closePopup();
    }

    return (
        <Grid container spacing={0}>
            <Grid item md={12} padding={0}>
                <h3>{translate('filter')}</h3>
                <FormControl variant="filled">
                    <InputLabel>{translate('role')}</InputLabel>
                    <Select
                        value={presetFilter.role}
                        label={translate('role')}
                        onChange={e => changeFilter('role', e.target.value)}
                    >
                        <MenuItem value={''}>{translate('all')}</MenuItem>
                        {
                            Storage.getValue('roles').map((role: any) => (
                                <MenuItem value={role.id}>{role.title}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <FormControl variant="filled">
                    <InputLabel>{translate('status')}</InputLabel>
                    <Select
                        value={presetFilter.status}
                        label={translate('status')}
                        onChange={e => changeFilter('status', e.target.value)}
                    >
                        <MenuItem value={''}>{translate('all')}</MenuItem>
                        <MenuItem value={'active'}>{translate('active')}</MenuItem>
                        <MenuItem value={'invited'}>{translate('invited')}</MenuItem>
                    </Select>
                </FormControl>

                <Button
                    type='primary'
                    onClick={applyFilter}
                    label={translate('apply_filter')}
                    preventLoading
                />
            </Grid>
        </Grid >
    );
}

export default FilterForm;