// import { TextField } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

interface ButtonProps {
    type: string;
    label: string;
    onClick?: any;
    isInput?: any;
    icon?: any;
    preventLoading?: boolean;
    className?: any;
    style?: any;
    selected?: boolean;
    textStyle?: any;
}

/**
 * 
 * @returns Button and changes States of the button
 */
const Button = forwardRef((props: ButtonProps, ref) => {
    const [state, setState] = useState('default');
    const {
        type,
        label,
        onClick,
        isInput,
        preventLoading,
        icon,
        className,
        style,
        textStyle,
        selected
    } = props;

    useImperativeHandle(ref, () => ({
        changeState(state: string) {
            setState(state);

            if (state === 'success' || state === 'error') {
                setTimeout(() => {
                    setState('default');
                }, 2000)
            }

        }
    }))

    const handleClick = () => {

        if (type === 'tertiary' || type === 'small' || preventLoading !== undefined) {
            onClick();
            return;
        }

        if (state === 'default') {
            onClick();
        }
        setState('loading');
    }

    const renderStateContainer = () => (
        <>
            <div style={{ ...textStyle }} className={`${styles.labelContainer}`}>
                {icon || null}<div className={`${styles.label} ${state !== 'default' ? styles.useStatus : undefined} `}>{label}</div>
            </div>
            {state === 'success' && <div className={`${styles.stateContainer} ${styles.successContainer}`}></div>}
            {state === 'error' && <div className={`${styles.stateContainer} ${styles.errorContainer}`}></div>}
            {state === 'loading' && <div className={`${styles.stateContainer} ${styles.loadingContainer}`}>
                <div className={styles['lds-ellipsis']}><div></div><div></div><div></div><div></div></div>
            </div>}
        </>
    )
    let classNames = ``;
    if (className !== undefined) {
        className.split(' ').forEach((cn: any) => {
            classNames += ` ${styles[cn]}`;
        });
    }

    if (isInput !== undefined) {
        return (
            <label
                className={`button ${styles.button} ${selected ? styles.hovered : undefined}  ${state !== 'default' ? styles.hovered : ''} ${styles[type]} ${styles[state]} ${classNames}`} style={{ ...style }}>
                <input type="submit" />
                {renderStateContainer()}
            </label>
        );
    }

    return (
        <div
            className={`button ${styles.button} ${selected ? styles.hovered : undefined} ${state !== 'default' ? styles.hovered : ''} ${styles[type]} ${styles[state]} ${classNames}`}
            style={{ ...style }}
            onClick={handleClick}
        >
            {renderStateContainer()}
        </div>
    );
})

export default Button;