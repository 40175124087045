import { createSlice } from '@reduxjs/toolkit'

export const searchSlice = createSlice({
    name: 'search',
    initialState: {},
    reducers: {
        setSearch: (state, action) => action.payload,
        removeSearh: (state, action) => {}
    },
})

// Action creators are generated for each case reducer function
export const { setSearch, removeSearh } = searchSlice.actions
export default searchSlice.reducer
