import Map from "../../../Components/Map"
import React, { useEffect, useState } from 'react';
import Clustering from 'dbscan_gps';
import wrlog from "../../../Helper/functions/wrlog";
import Unit from "../../../Services/Http";
import axios from "axios";

const Try = () => {

    const [routes, setRoutes] = useState([]);

    const [matrix, setMatrix] = useState([]);

    let coordinates = [
        [14.78543, 48.038466],
        [15.668167, 48.148579],
        [15.448744, 48.038826],
        [14.897301, 48.117908],
        [15.932998, 48.532428],
        [14.925555, 48.161487],
        [15.93208, 48.554506],
        [14.945585, 48.746903],
        [15.09031, 48.958178],
        [14.937409, 48.084302],
        [15.404661, 48.792495],
        [14.902738, 48.193178],
        [14.906136, 48.722196],
        [14.615865, 48.047543]
    ];

    let markers = [];

    coordinates.forEach(c => {
        markers.push({
            longitude: c[0],
            latitude: c[1],
        })
    })

    const calcMatrix = async (_coordinates) => {

        let _matrix = [];
        let count = 0;

        console.log(_coordinates.length)

        await Promise.all(_coordinates.map(async (coordinate1, key1) => {

            let newMatrix = [];

            await Promise.all(_coordinates.map(async (coordinate2, key2) => {

                count++;

                wrlog("COMPARE", key2, JSON.stringify(coordinate1) === JSON.stringify(coordinate2))

                if (JSON.stringify(coordinate1) === JSON.stringify(coordinate2)) {
                    newMatrix[key2] = 0;
                } else {
                    let body = {
                        instructions: false,
                        points_encoded: true,
                        points: [coordinate1, coordinate2],
                    }

                    var options = {
                        method: 'POST',
                        url: 'http://52.29.220.0:8989/route',
                        headers: { 'Content-Type': 'application/json' },
                        data: body
                    };

                    let result = await axios.request(options);

                    wrlog("COUNT", count, coordinate1, coordinate2, result.data.paths[0].distance)
                    newMatrix[key2] = result.data.paths[0].distance;

                }

            }))

            _matrix.push(newMatrix);

        }))

        setMatrix(_matrix);
        let route = getRoute(_matrix);
        wrlog("ROUTE12", route);
        setRoutes([route]);
        console.log(_matrix);
    }

    const getRoute = (_matrix) => {
        let routeKeys = [0];
        let lastRouteKey = 0;

        wrlog("Array", Object.keys(_matrix))

        let allKeys = Object.keys(_matrix);
        allKeys.splice(0, 1);
        let permutations = permutate(allKeys);

        let possibleRoutes = [];

        permutations.forEach((stops, key) => {
            permutations[key] = ['0', ...stops, '0'];
        })

        let shortestRouteDistance = null;
        let shortestRoute = null;

        permutations.forEach((stops, key) => {
            let distance = 0;
            for (let i = 0; i < stops.length - 1; i++) {
                distance += _matrix[stops[i]][stops[i + 1]];
            }

            possibleRoutes[key] = distance;

            if (shortestRoute === null || shortestRouteDistance > distance) {
                shortestRouteDistance = distance;
                shortestRoute = key;
            }

        })

        wrlog(possibleRoutes);


        // for (let i = 0; i < _matrix.length; i++) {
        //     let shortestDistance = null;
        //     let selectedKey = null;
        //     _matrix[lastRouteKey].forEach((distance, key) => {
        //         if (distance !== 0 && !routeKeys.includes(key)) {
        //             wrlog(`KEY ${i}`, shortestDistance, distance, shortestDistance > distance)
        //             if (shortestDistance === null || shortestDistance > distance) {
        //                 shortestDistance = distance;
        //                 selectedKey = key;
        //                 lastRouteKey = key;
        //             }
        //         }
        //     })

        //     if (selectedKey !== null) {
        //         routeKeys[i + 1] = selectedKey;
        //     }

        // }


        // return permutations[shortestRoute];

        let route = [];

        // wrlog(routeKeys);

        permutations[shortestRoute].forEach((routeKey, key) => {
            route[key] = (coordinates[routeKey]);
        });

        return route;

    }

    const permutate = (arr) => {
        if (arr.length < 2) return arr;
        var permutations = [];

        // arr.splice(0, 1);

        for (var i = 0; i < arr.length; i++) {
            var restArr = arr.slice(0, i).concat(arr.slice(i + 1, arr.length));

            for (var subPermutation of permutate(restArr))
                permutations.push([arr[i]].concat(subPermutation))

        }
        return permutations;
    };


    const getRoute1 = (_matrix) => {
        let routeKeys = [0];
        let lastRouteKey = 0;

        for (let i = 0; i < _matrix.length; i++) {
            let shortestDistance = null;
            let selectedKey = null;
            _matrix[lastRouteKey].forEach((distance, key) => {
                if (distance !== 0 && !routeKeys.includes(key)) {
                    wrlog(`KEY ${i}`, shortestDistance, distance, shortestDistance > distance)
                    if (shortestDistance === null || shortestDistance > distance) {
                        shortestDistance = distance;
                        selectedKey = key;
                        lastRouteKey = key;
                    }
                }
            })

            if (selectedKey !== null) {
                routeKeys[i + 1] = selectedKey;
            }

        }

        let route = [];

        wrlog(routeKeys);

        routeKeys.forEach((routeKey, key) => {
            route[key] = (coordinates[routeKey]);
        });

        return route;

    }

    useEffect(() => {

        // let body = {
        //     instructions: false,
        //     points_encoded: true,
        //     points: coordinates,
        // }

        // var options = {
        //     method: 'POST',
        //     url: 'http://52.29.220.0:8989/route',
        //     headers: { 'Content-Type': 'application/json' },
        //     data: body
        // };

        // axios.request(options).then(function (response) {
        //     console.log(response.data.paths[0].points.coordinates);
        //     setRoutes([response.data.paths[0].points.coordinates]);
        // }).catch(function (error) {
        //     console.error(error);
        // });

        calcMatrix(coordinates);

    }, [])

    return <>
        {matrix.length > 0 && <table style={{ marginTop: 100 }}>
            {
                <tr><td></td>
                    {
                        matrix.map((cell, key) => {
                            return <td>{key}</td>
                        })
                    }

                </tr>
            }
            {
                matrix.map((row, key) => {

                    return <tr><td>{key}</td>
                        {
                            row.map(cell => {
                                return <td>{(cell / 1000).toFixed(2)}</td>
                            })
                        }

                    </tr>;
                })
            }
        </table>
        }

        <Map
            markers={markers}
            clusters={[]}
            routes={routes}
            drivers={[]}
            flyTo={'bounds'}
        />
        {/* {sortedDistances.map(dis => <>{(dis * 1000).toString().replace('.', ',')}<br /></>)} */}
    </>

}


export default Try;


const recursive_dbscan = (orders, radius, fixedAmountRoutes) => {

    console.log("ORDERS", orders, radius, fixedAmountRoutes)

    let av_cluster_size = 2;
    let numClusters = 0;

    // let max_av_cluster_size = 9;
    // let max_len_cluster = 10;
    let bestRes = [];

    let curr_radius = radius;

    while (numClusters < fixedAmountRoutes) {

        var clustering = Clustering(orders, curr_radius, 'km', av_cluster_size);
        clustering.fit(function (e, clusters) {
            numClusters = clusters.length;
            if (numClusters < fixedAmountRoutes) { // not enough clusters (routes) -> defined by vehicles for the route
                curr_radius = curr_radius - 0.1
            }

            bestRes = clusters

        })

    }

    // Build back cluster with coordinates
    let noise = Object.keys(cloneArray(orders))
    let realClusters = [];
    bestRes.forEach((cluster, index) => {
        let realCluster = [];
        cluster.forEach(waypointIndex => {
            let index = noise.findIndex(e => parseInt(e) === parseInt(waypointIndex));
            noise.splice(index, 1);
            realCluster.push(orders[waypointIndex]);
        });
        realClusters.push(realCluster);
    })

    let noiseCluster = [];
    noise.forEach((n) => {
        noiseCluster.push(orders[n]);
    })

    // bestRes.push(noiseCluster);
    console.log("noise", bestRes)

    // let clonedBestRes = cloneArray(best_res);

    // cloneArray(best_res).forEach((cluster, index) => {
    //     if (cluster.length > max_len_cluster) {

    //         clonedBestRes.splice(index, 1);
    //         const radius = calculateRadius(cluster);
    //         best_res = [...clonedBestRes, ...recursive_dbscan(cluster, 0, radius)]
    //         console.log("cluster.length", best_res);
    //     }
    // });

    return { clusters: realClusters, clusterIndexes: bestRes, noise }
}

const createMatrix = (points1, points2) => {

    let matrix = [];
    let distances = [];

    points1.forEach(coordionate1 => {
        let _distances = [];
        points2.forEach(coordionate2 => {
            _distances.push(calcCrow(coordionate1[0], coordionate1[1], coordionate2[0], coordionate2[1]));
        })

        matrix.push(cloneArray(_distances));

        _distances = _distances.sort((a, b) => a - b);
        distances.push(_distances[2]);
    })

    return { matrix, distances };
}

const findNearestCluster = (cluster, clusters, number) => {

    let pointIndex = cluster[0];

    let points = [];
    clusters.forEach(cluster => {
        points = [...points, ...cluster];
    });

    let { matrix } = createMatrix([cluster[0]], points);
    matrix = matrix[0]

    let minNoiseIndex = null;
    let minNoisePoint = null;

    matrix.forEach((distance, distanceIndex) => {

        if ((minNoiseIndex === null || (distance < matrix[minNoiseIndex] && distance !== 0)) && cluster.findIndex(e => e[0] === points[distanceIndex][0] && e[1] === points[distanceIndex][1]) < 0) {
            minNoiseIndex = distanceIndex;
            minNoisePoint = points[distanceIndex];
        }

    })

    let _clusterIndex = -1;

    clusters.forEach((cPointIndexes, clusterIndexIndex) => {
        if (cPointIndexes.findIndex(e => e[0] === minNoisePoint[0] && e[1] === minNoisePoint[1]) > -1) {
            _clusterIndex = clusterIndexIndex;
        }
    })

    return _clusterIndex;
}




// const recursive_dbscan = (orders, min_radius, max_radius) => {

//     console.log("ORDERS", orders, min_radius, max_radius)

//     let min_no_clusters = 2;
//     let av_cluster_size = 2;
//     let max_av_cluster_size = 9;
//     let max_len_cluster = 10;
//     let best_res = [];


//     while (min_radius < max_radius) {
//         let curr_radius = (min_radius + max_radius) / 2;
//         console.log("CURR RADIUS", curr_radius)

//         var clustering = Clustering(orders, curr_radius, 'km', av_cluster_size);
//         clustering.fit(function (e, clusters) {
//             let no_clusters = 0;
//             if (clusters !== null) {
//                 no_clusters = clusters.length;
//                 av_cluster_size = orders.length / no_clusters;
//             }

//             console.log("av_cluster_size", av_cluster_size);

//             if (no_clusters < min_no_clusters) { // not enough clusters (routes) -> defined by vehicles for the route
//                 max_radius = curr_radius - 0.1
//             } else {
//                 min_radius = curr_radius + 0.1
//             }

//             if (av_cluster_size > max_av_cluster_size) { // choose the biggest cluster possible
//                 best_res = clusters
//                 max_av_cluster_size = av_cluster_size
//             }

//         })

//     }

//     // Build back cluster with coordinates
//     best_res.forEach((cluster, index) => {
//         let realCluster = [];
//         cluster.forEach(waypointIndex => {
//             realCluster.push(orders[waypointIndex]);
//         });
//         best_res[index] = realCluster;
//     })

//     let clonedBestRes = cloneArray(best_res);

//     cloneArray(best_res).forEach((cluster, index) => {
//         if (cluster.length > max_len_cluster) {

//             clonedBestRes.splice(index, 1);
//             const radius = calculateRadius(cluster);
//             best_res = [...clonedBestRes, ...recursive_dbscan(cluster, 0, radius)]
//             console.log("cluster.length", best_res);
//         }
//     });

//     return best_res
// }

const getDistance = (x1, y1, x2, y2) => {
    let y = x2 - x1;
    let x = y2 - y1;

    return Math.sqrt(x * x + y * y);
}

const calcCrow = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}

const toRad = (value) => {
    return value * Math.PI / 180;
}

const calculateRadius = (coordinates) => {
    const { distances, matrix } = createMatrix(coordinates, coordinates)

    let sortedDistances = distances.sort((a, b) => a - b);

    let maxDifference = 0;
    let optimalThreshold = 0;
    for (let i = 0; i < sortedDistances.length - 2; i++) {
        let x = sortedDistances[i + 2] - (2 * sortedDistances[i + 1]) + sortedDistances[i];
        if (maxDifference < x) {
            maxDifference = x;
            optimalThreshold = i;
        }
    }

    const radius = sortedDistances[sortedDistances.length - 1];
    return { radius, matrix };
}

const cloneArray = (array) => {
    return JSON.parse(JSON.stringify(array));
}