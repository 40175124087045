import wrlog from "./wrlog";

const sameJson = (oldJson: any, newJson: any) => {
    let same = true;
    if (Array.isArray(oldJson)) {
        same = same && compareArray(oldJson, newJson);
    } else {
        same = same && compareObject(oldJson, newJson);
    }

    return same;
}

const compareObject = (oldJson: any, newJson: any) => {
    let same = true;

    // Check if Key exists in new json
    Object.keys(oldJson).forEach(key => {
        if (Object.keys(newJson).includes(key)) {
            if (typeof oldJson[key] === 'object' && oldJson[key] !== null && oldJson[key] !== undefined) {
                if (Array.isArray(oldJson[key])) {
                    same = same && compareArray(oldJson[key], newJson[key]);
                } else {
                    same = same && compareObject(oldJson[key], newJson[key]);
                }
            } else {
                same = same && oldJson[key] === newJson[key];
            }
        } else {
            same = false;
        }
    });

    // Check if Key exists in new json
    Object.keys(newJson).forEach(key => {
        if (!Object.keys(oldJson).includes(key)) {
            same = false;
        }
    });

    return same;
}

const compareArray = (oldJson: any, newJson: any) => {
    let same = true;

    oldJson.forEach((value: any, key: number) => {
        if (newJson[key]) {
            if (typeof oldJson[key] === 'object' && oldJson[key] !== null && oldJson[key] !== undefined) {
                if (Array.isArray(oldJson[key])) {
                    same = same && compareArray(oldJson[key], newJson[key]);
                } else {
                    same = same && compareObject(oldJson[key], newJson[key]);
                }
            } else {
                same = same && oldJson[key] === newJson[key];
            }
        } else {
            same = false;
        }
    });
    console.log("newJson", newJson)
    // Check if Key exists in new json
    newJson.forEach((value: any, key: number) => {
        if (oldJson[key] === undefined) {
            same = false;
        }
    });

    return same;
}

export default sameJson;