import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';

interface DetailContainerProps {
    reload?: any;
    visible: boolean;
    close: any;
    children: any;
    disableClose: any;
    level?: any;
}

const DetailContainer = (props: DetailContainerProps) => {

    const { reload, visible, children, close, disableClose, level } = props;

    if (!visible) {
        return null;
    }

    return <div className={`${styles.container} ${styles[`level${level}`]}`}>
        {children}
        {disableClose && <div className={styles.closeButton} onClick={close}></div>}
    </div>

}

export default DetailContainer;