import { FormControl, Input, InputAdornment, InputLabel, Skeleton, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../Helper/multilingual';
import { Link, useNavigate } from 'react-router-dom';
import BackgroundImage from '../../Assets/LogoistikApp_Cover.jpg';
import Logo from '../../Assets/Logo.png';
import EmailIcon from '../../Assets/formIcons/email.svg';
import PasswordIcon from '../../Assets/formIcons/password.svg';

import styles from './styles.module.scss';

import { Grid } from '@mui/material';
import Button from '../../Components/Button';
import UserUnit from '../../Services/Http/User';
import wrlog from '../../Helper/functions/wrlog';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../Services/Redux/features/messages/messageSlice';
import { isLoggedIn, logout } from '../../Helper/functions/auth';
import Storage from '../../Services/Storage';

interface InvitationFormProps {
    closePopup?: any;
    invitationId: any;
}

/**
 * 
 * @returns Login Page
 */
const InvitationForm = (props: InvitationFormProps) => {

    const ForgotPasswordButtonRef = useRef();

    const { closePopup, invitationId } = props;
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [invitationData, setInvitationData] = useState({});

    useEffect(() => {
        UserUnit.getInvitation(invitationId).then((res: any) => {
            if (res.status !== 'active') {
                closePopup();
                navigate('/');
                return;
            }

            if (res.driverApp) {
                closePopup();
                window.location.href = `prontoroutedriver://invite/${invitationId}`;
                return;
            }
            
            if (res.createUser) {
                setValue('email', res.email);
                // logout();
            }

            setInvitationData(res);

        }).catch((err: any) => {
            closePopup();
            navigate('/');
        })
    }, [])

    /**
     * Logs in the User
     * @param {object} data 
     * 
     * @returns void
     */
    const acceptInvitation = () => {

        let buttonRef = ForgotPasswordButtonRef.current || null;
        if (buttonRef !== null) {
            buttonRef.changeState('loading');
        }

        UserUnit.acceptInvitation(invitationId).then(res => {
            buttonRef.changeState('success');

            setTimeout(() => {
                closePopup();
                dispatch(addMessage({
                    type: 'success',
                    title: translate('invitation_accepted_title'),
                    body: translate('invitation_accepted_body')
                }));
            }, 1000)
        }).catch(err => {
            wrlog(err);
            buttonRef.changeState('error');
        })

    }

    /**
     * Logs in the User
     * @param {object} data 
     * 
     * @returns void
     */
    const declineInvitation = () => {

        let buttonRef = ForgotPasswordButtonRef.current || null;
        if (buttonRef !== null) {
            buttonRef.changeState('loading');
        }

        UserUnit.declineInvitation(invitationId).then(res => {
            buttonRef.changeState('success');

            setTimeout(() => {
                closePopup();
                dispatch(addMessage({
                    type: 'success',
                    title: translate('invitation_declined_title'),
                    body: translate('invitation_declined_body')
                }));
            }, 1000)
        }).catch(err => {
            wrlog(err);
            buttonRef.changeState('error');
        })

    }

    /**
     * Logs in the User
     * @param {object} data 
     * 
     * @returns void
     */
    const acceptInitialInvitation = (data: any) => {

        let buttonRef = ForgotPasswordButtonRef.current || null;
        if (buttonRef !== null) {
            buttonRef.changeState('loading');
        }

        UserUnit.acceptInitialInvitation(invitationId, data.firstName, data.lastName, data.email, data.password).then(res => {
            UserUnit.login(data.email, data.password).then(res => {
                buttonRef.changeState('success');

                // set token
                localStorage.setItem('token', res.token);

                // set default/current company (first in array) --> if not set
                let currentCompany = Storage.getValue('currentCompany');
                if (currentCompany !== null) {
                    let included = false;
                    res.companies.forEach((company: any) => {
                        if (company.id === currentCompany) {
                            included = true;
                        }
                    });
                    if (!included) {
                        currentCompany = null;
                    }
                }

                if (currentCompany === null) {
                    Storage.setValue('currentCompany', res.companies[0].id);
                }

                window.location.reload();
            }).catch(err => {
                buttonRef.changeState('error');

                dispatch(addMessage({
                    type: 'error',
                    title: err.title,
                    body: err.description
                }))
            })

        }).catch(err => {
            wrlog(err);
            buttonRef.changeState('error');
        })

    }

    if (invitationData.createUser === undefined) {
        return <>
            <Skeleton variant='rectangular' animation="wave" height={20} width={'80%'} />
            <Skeleton variant='rectangular' animation="wave" height={14} width={'60%'} />
            <Skeleton variant='rectangular' animation="wave" height={14} width={'50%'} />
            <Skeleton variant='rectangular' animation="wave" height={50} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={14} width={'40%'} style={{ marginLeft: '30%' }} />
        </>;
    } else if (invitationData.createUser) {
        return (
            <>
                <h3>{translate('invitation_title', { company_title: invitationData.companyTitle })}</h3>
                <p>{translate('invitation_initial_body', { company_title: invitationData.companyTitle })}</p>

                <Grid container spacing={1} style={{ textAlign: 'center' }}>
                    <Grid item md={12} padding={0}>
                        <form onSubmit={handleSubmit(acceptInitialInvitation)}>
                            <Grid container spacing={3} style={{ textAlign: 'center' }}>
                                <Grid item md={6} padding={0}>
                                    <TextField
                                        {...register('firstName', { required: true })}
                                        error={errors.firstName}
                                        id="standard-basic"
                                        label={translate('firstname')}
                                        type="text"
                                        className='dark'
                                    />
                                </Grid>
                                <Grid item md={6} padding={0}>
                                    <TextField
                                        {...register('lastName', { required: true })}
                                        error={errors.email}
                                        id="standard-basic"
                                        label={translate('lastname')}
                                        type="text"
                                        className='dark'
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                {...register('email', { required: true })}
                                error={errors.email}
                                disabled
                                id="standard-basic"
                                label={translate('email')}
                                type="email"
                                className='dark'
                            />
                            <TextField
                                {...register('password', { required: true })}
                                error={errors.password}
                                id="standard-basic"
                                label={translate('password')}
                                type="password"
                                className='dark'
                            />
                            <Button
                                type='primary'
                                isInput
                                label={translate('accept_invitation_initial')}
                                ref={ForgotPasswordButtonRef}
                            />
                        </form>
                        <br />
                        <Button
                            type='tertiary'
                            className={'delete'}
                            onClick={declineInvitation}
                            label={translate('decline_invitation')}
                        />
                    </Grid>
                </Grid >
            </>
        );
    } else {
        return (
            <>
                <h3>{translate('invitation_title', { company_title: invitationData.companyTitle })}</h3>
                <p>{translate('invitation_body', { company_title: invitationData.companyTitle })}</p>

                <Grid container spacing={0} style={{ textAlign: 'center' }}>
                    <Grid item md={12} padding={0}>
                        <Button
                            type='primary'
                            onClick={acceptInvitation}
                            label={translate('accept_invitation')}
                            ref={ForgotPasswordButtonRef}
                        />
                        <br />
                        <Button
                            type='tertiary'
                            className={'delete'}
                            onClick={declineInvitation}
                            label={translate('decline_invitation')}
                        />
                    </Grid>
                </Grid >
            </>
        );
    }


}

export default InvitationForm;