import React, { useEffect, useRef, useState } from 'react';
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import DeliveryGuyImage from '../../../Assets/placeholder_images/delivery_guy.jpg';

import { Card, Checkbox, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Sidebar from '../Sidebar';
import CompanyUnit from '../../../Services/Http/Company';
import CustomSwitch from '../../../Components/Form/Switch';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';
import ImportsUnit from '../../../Services/Http/Imports';
import ListItem from '../../../Components/ListItem';
import moment from 'moment';

import ErrorIcon from '../../../Assets/messageIcons/error.svg'
import SuccessIcon from '../../../Assets/messageIcons/success.svg'
import { cloneArray } from '../../../Helper/functions/cluster';

interface Props {
}

/**
 * 
 * @returns Page for API Client settings
 */
const ImportsSettingsList = (props: Props) => {
    const [loading, setLoading] = useState(true);

    const [importDataPopupOpen, setImportDataPopupOpen] = useState(false);
    const [imports, setImports] = useState({} as any);
    const [importDatas, setImportDatas] = useState({} as any);
    const [importData, setImportData] = useState({} as any);
    const [selecedImport, setSelectedImport] = useState({} as any);
    const [selectedStatus, setSelectedStatus] = useState(null as any);
    const [selectedData, setSelectedData] = useState({} as any);

    const buttonRef = useRef();
    const workflowButtonRef = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        load();
    }, [])

    useEffect(() => {
        if (selecedImport?.id || selectedStatus) {
            loadDatas();
        }
    }, [selecedImport, selectedStatus])

    useEffect(() => {
        if (selectedData?.id) {
            loadData();
        }
    }, [selectedData])

    const load = () => {
        ImportsUnit.listImports(1, 20).then(res => {
            setImports(res);
            setLoading(false);
        }).catch(() => {
            setLoading(false)
        })
    }

    const loadDatas = () => {
        setLoading(true);
        ImportsUnit.listImportData(selecedImport?.id, selectedStatus, 1, 20).then(res => {
            setImportDatas(res);
            setLoading(false);
        }).catch(() => {
            setLoading(false)
        })
    }

    const loadData = () => {
        setLoading(true);
        ImportsUnit.getData(selecedImport?.id, selectedData.id).then(res => {
            setImportData(res);
            setLoading(false);
            setImportDataPopupOpen(true);
        })
        setLoading(false)
    }

    const updateSelectedData = (key: string, value: any) => {
        let clone = cloneArray(importData);

        if (key === 'tags') {
            value = value.split(',').map((v: string) => v.trim());
        }

        clone.data[key] = value;
        setImportData(clone)
    }

    const updateSelectedDataParcel = (index: number, key: string, value: any) => {
        let clone = cloneArray(importData.data.parcels);
        clone[index][key] = value;
        updateSelectedData('parcels', clone);
    }

    const listLoadingContainer = () => {
        return <Grid container spacing={0}>
            <Grid item md={12} padding={4}>
                <Skeleton variant='rectangular' animation="wave" height={60} width={'100%'} />
                <Skeleton variant='rectangular' animation="wave" height={60} width={'100%'} style={{ marginBottom: 40 }} />
                <Skeleton variant='rectangular' animation="wave" height={30} width={'100%'} />
                <Skeleton variant='rectangular' animation="wave" height={30} width={'100%'} />
            </Grid>
        </Grid>
    }

    const update = () => {
        ImportsUnit.updateData(selecedImport?.id, selectedData.id, importData.data).then(res => {
            loadDatas();
            setImportData({});
            setSelectedData({})
            setImportDataPopupOpen(false);
            buttonRef.current.changeState('success')
        }).catch(err => {
            buttonRef.current.changeState('error')
        })
    }

    const runWorkflows = async () => {
        workflowButtonRef.current.changeState('loading')

        try {
            ImportsUnit.rerunWorkflow(selecedImport?.id);
            workflowButtonRef.current.changeState('success')
        } catch (err) {
            console.log(err);
            workflowButtonRef.current.changeState('error')
        }


    }

    const toClustering = () => {
        navigate(`/settings/logistics/imports/clustering/${selecedImport.id}`)
    }

    return (
        <>
            <Sidebar />
            <div className='middleContainer'>
                {
                    loading && !selecedImport?.id ? listLoadingContainer() :
                        <Grid container spacing={0}>
                            <Grid item md={12} padding={4}>
                                <h3>{translate('imports')}</h3>
                                {imports?.results?.map((_import: any, rowKey: number) => (
                                    <ListItem
                                        key={rowKey}
                                        title={moment(new Date(_import.title)).format('DD.MM.YYYY HH:mm:ss')}
                                        detail={translate(_import.status)}
                                        badges={[
                                            {
                                                title: _import.created,
                                                icon: null,
                                                color: "yellow",
                                            },
                                            {
                                                title: _import.imported,
                                                icon: SuccessIcon,
                                                color: "green",
                                            },
                                            {
                                                title: _import.failed,
                                                icon: ErrorIcon,
                                                color: "red",
                                            }
                                        ]}
                                        selected={_import.id === selecedImport.id}
                                        onClick={() => {
                                            setSelectedImport({ ..._import })
                                        }}
                                        loading={_import.id === selecedImport.id && loading}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                }
            </div >
            <div className='rightContainer'>
                {importDatas?.results
                    && <Grid container spacing={0}>
                        <Grid item md={12} padding={4} style={{ textAlign: 'center' }}>
                            <h3>{translate('imported_data')}</h3>
                            <Button
                                onClick={() => runWorkflows()}
                                label={translate('runWorkflows')}
                                type={'tertiary'}
                                ref={workflowButtonRef}
                            />
                            <Button
                                onClick={() => toClustering()}
                                label={translate('runClustering')}
                                type={'tertiary'}
                            />
                            <hr style={{
                                width: '100%',
                                margin: 0
                            }} />
                            <div style={{ width: '100%', display: 'inline-block', verticalAlign: 'top', paddingTop: 15 }}>
                                <Button
                                    type='tertiary'
                                    label={`${translate('all')} (${parseInt(selecedImport.created) + parseInt(selecedImport.failed) + parseInt(selecedImport.imported)})`}
                                    onClick={() => setSelectedStatus(null)}
                                    selected={selectedStatus === null}
                                    preventLoading
                                />
                                <Button
                                    type='tertiary'
                                    label={`${translate('imported')} (${selecedImport.imported})`}
                                    onClick={() => setSelectedStatus('imported')}
                                    selected={selectedStatus === 'imported'}
                                    preventLoading
                                />
                                <Button
                                    type='tertiary'
                                    label={`${translate('failed')} (${selecedImport.failed})`}
                                    onClick={() => setSelectedStatus('failed')}
                                    selected={selectedStatus === 'failed'}
                                    preventLoading
                                />
                            </div>
                        </Grid>
                        <Grid item md={12} padding={4} style={{ textAlign: 'center' }}>
                            {importDatas?.results?.map((data: any, rowKey: number) => {
                                let color = 'yellow';
                                if (data.status === 'imported') {
                                    color = 'green';
                                } else if (data.status === 'failed') {
                                    color = 'red';
                                }

                                return <ListItem
                                    key={rowKey}
                                    title={data.title}
                                    detail={null}
                                    badges={[
                                        {
                                            title: translate(data.status),
                                            icon: null,
                                            color: color,
                                        }
                                    ]}
                                    selected={selectedData?.id === data.id}
                                    onClick={() => {
                                        setSelectedData({ ...data })
                                    }}
                                    loading={data.id === selectedData.id && loading}
                                />
                            })}
                        </Grid>
                    </Grid>
                }
            </div>

            <Popup
                open={importDataPopupOpen}
                close={() => setImportDataPopupOpen(false)}
                style={{ maxWidth: 500 }}
            >
                <h4>{translate('imported_data')}</h4>
                {
                    importData?.data
                    && Object.keys(importData.data).map((datakey: string, key: number) => {
                        if (datakey === 'parcels') {
                            return null;
                        }
                        return <TextField
                            key={key}
                            id="standard-basic"
                            label={translate(datakey)}
                            type="text"
                            value={datakey === 'tags' ? importData.data[datakey].join(', ') : importData.data[datakey]}
                            onChange={e => updateSelectedData(datakey, e.target.value)}
                            className={`dark`}
                        />
                    })
                }

                <h4>{translate('parcels')}</h4>
                {
                    importData?.data?.parcels?.map((parcel: any, key: number) => {
                        return <div key={'p-' + key}>
                            <TextField
                                id="standard-basic"
                                label={translate('externalId')}
                                type="text"
                                value={parcel?.externalId || ''}
                                onChange={e => updateSelectedDataParcel(key, 'externalId', e.target.value)}
                                className={`dark `}
                            />
                            <TextField
                                id="standard-basic"
                                label={translate('productTitle')}
                                type="text"
                                value={parcel?.productTitle || ''}
                                onChange={e => updateSelectedDataParcel(key, 'productTitle', e.target.value)}
                                className={`dark `}
                            />
                            <TextField
                                id="standard-basic"
                                label={translate('productSku')}
                                type="text"
                                value={parcel?.productSku || ''}
                                onChange={e => updateSelectedDataParcel(key, 'productSku', e.target.value)}
                                className={`dark `}
                            />
                            <TextField
                                id="standard-basic"
                                label={translate('companyTitle')}
                                type="text"
                                value={parcel?.companyTitle || ''}
                                onChange={e => updateSelectedDataParcel(key, 'companyTitle', e.target.value)}
                                className={`dark `}
                            />
                            <hr style={{ width: '100%' }} />
                        </div>
                    })
                }

                <Button
                    onClick={() => update()}
                    label={translate('updateDataAndImport')}
                    type={'primary'}
                    ref={buttonRef}
                />

            </Popup>

        </>
    );
}

export default ImportsSettingsList;