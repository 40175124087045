import React, { useEffect, useRef, useState } from 'react';
import { set, useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';

import styles from './styles.module.scss';

import { Card, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import UserUnit from '../../../Services/Http/User';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../Services/Http/Company';
import wrlog from '../../../Helper/functions/wrlog';
import CustomSwitch from '../../../Components/Form/Switch';
import RoleUnit from '../../../Services/Http/Role';
import Storage from '../../../Services/Storage';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';
import NotificationUnit from '../../../Services/Http/Notification';

interface NotificationSettingsProps {
}

const placeholders = [
    "tracking_link",
    "customer_name",
    "driver_name",
    "product",
    "earliest_time",
    "latest_time",
    "date",
    "company_name",
]

/**
 * 
 * @returns Login Page
 */
const NotificationSettings = (props: NotificationSettingsProps) => {

    const [companyData, setCompanyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingNotifications, setLoadingNotifications] = useState(true);
    const [settings, setSettings] = useState({});
    const [enabled, setEnabled] = useState(false);
    const [preferedContactMethodValue, setPreferedContactMethodValue] = useState(false);
    const [sendScheduledCustomerUpdatesSms, setSendScheduledCustomerUpdatesSms] = useState(false);
    const [sendScheduledCustomerUpdatesEmail, setSendScheduledCustomerUpdatesEmail] = useState(false);
    const [sendSuccessCustomerUpdatesSms, setSendSuccessCustomerUpdatesSms] = useState(false);
    const [sendSuccessCustomerUpdatesEmail, setSendSuccessCustomerUpdatesEmail] = useState(false);

    const [sendFailCustomerUpdatesSms, setSendFailCustomerUpdatesSms] = useState(false);
    const [sendFailCustomerUpdatesEmail, setSendFailCustomerUpdatesEmail] = useState(false);

    const [sendLateCustomerUpdatesSms, setSendLateCustomerUpdatesSms] = useState(false);
    const [sendLateCustomerUpdatesEmail, setSendLateCustomerUpdatesEmail] = useState(false);

    const [webhookEnabled, setWebhookEnabled] = useState(false);
    const [webhookUrl, setWebhookUrl] = useState(false);
    const [webhookDeliverySucceeded, setWebhookDeliverySucceeded] = useState(false);
    const [webhookDeliveryFailed, setWebhookDeliveryFailed] = useState(false);
    const [webhookDeliveryWillArrive24Hours, setWebhookDeliveryWillArrive24Hours] = useState(false);
    const [webhookStopAddedToRoute, setWebhookStopAddedToRoute] = useState(false);
    const [webhookStopImported, setWebhookStopImported] = useState(false);

    const [textPopupOpen, setTextPopupOpen] = useState(false);
    const [currentNotification, setCurrentNotification] = useState({});
    const [currentNotificationId, setCurrentNotificationId] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [notificationTriggers, setNotificationTriggers] = useState([]);


    const { register, handleSubmit, setValue, getValues, formState: { errors, isValid } } = useForm();
    const dispatch = useDispatch();

    const notificationSaveButton = useRef();

    useEffect(() => {
        CompanyUnit.getCompanySettings().then(settings => {
            if (settings !== undefined && settings !== null) {
                setSettings(settings);
                // setValue('enableCustomerUpdates', settings.enableCustomerUpdates === 'true')
                // setEnabled(settings.enableCustomerUpdates === 'true');
                // setValue('preferedContactMethod', settings.preferedContactMethod)
                // setPreferedContactMethodValue(settings.preferedContactMethod);
                // setValue('sendScheduledCustomerUpdatesSms', settings.sendScheduledCustomerUpdatesSms === 'true')
                // setSendScheduledCustomerUpdatesSms(settings.sendScheduledCustomerUpdatesSms === 'true')
                // setValue('sendScheduledCustomerUpdatesEmail', settings.sendScheduledCustomerUpdatesEmail === 'true')
                // setSendScheduledCustomerUpdatesEmail(settings.sendScheduledCustomerUpdatesEmail === 'true')

                // setValue('sendSuccessCustomerUpdatesSms', settings.sendSuccessCustomerUpdatesSms)
                // setSendSuccessCustomerUpdatesSms(settings.sendSuccessCustomerUpdatesSms === 'true')
                // setValue('sendSuccessCustomerUpdatesEmail', settings.sendSuccessCustomerUpdatesEmail)
                // setSendSuccessCustomerUpdatesEmail(settings.sendSuccessCustomerUpdatesEmail === 'true')

                // setValue('sendFailCustomerUpdatesSms', settings.sendFailCustomerUpdatesSms)
                // setSendFailCustomerUpdatesSms(settings.sendFailCustomerUpdatesSms === 'true')
                // setValue('sendFailCustomerUpdatesEmail', settings.sendFailCustomerUpdatesEmail)
                // setSendFailCustomerUpdatesEmail(settings.sendFailCustomerUpdatesEmail === 'true')

                // setValue('sendLateCustomerUpdatesEmail', settings.sendLateCustomerUpdatesEmail)
                // setSendLateCustomerUpdatesEmail(settings.sendLateCustomerUpdatesEmail === 'true')
                // setValue('sendLateCustomerUpdatesSms', settings.sendLateCustomerUpdatesSms)
                // setSendLateCustomerUpdatesSms(settings.sendLateCustomerUpdatesSms === 'true')

                // setValue('headlineDeliveryScheduled', settings.headlineDeliveryScheduled)
                // setValue('headlineDeliverySuccess', settings.headlineDeliverySuccess)
                // setValue('headlineDeliveryFailed', settings.headlineDeliveryFailed)
                // setValue('headlineDeliveryLate', settings.headlineDeliveryLate)

                // setValue('textDeliveryScheduled', settings.textDeliveryScheduled)
                // setValue('textDeliverySuccess', settings.textDeliverySuccess)
                // setValue('textDeliveryFailed', settings.textDeliveryFailed)
                // setValue('textDeliveryLate', settings.textDeliveryLate)

                setLoading(false);
            }
        })

        listNotificationTriggers();
        listNotifications();
    }, [])

    const saveNotification = () => {

        if (currentNotification.trigger === '' || currentNotification.headline === '' || currentNotification.body === '') {
            return false;
        }

        let _buttonRef = notificationSaveButton?.current;
        _buttonRef.changeState('loading')

        if (currentNotificationId === null) {
            NotificationUnit.createNotification(currentNotification.trigger, currentNotification.headline, currentNotification.body).then(res => {
                _buttonRef.changeState('success');
                listNotifications();
            }).catch(err => {
                _buttonRef.changeState('error')
            });
        } else {
            NotificationUnit.updateNotification(currentNotificationId, currentNotification.trigger, currentNotification.headline, currentNotification.body).then(res => {
                _buttonRef.changeState('success')
                listNotifications();
            }).catch(err => {
                _buttonRef.changeState('error')
            });
        }

    }

    const listNotifications = () => {
        setLoadingNotifications(true);
        NotificationUnit.listNotifications().then(res => {
            setNotifications(res);
            setLoadingNotifications(false);
        }).catch(err => {
            wrlog(err);
        })
    }

    const listNotificationTriggers = () => {
        wrlog("setNotificationTriggers");
        NotificationUnit.listNotificationTriggers().then(res => {
            wrlog("setNotificationTriggers", res);
            setNotificationTriggers(res);
        }).catch(err => {
            wrlog("setNotificationTriggers", err);
        })
    }

    const formChanged = (key: string, value?: any) => {

        let _settings = { ...settings };
        let formValue = settings[key];

        if (value !== undefined) {
            formValue = value
            _settings[key] = value;
        }

        setSettings(_settings);

        let body = [{
            key: key,
            value: formValue
        }];
        CompanyUnit.setCompanySettings(body)
            .then(() => {

            }).catch(err => {
                dispatch(addMessage({
                    type: 'error',
                    title: err.title,
                    body: err.description,
                }))
            })

    }

    const openTextPopup = (_currentNotification: any) => {
        if (_currentNotification === null) {
            setCurrentNotification({});
            setCurrentNotificationId(null);
        } else {
            setCurrentNotification({ ..._currentNotification });
            setCurrentNotificationId(_currentNotification.id);
        }
        setTextPopupOpen(true);
    }

    const changeNotification = (key: string, value: string) => {
        let _currentNotification = { ...currentNotification };
        _currentNotification[key] = value;
        setCurrentNotification(_currentNotification);
    }

    const addPlaceholderToBody = (key: string) => {
        let body = currentNotification.body || '';
        changeNotification('body', `${body}[${key}]`)
    }

    const loadingNotificationsContainer = () => {
        return <Grid container spacing={0}>
            <Grid item md={6} padding={4}>
                <Skeleton variant='rectangular' animation="wave" height={180} width={'100%'} />
                <Skeleton variant='rectangular' animation="wave" height={180} width={'100%'} style={{ marginTop: 30 }} />
            </Grid>
            <Grid item md={6} padding={4}>
                <Skeleton variant='rectangular' animation="wave" height={180} width={'100%'} />
                <Skeleton variant='rectangular' animation="wave" height={180} width={'100%'} style={{ marginTop: 30 }} />
            </Grid>
        </Grid>
    }

    const listLoadingContainer = () => {
        return <Grid container spacing={0}>
            <Grid item md={12} padding={4}>
                <Skeleton variant='rectangular' animation="wave" height={60} width={'100%'} />
                <Skeleton variant='rectangular' animation="wave" height={60} width={'100%'} style={{ marginBottom: 40 }} />
                <Skeleton variant='rectangular' animation="wave" height={30} width={'100%'} />
                <Skeleton variant='rectangular' animation="wave" height={30} width={'100%'} />
            </Grid>
        </Grid>

    }

    return (
        <>
            <Sidebar />
            <div className='middleContainer'>
                {
                    loading ? listLoadingContainer() :
                        <Grid container spacing={0}>
                            <Grid item md={12} padding={4}>
                                <h3>{translate('notifications')}</h3>
                                <p>{translate('notifications_setting_body')}</p>
                                <Card>
                                    <Grid container spacing={2}>
                                        <Grid item md={9} padding={0}>
                                            <h4>{translate('enable_notifications')}</h4>
                                        </Grid>
                                        <Grid item md={3} padding={0} className='align_right'>
                                            <CustomSwitch
                                                onChange={(e) => formChanged('enableCustomerUpdates', e.target.checked)}
                                                defaultChecked={settings['enableCustomerUpdates']}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                                {settings['enableCustomerUpdates']
                                    && <>
                                        <p>{translate('notifications_preference_text')}</p>
                                        <Card>
                                            <Grid container spacing={2}>
                                                <Grid item md={6} padding={0}>
                                                    <h4>{translate('preference')}</h4>
                                                </Grid>
                                                <Grid item md={6} padding={0} className='align_right'>
                                                    <FormControl variant="filled">
                                                        <InputLabel>{translate('preference')}</InputLabel>
                                                        <Select
                                                            required={true}
                                                            defaultValue={settings['preferedContactMethod']}
                                                            label={translate('preference')}
                                                            onChange={e => formChanged('preferedContactMethod', e.target.value)}
                                                            style={{ marginBottom: '0px !important' }}
                                                        >
                                                            <MenuItem value={'phone'}>{translate('phone')}</MenuItem>
                                                            <MenuItem value={'email'}>{translate('email')}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                        <Card style={{ marginTop: 30 }}>
                                            <Grid container spacing={2}>
                                                <Grid item md={12} padding={0}>
                                                    <h4>{translate('delivery_updates')}</h4>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item md={12} padding={0}>
                                                    <table style={{ width: '100%' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {translate('send_customer_updates_sms')}
                                                                </td>
                                                                <td className='align_right'>
                                                                    <Checkbox
                                                                        onChange={e => formChanged('sendScheduledCustomerUpdatesSms', e.target.checked)}
                                                                        defaultChecked={settings['sendScheduledCustomerUpdatesSms']}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {translate('send_customer_updates_email')}
                                                                </td>
                                                                <td className='align_right'>
                                                                    <Checkbox
                                                                        onChange={e => formChanged('sendScheduledCustomerUpdatesEmail', e.target.checked)}
                                                                        defaultChecked={settings['sendScheduledCustomerUpdatesEmail']}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                            </Grid>
                                        </Card>

                                        <Card style={{ marginTop: 30 }}>
                                            <Grid container spacing={2}>
                                                <Grid item md={12} padding={0}>
                                                    <h4>{translate('success_updates')}</h4>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item md={12} padding={0}>
                                                    <table style={{ width: '100%' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {translate('send_customer_updates_sms')}
                                                                </td>
                                                                <td className='align_right'>
                                                                    <Checkbox
                                                                        onChange={e => formChanged('sendSuccessCustomerUpdatesSms', e.target.checked)}
                                                                        defaultChecked={settings['sendSuccessCustomerUpdatesSms']}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {translate('send_customer_updates_email')}
                                                                </td>
                                                                <td className='align_right'>
                                                                    <Checkbox
                                                                        onChange={e => formChanged('sendSuccessCustomerUpdatesEmail', e.target.checked)}
                                                                        defaultChecked={settings['sendSuccessCustomerUpdatesEmail']}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                            </Grid>
                                        </Card>

                                        <Card style={{ marginTop: 30 }}>
                                            <Grid container spacing={2}>
                                                <Grid item md={12} padding={0}>
                                                    <h4>{translate('fail_updates')}</h4>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item md={12} padding={0}>
                                                    <table style={{ width: '100%' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {translate('send_customer_updates_sms')}
                                                                </td>
                                                                <td className='align_right'>
                                                                    <Checkbox
                                                                        onChange={e => formChanged('sendFailCustomerUpdatesSms', e.target.checked)}
                                                                        defaultChecked={settings['sendFailCustomerUpdatesSms']}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {translate('send_customer_updates_email')}
                                                                </td>
                                                                <td className='align_right'>
                                                                    <Checkbox
                                                                        onChange={e => formChanged('sendFailCustomerUpdatesEmail', e.target.checked)}
                                                                        defaultChecked={settings['sendFailCustomerUpdatesEmail']}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                            </Grid>
                                        </Card>

                                        <Card style={{ marginTop: 30 }}>
                                            <Grid container spacing={2}>
                                                <Grid item md={12} padding={0}>
                                                    <h4>{translate('late_updates')}</h4>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item md={12} padding={0}>
                                                    <table style={{ width: '100%' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {translate('send_customer_updates_sms')}
                                                                </td>
                                                                <td className='align_right'>
                                                                    <Checkbox
                                                                        onChange={e => formChanged('sendLateCustomerUpdatesSms', e.target.checked)}
                                                                        defaultChecked={settings['sendLateCustomerUpdatesSms']}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {translate('send_customer_updates_email')}
                                                                </td>
                                                                <td className='align_right'>
                                                                    <Checkbox
                                                                        onChange={e => formChanged('sendLateCustomerUpdatesEmail', e.target.checked)}
                                                                        defaultChecked={settings['sendLateCustomerUpdatesEmail']}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </>
                                }
                                <br />
                                <br />
                                <h3>{translate('webhooks')}</h3>
                                <p>{translate('webhooks_setting_body')}</p>
                                <Card>
                                    <Grid container spacing={2}>
                                        <Grid item md={9} padding={0}>
                                            <h4>{translate('enable_webhooks')}</h4>
                                        </Grid>
                                        <Grid item md={3} padding={0} className='align_right'>
                                            <CustomSwitch
                                                onChange={(e) => formChanged('webhook.enabled', e.target.checked)}
                                                defaultChecked={settings['webhook.enabled']}
                                            />
                                        </Grid>
                                    </Grid>
                                    {settings['webhook.enabled']
                                        && <>
                                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                                <Grid item md={12} padding={0}>
                                                    <TextField
                                                        id="standard-basic"
                                                        onBlur={e => formChanged('webhook.url', e.target.value)}
                                                        onChange={e => {
                                                            let _settings = { ...settings };
                                                            _settings['webhook.url'] = e.target.value;
                                                            setSettings(_settings);
                                                        }}
                                                        label={translate('webhook_url')}
                                                        type="text"
                                                        className='dark'
                                                        value={settings['webhook.url']}
                                                        style={{ marginBottom: 0 }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                                <Grid item md={9} padding={0}>
                                                    {translate('webhook_delivery_succeeded')}
                                                </Grid>
                                                <Grid item md={3} padding={0} className='align_right'>
                                                    <CustomSwitch
                                                        onChange={(e) => formChanged('webhook.deliverySucceeded', e.target.checked)}
                                                        defaultChecked={settings['webhook.deliverySucceeded']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                                <Grid item md={9} padding={0}>
                                                    {translate('webhook_delivery_failed')}
                                                </Grid>
                                                <Grid item md={3} padding={0} className='align_right'>
                                                    <CustomSwitch
                                                        onChange={(e) => formChanged('webhook.deliveryFailed', e.target.checked)}
                                                        defaultChecked={settings['webhook.deliveryFailed']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                                <Grid item md={9} padding={0}>
                                                    {translate('webhook_route_started')}
                                                </Grid>
                                                <Grid item md={3} padding={0} className='align_right'>
                                                    <CustomSwitch
                                                        onChange={(e) => formChanged('webhook.routeStarted', e.target.checked)}
                                                        defaultChecked={settings['webhook.routeStarted']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                                <Grid item md={9} padding={0}>
                                                    {translate('webhook_delivery_will_arrive_24_hours')}
                                                </Grid>
                                                <Grid item md={3} padding={0} className='align_right'>
                                                    <CustomSwitch
                                                        onChange={(e) => formChanged('webhook.deliveryWillArrive24Hours', e.target.checked)}
                                                        defaultChecked={settings['webhook.deliveryWillArrive24Hours']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                                <Grid item md={9} padding={0}>
                                                    {translate('webhook_stop_added_to_route')}
                                                </Grid>
                                                <Grid item md={3} padding={0} className='align_right'>
                                                    <CustomSwitch
                                                        onChange={(e) => formChanged('webhook.stopAddedToRoute', e.target.checked)}
                                                        defaultChecked={settings['webhook.stopAddedToRoute']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                                <Grid item md={9} padding={0}>
                                                    {translate('webhook_stop_imported')}
                                                </Grid>
                                                <Grid item md={3} padding={0} className='align_right'>
                                                    <CustomSwitch
                                                        onChange={(e) => formChanged('webhook.stopImported', e.target.checked)}
                                                        defaultChecked={settings['webhook.stopImported']}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Card>
                            </Grid>
                        </Grid>
                }
            </div>
            <div className='rightContainer'>


                <Grid container spacing={0}>
                    <Grid item md={12} padding={4}>
                        <h3>{translate('messages')}</h3>
                        <p>
                            {translate('messages_settings_description')}
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={2} padding={4}>
                    {
                        loadingNotifications ? loadingNotificationsContainer() :
                            notifications.map((notification: any, key: number) => {
                                return <Grid key={key} item md={6} padding={0}>
                                    <h4 style={{ marginBottom: 5 }}>{translate(notification.trigger)}</h4>
                                    <Card className='dark clickable' onClick={() => openTextPopup(notification)}>
                                        <strong>{notification.headline}</strong>
                                        <p
                                            style={{ marginBottom: 0 }}
                                        >{notification.body.substring(0, 90)} ...</p>
                                    </Card>
                                </Grid>
                            })
                    }

                </Grid>
                <Grid container spacing={0}>
                    <Grid item md={12} padding={4} style={{ textAlign: 'right' }}>
                        <Button
                            type='primary'
                            label={translate('add_notification')}
                            className={'smallWidth'}
                            onClick={() => openTextPopup(null)}
                            preventLoading
                        />
                    </Grid>
                </Grid>

            </div>

            <Popup
                open={textPopupOpen}
                close={() => {
                    setCurrentNotification({})
                    setCurrentNotificationId(null);
                    setTextPopupOpen(false)
                }}
            >
                <Grid container spacing={0}>
                    <Grid item md={7} padding={4}>
                        <h3>{currentNotificationId !== null ? translate(currentNotification?.trigger) : ''}</h3>
                        {currentNotificationId === null && <FormControl variant="filled">
                            <InputLabel>{translate('trigger')}</InputLabel>
                            <Select
                                label={translate('Auslöser')}
                                onChange={e => changeNotification('trigger', e.target.value)}
                                style={{ marginBottom: '0px !important' }}
                            >
                                {
                                    notificationTriggers.map((trigger: string, key: number) => {
                                        return <MenuItem key={key} value={trigger}>{translate(trigger)}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        }
                        <TextField
                            onChange={e => changeNotification('headline', e.target.value)}
                            error={errors.email}
                            id="standard-basic"
                            label={translate('title')}
                            type="text"
                            value={currentNotification.headline || ''}
                            className='dark'
                            inputProps={{ maxLength: 60 }}
                        />
                        <div>
                            {
                                placeholders.map(placeholder => {
                                    return <Button
                                        type='secondary'
                                        preventLoading
                                        label={placeholder}
                                        onClick={() => addPlaceholderToBody(placeholder)}
                                    />
                                })
                            }
                        </div>
                        <TextField
                            onChange={e => changeNotification('body', e.target.value)}
                            error={errors.email}
                            id="standard-basic"
                            label={translate('message')}
                            type="text"
                            className='dark noMargin'
                            inputProps={{ maxLength: 150 }}
                            value={currentNotification.body || ''}
                            multiline
                            minRows={3}
                            maxRows={10}
                            style={{ marginTop: 20 }}
                        />
                    </Grid>
                    <Grid item md={5} padding={4} style={{ paddingTop: 20, paddingLeft: 30 }}>
                        <strong>{translate('what_user_sees')}</strong>
                        <h4 style={{ marginBottom: 0 }}>{currentNotification.headline || translate('title')}</h4>
                        <p style={{ whiteSpace: 'pre-line' }}>{ReactHtmlParser(currentNotification.body) || translate('body')}</p>
                        <Button
                            type='primary'
                            label={translate('save')}
                            className='smallWidth'
                            preventLoading
                            ref={notificationSaveButton}
                            onClick={saveNotification}
                            style={{ bottom: 20, right: 20, position: 'absolute' }}
                        />
                    </Grid>
                </Grid>
            </Popup>
        </>
    );
}

export default NotificationSettings;