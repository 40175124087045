import axios from 'axios';
import { API_ENDPOINT } from '../../config.js';
import { getUserToken } from '../../Helper/functions/auth.js';
import wrlog from '../../Helper/functions/wrlog';
import Storage from '../Storage';


export default class Unit {

    static getAuthenticationHeader = () => {

        if (getUserToken() === "undefined" || getUserToken() === null || getUserToken() === false) {
            return {}
        }

        return { Authorization: `Bearer ${getUserToken()}` }
    }

    static getCompanyHeader = () => {

        let currentCompany = Storage.getValue('currentCompany');

        if (currentCompany === null) {
            return {}
        }

        return { Company: currentCompany }
    }

    static getSessionHeader = () => {

        const sessionId = sessionStorage.getItem('currentSession');

        if (sessionId === null) {
            return {}
        }

        return { Session: sessionId }
    }



    static handleResponse = (res) => {
        wrlog("Response from server", res)

        let promise = new Promise((resolve, reject) => {
            if (res.status !== undefined) {
                if (res.status === 200) {
                    resolve(res.data)
                } else {
                    reject(res)
                }
            } else {
                if (res.response.status === 400) {
                    wrlog("API Error Invalid Data", res.response.data)
                } else {
                    wrlog(res);
                }
                if (res.response !== undefined) {
                    wrlog("ERROR DATA", res.response.data)
                }
                reject(res.response.data)
            }
        })

        return promise;
    }

    static get = async (endpoint) => {

        wrlog('Endpoint TO SERVER', 'GET', API_ENDPOINT + endpoint);

        const res = await axios.get(API_ENDPOINT + endpoint, { headers: { ...this.getAuthenticationHeader(), ...this.getCompanyHeader(), ...this.getSessionHeader() } })

        return Unit.handleResponse(res);

    }

    static postMultipart = async (endpoint, formData) => {

        wrlog('BODY TO SERVER', 'POST Multipart', formData)
        wrlog('Endpoint TO SERVER', API_ENDPOINT + endpoint)

        try {
            const res = await axios.post(API_ENDPOINT + endpoint, formData, {
                headers: {
                    ...this.getAuthenticationHeader(),
                    ...this.getCompanyHeader(),
                    ...this.getSessionHeader(),
                    "Content-Type": "multipart/form-data"
                }
            })
            return Unit.handleResponse(res);
        } catch (err) {
            return Unit.handleResponse(err);
        }
    }

    static post = async (endpoint, body) => {

        wrlog('BODY TO SERVER', 'POST', body)
        wrlog('Endpoint TO SERVER', API_ENDPOINT + endpoint)

        try {
            const res = await axios.post(API_ENDPOINT + endpoint, body, { headers: { ...this.getAuthenticationHeader(), ...this.getCompanyHeader(), ...this.getSessionHeader() } })
            return Unit.handleResponse(res);
        } catch (err) {
            return Unit.handleResponse(err);
        }
    }

    static put = async (endpoint, body) => {

        wrlog('BODY TO SERVER', 'PUT', body)
        wrlog('Endpoint TO SERVER', API_ENDPOINT + endpoint)

        const res = await axios.put(API_ENDPOINT + endpoint, body, { headers: { ...this.getAuthenticationHeader(), ...this.getCompanyHeader(), ...this.getSessionHeader() } })
        return Unit.handleResponse(res);


    }

    static delete = async (endpoint, body) => {

        wrlog('BODY TO SERVER', 'DELETE', body)
        wrlog('Endpoint TO SERVER', API_ENDPOINT + endpoint)

        const res = await axios.delete(API_ENDPOINT + endpoint, { data: body, headers: { ...this.getAuthenticationHeader(), ...this.getCompanyHeader(), ...this.getSessionHeader() } })
        return Unit.handleResponse(res);
    }

    show_error = () => {

    }

}