import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../../Helper/multilingual';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../../../Assets/Logo.png';

import styles from './styles.module.scss';

import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../../Sidebar';
import WorkflowDetail from './WorkflowDetail';
import WorkflowList from './WorkflowList';

interface Props {
}

/**
 * 
 * @returns Login Page
 */
const WorkflowSettings = (props: Props) => {

    const LoginButtonRef = useRef();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [currentGroup, setCurrentGroup] = useState(null);
    const [reload, setReload] = useState(null);

    const dispatch = useDispatch();

    const params = useParams();

    return (
        <>
            <Sidebar />
            <div className='middleContainer'>
                <Grid container spacing={0}>
                    <Grid item md={12} padding={4}>
                        <h3>{translate('workflows')}</h3>
                        <WorkflowList
                            currentstopGroup={currentGroup}
                            selectstopGroup={setCurrentGroup}
                            reload={reload}
                            setReload={setReload}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className='rightContainer'>
                <Grid container spacing={0}>
                    <Grid item md={12} padding={4}>
                        <WorkflowDetail
                            currentGroup={currentGroup}
                            reload={reload}
                            setReload={setReload}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default WorkflowSettings;