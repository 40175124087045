import { configureStore } from '@reduxjs/toolkit'
import messageReducer from './features/messages/messageSlice'
import routeStopsReducer from './features/routeStops/routeStopsSlice'
import routeStopReducer from './features/routeStop/routeStopSlice'
import stopGroupReducer from './features/stopGroups/stopGroupsSlice'
import jobReducer from './features/job/jobSlice'
import routesReducer from './features/routes/routesSlice'
import searchReducer from './features/search/searchSlice'

export default configureStore({
    reducer: {
        message: messageReducer,
        routeStops: routeStopsReducer,
        routeStop: routeStopReducer,
        stopGroup: stopGroupReducer,
        job: jobReducer,
        routes: routesReducer,
        search: searchReducer
    },
})