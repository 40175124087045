import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 55,
    height: 28,
    padding: 0,
    display: 'inline-flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 30,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(19px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(27px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                background: 'linear-gradient(#2D93E0, #01ACED)',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 22,
        height: 22,
        borderRadius: 22,
        marginTop: 1,
        marginLeft: 1,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 36 / 2,
        opacity: 1,
        background: '#F2F3F5',
        boxSizing: 'border-box',
    },
}));

export default CustomSwitch;