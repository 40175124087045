import { createSlice } from '@reduxjs/toolkit'

interface MessageProps {
    type: string;
    title: string;
    body: string;
    created?: number;
}

interface AddMessageActionProps {
    payload: MessageProps;
}

export const messageSlice = createSlice({
    name: 'message',
    initialState: {
        messages: [],
    },
    reducers: {
        addMessage: (state, action: AddMessageActionProps) => {
            if (action.payload.title !== undefined && action.payload.title !== '') {
                action.payload.created = Date.now();

                state.messages.push(action.payload);
            }
        },
        removeMessage: (state) => {
            state.messages.pop();
        },
        removeMessages: (state) => {
            state.messages = [];
        }
    },
})

// Action creators are generated for each case reducer function
export const { addMessage, removeMessage, removeMessages } = messageSlice.actions

export default messageSlice.reducer
