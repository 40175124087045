// db.ts
import Dexie, { Table } from 'dexie';

export interface RouteStop {
    id?: string;
    address: string;
    addressAddition: string;
    streetNumber: string;
    postcode: string;
    city: string;
    latitude: string;
    longitude: string;
    note: string;
    name: string;
    email: string;
    phone: string;
    status: string;
    productTitle: string;
    productSku: string;
    driverId: string;
    priority: string;
    country: string;
    arrivalFrom: string;
    arrivalTo: string;
    trackingCode: string;
}

export interface Jobs {
    id?: string;
    title: string;
    start?: any;
    end?: any;
}

export interface Routes {
    id?: string;
    driverId: string;
    date?: any;
    status?: any;
}

export class ProntoDexie extends Dexie {
    // 'friends' is added by dexie when declaring the stores()
    // We just tell the typing system this is the case
    routeStops!: Table<RouteStop>;
    jobs!: Table<Jobs>;
    routes!: Table<Routes>;

    constructor() {
        super('testDatabase');
        this.version(6).stores({
            routeStops: 'id', // Primary key and indexed props
            jobs: 'id',
            routes: 'id'
        });
    }
}

export const db = new ProntoDexie();

