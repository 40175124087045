import React, { useEffect, useRef, useState } from 'react';
import { translate } from "../../../Helper/multilingual";
import Calendar from '../../../Components/Calendar';

import { ReactComponent as TruckIcon } from '../../../Assets/navigationIcons/logistics.svg';
import { ReactComponent as OntimeIcon } from '../../../Assets/navigationIcons/ontime.svg';
import { ReactComponent as DelayedIcon } from '../../../Assets/navigationIcons/delayed.svg';
import { ReactComponent as FailedIcon } from '../../../Assets/navigationIcons/failed.svg';
import AddIcon from '../../../Assets/add.svg';
import { Card, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Button from "../../../Components/Button";
import RouteUnit from "../../../Services/Http/Route";
import RouteStopUnit from "../../../Services/Http/RouteStop";
import { useDispatch, useSelector } from 'react-redux';
import StopGroupUnit from '../../../Services/Http/StopGroup';
import { setStopGroup } from '../../../Services/Redux/features/stopGroups/stopGroupsSlice';
import wrlog from '../../../Helper/functions/wrlog';
import CompanyUnit from '../../../Services/Http/Company';

interface Props {
    stopsForRoute: any,
    setMarkers: any,
    loadStopsLoading: boolean,
    detailLoading: any,
    calculateRoutes: any,
    createRoutesManually: any,
    drivers: any,
    driversForRoute: any;
    setDriversForRoute: any;
    amountRoutes: any;
    setAmountRoutes: any;
}


const SelectRoutes = (
    props: Props
) => {

    const {
        stopsForRoute,
        setMarkers,
        loadStopsLoading,
        detailLoading,
        calculateRoutes,
        createRoutesManually,
        drivers,
        driversForRoute,
        setDriversForRoute,
        amountRoutes,
        setAmountRoutes
    } = props;

    const [valid, setValid] = useState(true);

    useEffect(() => {
        wrlog("stopsForRoute", stopsForRoute);
    }, [stopsForRoute]);

    const newDriver = () => {
        let _drivers = [...driversForRoute];
        _drivers.push({});

        if (amountRoutes < _drivers.length) {
            setAmountRoutes(_drivers.length);
        }
        setDriversForRoute(_drivers);
    }

    const setDriver = (driverId) => {
        let _drivers = [...driversForRoute];

        const driverIndex = drivers.findIndex(d => d.id === driverId);
        _drivers[_drivers.length - 1] = drivers[driverIndex];
        setDriversForRoute(_drivers);
    }

    const removeDriver = (driverId: string) => {
        let _drivers = [...driversForRoute];

        const driverIndex = driversForRoute.findIndex(d => d.id === driverId);
        _drivers.splice(driverIndex, 1);
        setDriversForRoute(_drivers);

        if (_drivers.length + 1 === parseInt(amountRoutes)) {
            setAmountRoutes(_drivers.length);
        }
    }

    return (
        <div className='smallListContainer fullHeight' style={{ marginBottom: 100 }}>
            <h4>{stopsForRoute.length} {translate('stops')}</h4>
            {stopsForRoute.length > 0
                ? (loadStopsLoading ? detailLoading() : <>
                    <Grid container padding={0}>
                        <Grid item md={6} padding={0}>
                            <h4 style={{ marginTop: 10 }}>{translate('driver')}</h4>
                        </Grid>
                        <Grid item md={6} padding={0} className='align_right'>
                            <Button
                                type='tertiary'
                                icon={<img src={AddIcon} />}
                                label={translate('add')}
                                onClick={newDriver}
                                preventLoading={true}
                                className={(driversForRoute.length > 0 && driversForRoute[driversForRoute.length - 1].id === undefined) || driversForRoute.length >= drivers.length ? 'disabled' : undefined}
                            />
                        </Grid>
                    </Grid>
                    <div className={'smallListContainer'}>
                        {driversForRoute.map((driver: any, key: number) => {

                            if (driver.id === undefined) {
                                return <div
                                    // onClick={() => selectAddress(prediction)}
                                    className={'smallListItem white'}
                                >
                                    <FormControl variant="filled" style={{ marginBottom: -20 }}>
                                        <InputLabel>{translate('contractor')}</InputLabel>
                                        <Select
                                            label={translate('contractor')}
                                            onChange={e => setDriver(e.target.value)}
                                            style={{ marginBottom: '20px !important' }}
                                            defaultValue={'new'}
                                        >
                                            {
                                                drivers.filter(d => {
                                                    let used = false;

                                                    driversForRoute.forEach(driver => {
                                                        if (driver.id === d.id) {
                                                            used = true;
                                                        }
                                                    })

                                                    return !used;
                                                }).map(driver => <MenuItem value={driver.id}>{driver.firstName} {driver.lastName}</MenuItem>)
                                            }

                                        </Select>
                                    </FormControl>
                                </div>
                            }

                            return <div
                                // onClick={() => selectAddress(prediction)}
                                className={'smallListItem white'}
                            >
                                <strong>{driver.firstName} {driver.lastName}</strong>
                                <Button
                                    type="tertiary"
                                    className='delete'
                                    preventLoading
                                    label={translate('delete')}
                                    onClick={() => removeDriver(driver.id)}
                                    style={{ position: 'absolute', top: 0, right: 10 }}
                                />

                            </div>
                        })}
                    </div>

                    <strong>{translate('how_many_routes')}</strong>
                    <TextField
                        placeholder={translate('amount_routes')}
                        type="number"
                        className='extrasmall'
                        onChange={e => {
                            setAmountRoutes(e.target.value);
                        }}
                        value={amountRoutes}
                        InputProps={{ inputProps: { min: (driversForRoute.length >= 1 ? driversForRoute.length : 1) } }}
                    />

                    <div style={{ width: '100%', height: 200, display: 'block' }}></div>
                    {!valid &&
                        <div
                            style={{ position: 'fixed', bottom: 110, left: 20, width: 460, background: 'white', padding: 15, borderRadius: 15 }}
                        >
                            <strong style={{ color: 'red' }}>{translate('too_many_routes')}</strong>
                            <p style={{ color: 'red', marginBottom: 0 }}>{translate('too_many_routes_desc')}</p>
                        </div>
                    }
                    { }
                    <>
                        <Button
                            type={'primary'}
                            label={amountRoutes === 1 ? translate('calculate_route') : translate('calculate_routes')}
                            onClick={calculateRoutes}
                            className={amountRoutes > 0 && valid ? undefined : 'disabled'}
                            preventLoading
                            style={{ position: 'fixed', bottom: 70, left: 20, width: 460 }}
                        />
                        <div
                            style={{ position: 'fixed', bottom: 20, left: 20, width: 460, textAlign: 'center' }}
                        >
                            <Button
                                type={'tertiary'}
                                label={translate('create_route_manually')}
                                onClick={createRoutesManually}
                                className={amountRoutes > 0 ? undefined : 'disabled'}
                                preventLoading
                            />
                        </div>
                    </>

                </>)
                : <>
                    {translate('no_stops_selected_body')}
                    <Button
                        type={'primary'}
                        label={translate('create_empty_route')}
                        onClick={createRoutesManually}
                        preventLoading
                        style={{ position: 'fixed', bottom: 70, left: 20, width: 460 }}
                    />
                </>}
        </div>
    )
}

export default SelectRoutes;