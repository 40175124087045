import { FormControl, Input, InputAdornment, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../Helper/multilingual';
import { Link, useNavigate } from 'react-router-dom';
import BackgroundImage from '../../Assets/LogoistikApp_Cover.jpg';
import Logo from '../../Assets/Logo.png';
import EmailIcon from '../../Assets/formIcons/email.svg';
import PasswordIcon from '../../Assets/formIcons/password.svg';

import styles from './styles.module.scss';

import { Grid } from '@mui/material';
import Button from '../../Components/Button';
import UserUnit from '../../Services/Http/User';
import wrlog from '../../Helper/functions/wrlog';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../Services/Redux/features/messages/messageSlice';

interface ForgotPasswordFormProps {
    closePopup?: any;
}

/**
 * 
 * @returns Login Page
 */
const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {

    const ForgotPasswordButtonRef = useRef();

    const { closePopup } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();

    /**
     * Logs in the User
     * @param {object} data 
     * 
     * @returns void
     */
    const doForgotPassword = (data: any) => {

        let buttonRef = ForgotPasswordButtonRef.current || null;
        if (buttonRef !== null) {
            buttonRef.changeState('loading');
        }

        UserUnit.forgotPassword(data.email).then(res => {
            buttonRef.changeState('success');

            setTimeout(() => {
                closePopup();
                dispatch(addMessage({
                    type: 'success',
                    title: translate('forgot_password_success_title'),
                    body: translate('forgot_password_success_body')
                }));
            }, 1000)
        }).catch(err => {
            wrlog(err);
            buttonRef.changeState('error');
        })

    }

    return (
        <Grid container spacing={0}>
            <Grid item md={12} padding={0}>
                <h3>{translate('forgot_password')}</h3>
                <p>{translate('forgot_password_text')}</p>
                <form onSubmit={handleSubmit(doForgotPassword)}>
                    <TextField
                        {...register('email', { required: true })}
                        error={errors.email}
                        id="standard-basic"
                        label={<><img src={EmailIcon} /><div>{translate('email')}</div></>}
                        type="email"
                        className='hasIcon dark'
                    />
                    <Button
                        type='primary'
                        isInput
                        label={translate('request_recoverylink')}
                        ref={ForgotPasswordButtonRef}
                    />
                </form>
            </Grid>
        </Grid>
    );
}

export default ForgotPasswordForm;