import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { Grid, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import UserUnit from '../../../Services/Http/User';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../Services/Http/Company';
import wrlog from '../../../Helper/functions/wrlog';
import CustomSwitch from '../../../Components/Form/Switch';
import RoleUnit from '../../../Services/Http/Role';
import Storage from '../../../Services/Storage';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';

interface SettingsProps {
}

/**
 * 
 * @returns Login Page
 */
const CompanySettings = (props: SettingsProps) => {

    const [loading, setLoading] = useState(true);
    const { register, handleSubmit, setValue, getValues, formState: { errors, isValid } } = useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        loadCompany();
    }, [])

    const loadCompany = () => {

        CompanyUnit.getCompanySettings().then(settings => {
            if (settings !== undefined && settings !== null) {
                setValue('companyTitle', settings.companyTitle)
                setValue('companyContactPerson', settings.companyContactPerson)
                setValue('companyContactEmail', settings.companyContactEmail)
                setValue('companyContactPhone', settings.companyContactPhone)

                setValue('companyAddress', settings.companyAddress)
                setValue('companyCity', settings.companyCity)
                setValue('companyPostcode', settings.companyPostcode)
                setValue('companyCountry', settings.companyCountry)

                setValue('companyVat', settings.companyVat)
                setLoading(false);
            }
            wrlog(settings);
        }).catch((err: any) => {
            wrlog(err);
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
        })
    }

    const formChanged = () => {
        const formValues = getValues();
        let body = [];

        Object.keys(formValues).forEach(key => {
            body.push({
                key: key,
                value: formValues[key]
            })
        })

        CompanyUnit.setCompanySettings(body)
            .then(() => {

            }).catch(err => {
                dispatch(addMessage({
                    type: 'error',
                    title: err.title,
                    body: err.description,
                }))
            })
    }

    if (loading) {
        return <>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <div className={styles.icon}></div>
                </Grid>
                <Grid item md={8}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <strong>{translate('firstname')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={170} />
                        </Grid>
                        <Grid item md={6}>
                            <strong>{translate('lastname')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={150} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <strong>{translate('email')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={290} />
                        </Grid>
                        <Grid item md={6}>
                            <strong>{translate('phone_number')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={180} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    }

    return (
        <>
            <form>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={12}>
                        <h3>{translate('general_settings')}</h3>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={3} padding={0}>
            
                    </Grid>
                    <Grid item md={9} padding={0}>
                        <Grid container spacing={3} style={{ textAlign: 'center' }}>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('companyTitle', { required: true })}
                                    onBlur={formChanged}
                                    error={errors.companyTitle}
                                    id="standard-basic"
                                    label={translate('company_title')}
                                    type="text"
                                    className='dark'
                                />
                            </Grid>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('companyContactPerson', { required: true })}
                                    onBlur={formChanged}
                                    error={errors.companyContactPerson}
                                    id="standard-basic"
                                    label={translate('contact_person')}
                                    type="text"
                                    className='dark'
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ textAlign: 'center' }}>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('companyContactEmail', { required: true })}
                                    onBlur={formChanged}
                                    error={errors.companyContactEmail}
                                    id="standard-basic"
                                    label={translate('email')}
                                    type="email"
                                    className='dark'
                                />
                            </Grid>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('companyContactPhone', { required: true })}
                                    onBlur={formChanged}
                                    error={errors.companyContactPhone}
                                    id="standard-basic"
                                    label={translate('phone_number')}
                                    type="phone"
                                    className='dark'
                                />
                            </Grid>
                        </Grid>
                        <hr style={{ width: '100%', marginTop: 10 }} />
                        <Grid container spacing={3} style={{ textAlign: 'center' }}>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('companyAddress', { required: true })}
                                    onBlur={formChanged}
                                    error={errors.companyAddress}
                                    id="standard-basic"
                                    label={translate('street')}
                                    type="text"
                                    className='dark'
                                />
                            </Grid>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('companyPostcode', { required: true })}
                                    onBlur={formChanged}
                                    error={errors.companyPostcode}
                                    id="standard-basic"
                                    label={translate('postcode')}
                                    type="text"
                                    className='dark'
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ textAlign: 'center' }}>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('companyCity', { required: true })}
                                    onBlur={formChanged}
                                    error={errors.companyCity}
                                    id="standard-basic"
                                    label={translate('city')}
                                    type="text"
                                    className='dark'
                                />
                            </Grid>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('companyCountry', { required: true })}
                                    onBlur={formChanged}
                                    error={errors.companyCountry}
                                    id="standard-basic"
                                    label={translate('country')}
                                    type="text"
                                    className='dark'
                                />
                            </Grid>
                        </Grid>
                        <hr style={{ width: '100%', marginTop: 10 }} />
                        <Grid container spacing={3} style={{ textAlign: 'center' }}>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('companyVat', { required: true })}
                                    onBlur={formChanged}
                                    error={errors.companyVat}
                                    id="standard-basic"
                                    label={translate('vat')}
                                    type="text"
                                    className='dark'
                                />
                            </Grid>
                            <Grid item md={6} padding={0}></Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </form>
        </>
    );
}

export default CompanySettings;