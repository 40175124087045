import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { FileUploader } from "react-drag-drop-files";
import Papa from 'papaparse';
import * as XLSX from 'xlsx/xlsx.mjs';

import { translate } from '../../Helper/multilingual';
import Pagination from '../Pagination';
import Button from '../Button';
import { LinearProgress } from '@mui/material';

interface Props {
    progress: any,
}

const Progress = (props: Props) => {

    const { progress } = props;

    return <div style={{ textAlign: 'center' }}>
        <LinearProgress
            style={{ marginTop: 40 }}
            variant="determinate" value={progress} />
        <h4 style={{ marginTop: 20, marginBottom: 40 }}>{translate('upload_running')}</h4>
    </div>

}

export default Progress;