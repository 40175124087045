import React, { useEffect, stopGroupef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../../../Assets/Logo.png';
import AddIcon from '../../../../Assets/add.svg';

import styles from './styles.module.scss';

import { Grid, Skeleton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../../Services/Http/Company';
import wrlog from '../../../../Helper/functions/wrlog';
import Button from '../../../../Components/Button';
import Popup from '../../../../Components/Popup';
import AddStopGroupForm from './AddStopGroupForm';
import ListItem from '../../../../Components/ListItem';
import StopGroupUnit from '../../../../Services/Http/StopGroup';

interface Props {
    currentStopGroup: string;
    selectStopGroup: any;
    reload: any;
    setReload: any;
}

/**
 * 
 * @returns Login Page
 */
const WorkflowList = (props: Props) => {

    const [loading, setLoading] = useState(true);
    const [stopGroups, setGroups] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [addStopGroupPopupOpen, setAddStopGroupPopupOpen] = useState(false);

    const { selectStopGroup, currentStopGroup, reload, setReload } = props;

    const { register, handleSubmit, formState: { errors } } = useForm();

    const dispatch = useDispatch();
    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        loadGroups();
    }, [])

    useEffect(() => {
        if (reload) {
            loadGroups();
        }
    }, [reload])

    useEffect(() => {
        if (params.stopGroupId !== undefined) {
            setSelectedItem(params.stopGroupId)
        }
    }, [params])

    const loadGroups = () => {
        setLoading(true)
        StopGroupUnit.listSettings().then(res => {
            setLoading(false)
            setGroups(res);
        }).catch((err: any) => {
            setLoading(false)
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
        })
    }

    return (
        <>
            <Grid container style={{ marginBottom: 30 }}>
                <Grid item md={6}>
                    {/* <Button
                        type='tertiary'
                        icon={<img src={FilterIcon} />}
                        label={translate('filter')}
                        onClick={() => setFilterPopupOpen(true)}
                        preventLoading={true}
                    /> */}
                </Grid>
                <Grid item md={6} className='align_right'>
                    <Button
                        type='tertiary'
                        icon={<img src={AddIcon} />}
                        label={translate('add_workflow')}
                        onClick={() => setAddStopGroupPopupOpen(true)}
                        preventLoading={true}
                    />
                </Grid>
            </Grid>
            {
                loading ? <>
                    <Skeleton variant='rectangular' animation="wave" height={60} />
                    <Skeleton variant='rectangular' animation="wave" height={60} style={{ opacity: 0.8 }} />
                    <Skeleton variant='rectangular' animation="wave" height={60} style={{ opacity: 0.6 }} />
                    <Skeleton variant='rectangular' animation="wave" height={60} style={{ opacity: 0.4 }} />
                </>
                    :
                    stopGroups.length > 0 ? stopGroups.map((stopGroup: any, key: number) => {

                        return <ListItem
                            key={key}
                            title={stopGroup.title}
                            detail={''}
                            badges={[]}
                            selected={stopGroup.id === selectedItem}
                            onClick={() => navigate(`/settings/logistics/stopgroups/${stopGroup.id}`)}
                        />
                    })

                        : <h4>{translate('no_stop_workflows_found')}</h4>
            }
            <Button
                type='primary'
                label={translate('add_workflow')}
                onClick={() => setAddStopGroupPopupOpen(true)}
                preventLoading={true}
            />
            <Popup
                open={addStopGroupPopupOpen}
                close={() => setAddStopGroupPopupOpen(false)}
                style={{ maxWidth: 400 }}
            >
                <AddStopGroupForm
                    closePopup={() => setAddStopGroupPopupOpen(false)}
                    setReload={setReload}
                />
            </Popup>
        </>
    );
}


export default WorkflowList;