import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '../../../Assets/add.svg';

import { Grid, Skeleton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';
import ListItem from '../../../Components/ListItem';
import WarehouseUnit from '../../../Services/Http/Warehouse';
import AddWarehouseForm from './AddWarehouseForm';

interface Props {
    selectedWarehouse: string;
    selectWarehouse: any;
    reload: any;
    setReload: any;
}
const WarehouseList = (props: Props) => {

    const [loading, setLoading] = useState(true);
    const [warehouses, setWarehouses] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [addWarehousePopupOpen, setAddWarehousePopupOpen] = useState(false);
    const [filterPopupOpen, setFilterPopupOpen] = useState(false);
    const [filter, setFilter] = useState({
        search: '',
        status: '',
        role: '',
    });
    const [resendingInvitationIds, setResendingInvitationIds] = useState([]);

    const { selectWarehouse, selectedWarehouse, reload, setReload } = props;

    const { register, handleSubmit, formState: { errors } } = useForm();

    const dispatch = useDispatch();
    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        loadWarehouses();
    }, [filter])

    useEffect(() => {
        if (reload) {
            loadWarehouses();
        }
    }, [reload])


    const loadWarehouses = () => {
        setLoading(true)
        WarehouseUnit.list().then(res => {
            setLoading(false)
            setWarehouses(res);
        }).catch((err: any) => {
            setLoading(false)
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
        })
    }

    return (
        <>
            <Grid container style={{ marginBottom: 30 }}>
                <Grid item md={6}>

                </Grid>
                <Grid item md={6} className='align_right'>
                    <Button
                        type='tertiary'
                        icon={<img src={AddIcon} />}
                        label={translate('addWarehouse')}
                        onClick={() => setAddWarehousePopupOpen(true)}
                        preventLoading={true}
                    />
                </Grid>
            </Grid>
            {
                loading ? <>
                    <Skeleton variant='rectangular' animation="wave" height={60} />
                    <Skeleton variant='rectangular' animation="wave" height={60} style={{ opacity: 0.8 }} />
                    <Skeleton variant='rectangular' animation="wave" height={60} style={{ opacity: 0.6 }} />
                    <Skeleton variant='rectangular' animation="wave" height={60} style={{ opacity: 0.4 }} />
                </>
                    :
                    warehouses.length > 0 ? warehouses.map((warehouse: any, key: number) => {
                        return <ListItem
                            key={key}
                            title={warehouse.title || ''}
                            detail={warehouse.address ? `${warehouse.address} ${warehouse.streetNumber}, ${warehouse.postcode} ${warehouse.city}, ${warehouse.country}`: translate('missing_location')}
                            selected={warehouse.id === selectedWarehouse}
                            onClick={() => navigate(`/settings/warehouses/${warehouse.id}`)}
                        />
                    })

                        : <h4>{translate('no_warehouses_found')}</h4>
            }
            <Button
                type='primary'
                label={translate('addWarehouse')}
                onClick={() => setAddWarehousePopupOpen(true)}
                preventLoading={true}
            />
            <Popup
                open={addWarehousePopupOpen}
                close={() => setAddWarehousePopupOpen(false)}
                style={{ maxWidth: 400 }}
            >
                <AddWarehouseForm
                    closePopup={() => setAddWarehousePopupOpen(false)}
                    setReload={setReload}
                />
            </Popup>
        </>
    );
}


export default WarehouseList;