import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import UserUnit from '../../../Services/Http/User';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../Services/Http/Company';
import wrlog from '../../../Helper/functions/wrlog';
import CustomSwitch from '../../../Components/Form/Switch';
import RoleUnit from '../../../Services/Http/Role';
import Storage from '../../../Services/Storage';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';

import DeliveryGuyImage from '../../../Assets/placeholder_images/delivery_guy.jpg';

interface UsersDetailProps {
    currentUser: string;
    reload: any;
    setReload: any;
}

/**
 * 
 * @returns Login Page
 */
const UserDetail = (props: UsersDetailProps) => {

    const { reload, setReload } = props;

    const [userData, setUserData] = useState(null);
    const [roles, setRoles] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [askDeletePopupOpen, setAskDeletePopupOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        setLoading(true);
        if (params.userId !== undefined) {
            setCurrentUser(params.userId)
        } else {
            setLoading(false);
            setCurrentUser(null);
        }
    }, [params])

    useEffect(() => {
        wrlog("currentUser", currentUser);
        loadUser();
    }, [currentUser])


    useEffect(() => {
        let _roles = Storage.getValue('roles');

        if (_roles === null) {
            _roles = [];
        }
        setRoles(_roles);
    }, [])

    const loadUser = () => {
        if (currentUser === null) {
            setLoading(false);
            return;
        }

        CompanyUnit.getUserData(currentUser).then(res => {
            wrlog(res);
            setUserData(res);
            setLoading(false);
        }).catch((err: any) => {
            navigate(`/settings/users/`);
            wrlog(err);
            if (err.code !== 404) {
                dispatch(addMessage({
                    type: 'error',
                    title: err.title,
                    body: err.description,
                }))
            }
        })
    }

    const changeSwitch = (key: string, event: any) => {
        UserUnit.editRole(currentUser, key, event.target.checked)
    }

    const confirmDelete = () => {
        CompanyUnit.removeUser(currentUser).then((res) => {
            dispatch(addMessage({
                type: 'success',
                title: translate('delete_user_from_company_success_title'),
                body: translate('delete_user_from_company_success_body'),
            }))
            navigate(`/settings/users/`);
            setTimeout(() => {
                setAskDeletePopupOpen(false);
            }, 500)

            setReload(true);
            setTimeout(() => {
                setReload(false);
            }, 100)
        }).catch((err) => {
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
            setAskDeletePopupOpen(false);
        })
    }

    if (currentUser === null) {
        return <Grid container spacing={2} className={styles.detailContainer}>
            <Grid item md={12}>
                <img src={DeliveryGuyImage} style={{ maxHeight: '30vh', maxWidth: '50%' }} />
                <h3>{translate('choose_a_user')}</h3>
                <p>{translate('choose_a_user_text')}</p>
            </Grid>
        </Grid>
    }

    if (loading || userData === null) {
        return <>
            <Grid container spacing={2} className={styles.detailContainer}>
                <Grid item md={4}>
                    <div className={styles.icon}></div>
                </Grid>
                <Grid item md={8}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <strong>{translate('firstname')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={170} />
                        </Grid>
                        <Grid item md={6}>
                            <strong>{translate('lastname')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={150} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <strong>{translate('email')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={290} />
                        </Grid>
                        <Grid item md={6}>
                            <strong>{translate('phone_number')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={180} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    }

    return (
        <>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
                <Grid item md={12}>
                    <h3>{translate('profile')}</h3>
                </Grid>
            </Grid>
            <Grid container spacing={2} className={styles.detailContainer}>
                <Grid item md={4}>
                    <div className={styles.icon}>
                        {userData.firstName?.substring(0, 1)}
                        {userData.lastName?.substring(0, 1)}
                    </div>
                </Grid>
                <Grid item md={8}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <strong>{translate('firstname')}</strong><br />
                            {userData.firstName || translate('not_set')}
                        </Grid>
                        <Grid item md={6}>
                            <strong>{translate('lastname')}</strong><br />
                            {userData.lastName || translate('not_set')}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: 40, marginBottom: 40 }}>
                        <Grid item md={6}>
                            <strong>{translate('email')}</strong><br />
                            <a href={`mailto:${userData.email}`}>{userData.email}</a>
                        </Grid>
                        <Grid item md={6}>
                            <strong>{translate('phone_number')}</strong><br />
                            {userData.phone !== undefined ? <a href={`tel:${userData.phone}`}>{userData.phone}</a> : translate('not_set')}
                        </Grid>
                    </Grid>
                    <hr />
                    <h4>Rollen</h4>
                    <Grid container spacing={2}>
                        {
                            roles !== null ? roles.map((role: any) => {

                                let userHasRole = false;
                                userData.roles.forEach((userRole: any) => {
                                    if (userRole.id === role.id) {
                                        userHasRole = true;
                                    }
                                })

                                return <Grid item md={6}>
                                    <Grid container spacing={1} style={{ marginBottom: 20 }}>
                                        <Grid item xs={6}>
                                            <Typography>{translate(role.title)}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomSwitch onChange={(e) => changeSwitch(role.id, e)} defaultChecked={userHasRole} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            })
                                :
                                <>
                                    <Skeleton variant='rectangular' animation="wave" height={30} width={290} />
                                    <Skeleton variant='rectangular' animation="wave" height={30} width={290} />
                                </>
                        }
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: 50 }}>
                        <Grid item md={12}>
                            <Button
                                type={"tertiary"}
                                onClick={() => setAskDeletePopupOpen(true)}
                                label={translate('delete_user_from_company')}
                                className={'delete'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Popup
                    open={askDeletePopupOpen}
                    close={() => setAskDeletePopupOpen(false)}
                    style={{ width: 400 }}
                >
                    <h3>{translate('are_you_sure?')}</h3>
                    <p>{translate('are_you_sure_delete_user')}</p>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            type={"primary"}
                            onClick={confirmDelete}
                            label={translate('yes_delete')}
                            className={'delete'}
                        />
                        <br />
                        <Button
                            type={"tertiary"}
                            onClick={() => setAskDeletePopupOpen(false)}
                            label={translate('cancel')}
                        />
                    </div>

                </Popup>
            </Grid>
        </>
    );
}

export default UserDetail;