import { Checkbox, Menu } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import translatePriority from '../../Helper/functions/translatePriority';
import wrlog from '../../Helper/functions/wrlog';
import ContextMenu from './ContextMenu';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import { translate } from '../../Helper/multilingual';
import {
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';
import moment from 'moment';
import getStatusColor from '../../Helper/functions/getStatusColor';
import StatusSelect from '../Form/StatusSelect';
import Badge from '../Badge';

import CheckIcon from '../../Assets/statusIcons/success.svg'
import ErrorIcon from '../../Assets/statusIcons/error.svg'

interface Props {
    routeStop: any,
    selectRouteStop: any
    contextMenu?: any,
    sortable?: boolean,
    index?: number,
    selectable?: boolean;
    selected?: boolean;
    setSelected?: any;
    color?: string;
}

const RouteStopListItem = (
    props: Props
) => {

    const {
        routeStop,
        contextMenu,
        sortable,
        selectRouteStop,
        index,
        selectable,
        selected,
        setSelected,
        color
    } = props;

    const DragHandle = SortableHandle(() => <Tooltip title={translate('sort_by_drag')}>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            // onClick={handleContectMenuClick}
            style={{ position: 'absolute', top: 'calc(50% - 10px)', right: 60 }}
        >
            <DehazeIcon />
        </IconButton>
    </Tooltip>);

    const Element = () => <div
        className='smallListItem cuttext'
        onClick={(e) => {
            if (selectable) {
                setSelected(routeStop.id)
            } else {
                selectRouteStop(routeStop, e);
            }

        }}
        style={{ display: 'flex', alignItems: 'center' }}
    >
        {selectable
            && <div>
                <Checkbox
                    onChange={e => {
                        setSelected(routeStop.id)
                    }}
                    checked={selected}
                    defaultChecked={selected}
                />
            </div>
        }
        <div>
            <div style={{ display: 'flex', alignItems: 'center', height: 30 }}>
                <div style={{
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    backgroundColor: color,
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'center',
                    fontWeight: 700,
                    marginRight: 10
                }}><small>{index + 1}</small></div>
                <div><strong>{routeStop.address} {routeStop.streetNumber}</strong></div>
            </div>
            {routeStop.postcode} {routeStop.city}<br />
            {
                routeStop.est_arrival !== null && routeStop.est_leave !== null
                && <>
                    <strong>{moment(new Date(routeStop.arrival)).format('HH:mm')} - {moment(new Date(routeStop.leave)).format('HH:mm')}</strong>
                    <br /></>
            }
            {routeStop.priority > 0 && <div className='badge'>{translatePriority(routeStop.priority)}</div>}
            {routeStop?.tags?.length > 0 &&
                routeStop?.tags?.map((tag: string, key: number) => (
                    <div key={key} className='badge'>{tag}</div>
                ))
            }
            {['failed', 'success', 'problem'].includes(routeStop.status)
                && routeStop.attemptDateTime
                ? <Badge
                    label={moment(new Date(routeStop.attemptDateTime)).format('HH:mm')}
                    icon={routeStop.status === 'success' ? CheckIcon : ErrorIcon}
                    small
                    state={routeStop.status}
                    style={{ position: 'absolute', top: 5, right: 5 }}
                />
                :
                <Badge
                    label={translate(`delivery_status_${routeStop.status}`)}
                    // icon={CheckIcon}
                    small
                    state={routeStop.status === 'assigned_to_route' ? 'grey' : 'yellow'}
                    style={{ position: 'absolute', top: 5, right: 5 }}
                />
            }
            {
                !selectable && contextMenu && contextMenu.length > 0 && <ContextMenu
                    style={{ marginTop: 10 }}
                    contextMenu={contextMenu}
                />
            }

            {
                sortable && !selectable && <DragHandle />
            }
        </div>
    </div>

    if (sortable) {

        const SortableItem = SortableElement(() => (
            <Element />
        ));


        return <SortableItem index={index} key={`item-${routeStop.id}`} />
    }

    return <Element />
}

export default RouteStopListItem