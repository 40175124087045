import { Cancel, Tag } from "@mui/icons-material";
import { FormControl, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { translate } from "../../../Helper/multilingual";
import styles from './styles.module.scss';

const Tags = ({ data, handleDelete }) => {
    return (
        <Box
            sx={{
                background: "#A3D7F5",
                height: "35px",
                display: "flex",
                padding: "5px 10px",
                margin: "0 0.5rem 0 0",
                justifyContent: "center",
                borderRadius: 5,
                alignContent: "center",
                color: "#ffffff",
            }}
        >
            <Stack direction='row' gap={1}>
                <Typography>{data}</Typography>
                <Cancel
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        handleDelete(data);
                    }}
                />
            </Stack>
        </Box>
    );
};

export default function InputTags({ onChange, value, title, disabled }) {

    let defaultValue = [];
    if (typeof value === 'object') {
        defaultValue = value
    }

    let _title = 'tags';
    if (title !== undefined && title !== null) {
        _title = title;
    }

    const [tags, SetTags] = useState(defaultValue);
    const [isInit, setIsInit] = useState(true);
    const tagRef = useRef();

    useEffect(() => {
        if (!isInit) {
            onChange(tags);
        }
        setIsInit(false);
    }, [tags])

    useEffect(() => {
        if (!isInit) {
            onChange(tags);
        }
        setIsInit(false);
    }, [])

    const handleDelete = (value) => {
        const newtags = tags.filter((val) => val !== value);
        SetTags(newtags);
    };
    const handleOnSubmit = (e) => {
        e.preventDefault();
        let value = tagRef.current.value

        if (value.includes(',')) {
            value = value.split(',').filter(v => v.trim() !== '').map(v => v.trim());
        } else {
            value = [value.trim()];
        }

        SetTags([...tags, ...value]);
        tagRef.current.value = "";
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleOnSubmit}>
                <TextField
                    inputRef={tagRef}
                    id='standard-basic'
                    size='small'
                    style={{ minWidth: 100 }}
                    sx={{ margin: "1rem 0" }}
                    label={translate(_title)}
                    className={`${styles.input} dark`}
                    margin='none'
                    disabled={disabled || false}
                    onChange={e => {
                        if (e.target.value.includes(',')) {
                            handleOnSubmit(e);
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <Box sx={{ marginTop: '30px', marginBottom: 1, display: "flex" }}>
                                {tags.map((data, index) => {
                                    return (
                                        <Tags data={data} handleDelete={handleDelete} key={index} />
                                    );
                                })}
                            </Box>
                        ),
                    }}
                />
            </form>
        </Box>
    );
}