import React, { useEffect, useRef, useState } from 'react';
import { translate } from "../../../Helper/multilingual";
import Calendar from '../../../Components/Calendar';

import { ReactComponent as TruckIcon } from '../../../Assets/navigationIcons/logistics.svg';
import { ReactComponent as OntimeIcon } from '../../../Assets/navigationIcons/ontime.svg';
import { ReactComponent as DelayedIcon } from '../../../Assets/navigationIcons/delayed.svg';
import { ReactComponent as FailedIcon } from '../../../Assets/navigationIcons/failed.svg';
import AddIcon from '../../../Assets/add.svg';
import { Card, Checkbox, Grid, TextField } from "@mui/material";
import Button from "../../../Components/Button";
import RouteUnit from "../../../Services/Http/Route";
import RouteStopUnit from "../../../Services/Http/RouteStop";
import { useDispatch, useSelector } from 'react-redux';
import StopGroupUnit from '../../../Services/Http/StopGroup';
import { setRouteStopData } from '../../../Services/Redux/features/routeStop/routeStopSlice';
import { setStopGroup } from '../../../Services/Redux/features/stopGroups/stopGroupsSlice';
import wrlog from '../../../Helper/functions/wrlog';
import AddRouteChooseStops from './addRouteChooseStops';
import SelectRoutes from './addRouteSelectRoutes';

interface Props {
    setIsAddRoute: any,
    loadRoutes: any,
    detailLoading: any,
    setSelectedDate: any,
    setMarkers: any,
    setShowDetail: any;
    calculateRoutes: any;
    createRoutesManually: any;
    drivers: any;
    setDrivers: any;
    stopsForRoute: any;
    setStopsForRoute: any;
    driversForRoute: any;
    setDriversForRoute: any;
    amountRoutes: any;
    setAmountRoutes: any;
}


const AddRouteSidebar = (
    props: Props
) => {

    const {
        setIsAddRoute,
        loadRoutes,
        detailLoading,
        setSelectedDate,
        stopsForRoute,
        setStopsForRoute,
        setMarkers,
        setShowDetail,
        calculateRoutes,
        createRoutesManually,
        drivers,
        driversForRoute,
        setDriversForRoute,
        amountRoutes,
        setAmountRoutes
    } = props;

    const [availableGroups, setAvailableGroups] = useState([]);
    const [availableStops, setAvailableStops] = useState([]);
    const [currentGroup, setCurrentGroup] = useState({});
    const [showStopIds, setShowStopIds] = useState([]);
    const [routeStopLoading, setRouteStopLoading] = useState([]);

    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedStops, setSelectedStops] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);

    const [loadStopsLoading, setLoadStopsLoading] = useState(false);


    const dispatch = useDispatch();

    const routeStops = useSelector(state => state.routeStop);

    useEffect(() => {
        RouteUnit.getAvailableGroups().then(res => {
            setAvailableGroups(res);
        });
        RouteStopUnit.listSolo().then(res => {
            setAvailableStops(res);
        });
    }, [])

    const loadRouteStops = (id: string) => {
        setRouteStopLoading(true);
        StopGroupUnit.listAvailableStops(id).then((res: any) => {
            dispatch(setStopGroup(res));
            let stopIds = res.stops.map((stop: any) => {
                dispatch(setRouteStopData(stop));

                return stop.id;
            });
            setShowStopIds(stopIds)
            dispatch(setStopGroup(res));
            setRouteStopLoading(false);

        }).catch((err: any) => {
            wrlog(err)
        })
    }

    const selectStop = (stopId, checked) => {
        let _selectedStops = [...selectedStops];
        wrlog(stopId);

        if (checked) {
            _selectedStops.push(stopId);
        } else {
            let index = _selectedStops.findIndex(s => s.id === stopId);
            _selectedStops.splice(index, 1);
        }
        wrlog(_selectedStops);

        setSelectedStops(_selectedStops);
    }

    const selectGroup = (groupId, checked) => {
        let _selectedGroups = [...selectedGroups];

        if (checked) {
            _selectedGroups.push(groupId);
        } else {
            let index = _selectedGroups.findIndex(g => g.id === groupId);
            _selectedGroups.splice(index, 1);
        }

        setSelectedGroups(_selectedGroups);
    }

    const loadStopCoordinates = async () => {
        setLoadStopsLoading(true);

        let stops = selectedStops.map((stop: any) => {
            return {
                id: stop.id,
                longitude: stop.longitude,
                latitude: stop.latitude
            }
        });

        await Promise.all(selectedGroups.map(async (groupId: any) => {
            let res = await StopGroupUnit.listAvailableStops(groupId);
            let _stops = res.stops.map((stop: any) => {
                return {
                    id: stop.id,
                    longitude: stop.longitude,
                    latitude: stop.latitude
                }
            });
            stops = [...stops, ..._stops];
        }))

        setStopsForRoute(stops);
        setLoadStopsLoading(false);
        setMarkers(stops);
    }

    useEffect(() => {
        if (currentPage === 1) {
            loadStopCoordinates();
            setShowDetail(true);
        } else {
            setShowDetail(false);
        }
    }, [currentPage])

    return (
        <>
            <Card style={{ marginBottom: 30 }}>
                <Grid container padding={0}>
                    <Grid item md={6} padding={0}>
                        <h4 style={{ marginBottom: 0, marginLeft: 5 }}>{translate('create_route')}</h4>
                    </Grid>
                    <Grid item md={6} padding={0} className='align_right'>
                        {
                            currentPage === 0 ?
                                <Button
                                    type='tertiary'
                                    label={translate('cancel')}
                                    onClick={() => setIsAddRoute(false)}
                                    preventLoading={true}
                                />
                                : <Button
                                    type='tertiary'
                                    label={translate('back')}
                                    onClick={() => setCurrentPage(prev => prev - 1)}
                                    preventLoading={true}
                                />
                        }
                    </Grid>
                </Grid>
                <Grid container padding={0}>
                    <Grid item md={12} padding={0}>
                        <div style={{ width: '100%' }}>
                            <Calendar
                                onChange={setSelectedDate}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Card>
            {
                currentPage === 0 && <AddRouteChooseStops
                    selectGroup={selectGroup}
                    selectStop={selectStop}
                    availableGroups={availableGroups}
                    availableStops={availableStops}
                    showStopIds={showStopIds}
                    routeStopLoading={routeStopLoading}
                    detailLoading={detailLoading}
                    loadRouteStops={loadRouteStops}
                    setCurrentPage={setCurrentPage}
                    stopsSelected={selectedGroups.length > 0 || selectedStops.length > 0}
                />
            }
            {
                currentPage === 1 && <SelectRoutes
                    stopsForRoute={stopsForRoute}
                    setMarkers={setMarkers}
                    loadStopsLoading={loadStopsLoading}
                    detailLoading={detailLoading}
                    calculateRoutes={calculateRoutes}
                    createRoutesManually={createRoutesManually}
                    drivers={drivers}
                    driversForRoute={driversForRoute}
                    setDriversForRoute={setDriversForRoute}
                    amountRoutes={amountRoutes}
                    setAmountRoutes={setAmountRoutes}
                />
            }
        </>

    )
}

export default AddRouteSidebar;