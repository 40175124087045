import React from 'react'
import { translate } from '../../Helper/multilingual';
import Badge from '../Badge';
import styles from './styles.module.scss'

const ListItem = (
    {
        title,
        detail,
        badges = [],
        selected,
        onClick,
        loading = false,
        icon = null,
        sideImage = null,
        badgeInline = true
    }
) => (
    <div className={`${styles.listContainer} ${selected ? styles.selected : undefined}`} onClick={onClick}>
        <div className={`${styles.inner}`}>
            <div className={`${styles.titleContainer} ${badgeInline ? styles.inline : undefined}`}>
                {icon}
                {badgeInline
                    ? <>
                        <div className={styles.title}>
                            <h4>{title}</h4>
                        </div>
                        {
                            badges.map((badge: any, key: number) => (
                                <Badge
                                    key={key}
                                    label={badge.title}
                                    icon={badge.icon}
                                    small
                                    state={badge.color}
                                />
                            ))
                        }
                    </>
                    : <>
                        {
                            badges.map((badge: any, key: number) => (
                                <Badge
                                    key={key}
                                    label={badge.title}
                                    icon={badge.icon}
                                    small
                                    state={badge.color}
                                    style={{
                                        marginLeft: 0,
                                        marginRight: 5,
                                    }}
                                />
                            ))
                        }
                        <div className={styles.title}>
                            <h4>{title}</h4>
                        </div>
                    </>
                }
            </div>
            {
                detail !== ''
                && <div className={`${styles.detail} ${loading ? 'disabled' : undefined}`}>
                    {detail}
                </div>
            }
        </div>
        {
            sideImage
            && <div className={`${styles.sideImageContainer}`}>
                <img src={sideImage}
                    className={`${styles.sideImage}`}
                />
            </div>
        }
    </div>
)

export default ListItem;