import wrlog from '../../Helper/functions/wrlog';
import Storage from '../Storage';
import Unit from './index';

export default class CompanyUnit extends Unit {

    static listUsers = async (search: string, status: string, role: string, skip: number, take: number) => {

        const companyId = Storage.getValue('currentCompany');

        let body = {
            companyId: companyId,
            filters: {
                search: search,
                status: status,
                role: role,
            },
            skip: skip,
            take: take,
        };
        return await Unit.post('/company/users', body);
    }

    static getUserData = async (companyUserId: string) => {
        return await Unit.post('/company/user', { userId: companyUserId });
    }

    static removeUser = async (userId: string) => {
        return await Unit.post('/user/disconnect', { userId: userId });
    }

    static getCompanySettings = async () => {
        return await Unit.get('/company/settings');
    }

    static getCompanySettingsKeys = async () => {
        return await Unit.get('/company/settingkeys');
    }

    static setCompanySettings = async (settings: any) => {
        return await Unit.post('/company/settings', { settings: settings });
    }


    /* API */
    static getApiClients = async () => {
        return await Unit.get('/company/api');
    }

    static createApiClient = async (permissions: any) => {
        return await Unit.post('/company/api', { permissions: permissions });
    }

    static revokeApiClient = async (clientKey) => {
        return await Unit.delete(`/company/api/${clientKey}`);
    }
}



