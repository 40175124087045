import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { Grid, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import UserUnit from '../../../Services/Http/User';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../Services/Http/Company';
import wrlog from '../../../Helper/functions/wrlog';
import CustomSwitch from '../../../Components/Form/Switch';
import RoleUnit from '../../../Services/Http/Role';
import Storage from '../../../Services/Storage';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';

interface ProfileProps {
}

/**
 * 
 * @returns Login Page
 */
const ChangePassword = (props: ProfileProps) => {

    const saveButtonRef = useRef();

    const [companyData, setCompanyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { register, handleSubmit, setValue, getValues, formState: { errors, isValid } } = useForm();

    const dispatch = useDispatch();

    const changePassword = (data: any) => {
        let buttonRef = saveButtonRef.current || null;
        if (buttonRef !== null) {
            buttonRef.changeState('loading');
        }

        UserUnit.changePassword(data.password).then(res => {
            buttonRef.changeState('success');
            dispatch(addMessage({
                type: 'success',
                title: translate('successfully_changed_password_title'),
                body: translate('successfully_changed_password_description')
            }))
        }).catch(err => {
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description
            }))
        })
    }

    return (
        <>
            <form onSubmit={handleSubmit(changePassword)}>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={12}>
                        <h3>{translate('change_password')}</h3>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={3} padding={0}>

                    </Grid>
                    <Grid item md={9} padding={0}>
                        <Grid container spacing={3} style={{ textAlign: 'center' }}>
                            <Grid item md={12} padding={0}>
                                <TextField
                                    {...register('password', { required: true })}
                                    error={errors.password}
                                    id="standard-basic"
                                    label={translate('password')}
                                    type="password"
                                    className='dark'
                                />

                                <Button
                                    type='primary'
                                    isInput
                                    label={translate('change_password')}
                                    ref={saveButtonRef}
                                />

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default ChangePassword;