import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { Card, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../../../Logistics/Sidebar';
import Map, { calculateClusterBounds } from '../../../../Components/Map';
import Button from '../../../../Components/Button';
import wrlog from '../../../../Helper/functions/wrlog';
import ImportsUnit from '../../../../Services/Http/Imports';
import { cloneArray } from '../../../../Helper/functions/cluster';
import axios from 'axios';
import { API_ENDPOINT } from '../../../../config'; 
import moment from 'moment';
import Calendars from '../../../../Components/Calendar';


/**
 * 
 * @returns Login Page
 */
const Clustering = () => {
    const { importId } = useParams();

    const [hasClusters, setHasClusters] = useState(false);
    const [runningStep, setRunningStep] = useState(null);
    const [routesCreated, setRoutesCreated] = useState(false);
    const [showDetails, setShowDetails] = useState(null);
    const [settings, setSettings] = useState({
        amountClusters: 30,
        deviation: 0.15,
        useDatePredictions: true,
        startDate: getNextMonday()
    });

    const [clusterLength, setClusterLength] = useState(0);
    const [stopAmount, setStopAmount] = useState(0);



    const deviations = [
        {
            id: 0.05, // 5%+-
            title: translate('deviation.0.05')
        },
        {
            id: 0.1, // 10%+-
            title: translate('deviation.0.1')
        },
        {
            id: 0.15, // 15%+-
            title: translate('deviation.0.15')
        },
        {
            id: 0.3, // 30%+-
            title: translate('deviation.0.3')
        },
        {
            id: 0.4, // 40%+-
            title: translate('deviation.0.4')
        }
    ]


    const navigate = useNavigate();

    const buttonClusteringRef = useRef();
    const buttonCreateRouteRef = useRef();
    const mapRef = useRef();

    useEffect(() => {
        if (importId) {
            loadResults();
        }
    }, [importId]);

    const lookForResults = async () => {

        const interval = setInterval(async () => {
            const { clusters } = await ImportsUnit.getClustering(importId);
            if (clusters) {
                buttonClusteringRef.current.changeState('success');
                clearInterval(interval);
                setHasClusters(true);
                setRunningStep(null);

                if (clusters.length > 0) {
                    let stopCount = 0;
                    clusters.forEach(cluster => {
                        stopCount += cluster.length;
                    });

                    setStopAmount(stopCount);
                    setClusterLength(clusters.length)
                    const bounds = calculateClusterBounds(clusters);
                    mapRef.current.flyToBounds(bounds)
                    mapRef.current.setClusters(clusters);
                }
            }

        }, 10000);


    }

    const loadResults = async () => {
        try {
            const { clusters, deviation, amountClusters, routesCreated: _routesCreated } = await ImportsUnit.getClustering(importId);

            if (clusters) {
                setHasClusters(true);

                if (clusters.length > 0) {
                    let stopCount = 0;
                    clusters.forEach(cluster => {
                        stopCount += cluster.length;
                    });

                    setStopAmount(stopCount);
                    setClusterLength(clusters.length)
                    const bounds = calculateClusterBounds(clusters);
                    mapRef.current.flyToBounds(bounds)
                    mapRef.current.setClusters(clusters);
                }
            }

            if (deviation) {
                setSetting('deviation', deviation);
            }

            if (amountClusters) {
                setSetting('amountClusters', amountClusters);
            }

            setRoutesCreated(_routesCreated)

        } catch (err) {
            console.log(err);
            navigate('/settings/logistics/imports')
        }
    }

    const setSetting = (key, value) => {
        if (key === 'amountClusters') {
            if (value !== '') {
                if (isNaN(parseInt(value))) {
                    value = '';
                } else {
                    value = parseInt(value);
                }
            }
        }
        const _settings = cloneArray(settings);
        _settings[key] = value;
        setSettings(_settings);
    }

    const setSelectedDate = (date) => {
        setSetting('startDate', date)
    }

    const runClustering = async () => {
        buttonClusteringRef.current.changeState('loading');
        setRunningStep('clustering');
        setClusterLength(0)

        const body = {
            amountClusters: settings.amountClusters,
            deviation: settings.deviation
        }

        try {
            await ImportsUnit.runClustering(importId, body);
            mapRef.current.setClusters([]);
            lookForResults();
        } catch (err) {
            buttonClusteringRef.current.changeState('error');
        }
    }

    const createRoutes = async () => {
        buttonCreateRouteRef.current.changeState('loading');
        setRunningStep('createRoutes');

        const body = {
            useDatePredictions: settings.useDatePredictions,
            startDate: moment(settings.startDate).format('YYYY-MM-DD')
        }
        try {
            await ImportsUnit.createRoutes(importId, body);
            buttonCreateRouteRef.current.changeState('success');
        } catch (err) {
            wrlog(err);
            buttonCreateRouteRef.current.changeState('error');
        }
        setRunningStep(null);
    }

    return (
        <>
            <Sidebar>
                <div
                    className={styles.line}
                >
                    <Card
                        style={{
                            marginBottom: 50
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <div className={`${styles.status} ${hasClusters ? styles.success : styles.todo}`}></div>
                                <div>
                                    <h4 style={{ marginBottom: 0 }}>{translate('cluster.startClustering')}</h4>
                                    <Button
                                        onClick={() => showDetails === 'clustering' ? setShowDetails(null) : setShowDetails('clustering')}
                                        label={showDetails === 'clustering' ? translate('cluster.closeSettings') : translate('cluster.openSettings')}
                                        type={'small'}
                                        preventLoading
                                        disabled={routesCreated}
                                        className={(runningStep !== null || routesCreated) ? 'disabled' : undefined}
                                    />
                                </div>
                            </div>
                            <Button
                                onClick={runClustering}
                                label={runningStep === 'clustering' ? translate('stepRunning') : translate('stepStart')}
                                type={'secondary'}
                                ref={buttonClusteringRef}
                                disabled={settings.amountClusters < 1 || routesCreated}
                                className={(runningStep === 'createRoutes' || settings.amountClusters < 1 || routesCreated) ? 'disabled' : undefined}
                            />
                        </div>
                        {showDetails === 'clustering'
                            && <div style={{ marginTop: 20 }}>
                                <TextField
                                    id="standard-basic"
                                    onChange={e => setSetting('amountClusters', e.target.value)}
                                    label={translate('cluster.amountClusters')}
                                    type="text"
                                    className='dark'
                                    value={settings.amountClusters}
                                    disabled={runningStep !== null}
                                />
                                <FormControl variant="filled" style={{ marginBottom: 20 }}>
                                    <InputLabel>{translate('cluster.deviation')}</InputLabel>
                                    <Select
                                        defaultValue={settings.deviation}
                                        onChange={e => setSetting('deviation', e.target.value)}
                                    >
                                        {
                                            deviations?.map((option, key) => {
                                                return <MenuItem key={key} value={option.id}>{option.title}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        }
                        {
                            clusterLength > 0
                            && <small><strong>{translate('cluster.clusteringResultBody', { amount: clusterLength, stopAmount })}<br /></strong></small>
                        }
                        {
                            settings.amountClusters > -1
                                ? <small>{translate('cluster.startClusteringBody', { amount: settings.amountClusters, deviation: translate(`deviation.${settings.deviation}`) })}</small>
                                : <small>{translate('cluster.startClusteringBodyOpen', { deviation: translate(`deviation.${settings.deviation}`) })}</small>
                        }
                    </Card>
                    <Card>
                        <div style={{
                            opacity: (!hasClusters ? 0.6 : 1)
                        }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <div className={`${styles.status} ${!routesCreated ? styles.todo : styles.success}`}></div>
                                <div>
                                    <h4 style={{ marginBottom: 0 }}>{translate('createRoutes')}</h4>
                                    <Button
                                        onClick={() => showDetails === 'createRoutes' ? setShowDetails(null) : setShowDetails('createRoutes')}
                                        label={showDetails === 'createRoutes' ? translate('cluster.closeSettings') : translate('cluster.openSettings')}
                                        type={'small'}
                                        preventLoading
                                        disabled={runningStep !== null || routesCreated}
                                        className={(!hasClusters || routesCreated) ? 'disabled' : undefined}
                                    />
                                </div>
                            </div>
                            <p>{translate('clusteringCreateRoutesDescription')}</p>
                            {showDetails === 'createRoutes'
                                && <div style={{ marginTop: 20 }}>
                                    <label>
                                        <Checkbox
                                            onChange={e => setSetting('useDatePredictions', e.target.checked)}
                                            checked={settings.useDatePredictions}
                                            defaultChecked={settings.useDatePredictions}
                                        />
                                        Intelligente Zuweisung der Liefertage
                                    </label>

                                    <Calendars
                                        onChange={setSelectedDate}
                                        initialValue={settings.startDate}
                                        weekly={settings.useDatePredictions}
                                        onlyFuture
                                    />
                                </div>
                            }
                            {
                                settings.useDatePredictions
                                    ? <small>
                                        {translate('cluster.intelligentPrediction', {
                                            from: moment(settings.startDate).format('DD.MM'),
                                            to: moment(settings.startDate).add(6, 'd').format('DD.MM')
                                        })}
                                    </small>
                                    : <small>{translate('cluster.onDay', { date: moment(settings.startDate).format('DD.MM') })}</small>
                            }

                            <Button
                                onClick={createRoutes}
                                label={runningStep === 'createRoutes' ? translate('stepRunning') : translate('stepStart')}
                                type={'primary'}
                                ref={buttonCreateRouteRef}
                                style={{ marginTop: 20 }}
                                disabled={!hasClusters || (runningStep !== null) || routesCreated}
                                className={!hasClusters || (runningStep !== null) || routesCreated ? 'disabled' : undefined}
                            />
                            <small>{translate('canTakeAWhile')}</small>
                        </div>
                    </Card>
                </div>
            </Sidebar>
            <div className='mapContainer'>
                <Map
                    ref={mapRef}
                    markers={[]}
                    routes={[]}
                    drivers={[]}
                />
            </div>

        </>
    );
}

const getNextMonday = (startDate = new Date()) => {
    let day = startDate.getDay();
    if (day === 0) day = 7;

    const daysToNextMonday = 7 - (day - 1);
    startDate.setDate(startDate.getDate() + daysToNextMonday);
    return startDate;
}

export default Clustering;

export { getNextMonday }