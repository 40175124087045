import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../../Assets/Logo.png';

import styles from './styles.module.scss';

import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import WarehouseList from './WarehouseList';
import WarehouseDetail from './WarehouseDetail';

interface Props {
}

/**
 * 
 * @returns Login Page
 */
const Warehouses = (props: Props) => {

    const LoginButtonRef = useRef();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [currentWarehouse, setCurrentWarehouse] = useState(null) as any;
    const [reload, setReload] = useState(null);

    const dispatch = useDispatch();

    const params = useParams();

    return (
        <>
            <Sidebar />
            <div className='middleContainer'>
                <Grid container spacing={0}>
                    <Grid item md={12} padding={4}>
                        <h3>{translate('warehouses')}</h3>
                        <WarehouseList
                            selectedWarehouse={currentWarehouse}
                            selectWarehouse={setCurrentWarehouse}
                            reload={reload}
                            setReload={setReload}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className='rightContainer'>
                <Grid container spacing={0}>
                    <Grid item md={12} padding={4}>
                        <WarehouseDetail
                            selectedWarehouse={currentWarehouse}
                            reload={reload}
                            setReload={setReload}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default Warehouses;