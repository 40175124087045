import { TextField, Autocomplete, CircularProgress, FormControl, InputLabel, Select, MenuItem, createFilterOptions } from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { translate } from '../../../../Helper/multilingual';
import UsersIcon from '../../../../Assets/navigationIcons/users.svg';

import { Grid } from '@mui/material';
import Button from '../../../../Components/Button';
import UserUnit from '../../../../Services/Http/User';
import wrlog from '../../../../Helper/functions/wrlog';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../../Services/Redux/features/messages/messageSlice';
import Storage from '../../../../Services/Storage';
import StopGroupUnit from '../../../../Services/Http/StopGroup';

interface AddUserFormProps {
    closePopup?: any;
    setReload?: any;
}

/**
 * 
 * @returns Login Page
 */
const AddUserForm = (props: AddUserFormProps) => {

    const AddUserButtonRef = useRef();

    const filter = createFilterOptions();

    const { closePopup, setReload } = props;

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState(null);

    const [titleError, setTitleError] = useState(false);


    const { register, handleSubmit, formState: { errors }, control } = useForm();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (search.length > 2) {
            setLoading(true);
            UserUnit.searchByEmail(search).then((res) => {
                if (res.length > 0) {
                    setOptions(res.map((user: any) => ({ inputValue: user.email, title: user.email })));
                }
                setLoading(false);
            })
        }
    }, [search])

    const setTitle = (title: string) => {
        setSelectedTitle(title)
    }

    const create = () => {

        setTitleError(false);
        let valid = true;
        if (selectedTitle === '' || selectedTitle === null) {
            setTitleError(true);
            valid = false;
        }

        if (!valid) {
            return;
        }

        let buttonRef = AddUserButtonRef.current || null;
        if (buttonRef !== null) {
            buttonRef.changeState('loading');
        }

        StopGroupUnit.createSetting({ title: selectedTitle }).then(res => {

            buttonRef.changeState('success');

            navigate(`/settings/logistics/stopgroups/${res.id}`);
            setReload(true);
            setTimeout(() => {
                setReload(false);
            }, 100);
            setTimeout(() => {
                closePopup();
                dispatch(addMessage({
                    type: 'success',
                    title: translate('add_stopgroup_draft_success_title'),
                    body: translate('add_stopgroup_draft_success_body')
                }));
            }, 1000)

        }).catch((err: any) => {
            wrlog(err);
            buttonRef.changeState('error');
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description
            }));
        })
    }


    return (
        <Grid container spacing={0}>
            <Grid item md={12} padding={0}>
                <form onSubmit={handleSubmit(create)}>
                    <TextField
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                        label={translate('title')}
                        className='dark'
                        error={titleError}
                    />

                    <Button
                        type='primary'
                        isInput
                        label={translate('add_stop_group_draft')}
                        ref={AddUserButtonRef}
                    />
                </form>
            </Grid>
        </Grid >
    );
}

export default AddUserForm;