import Unit from './index';

export default class ParcelUnit extends Unit {

    static create = async (body: any) => {
        return await Unit.post(`/parcel`, body);
    }

    static update = async (body: any) => {
        return await Unit.put(`/parcel`, body);
    }

}



