export const possibleReasonOfFailure = [
    'not_met',
    'wrong_address',
    'didnt_accept_parcel',
    'parcel_not_found',
    'parcel_damaged'
];

export const possibleReasonOfFailureActions = [
    'call_dispatcher',
    'call_recipient',
    'photo',
];

export const reasonOfFailureOptionsDefault = {
    'not_met': [],
    'wrong_address': [],
    'goods_not_accepted': [],
    'parcel_not_found': [],
    'parcel_damaged': [],
    'no_entry_to_house': [],
}