import { translate } from "../../../Helper/multilingual";
import Calendar from '../../../Components/Calendar';

import { ReactComponent as TruckIcon } from '../../../Assets/navigationIcons/logistics.svg';
import { ReactComponent as OntimeIcon } from '../../../Assets/navigationIcons/ontime.svg';
import { ReactComponent as DelayedIcon } from '../../../Assets/navigationIcons/delayed.svg';
import { ReactComponent as FailedIcon } from '../../../Assets/navigationIcons/failed.svg';
import { ReactComponent as AddIconComp } from '../../../Assets/add.svg';
import AddIcon from '../../../Assets/add.svg';
import DownloadIcon from '../../../Assets/download.png';

import { Card, Checkbox, CircularProgress, Grid, TextField, Tooltip } from "@mui/material";
import Button from "../../../Components/Button";
import moment from "moment";
import RouteStopListItem from "../../../Components/RouteStopListItem";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import {
    sortableContainer,
} from 'react-sortable-hoc';
import { useEffect, useRef, useState } from "react";
import wrlog from "../../../Helper/functions/wrlog";
import ContextMenu from "../../../Components/RouteStopListItem/ContextMenu";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import RouteUnit from "../../../Services/Http/Route";
import { Buffer } from "buffer";
import ReactExport from "react-export-excel-xlsx-fix";
import Popup from "../../../Components/Popup";
import ChangeAddressLocation from "../../../Components/ChangeAddressLocation/index.tsx";
import StatusSelect from "../../../Components/Form/StatusSelect";
import getStatusColor from "../../../Helper/functions/getStatusColor";
import { useDispatch, useSelector } from "react-redux";

import { setRoutesData } from '../../../Services/Redux/features/routes/routesSlice';
import Badge from "../../../Components/Badge";
import CheckIcon from '../../../Assets/statusIcons/success.svg'
import ErrorIcon from '../../../Assets/statusIcons/error.svg'
import { mapColor } from "../../../Components/Map";
import { setSearch } from "../../../Services/Redux/features/search/searchSlice";
import RouteStopUnit from "../../../Services/Http/RouteStop";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const RouteListSidebar = (
    {
        setIsAddRoute,
        filterStatus = 'all',
        setFilterStatus,
        setSelectedDate,
        selectedDate,
        filteredRoutes = [],
        allRoutes = [],
        currentRoute,
        selectRoute,
        routeStopLoading,
        routeLoading,
        detailLoading,
        // routeStops,
        showStopIds,
        selectRouteStop,
        setSelectDriverPopupOpen,
        expandRouteStops,
        setChangeDatePopupOpen,
        setAssignToDifferentRoutePopupOpen,
        setRemoveRouteStopPopupOpen,
        setChangedDate,
        onRouteStopSort,
        setRemoveRoutePopupOpen,
        currentStop,
        setRouteStopsToChange,
        changingRoute,
        setChangingRoute,
        optimize
    }
) => {

    const [loadingRoutes, setLoadingRoutes] = useState([]);
    const [csvData, setCsvData] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [changeAddressLocationPopupOpen, setChangeAddressLocationPopupOpen] = useState(false);
    const [changeTitlePopupOpen, setChangeTitlePopupOpen] = useState(false)
    const [routeTitle, setRouteTitle] = useState('')
    const [filteredRouteStops, setFilteredRouteStops] = useState([]);
    const [changingRouteStops, setChangingRouteStops] = useState([]);
    const [listRouteStopsPopupOpen, setListRouteStopsPopupOpen] = useState(false);
    const [soloRouteStops, setSoloRouteStops] = useState([]);
    const [selectedStops, setSelectedStops] = useState([]);
    const [routeCounts, setRouteCounts] = useState({ all: 0, open: 0, success: 0, failed: 0 });

    const downloadButtonRef = useRef();
    const changeTitleButtonRef = useRef() as any;
    const addToRouteButtonRef = useRef();


    const routes = useSelector(state => state.routes);
    const routeStops = useSelector(state => state.routeStops);
    const searchTerm = useSelector(state => state.search);
    const dispatch = useDispatch();

    useEffect(() => {
        setRouteTitle(currentRoute?.title);
    }, [currentRoute])

    useEffect(() => {

        let counts = { all: 0, open: 0, success: 0, failed: 0 };
        filteredRoutes.map((route: { count: any }) => {
            counts.all += route.count.sum;
            counts.open += route.count.open;
            counts.success += route.count.success;
            counts.failed += route.count.failed;
        })

        setRouteCounts(counts)

    }, [filteredRoutes])

    useEffect(() => {
        if (listRouteStopsPopupOpen) {
            RouteStopUnit.listSolo().then(res => {
                setSoloRouteStops(res);
            })
        }
    }, [listRouteStopsPopupOpen])

    useEffect(() => {
        let filteredStops = [...routeStops];

        if (searchTerm?.route && searchTerm?.route !== '') {
            let splittedSearchTerm = searchTerm?.route.split(' ').map((t: string) => t.toLowerCase()).filter((t: string) => t.length > 0);
            filteredStops = filteredStops?.filter((s: any) => {
                return (
                    splittedSearchTerm.some((t: string) => s?.address?.toString().toLowerCase().includes(t))
                    || splittedSearchTerm.some((t: string) => s?.streetNumber?.toString().toLowerCase().includes(t))
                    || splittedSearchTerm.some((t: string) => s?.postcode?.toString().toLowerCase().includes(t))
                    || splittedSearchTerm.some((t: string) => s?.city?.toString().toLowerCase().includes(t))
                    || splittedSearchTerm.some((t: string) => s?.name?.toString().toLowerCase().includes(t))
                    || splittedSearchTerm.some((t: string) => s?.tags?.includes(t))
                )
            })

            if (filteredStops.length < 1) {
                filteredStops = [...routeStops];
            }
        }
        setFilteredRouteStops(filteredStops);

    }, [searchTerm, routeStops])

    useEffect(() => {
        if (csvData !== null) {
            setTimeout(() => setCsvData(null), 1000)
        }
    }, [csvData])

    const downloadRouteList = () => {

        // downloadButtonRef.current.changeState('loading');

        let body = {
            date: moment(selectedDate).format('YYYY-MM-DD'),
            status: ""
        }

        RouteUnit.getCsvData(body).then(res => {
            // downloadButtonRef.current.changeState('success');
            setCsvData(res);
        }).catch(err => {
            // downloadButtonRef.current.changeState('error');
            setDownloading(false);
        })
    }

    const SortableContainer = sortableContainer(({ children }) => {
        return <div>{children}</div>;
    });

    const changeDate = (d) => {
        selectRoute(null, null);
        setSelectedDate(d);
    }

    const changeSelectedRouteStop = (routeStopId: string) => {
        let _changingRouteStops = [...changingRouteStops];
        if (_changingRouteStops.includes(routeStopId)) {
            const index = _changingRouteStops.findIndex((p: string) => p === routeStopId);
            _changingRouteStops.splice(index, 1);
        } else {
            _changingRouteStops.push(routeStopId);
        }
        setChangingRouteStops(_changingRouteStops);
    }

    const changeStatus = (status: string) => {
        if (currentRoute.id === undefined) {
            return;
        }
        RouteUnit.update(currentRoute.id, { status });

        const index = routes.findIndex(r => r.id === currentRoute.id)
        let routeCopy = { ...routes[index] };
        routeCopy.status = status;

        dispatch(setRoutesData(routeCopy));
    }

    const changeTitle = () => {
        if (currentRoute.id === undefined) {
            return;
        }

        let body = {
            title: routeTitle
        }

        RouteUnit.update(currentRoute.id, body).then(res => {
            changeTitleButtonRef.current.changeState('success');
            setTimeout(() => {
                const index = routes.findIndex((r: { id: string }) => r.id === currentRoute.id)
                let routeCopy = { ...routes[index] };
                routeCopy.title = routeTitle;
                dispatch(setRoutesData(routeCopy));
                setChangeTitlePopupOpen(false);
            }, 500)
        }).catch(err => {
            changeTitleButtonRef.current.changeState('error');
        })
    }

    const downloadPackingList = async (routeId: string) => {
        setLoadingRoutes(prev => ([...prev, routeId]));
        try {
            const { base64, fileName } = await RouteUnit.downloadPackingList(routeId)

            const url = window.URL.createObjectURL(new Blob([Buffer.from(base64, 'base64')]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();

            setLoadingRoutes([]);
        } catch (err) {
            setLoadingRoutes([]);
            wrlog(err)
        }
    }

    const downloadAllPackingLists = async () => {
        for (let i = 0; i < filteredRoutes.length; i++) {
            await downloadPackingList(filteredRoutes[i]['id']);
        }
    }

    const selectStopToAssignToRoute = (stopId: string, checked: boolean) => {
        let _selectedStops = [...selectedStops];
        wrlog(stopId);

        if (checked) {
            _selectedStops.push(stopId);
        } else {
            let index = _selectedStops.findIndex(s => s.id === stopId);
            _selectedStops.splice(index, 1);
        }
        setSelectedStops(_selectedStops);
    }

    const attachToRoute = async () => {
        try {
            await RouteUnit.attachRouteStops(currentRoute.id, selectedStops);
            addToRouteButtonRef.current.changeState('success');
            await optimize(currentRoute.id);
            setListRouteStopsPopupOpen(false);
        } catch (err) {
            addToRouteButtonRef.current.changeState('error');
        }
    }



    return (
        <>
            <Card style={{ marginBottom: 30 }}>
                <Grid container padding={0}>
                    <Grid item md={6} padding={0}>
                        <h4 style={{ marginBottom: 0, marginLeft: 5 }}>{translate('routes')}</h4>
                    </Grid>
                    <Grid item md={6} padding={0} className='align_right'>
                        <Button
                            type='tertiary'
                            icon={<img src={AddIcon} />}
                            label={translate('add')}
                            onClick={() => setIsAddRoute(true)}
                            preventLoading={true}
                        />

                        {/* <Button
                            type='tertiary'
                            icon={<img src={DownloadIcon} />}
                            label={translate('download')}
                            onClick={() => ()}
                            ref={downloadButtonRef}
                            className={downloading ? 'disabled' : undefined}
                            preventLoading={true}
                        />
                        <Button
                            type='tertiary'
                            icon={<img src={DownloadIcon} />}
                            label={translate('downloadPackingList')}
                            onClick={() => ()}
                            ref={downloadButtonRef}
                            className={downloading ? 'disabled' : undefined}
                            preventLoading={true}
                        /> */}
                        <ContextMenu
                            style={{ position: 'relative', marginBottom: 10, marginLeft: 10 }}
                            contextMenu={[
                                {
                                    label: translate('download_routes'),
                                    icon: () => <img src={DownloadIcon} />,
                                    onClick: downloadRouteList
                                },
                                {
                                    label: translate('download_packing_list'),
                                    icon: () => <img src={DownloadIcon} />,
                                    onClick: downloadAllPackingLists
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid container padding={0}>
                    <Grid item md={12} padding={0}>
                        <div style={{ width: '100%' }}>
                            <Calendar
                                initialValue={selectedDate || new Date()}
                                onChange={(date: Date) => {
                                    changeDate(date);
                                    sessionStorage.setItem('savedDate', date.toString())
                                }}
                                useSavedDate={true}
                            />
                            <TextField
                                id="standard-basic"
                                placeholder={translate('find_stops')}
                                type="search"
                                className='dark small search'
                                style={{ width: '100%', marginTop: 20 }}
                                onChange={e => {
                                    dispatch(setSearch({ route: e.target.value }))
                                }}
                            />
                            <>
                                <Tooltip title={translate('all')}>
                                    <Badge
                                        state={'grey'}
                                        small
                                        label={routeCounts.all.toString()}
                                        style={{ marginLeft: 0, marginTop: 10, marginBottom: 0 }}
                                    />
                                </Tooltip>
                                <Tooltip title={translate('todo')}>
                                    <Badge
                                        state={'yellow'}
                                        small
                                        label={routeCounts.open.toString()}
                                        style={{ marginTop: 10, marginBottom: 0 }}
                                    />
                                </Tooltip>
                                <Tooltip title={translate('success')}>
                                    <Badge
                                        icon={CheckIcon}
                                        state={'green'}
                                        small
                                        label={routeCounts.success.toString()}
                                        style={{ marginTop: 10, marginBottom: 0 }}
                                    />
                                </Tooltip>
                                <Tooltip title={translate('failed')}>
                                    <Badge
                                        icon={ErrorIcon}
                                        state={'red'}
                                        small
                                        label={routeCounts.failed.toString()}
                                        style={{ marginTop: 10, marginBottom: 0 }}
                                    />
                                </Tooltip>
                            </>

                            <div style={{ width: '100%', display: 'inline-block', verticalAlign: 'top', paddingTop: 15 }}>
                                <Button
                                    type='tertiary'
                                    label={`${translate('all')} (${allRoutes.length})`}
                                    onClick={() => setFilterStatus('all')}
                                    selected={filterStatus === 'all'}
                                    preventLoading
                                    style={{ marginLeft: 0, marginRight: 10 }}
                                />
                                <Button
                                    type='tertiary'
                                    label={`${translate('ongoing')} (${allRoutes.filter((r: any) => r.status === 'running').length})`}
                                    onClick={() => setFilterStatus('running')}
                                    selected={filterStatus === 'running'}
                                    preventLoading
                                    icon={<TruckIcon style={{ fill: '#4BABE9', height: 18, marginRight: 3, marginLeft: -4 }} />}
                                    style={{ marginLeft: 0, marginRight: 10 }}
                                />
                                {/* <Button
                                    type='tertiary'
                                    label={`${translate('ontime')} (${allRoutes.filter((r: any) => r.status === 'ontime').length})`}
                                    onClick={() => setFilterStatus('ontime')}
                                    selected={filterStatus === 'ontime'}
                                    preventLoading
                                    icon={<OntimeIcon style={{ fill: '#4BABE9', height: 18, marginRight: 3, marginLeft: -4 }} />}
                                    style={{ marginLeft: 0, marginRight: 10 }}
                                /> */}
                                {/* <Button
                                    type='tertiary'
                                    label={`${translate('delayed')} (${allRoutes.filter((r: any) => r.status === 'delayed').length})`}
                                    onClick={() => setFilterStatus('delayed')}
                                    selected={filterStatus === 'delayed'}
                                    preventLoading
                                    icon={<DelayedIcon style={{ stroke: '#4BABE9', height: 18, marginRight: 3, marginLeft: -4 }} />}
                                    style={{ marginLeft: 0, marginRight: 10 }}
                                /> */}
                                <Button
                                    type='tertiary'
                                    label={`${translate('success')} (${allRoutes.filter((r: any) => r.status === 'done').length})`}
                                    onClick={() => setFilterStatus('done')}
                                    selected={filterStatus === 'done'}
                                    preventLoading
                                    icon={<OntimeIcon style={{ fill: '#4BABE9', height: 18, marginRight: 3, marginLeft: -4 }} />}
                                    style={{ marginLeft: 0, marginRight: 10 }}
                                />
                                <Button
                                    type='tertiary'
                                    label={`${translate('failed')} (${allRoutes.filter((r: any) => r.status === 'failed' || r.status === 'stopped_early').length})`}
                                    onClick={() => setFilterStatus('failed')}
                                    selected={filterStatus === 'failed'}
                                    preventLoading
                                    icon={<FailedIcon style={{ fill: '#4BABE9', height: 18, marginRight: 3, marginLeft: -4 }} />}
                                    style={{ marginLeft: 0, marginRight: 10 }}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Card>
            <div className='smallListContainer fullHeight'>
                {
                    routeLoading ? detailLoading()
                        :
                        filteredRoutes.length > 0 ?
                            filteredRoutes.map((route: any, key: number) => {

                                let contextMenuItems = [
                                    {
                                        label: translate('show_details'),
                                        icon: () => <VisibilityIcon />,
                                        onClick: () => {
                                            selectRoute(null, { ...route })
                                        }
                                    },
                                    {
                                        label: translate('download_packing_list'),
                                        icon: () => <ArrowCircleDownIcon />,
                                        onClick: () => {
                                            downloadPackingList(route.id)
                                        }
                                    },
                                    {
                                        label: translate('change_date'),
                                        icon: () => <CalendarMonthIcon />,
                                        onClick: () => {
                                            setChangeDatePopupOpen(true)
                                            setChangedDate(new Date(route.startDateTime))
                                        }
                                    },
                                    {
                                        label: translate('optimize_route'),
                                        icon: () => <InfoIcon />,
                                        onClick: () => {
                                            optimize(route.id)
                                        }
                                    },
                                    {
                                        label: translate('addRouteStops'),
                                        icon: () => <AddIconComp />,
                                        onClick: () => {
                                            setListRouteStopsPopupOpen(true)
                                        }
                                    }
                                ]

                                if (route.driver.id !== null) {
                                    contextMenuItems.push({
                                        label: translate('change_driver'),
                                        icon: () => <DirectionsCarIcon />,
                                        onClick: () => {
                                            setSelectDriverPopupOpen(true)
                                        }
                                    })
                                }

                                contextMenuItems.push({
                                    label: translate('change_title'),
                                    icon: () => <InfoIcon />,
                                    onClick: () => {
                                        setChangeTitlePopupOpen(true)
                                    }
                                })

                                contextMenuItems.push({
                                    label: translate('delete_route'),
                                    icon: () => <DeleteIcon />,
                                    onClick: () => {
                                        setRemoveRoutePopupOpen(true)
                                    }
                                })

                                return <div
                                    key={key}
                                    className={`smallListItem cuttext expandable white ${currentRoute?.id === route.id && expandRouteStops ? 'open' : undefined}`}
                                >
                                    <div className='header'
                                        onClick={(e) => selectRoute(e, { ...route })}
                                        style={currentRoute?.id === route.id && expandRouteStops ? { borderBottom: '2px solid var(--light-grey-color)' } : {}}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            marginTop: 5
                                        }}>
                                            <div style={{
                                                display: 'block',
                                                marginRight: 10,
                                                width: 20,
                                                height: 20,
                                                borderRadius: 20,
                                                backgroundColor: mapColor(key + 1)
                                            }}></div>
                                            {route.title
                                                && <h4 className="cuttext" style={{ margin: 0, width: 'auto', maxWidth: 180, display: 'block' }}>{route.title}</h4>
                                            }
                                            <strong className="cuttext" style={{ margin: 0, marginLeft: 10, width: 'auto', maxWidth: 180, display: 'inline-block' }}>{route.driver.name && route.driver.name.trim() !== '' ? route.driver.name : route.driver.email}</strong>
                                            {route.driver.id === null
                                                && <Button
                                                    label={translate('select_driver')}
                                                    onClick={() => setSelectDriverPopupOpen(true)}
                                                    type={'tertiary'}
                                                    style={{ marginLeft: 0, marginTop: -10, top: 7 }}
                                                />
                                            }
                                        </div>
                                        <strong style={{ margin: 0 }}>
                                            {
                                                moment(new Date(route.startDateTime)).format('HH:mm')}{route.endDateTime !== null
                                                    && ` - ${moment(new Date(route.endDateTime)).format('HH:mm')}`
                                            }
                                        </strong>
                                        <strong style={{ margin: 0, marginLeft: 20 }}>
                                            {route.distance}km
                                        </strong>
                                        <br />
                                        {changingRoute
                                            && currentRoute.id === route.id
                                            ? <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                marginTop: 5
                                            }}>
                                                <Button
                                                    type='tertiary'
                                                    label={translate('assign_to_different_route')}
                                                    onClick={() => {
                                                        setRouteStopsToChange([...changingRouteStops])
                                                        setAssignToDifferentRoutePopupOpen(true)
                                                    }}
                                                    preventLoading={true}
                                                />
                                                <Button
                                                    type='tertiary'
                                                    label={translate('cancel')}
                                                    onClick={() => setChangingRoute(false)}
                                                    className={'delete'}
                                                    preventLoading={true}
                                                />
                                            </div>
                                            : <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    marginTop: 5
                                                }}>
                                                    <Badge
                                                        state={'yellow'}
                                                        small
                                                        label={route?.count?.open}
                                                        style={{ marginLeft: 0, marginTop: 0, marginBottom: 0 }}
                                                    />
                                                    <Badge
                                                        icon={CheckIcon}
                                                        state={'green'}
                                                        small
                                                        label={route?.count?.success}
                                                        style={{ marginTop: 0, marginBottom: 0 }}
                                                    />
                                                    <Badge
                                                        icon={ErrorIcon}
                                                        state={'red'}
                                                        small
                                                        label={route?.count?.failed}
                                                        style={{ marginTop: 0, marginBottom: 0 }}
                                                    />
                                                    <StatusSelect
                                                        value={route.status}
                                                        onChange={(v: string) => changeStatus(v)}
                                                        color={getStatusColor(route.status)}
                                                        style={{
                                                            // position: 'absolute', top: 0, right: 0
                                                            marginLeft: 20
                                                        }}
                                                        options={[
                                                            {
                                                                value: 'created',
                                                                label: translate('todo'),
                                                            },
                                                            {
                                                                value: 'running',
                                                                label: translate('running'),
                                                            },
                                                            {
                                                                value: 'stopped_early',
                                                                label: translate('stopped_early'),
                                                                disabled: true
                                                            },
                                                            {
                                                                value: 'done',
                                                                label: translate('done')
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                                <ContextMenu
                                                    style={{ right: 50, top: 'calc(50% - 20px)' }}
                                                    contextMenu={
                                                        contextMenuItems
                                                    }
                                                />
                                                {loadingRoutes.includes(route.id) && <CircularProgress
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: 90,
                                                        marginTop: -10
                                                    }}
                                                />}
                                                <div className='expendableIcon'></div>
                                            </>
                                        }
                                    </div>
                                    {
                                        currentRoute?.id === route.id && expandRouteStops && <div className='innerWrapper'>
                                            {routeStopLoading ? detailLoading() :
                                                <SortableContainer onSortEnd={onRouteStopSort} useDragHandle>
                                                    {filteredRouteStops?.length > 0 ?
                                                        filteredRouteStops?.map((routeStop: any, index: number) => {

                                                            return <RouteStopListItem
                                                                routeStop={routeStop}
                                                                index={index}
                                                                selectable={changingRoute}
                                                                selected={changingRouteStops.includes(routeStop?.id)}
                                                                setSelected={changeSelectedRouteStop}
                                                                key={index}
                                                                selectRouteStop={selectRouteStop}
                                                                color={mapColor(key + 1)}
                                                                sortable={true}
                                                                contextMenu={[
                                                                    {
                                                                        label: translate('assign_to_different_route'),
                                                                        icon: () => <CompareArrowsIcon />,
                                                                        onClick: () => {
                                                                            setChangingRoute(true);
                                                                            setChangingRouteStops([routeStop.id])
                                                                        }
                                                                    },
                                                                    {
                                                                        label: translate('edit_address_location'),
                                                                        icon: () => <InfoIcon />,
                                                                        onClick: () => {
                                                                            selectRouteStop({ ...routeStop }, null);
                                                                            setChangeAddressLocationPopupOpen(true)
                                                                        }
                                                                    },
                                                                    {
                                                                        label: translate('remove_from_route'),
                                                                        icon: () => <WrongLocationIcon />,
                                                                        onClick: () => {
                                                                            setRemoveRouteStopPopupOpen(true)
                                                                            selectRouteStop({ ...routeStop }, null);
                                                                        }
                                                                    }
                                                                ]}
                                                            />
                                                        })
                                                        : <strong>{translate('no_route_stops_found')}</strong>
                                                    }
                                                </SortableContainer>
                                            }

                                        </div>
                                    }

                                </div>
                            })

                            : <>
                                <div style={{
                                    padding: '0 20px'
                                }}>
                                    <p style={{ textAlign: 'center' }}>{translate('no_routes_found')}</p>
                                    <Button
                                        type="primary"
                                        label={translate('create_route')}
                                        onClick={() => setIsAddRoute(true)}
                                        preventLoading
                                    />
                                </div>
                            </>
                }
            </div>
            {csvData !== null && <ExcelFile
                hideElement={true}
            >
                {
                    csvData.map((route: any, k: number) => {

                        return <ExcelSheet key={k} data={route.stops} name={route.title !== null ? route.title : `${translate('route')} #${k + 1}`}>
                            <ExcelColumn label="id" value="id" />
                            <ExcelColumn label="status" value="status" />
                            <ExcelColumn label="name" value="name" />
                            <ExcelColumn label="email" value="email" />
                            <ExcelColumn label="phone" value="phone" />
                            <ExcelColumn label="address" value="address" />
                            <ExcelColumn label="streetnumber" value="streetNumber" />
                            <ExcelColumn label="addressAddition" value="addressAddition" />
                            <ExcelColumn label="postcode" value="postcode" />
                            <ExcelColumn label="city" value="city" />
                            <ExcelColumn label="country" value="country" />
                            <ExcelColumn label="note" value="note" />
                            <ExcelColumn label="product" value="sku" />
                            <ExcelColumn label="productTitle" value="product" />
                            <ExcelColumn label="productExternalId" value="productExternalId" />
                        </ExcelSheet>
                    })
                }
            </ExcelFile>}

            <Popup
                close={() => setChangeTitlePopupOpen(false)}
                open={changeTitlePopupOpen}
                style={{ maxWidth: 400 }}
            >
                <h4 style={{ marginTop: 0 }}>{translate('change_title')}</h4>
                <TextField
                    id="standard-basic"
                    label={translate('title')}
                    value={routeTitle}
                    onChange={e => setRouteTitle(e.target.value)}
                    className='dark'
                    style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
                />
                <Button
                    type='primary'
                    onClick={changeTitle}
                    label={translate('change_title')}
                    ref={changeTitleButtonRef}
                />
            </Popup>

            <Popup
                open={changeAddressLocationPopupOpen}
                close={() => setChangeAddressLocationPopupOpen(false)}
            >
                <ChangeAddressLocation
                    open={changeAddressLocationPopupOpen}
                    setOpen={setChangeAddressLocationPopupOpen}
                    routeStop={currentStop}
                />
            </Popup>
            <Popup
                open={listRouteStopsPopupOpen}
                close={() => setListRouteStopsPopupOpen(false)}
            >
                <h4>{translate('stops')}</h4>
                <table>
                    <tbody>
                        {
                            soloRouteStops.map(stop => {
                                return <tr>
                                    <td>
                                        <Checkbox
                                            onChange={(e: any) => selectStopToAssignToRoute(stop.id, e.target.checked)}
                                        />
                                    </td>
                                    <td>{stop.address} {stop.streetNumber}, {stop.postcode} {stop.city}</td>
                                    <td></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <Button
                    type='primary'
                    onClick={attachToRoute}
                    label={translate('addToRoute')}
                    ref={addToRouteButtonRef}
                />
            </Popup>


        </>
    )
}



export default RouteListSidebar;