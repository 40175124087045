import moment from "moment";

const formatTime = (dateTime) => {

    if (typeof dateTime === 'string') {
        if (dateTime.length < 4) {
            return dateTime;
        }
        try {
            let date = new Date(dateTime);
            if (isNaN(date)) {
                return dateTime;
            }

            return moment(date).format('HH:mm');
        } catch (err) {
            return dateTime;
        }
    } else {
        return moment(dateTime).format('HH:mm');
    }
}

export default formatTime;