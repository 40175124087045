import { createSlice } from '@reduxjs/toolkit'

interface AddRouteStopsProps {
    payload: any;
}

export const reduceData = ({ id, recepientName, address, streetNumber, postcode, city, arrival, leave, status, order, longitude, latitude, attemptDateTime, tags }) => {
    return { id, name: recepientName, address, streetNumber, postcode, city, arrival, leave, status, order, longitude, latitude, attemptDateTime, tags };
}

export const routeStopsSlice = createSlice({
    name: 'route',
    initialState: [],
    reducers: {
        setRouteStops: (state, action) => action.payload.map((s: any) => reduceData(s)),
        updateRouteStop: (state, action) => {
            let index = state.findIndex((e: any) => e.id === action.payload.id);
            state[index] = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateRouteStop, setRouteStops } = routeStopsSlice.actions
export default routeStopsSlice.reducer
