import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import Popup from '../Popup';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import { translate } from '../../Helper/multilingual';
import wrlog from '../../Helper/functions/wrlog';
import { TextField } from '@mui/material';
import RouteStopUnit from '../../Services/Http/RouteStop';

mapboxgl.accessToken = 'pk.eyJ1IjoiYWZyZXNoZWQiLCJhIjoiY2w0Y214aDB2MDAzZjNicGE5b2RhMXl1cSJ9.Hj4CU2_lKV7E52qb_dTQ7A';

interface AddressAutocompleteProps {
    reload?: any;
    save?: any;
    id: string;
}

const AddressAutocomplete = (props: AddressAutocompleteProps) => {

    const { reload, save, id } = props;

    const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        types: 'address',
    });

    useEffect(() => {
        const geocoderContainer = document.getElementById(id);
        geocoderContainer.innerHTML = '';
        geocoder.addTo(`#${id}`);

        var inputNodes = geocoderContainer.getElementsByTagName('input');
        for (var i = 0; i < inputNodes.length; ++i) {
            var inputNode = inputNodes[i];
            inputNode.setAttribute('type', 'search');
            inputNode.setAttribute('placeholder', translate('add_stop'));

            inputNode.addEventListener('search', function () {
                geocoder.clear();
            });
        }

        geocoder.on('results', (e) => {
            wrlog("RESULTSSS", e);
        });

        geocoder.on('result', (e) => {
            wrlog("RESULT", e.result);

            if (e.result === undefined) {
                return;
            }

            let postcode = '';
            let city = '';
            let country = '';

            e.result.context.forEach(element => {
                if (element.id.includes('postcode')) {
                    postcode = element.text
                } else if (element.id.includes('place')) {
                    city = element.text
                } else if (element.id.includes('country')) {
                    country = element.short_code
                }
            });

            save({
                address: e.result.text,
                streetNumber: e.result.address,
                postcode: postcode,
                city: city,
                longitude: e.result.center[0],
                latitude: e.result.center[1],
                country: country
            })

            geocoder.clear();

        });

        geocoder.on('clear', () => {
            // results.innerText = '';
        });
    }, [])

    const onResult = (res, data) => {
        wrlog(res, data)
    }

    return <>
        {/* <TextField
            placeholder={translate('add_stop')}
            type="search"
            className='extrasmall dark'
            onInput={doSearch}
        /> */}
        <div id={id} className={styles.container}></div>
    </>

}

export default AddressAutocomplete;