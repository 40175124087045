import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { FileUploader } from "react-drag-drop-files";
import Papa from 'papaparse';
import * as XLSX from 'xlsx/xlsx.mjs';

import { translate } from '../../Helper/multilingual';
import Pagination from '../Pagination';
import Button from '../Button';

interface Props {
    setPage: any;
    setJson: any;
    json: any,
}

const CheckData = (props: Props) => {

    const { setJson, json, setPage } = props;

    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;
    const pages = Math.ceil(json.length / perPage);

    return <>
        <div className={styles.createTableScrollContainer}>
            <table
                style={{ tableLayout: 'fixed' }}
                className={styles.checkTable}
            >
                <thead>
                    <tr>
                        {Object.keys(json[0]).map(k => (<td>{k}</td>))}
                    </tr>
                </thead>
                <tbody>
                    {json.slice(((currentPage - 1) * perPage), (currentPage * perPage)).map(r => (
                        <tr>
                            {Object.keys(r).map(k => (
                                <td>
                                    {r[k]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        <div style={{ display: 'flex' }}>
            <Pagination
                current={currentPage}
                pages={pages}
                pageChange={setCurrentPage}
            />
            <Button
                type={'secondary'}
                style={{width: 'max-content'}}
                textStyle={{width: 'max-content'}}
                preventLoading
                label={translate('use_and_continue')}
                onClick={() => setPage(3)}
            />
        </div>
    </>

}

export default CheckData;