import { TextField, Autocomplete, CircularProgress, FormControl, InputLabel, Select, MenuItem, createFilterOptions } from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import UsersIcon from '../../../Assets/navigationIcons/users.svg';

import { Grid } from '@mui/material';
import Button from '../../../Components/Button';
import UserUnit from '../../../Services/Http/User';
import wrlog from '../../../Helper/functions/wrlog';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import Storage from '../../../Services/Storage';
import WarehouseUnit from '../../../Services/Http/Warehouse';

interface Props {
    closePopup?: any;
    setReload?: any;
}

const AddWarehouseForm = (props: Props) => {

    const buttonRef = useRef() as { current: any };

    const { closePopup, setReload } = props;

    const [title, setTitle] = useState('');

    const { handleSubmit } = useForm();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const create = async () => {

        buttonRef.current.changeState('loading');

        try {
            const { id } = await WarehouseUnit.create({ title })

            buttonRef.current.changeState('success');
            setReload(true);

            navigate(`/settings/warehouses/${id}`);

            setTimeout(() => {
                setReload(false);
            }, 100);

            setTimeout(() => {
                closePopup();
            }, 1000)

        } catch (err) {
            wrlog(err);
            buttonRef.current.changeState('error');
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description
            }));
        }
    }


    return (
        <Grid container spacing={0}>
            <Grid item md={12} padding={0}>
                <h3>{translate('addWarehouse')}</h3>
                <form onSubmit={handleSubmit(create)}>
                    <TextField
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                        label={translate('title')}
                        className='dark'
                    />

                    <Button
                        type='primary'
                        isInput
                        label={translate('addWarehouse')}
                        ref={buttonRef}
                    />
                </form>
            </Grid>
        </Grid >
    );
}

export default AddWarehouseForm;