import { createSlice, current } from '@reduxjs/toolkit'
import wrlog from '../../../../Helper/functions/wrlog';
import { db } from '../../../Database';

interface JobProps {
    payload: any;
}

export const jobSlice = createSlice({
    name: 'job',
    initialState: { job: [] },
    reducers: {
        initJobs: (state, action: any) => {
            state.job = action.payload;
        },
        setJob: (state, action: JobProps) => {
            let index = current(state.job).findIndex((e: any) => e.id === action.payload.id);
            wrlog(action.payload)
            if (action.payload.status !== 'deleted') {
                if (index < 0) {
                    state.job.push(action.payload);
                    if (db.jobs.get(action.payload.id)) {
                        db.jobs.update(action.payload.id, action.payload)
                    } else {
                        db.jobs.add(action.payload);
                    }
                } else {
                    delete action.payload.id;

                    state.job[index] = { ...current(state.job)[index], ...action.payload };

                    action.payload.id = current(state.job)[index].id;
                    db.jobs.update(action.payload.id, action.payload)
                }
            } else {
                state.job.splice(index, 1);
                db.jobs.delete(action.payload.id);
            }
        },
        removeJob: (state, action: JobProps) => {
            let index = current(state.job).findIndex((e: any) => e.id === action.payload);
            state.job.splice(index, 1);
            db.jobs.delete(action.payload);
        }
    },
})

// Action creators are generated for each case reducer function
export const { setJob, initJobs, removeJob } = jobSlice.actions

export default jobSlice.reducer
