import Unit from './index';

export default class LocationUnit extends Unit {

    static getLocation = async (locationId) => {
        return await Unit.get(`/stop/${locationId}/location`);
    }

    static updateLocation = async (locationId, location) => {
        return await Unit.put(`/location/${locationId}`, location);
    }

}



