import { TextField, Autocomplete, CircularProgress, FormControl, InputLabel, Select, MenuItem, createFilterOptions } from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import UsersIcon from '../../../Assets/navigationIcons/users.svg';

import { Grid } from '@mui/material';
import Button from '../../../Components/Button';
import UserUnit from '../../../Services/Http/User';
import wrlog from '../../../Helper/functions/wrlog';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import Storage from '../../../Services/Storage';

interface AddUserFormProps {
    closePopup?: any;
    setReload?: any;
}

/**
 * 
 * @returns Login Page
 */
const AddUserForm = (props: AddUserFormProps) => {

    const AddUserButtonRef = useRef();

    const filter = createFilterOptions();

    const { closePopup, setReload } = props;

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedEmail, setSelectedEmail] = useState(null);

    const [emailError, setEmailError] = useState(false);
    const [roleError, setRoleError] = useState(false);


    const { register, handleSubmit, formState: { errors }, control } = useForm();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }



        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (search.length > 2) {
            setLoading(true);
            UserUnit.searchByEmail(search).then((res) => {
                if (res.length > 0) {
                    setOptions(res.map((user: any) => ({ inputValue: user.email, title: user.email })));
                }
                setLoading(false);
            })
        }
    }, [search])

    const setEmail = (email: string) => {
        setSelectedEmail(email)
    }

    const changeRole = (e) => {
        setSelectedRole(e.target.value)
    }

    const invite = () => {

        setEmailError(false);
        setRoleError(false);
        let valid = true;
        if (selectedEmail === '' || selectedEmail === null) {
            setEmailError(true);
            valid = false;
        }
        if (selectedRole === '' || selectedRole === null) {
            setRoleError(true);
            valid = false;
        }

        wrlog(selectedEmail, selectedRole)

        if (!valid) {
            return;
        }

        let buttonRef = AddUserButtonRef.current || null;
        if (buttonRef !== null) {
            buttonRef.changeState('loading');
        }

        UserUnit.invite(selectedEmail, selectedRole).then(res => {

            buttonRef.changeState('success');

            navigate(`/settings/users/${res.userId}`);
            setReload(true);
            setTimeout(() => {
                setReload(false);
            }, 100);
            setTimeout(() => {
                closePopup();
                dispatch(addMessage({
                    type: 'success',
                    title: translate('invite_success_title'),
                    body: translate('invite_success_body')
                }));
            }, 1000)

        }).catch((err: any) => {
            wrlog(err);
            buttonRef.changeState('error');
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description
            }));
        })
    }


    return (
        <Grid container spacing={0}>
            <Grid item md={12} padding={0}>
                <h3>{translate('add_user_description_title')}</h3>
                <p>{translate('add_user_description_text')}</p>
                <form onSubmit={handleSubmit(invite)}>
                    <TextField
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        label="E-Mail"
                        className='dark'
                        error={emailError}
                    />

                    <FormControl variant="filled"
                        error={roleError}
                    >
                        <InputLabel>{translate('role')}</InputLabel>
                        <Select
                            value={selectedRole}
                            label={translate('role')}
                            onChange={changeRole}
                        >
                            {
                                Storage.getValue('roles').map((role: any) => (
                                    <MenuItem value={role.id}>{role.title}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <Button
                        type='primary'
                        isInput
                        label={translate('add_user')}
                        ref={AddUserButtonRef}
                    />
                </form>
            </Grid>
        </Grid >
    );
}

export default AddUserForm;