import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../../Assets/Logo.png';
import AddIcon from '../../../Assets/add.svg';
import FilterIcon from '../../../Assets/filter.svg';

import styles from './styles.module.scss';

import { Grid, Skeleton } from '@mui/material';
import { useDispatch } from 'react-redux';
import UserUnit from '../../../Services/Http/User';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../Services/Http/Company';
import wrlog from '../../../Helper/functions/wrlog';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';
import AddUserForm from './AddUserForm';
import FilterForm from './FilterForm';
import ListItem from '../../../Components/ListItem';

interface UsersListProps {
    currentUser: string;
    selectUser: any;
    reload: any;
    setReload: any;
}

/**
 * 
 * @returns Login Page
 */
const UsersList = (props: UsersListProps) => {

    const LoginButtonRef = useRef();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [addUserPopupOpen, setAddUserPopupOpen] = useState(false);
    const [filterPopupOpen, setFilterPopupOpen] = useState(false);
    const [filter, setFilter] = useState({
        search: '',
        status: '',
        role: '',
    });
    const [resendingInvitationIds, setResendingInvitationIds] = useState([]);

    const { selectUser, currentUser, reload, setReload } = props;

    const { register, handleSubmit, formState: { errors } } = useForm();

    const dispatch = useDispatch();
    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        loadUsers();
    }, [filter])

    useEffect(() => {
        if (reload) {
            loadUsers();
        }
    }, [reload])

    useEffect(() => {
        if (params.userId !== undefined) {
            setSelectedItem(params.userId)
        }
    }, [params])

    const loadUsers = () => {
        setLoading(true)
        CompanyUnit.listUsers(filter.search, filter.status, filter.role, 0, 500).then(res => {
            setLoading(false)
            setUsers(res);
        }).catch((err: any) => {
            setLoading(false)
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
        })
    }

    const applyFilter = (_filter: any) => {
        setFilter(_filter);
    }

    const resendInvitation = (invitationId: string) => {
        setResendingInvitationIds(prev => [...prev, invitationId]);

        UserUnit.resendInvite(invitationId).then(() => {
            dispatch(addMessage({
                type: 'success',
                title: translate('invite_success_title'),
                body: translate('invite_success_body')
            }));
        }).catch(err => {
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
        }).finally(() => {
            setResendingInvitationIds(prev => {
                let arr = [];
                prev.forEach(id => {
                    if (id !== invitationId) {
                        arr.push(id);
                    }
                })

                return arr;
            });
        })
    }

    return (
        <>
            <Grid container style={{ marginBottom: 30 }}>
                <Grid item md={6}>
                    <Button
                        type='tertiary'
                        icon={<img src={FilterIcon} />}
                        label={translate('filter')}
                        onClick={() => setFilterPopupOpen(true)}
                        preventLoading={true}
                    />
                </Grid>
                <Grid item md={6} className='align_right'>
                    <Button
                        type='tertiary'
                        icon={<img src={AddIcon} />}
                        label={translate('add_user')}
                        onClick={() => setAddUserPopupOpen(true)}
                        preventLoading={true}
                    />
                </Grid>
            </Grid>
            {
                loading ? <>
                    <Skeleton variant='rectangular' animation="wave" height={60} />
                    <Skeleton variant='rectangular' animation="wave" height={60} style={{ opacity: 0.8 }} />
                    <Skeleton variant='rectangular' animation="wave" height={60} style={{ opacity: 0.6 }} />
                    <Skeleton variant='rectangular' animation="wave" height={60} style={{ opacity: 0.4 }} />
                </>
                    :
                    users.length > 0 ? users.map((user: any, key: number) => {

                        let badges = [];

                        user.roles.forEach(role => {
                            let color = '';
                            if (role === 'Admin') {
                                color = "red"
                            } else if (role === 'Driver') {
                                color = "blue"
                            } else if (role === 'Dispatcher') {
                                color = "green"
                            } else {
                                color = "yellow"
                            }

                            badges.push({
                                color: color,
                                title: role
                            })

                        });

                        return <ListItem
                            key={key}
                            title={user.firstName !== '' ? `${user.firstName} ${user.lastName}` : user.email}
                            detail={user.status === 'invited' ? <>{translate('invited')} <Button type="tertiary" onClick={() => resendInvitation(user.invitationId)} label={translate('resend_invitation')} /></> : ''}
                            badges={badges}
                            selected={user.id === selectedItem}
                            onClick={() => navigate(`/settings/users/${user.id}`)}
                            loading={resendingInvitationIds.includes(user.invitationId)}
                        />
                    })

                        : <h4>{translate('no_users_found')}</h4>
            }
            <Button
                type='primary'
                label={translate('add_user')}
                onClick={() => setAddUserPopupOpen(true)}
                preventLoading={true}
            />
            <Popup
                open={addUserPopupOpen}
                close={() => setAddUserPopupOpen(false)}
                style={{ maxWidth: 400 }}
            >
                <AddUserForm
                    closePopup={() => setAddUserPopupOpen(false)}
                    setReload={setReload}
                />
            </Popup>
            <Popup
                open={filterPopupOpen}
                close={() => setFilterPopupOpen(false)}
                style={{ maxWidth: 400 }}
            >
                <FilterForm
                    closePopup={() => setFilterPopupOpen(false)}
                    filter={filter}
                    setFilter={applyFilter}
                />
            </Popup>
        </>
    );
}


export default UsersList;