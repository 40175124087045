import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../Helper/multilingual';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../Assets/Logo.png';

import styles from './styles.module.scss';

import { Autocomplete, Card, Grid, InputAdornment, Skeleton, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Components/Button';
import ParcelUnit from '../../Services/Http/Parcel';
import CustomSwitch from '../Form/Switch';
import { possibleReasonOfFailure, possibleReasonOfFailureActions } from '../../Helper/Data/reasonOfFailure';
import { possibleProofOfDeliveryOptionsDropoffPermit, possibleProofOfDeliveryOptionsPersonally } from '../../Helper/Data/proofOfDelivery';
import StatusSelect from '../Form/StatusSelect';
import getStatusColor from '../../Helper/functions/getStatusColor';
import moment from 'moment';
import { API_ENDPOINT } from '../../config';

interface Props {
    proofOfDelivery?: any;
    reasonOfFailure?: any;
    status?: string;
}

/**
 * 
 * @returns Login Page
 */
const ParcelProof = (props: Props) => {

    const { proofOfDelivery, reasonOfFailure, status } = props;

    const renderProof = (proofs: any) => {

        return Object.keys(proofs).map(key => {
            const data = proofs[key];

            switch (key) {
                case 'photo':
                    return renderImage(data);
                case 'signature':
                    return renderSignature(data.image, data.name);
                case 'call_dispatcher':
                    return renderCallDispatcher(data.number, parseInt(data.duration), data.started);
                case 'call_recipient':
                    return renderCallRecipient(data.number, parseInt(data.duration), data.started);
            }

        })
    }

    const renderImage = (data: any) => {
        if (data.images) {
            return data.images.map((imageUrl: string) => {
                return <img src={`${API_ENDPOINT}/bucket/${imageUrl}`} />
            })
        } else {
            return <img src={`${API_ENDPOINT}/bucket/${data.image}`} />
        }
    }

    const renderSignature = (imageUrl: string, name: string) => {
        return <>
            <img src={`${API_ENDPOINT}/bucket/${imageUrl}`} /><br />
            <small>{name}</small>
        </>
    }

    const renderCallDispatcher = (phone: string, duration: number, started: string, name?: string) => {
        const minutes = (duration / 60).toFixed();
        const seconds = duration - (minutes * 60);
        return <>
            <strong>{translate('call_dispatcher')}</strong>
            <div>
                {phone}<br />
                {moment(new Date(started)).format('HH:mm')}, {minutes}{translate('min')} {seconds}{translate('sek')}
            </div>
        </>
    }

    const renderCallRecipient = (phone: string, duration: number, started: string, name?: string) => {
        const minutes = (duration / 60).toFixed();
        const seconds = duration - (minutes * 60);

        return <>
            <strong>{translate('call_recipient')}</strong>
            <div>
                {name}, {phone}<br />
                {moment(new Date(started)).format('HH:mm')}, {minutes}{translate('min')} {seconds}{translate('sek')}
            </div>
        </>
    }

    if (proofOfDelivery && status === 'delivered') {

        return <Card
            className={`${styles.proofCard} ${styles.success}`}
            style={{ marginBottom: 20 }}>
            {renderProof(proofOfDelivery)}
        </Card>

    } else if (reasonOfFailure && status === 'failed') {
        return <Card
            className={`${styles.proofCard} ${styles.failed}`}
            style={{ marginBottom: 20 }}>
            <h4
                style={{ marginBottom: 0 }}
            >{translate(reasonOfFailure.reason)}</h4>
            {renderProof(reasonOfFailure.proof)}
        </Card>
    }

    return <></>
}

export default ParcelProof;