import React, { useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import AddressAutocomplete from '../../AddressAutocomplete';
import Button from '../../Button';
import { translate } from '../../../Helper/multilingual';

const StartLocationInput = ({ value, onChange }) => {

    const [edit, setEdit] = useState(false);

    if (edit) {
        return (
            <Grid container spacing={2} style={{ alignItems: 'center' }}>
                <Grid item md={12} padding={0} style={{ display: 'flex', alignItems: 'center' }}>
                    <AddressAutocomplete
                        id="updateAddress"
                        save={(res: any) => {
                            const {
                                address,
                                streetNumber,
                                postcode,
                                city,
                                longitude,
                                latitude
                            } = res;

                            const title = `${address} ${streetNumber}, ${postcode} ${city}`;
                            const location = [longitude, latitude];

                            onChange({ title, location });
                            setEdit(false);
                        }}
                    />
                    <Button
                        type={'tertiary'}
                        label={translate('cancel')}
                        onClick={() => setEdit(false)}
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid item md={6} padding={0} className='' >
                {value}
            </Grid>
            <Grid item md={6} padding={0} className='align_right' >
                <Button
                    type={'tertiary'}
                    label={translate('edit')}
                    onClick={() => setEdit(true)}
                />
            </Grid>
        </Grid>
    )
}

export default StartLocationInput