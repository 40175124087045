import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import uuid from "react-uuid";

const Tooltip = ({ children, title, style = {} }) => {

    const id = uuid();

    return (
        <>
            <div
                data-tooltip-id={`tt-${id}`}
                data-tooltip-content={title}
                style={{ marginRight: 10, display: 'flex', alignItems: 'center', ...style }}>
                <span className="w-full h-full absolute"></span>
                {children}
            </div>
            <ReactTooltip style={{ maxWidth: 300 }} id={`tt-${id}`} effect='solid' multiline={true} />
        </>
    );
};
export default Tooltip;
