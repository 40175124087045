import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { Grid, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import UserUnit from '../../../Services/Http/User';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../Services/Http/Company';
import wrlog from '../../../Helper/functions/wrlog';
import CustomSwitch from '../../../Components/Form/Switch';
import RoleUnit from '../../../Services/Http/Role';
import Storage from '../../../Services/Storage';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';

interface ProfileProps {
}

/**
 * 
 * @returns Login Page
 */
const Profile = (props: ProfileProps) => {

    const [companyData, setCompanyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { register, handleSubmit, setValue, getValues, formState: { errors, isValid } } = useForm();

    useEffect(() => {
        UserUnit.getProfile().then(res => {
            wrlog(res);
            setValue('firstName', res.firstName)
            setValue('lastName', res.lastName)
            setValue('email', res.email)
            setValue('phone', res.phone)
            setLoading(false);
        }).catch(err => {
            wrlog(err);
        })
    }, [])

    const formChanged = (key: string) => {
        const value = getValues(key);
        UserUnit.updateProfile(key, value)
    }

    if (loading) {
        return <>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <div className={styles.icon}></div>
                </Grid>
                <Grid item md={8}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <strong>{translate('firstname')}asdasd</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={170} />
                        </Grid>
                        <Grid item md={6}>
                            <strong>{translate('lastname')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={150} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <strong>{translate('email')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={290} />
                        </Grid>
                        <Grid item md={6}>
                            <strong>{translate('phone_number')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={180} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    }

    return (
        <>
            <form>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={12}>
                        <h3>{translate('general_settings')}</h3>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item md={3} padding={0}>

                    </Grid>
                    <Grid item md={9} padding={0}>
                        <Grid container spacing={3} style={{ textAlign: 'center' }}>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('firstName', { required: true })}
                                    onBlur={() => formChanged('firstName')}
                                    error={errors.firstName}
                                    id="standard-basic"
                                    label={translate('firstname')}
                                    type="text"
                                    className='dark'
                                />
                            </Grid>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('lastName', { required: true })}
                                    onBlur={() => formChanged('lastName')}
                                    error={errors.lastName}
                                    id="standard-basic"
                                    label={translate('lastname')}
                                    type="text"
                                    className='dark'
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ textAlign: 'center' }}>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('email', { required: true })}
                                    onBlur={() => formChanged('email')}
                                    error={errors.email}
                                    id="standard-basic"
                                    label={translate('email')}
                                    type="email"
                                    className='dark'
                                />
                            </Grid>
                            <Grid item md={6} padding={0}>
                                <TextField
                                    {...register('phone', { required: true })}
                                    onBlur={() => formChanged('phone')}
                                    error={errors.phone}
                                    id="standard-basic"
                                    label={translate('phone_number')}
                                    type="phone"
                                    className='dark'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </form>
        </>
    );
}

export default Profile;