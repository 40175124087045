import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../Helper/multilingual';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../Assets/Logo.png';

import styles from './styles.module.scss';

import { Autocomplete, Card, Grid, Skeleton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import Map from '../../Components/Map';
import Button from '../../Components/Button';
import CsvImporter from '../../Components/CsvImporter';
import RouteStopUnit from '../../Services/Http/RouteStop';
import wrlog from '../../Helper/functions/wrlog';
import AddressAutocomplete from '../../Components/AddressAutocomplete/index';
import DetailContainer from '../../Components/DetailContainer';
import { db } from '../../Services/Database';
import ListItem from '../../Components/ListItem';
import ParcelUnit from '../../Services/Http/Parcel';
import InputTags from '../../Components/Form/Tags';
import StatusSelect from '../../Components/Form/StatusSelect';
import getStatusColor from '../../Helper/functions/getStatusColor';
import CheckIcon from '../../Assets/statusIcons/success.svg'
import ErrorIcon from '../../Assets/statusIcons/error.svg'
import { setRoutesData } from '../../Services/Redux/features/routes/routesSlice';
import { cloneArray } from '../../Helper/functions/cluster';
import { API_ENDPOINT } from '../../config';
import Tooltip from '../../Components/Tooltip/Tooltip';

interface RouteStopDetailProps {
    reload?: any;
    setSaveState: any;
    reloadSingle: any;
    closeSidebar: any;
    selectedRouteStopId: string;
    setOpenParcelDetail: any;
    currentParcelId: any;
    setCurrentParcelId: any;
    currentRouteId?: any;
}

/**
 * 
 * @returns Login Page
 */
const RouteStopDetail = (props: RouteStopDetailProps) => {

    const { reload, setSaveState, reloadSingle, closeSidebar, selectedRouteStopId, currentParcelId, setCurrentParcelId, currentRouteId } = props;
    const [currentStop, setCurrentStop] = useState({});
    const [currentStopCheck, setCurrentStopCheck] = useState({});
    const routeStop = useSelector(state => state.routeStop);
    const routes = useSelector(state => state.routes);
    const dispatch = useDispatch();

    const [copiedDropoffLocation, setCopiedDropoffLocation] = useState(false);
    const [copiedTrackingLink, setCopiedTrackingLink] = useState(false);

    useEffect(() => {
        setCurrentStop({ ...routeStop });
        setCurrentStopCheck({ ...routeStop });
        console.log(routeStop);
    }, [routeStop])

    const [editAddress, setEditAddress] = useState(false);

    const updateAddress = (data: any) => {
        if (!currentStop.canWrite) {
            // return;
        }
        setSaveState('saving');

        let body = {
            id: currentStop.id,
            address: data.address,
            streetNumber: data.streetNumber,
            postcode: data.postcode,
            city: data.city,
            country: data.country || null,
            longitude: data.longitude,
            latitude: data.latitude,
        };

        RouteStopUnit.update(body).then(res => {
            reload();
            reloadSingle();
            setSaveState('saved');
            setEditAddress(false);
            setTimeout(() => {
                setSaveState('default');
            }, 2000)
        }).catch(err => {

        })

    }

    const deleteRouteStop = () => {

        const body = {
            id: currentStop.id,
            status: 'deleted'
        }
        RouteStopUnit.update(body).then(res => {
            reload();
            setSaveState('saved');
            closeSidebar();
            setTimeout(() => {
                setSaveState('default');
            }, 2000)
        }).catch(err => {

        })
    }

    if (editAddress) {
        return <Grid container style={{ marginTop: 30, minHeight: 400 }}>
            <Grid item md={12} className={styles.center}>
                <Button
                    type="secondary"
                    preventLoading
                    label={translate('back')}
                    onClick={() => setEditAddress(false)}
                />
                <AddressAutocomplete
                    id="updateAddress"
                    reload={reload}
                    save={updateAddress}
                />
            </Grid>
        </Grid>
    }

    const formChanged = (value: string, key: string) => {
        const body = {
            id: currentStop.id
        }

        if (value == currentStopCheck[key] || (value === '' && currentStopCheck[key] === null)) {
            return;
        }

        setSaveState('saving');

        body[key] = value;

        setCurrentStopCheck({ ...currentStop })

        RouteStopUnit.update(body)
            .then(() => {
                setSaveState('saved');
                setTimeout(() => {
                    setSaveState('default');
                }, 2000)
            }).catch(err => {

            })
    }

    const formChangedValue = (value: string, key: string) => {
        let _currentStop = { ...currentStop };
        _currentStop[key] = value;
        setCurrentStop(_currentStop)
    }

    const addParcel = () => {
        const body = {
            stopId: currentStop.id
        }

        ParcelUnit.create(body)
            .then((res) => {
                reloadSingle();
            }).catch(err => {

            })
    }

    const copyDropOffLocation = () => {
        setCopiedDropoffLocation(true);
        window.navigator.clipboard.writeText(`${currentStop?.attemptLocation[0]},${currentStop?.attemptLocation[1]}`);
        setTimeout(() => setCopiedDropoffLocation(false), 2000);
    }

    const copyTrackingLink = () => {
        setCopiedTrackingLink(true);
        window.navigator.clipboard.writeText(`https://tracking.prontoroute.com/${currentStop?.trackingCode}`);
        setTimeout(() => setCopiedTrackingLink(false), 2000);
    }

    if (currentStop.id === undefined) {
        return <></>
    }

    const listParcels = () => {
        return <>
            <Grid container>
                <Grid item xs={8}>
                    <strong>{translate('parcels')}</strong>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                    {
                        routeStop.status === 'assigned_to_route' || routeStop.status === 'to_assign'
                        && <Button
                            type='secondary'
                            preventLoading
                            label={translate('add')}
                            onClick={() => {
                                addParcel()
                            }}
                        />
                    }
                </Grid>
            </Grid>
            {
                currentStop.parcels.map((parcel: any, key: number) => {

                    let statusColor = 'yellow';
                    let icon = null;
                    if (parcel.routeStopStatus === 'failed') {
                        statusColor = 'red';
                        icon = ErrorIcon;
                    } else if (parcel.routeStopStatus === 'delivered') {
                        statusColor = 'green';
                        icon = CheckIcon;
                    }

                    let badges = [{
                        title: translate(`delivery_status_${parcel.routeStopStatus}`),
                        color: statusColor,
                        icon: icon
                    }];

                    let file = null as any;

                    parcel.proofOfDelivery
                        && Object.keys(parcel.proofOfDelivery).forEach(proofKey => {
                            if (parcel.proofOfDelivery[proofKey].image) {
                                file = `${API_ENDPOINT}/bucket/${parcel.proofOfDelivery[proofKey].image}`
                            } else if (parcel.proofOfDelivery[proofKey].images) {
                                file = `${API_ENDPOINT}/bucket/${parcel.proofOfDelivery[proofKey].images[0]}`
                            }
                        })

                    return <ListItem
                        badgeInline={false}
                        key={key}
                        title={parcel.title !== null ? `${parcel.title}` : `${translate('parcel')} #${key + 1}`}
                        detail={parcel.sku !== null ? parcel.sku : ''}
                        badges={badges}
                        sideImage={file}
                        selected={currentParcelId === parcel.id}
                        onClick={() => {
                            setCurrentParcelId(null)
                            setTimeout(() => setCurrentParcelId(parcel.id), 100)
                        }}
                    />
                })
            }
        </>
    }

    return (
        <>
            {currentStop.status !== 'to_assign'
                && <StatusSelect
                    value={currentStop?.status}
                    onChange={(status: string) => {
                        formChanged(status, 'status')
                        formChangedValue(status, 'status')

                        const index = routes.findIndex(r => r.id === currentRouteId)
                        let routeCopy = cloneArray(routes[index]);
                        const stopIndex = routeCopy.stops.findIndex(s => s.id === selectedRouteStopId);

                        routeCopy.stops[stopIndex].status = status;

                        dispatch(setRoutesData(routeCopy));
                    }}
                    color={getStatusColor(currentStop?.status)}
                    style={{
                        position: 'absolute',
                        top: 23,
                        right: 20
                    }}
                    options={[
                        {
                            value: 'assigned_to_route',
                            label: translate('todo'),
                        },
                        {
                            value: 'driving',
                            label: translate('driving'),
                        },
                        {
                            value: 'arrived',
                            label: translate('arrived'),
                        },
                        {
                            value: 'success',
                            label: translate('success'),
                        },
                        {
                            value: 'failed',
                            label: translate('failed'),
                        },
                        {
                            value: 'problem',
                            label: translate('problem'),
                        }
                    ]}
                />
            }
            <div className={styles.header} style={{ marginTop: currentStop.status !== 'to_assign' ? 60 : 0 }}>
                <Grid container style={{ paddingTop: 20, marginTop: 0, marginBottom: 10 }}>
                    <Grid item md={2} className={styles.center}>
                        <div className={styles.circle}>
                            {currentStop.order !== null ? currentStop.order : '?'}
                        </div>
                    </Grid>
                    <Grid item md={10} className={styles.center}>
                        <h4 style={{ margin: 0 }}>{currentStop.address} {currentStop.streetNumber} {currentStop.addressAddition !== null && ` ${currentStop.addressAddition}`}</h4>
                        <p style={{ margin: 0 }}>{currentStop.postcode} {currentStop.city}</p>
                    </Grid>
                </Grid>
                {
                    (currentStop?.canWrite || currentStop?.canDelete || 1 == 1) && <>
                        {
                            (routeStop.status === 'assigned_to_route' || routeStop.status === 'to_assign')
                                ? <>
                                    <hr style={{ width: '100%', marginTop: 20, marginBottom: 10 }} />
                                    <Grid container style={{ marginTop: 10 }}>
                                        <Grid item md={6}>
                                            <Button
                                                type="tertiary"
                                                preventLoading
                                                label={translate('edit_address')}
                                                style={{ marginRight: 10 }}
                                                onClick={() => setEditAddress(true)}
                                            />
                                        </Grid>
                                        <Grid item md={6} className='align_right'>
                                            <Button
                                                type="tertiary"
                                                preventLoading
                                                className='delete'
                                                label={translate('delete')}
                                                onClick={() => deleteRouteStop()}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                                : <>
                                    <hr style={{ width: '100%', marginTop: 20, marginBottom: 10 }} />
                                    <Grid container style={{ marginTop: 10 }}>
                                        <Grid item md={6}>
                                            <Tooltip title={copiedDropoffLocation ? translate('copied_to_clipboard') : translate('copy')}>
                                                <Button
                                                    type="tertiary"
                                                    preventLoading
                                                    label={translate('copyDropoffLocation')}
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => copyDropOffLocation()}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={6} className='align_right'>
                                            <Tooltip
                                                style={{
                                                    justifyContent: 'flex-end'
                                                }}
                                                title={copiedTrackingLink ? translate('copied_to_clipboard') : translate('copy')}>
                                                <Button
                                                    type="tertiary"
                                                    preventLoading
                                                    label={translate('copyTrackingLink')}
                                                    onClick={() => copyTrackingLink()}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </>
                        }
                    </>
                }
                <hr style={{ width: '100%', marginTop: 10, marginBottom: 20 }} />
            </div>
            <Grid container style={{ marginTop: 10 }}>
                <Grid item md={12}>
                    {
                        currentStop?.driverNote
                        && <TextField
                            id="standard-basic"
                            label={translate('driverNote')}
                            type="text"
                            multiline
                            minRows={2}
                            className='dark'
                            value={currentStop?.driverNote}
                            disabled={true}
                        />
                    }
                    {
                        routeStop.status !== 'assigned_to_route' && routeStop.status !== 'to_assign'
                        && listParcels()
                    }

                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'note')}
                        onChange={e => formChangedValue(e.target.value, 'note')}
                        label={translate('notes')}
                        type="text"
                        multiline
                        minRows={2}
                        className='dark'
                        value={currentStop.note || ''}
                        disabled={routeStop.status !== 'assigned_to_route' && routeStop.status !== 'to_assign'}
                    />
                    {/* <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'driverId')}
                        onChange={e => formChangedValue(e.target.value, 'driverId')}
                        label={translate('driver')}
                        type="text"
                        className='dark'
                        value={currentStop.driverId}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                id="standard-basic"
                                onBlur={e => formChanged(e.target.value, 'arrivalFrom')}
                                onChange={e => formChangedValue(e.target.value, 'arrivalFrom')}
                                label={translate('arrival_from')}
                                type="text"
                                className='dark'
                                value={currentStop.arrivalFrom}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="standard-basic"
                                onBlur={e => formChanged(e.target.value, 'arrivalTo')}
                                onChange={e => formChangedValue(e.target.value, 'arrivalTo')}
                                label={translate('arrival_to')}
                                type="text"
                                className='dark'
                                value={currentStop.arrivalTo}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <strong>{translate('time_at_stop')}</strong>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                id="standard-basic"
                                onBlur={e => formChanged(e.target.value, 'timeAtStopMinutes')}
                                onChange={e => formChangedValue(e.target.value, 'timeAtStopMinutes')}
                                label={translate('minutes')}
                                type="text"
                                className='dark'
                                value={currentStop.timeAtStopMinutes}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="standard-basic"
                                onBlur={e => formChanged(e.target.value, 'timeAtStopSeconds')}
                                onChange={e => formChangedValue(e.target.value, 'timeAtStopSeconds')}
                                label={translate('seconds')}
                                type="text"
                                className='dark'
                                value={currentStop.timeAtStopSeconds}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <strong>{translate('priority')}</strong>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{
                                width: '100%',
                                overflow: 'scroll',
                                display: 'flex'
                            }}>
                                <Button
                                    type='secondary'
                                    preventLoading
                                    label={translate('none')}
                                    selected={parseInt(currentStop.priority) === 0 || currentStop.priority === null}
                                    onClick={() => {
                                        formChanged('0', 'priority')
                                        formChangedValue('0', 'priority')
                                    }}
                                />
                                <Button
                                    type='secondary'
                                    preventLoading
                                    label={translate('low')}
                                    selected={parseInt(currentStop.priority) === 1}
                                    onClick={() => {
                                        formChanged('1', 'priority')
                                        formChangedValue('1', 'priority')
                                    }}
                                />
                                <Button
                                    type='secondary'
                                    preventLoading
                                    label={translate('middle')}
                                    selected={parseInt(currentStop.priority) === 2}
                                    onClick={() => {
                                        formChanged('2', 'priority')
                                        formChangedValue('2', 'priority')
                                    }}
                                />
                                <Button
                                    type='secondary'
                                    preventLoading
                                    label={translate('high')}
                                    selected={parseInt(currentStop.priority) === 3}
                                    onClick={() => {
                                        formChanged('3', 'priority')
                                        formChangedValue('3', 'priority')
                                    }}
                                />
                                <Button
                                    type='secondary'
                                    preventLoading
                                    label={translate('asap')}
                                    selected={parseInt(currentStop.priority) === 4}
                                    onClick={() => {
                                        formChanged('4', 'priority')
                                        formChangedValue('4', 'priority')
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid> */}
                    <Grid container>
                        <Grid item xs={12}>
                            <strong>{translate('recepient_information')}</strong>
                        </Grid>
                    </Grid>
                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'recepientName')}
                        onChange={e => formChangedValue(e.target.value, 'recepientName')}
                        label={translate('name')}
                        type="text"
                        className='dark'
                        value={currentStop.recepientName}
                        disabled={routeStop.status !== 'assigned_to_route' && routeStop.status !== 'to_assign'}
                    />
                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'recepientEmail')}
                        onChange={e => formChangedValue(e.target.value, 'recepientEmail')}
                        label={translate('email')}
                        type="email"
                        className='dark'
                        value={currentStop.recepientEmail}
                        disabled={routeStop.status !== 'assigned_to_route' && routeStop.status !== 'to_assign'}
                    />
                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'recepientPhone')}
                        onChange={e => formChangedValue(e.target.value, 'recepientPhone')}
                        label={translate('phone')}
                        type="text"
                        className='dark'
                        value={currentStop.recepientPhone}
                        disabled={routeStop.status !== 'assigned_to_route' && routeStop.status !== 'to_assign'}
                    />
                    {
                        (routeStop.status === 'assigned_to_route' || routeStop.status === 'to_assign')
                        && <Grid container>
                            <Grid item md={12}>
                                <Tooltip
                                    title={copiedTrackingLink ? translate('copied_to_clipboard') : translate('copy')}>
                                    <Button
                                        type="tertiary"
                                        preventLoading
                                        label={translate('copyTrackingLink')}
                                        onClick={() => copyTrackingLink()}
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    }
                    <Grid container style={{ marginTop: 20 }}>
                        <Grid item xs={12}>
                            <strong>{translate('tags')}</strong>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputTags
                                onChange={(res: any) => formChanged(res, 'tags')}
                                value={currentStop.tags}
                                title={translate('tags')}
                                disabled={routeStop.status !== 'assigned_to_route' && routeStop.status !== 'to_assign'}
                            />
                        </Grid>
                    </Grid>
                    {
                        (routeStop.status === 'assigned_to_route' || routeStop.status === 'to_assign')
                        && listParcels()
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default RouteStopDetail;