import wrlog from '../../Helper/functions/wrlog';
import Storage from '../Storage';
import Unit from './index';

export default class WarehouseUnit extends Unit {

    static list = async () => {
        return await Unit.get('/warehouse');
    }

    static get = async (id: string) => {
        return await Unit.get(`/warehouse/${id}`);
    }

    static create = async (data: any) => {
        return await Unit.post('/warehouse', data);
    }

    static update = async (id: string, data: any) => {
        return await Unit.put(`/warehouse/${id}`, data);
    }

}



