import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import wrlog from '../../Helper/functions/wrlog'
import { addMessage, removeMessage, removeMessages } from '../../Services/Redux/features/messages/messageSlice'
import Message from './Message'
import styles from './styles.module.scss';

const Messages = () => {
    const messages = useSelector((state: any) => state.message.messages)
    const dispatch = useDispatch()

    useEffect(() => {

        let interval = null;

        if (messages.length > 0) {
            interval = setInterval(() => {

                let removeable = true;

                messages.forEach((message: any) => {
                    if ((message.created + 100000) > Date.now()) {
                        removeable = false;
                    }
                })

                if (removeable) {
                    dispatch(removeMessages());
                }
            }, 10)

            return () => {
                if (interval !== null) {
                    clearInterval(interval);
                }
            }
        }
    }, [messages])

    return (
        <div className={styles.messagesWrapper}>
            {
                messages.map((message: any, key: number) => {
                    return <Message
                        key={key}
                        type={message.type}
                        title={message.title}
                        body={message.body}
                        created={message.created}
                    />
                })
            }
        </div>
    )
}

export default Messages;