import { createSlice } from '@reduxjs/toolkit'
import wrlog from '../../../../Helper/functions/wrlog';
import { db } from '../../../Database';

interface AddRouteStopProps {
    payload: any;
}

export const routeStopSlice = createSlice({
    name: 'routeStop',
    initialState: {},
    reducers: {
        setRouteStopData: (state, action: AddRouteStopProps) => action.payload,
        updateRouteStopData: (state, action: AddRouteStopProps) => {
            let index = state.findIndex((e: any) => e.id === action.payload.id);
            if (index > -1) {

                const data = { ...state[index], ...action.payload };
                db.routeStops.update(action.payload.id, data)
                state[index] = data;
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { setRouteStopData, updateRouteStopData } = routeStopSlice.actions

export default routeStopSlice.reducer
