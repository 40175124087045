import UserUnit from "../../Services/Http/User";
import Storage from "../../Services/Storage";
import wrlog from "./wrlog";

export const isLoggedIn = async () => {
    return await UserUnit.validateToken();
}

export const getUserToken = () => Storage.getValue('token');



// Check if user has certain permission
export const hasPermission = (permission_type, action) => {

    let companies = Storage.getValue('user', 'companies');


    let permissions = [];
    companies.forEach((company) => {
        if (company.id === Storage.getValue('currentCompany')) {
            permissions = company.permissions;
        }
    })

    if (permissions == null) return false;

    if (permissions[permission_type] === undefined) return false;

    if (typeof action === 'object') {
        let valid = false;

        action.forEach(_action => {
            if (permissions[permission_type][_action] === true) {
                valid = true;
            }
        });

        return valid;
    }

    return permissions[permission_type][action] === true;

}

export const logout = () => {
    Storage.removeValue('token');
    Storage.removeValue('user');
    Storage.removeValue('roles');
    Storage.removeValue('currentCompany');
    
    if (typeof window !== 'undefined') {
        window.location.reload();
    }
}