import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import styles from './styles.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import polyline from '@mapbox/polyline'; // eslint-disable-line import/no-webpack-loader-syntax
import WarehouseIcon from '@mui/icons-material/Warehouse';
import wrlog from '../../Helper/functions/wrlog';
import sameJson from '../../Helper/functions/compareJson';

mapboxgl.accessToken = 'pk.eyJ1IjoiYWZyZXNoZWQiLCJhIjoiY2w0Y214aDB2MDAzZjNicGE5b2RhMXl1cSJ9.Hj4CU2_lKV7E52qb_dTQ7A';

interface MapProps {
    flyTo?: any;
    ref?: any;
    draggableMarker?: object;
    highlightedRouteStopIds?: any;
    onDragged?: any;
    style?: object;
}

const draggableGeojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': [0, 0]
            }
        }
    ]
};

const calculateClusterBounds = (clusters: any) => {
    let bounds = [];

    bounds = new mapboxgl.LngLatBounds([
        clusters[0][0].lon,
        clusters[0][0].lat
    ], [
        clusters[0][0].lon,
        clusters[0][0].lat
    ]);
    clusters.forEach((cluster: any) => {
        cluster.forEach((marker: any) => {
            bounds.extend([
                marker.lon,
                marker.lat
            ]);
        })
    })

    return bounds;
}

const Map = React.forwardRef((props: MapProps, ref) => {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(13.76667);
    const [lat, setLat] = useState(47.633331);
    const [zoom, setZoom] = useState(7);
    const [routeIds, setRouteIds] = useState([]);
    const [routeData, setRouteData] = useState([]);
    const [currentMarkers, setCurrentMarkers] = useState([]);
    const [markerData, setMarkerData] = useState([]);
    const [clusterData, setClusterData] = useState([]);
    const [currentClusters, setCurrentClusters] = useState([]);

    const [driverMarkers, setDriverMarkers] = useState([]);
    const [driverData, setDriverData] = useState([]);
    const [initedDraggableMarker, setInitedDraggableMarker] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [dropoffMarker, setDropoffMarker] = useState(null);

    const {
        markers,
        drivers,
        flyTo,
        highlightedRouteStopIds,
        draggableMarker,
        onDragged,
        style
    } = props;

    useImperativeHandle(ref, () => ({
        flyTo(location: any) {
            map.current.flyTo({
                center: [
                    location[0],
                    location[1]
                ],
                zoom: 15,
                speed: 3,
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
        },
        flyToBounds(bounds: any) {
            map.current.fitBounds(bounds, {
                padding: 100
            });

        },
        setDropoffMarker(longitude: number, latitude: number, routeId: number, packages: any) {
            if (dropoffMarker !== null) {
                dropoffMarker.remove();
            }

            const markerElement = document.createElement('div');
            markerElement.classList.add(styles.marker);
            markerElement.classList.add(styles.dropoff);

            const index = routeData.findIndex(r => r.id === routeId);
            if (index > -1) {
                let colorIndex = routeData[index]?.colorIndex;

                if (colorIndex) {
                    markerElement.classList.add(styles[`b${mapColor(colorIndex, false)}`]);
                }
            }

            console.log("MARKER", longitude, latitude, routeId)

            const marker = new mapboxgl.Marker(markerElement)
                .setLngLat([longitude, latitude])
                // .setPopup(
                //     new mapboxgl.Popup({ offset: 25 }) // add popups
                //         .setHTML(
                //             `<p>${marker.address} ${marker.streetNumber}, ${marker.postcode} ${marker.city}</p>`
                //         )
                // )
                .addTo(map.current);

            setDropoffMarker(marker);
        },
        removeDropoffMarker() {
            if (dropoffMarker !== null) {
                dropoffMarker.remove();
            }
        },
        setMarkers(markers: any) {

            const same = sameJson(markerData, markers);

            if (same) {
                return;
            }

            setMarkerData(markers);

            currentMarkers.forEach((marker: any) => {
                marker.remove();
            })

            let _markers = [];

            markers.forEach((marker: any, key: number) => {
                const markerElement = document.createElement('div');
                markerElement.classList.add(styles.marker);

                if (marker.colorIndex) {
                    markerElement.classList.add(styles[`b${mapColor(marker.colorIndex, false)}`]);
                }

                // if (highlightedRouteStopIds !== undefined && highlightedRouteStopIds.length > 0 && !highlightedRouteStopIds.includes(marker.id)) {
                //     markerElement.classList.add(styles.lowlight);
                // }

                if (marker.number !== undefined && marker.status !== 'success' && marker.status !== 'failed' && marker.status !== 'problem') {
                    markerElement.innerHTML = `<div class='${styles.number}'>${marker.number}</div>`;
                } else if (marker.icon !== undefined && marker.icon === 'start') {
                    markerElement.classList.add(styles.start);
                }

                if (marker.status) {
                    markerElement.classList.add(styles[`${marker.status}`]);
                }

                const _marker = new mapboxgl.Marker(markerElement)
                    .setLngLat([marker.longitude, marker.latitude])
                    .setPopup(
                        new mapboxgl.Popup({ offset: 25 }) // add popups
                            .setHTML(
                                `<p>${marker.address} ${marker.streetNumber}, ${marker.postcode} ${marker.city}</p>`
                            )
                    )
                    .addTo(map.current);

                _markers.push(_marker);
            })

            setCurrentMarkers(_markers);
        },
        setClusters(clusters: any) {
            setClusterData(clusters);

            currentClusters.forEach((marker: any) => {
                marker.remove();
            })

            let _markers = [];

            clusters.forEach((cluster: any, key: number) => {
                const color = mapColor(key + 1, false);
                console.log(color);
                cluster.forEach((marker: any) => {
                    const markerElement = document.createElement('div');
                    markerElement.classList.add(styles.clusterMarker);

                    markerElement.classList.add(styles[`b${color}`]);

                    const _marker = new mapboxgl.Marker(markerElement)
                        .setLngLat([marker.lon, marker.lat])
                        .addTo(map.current);

                    _markers.push(_marker);
                })
            })

            setCurrentClusters(_markers);
        },
        setRoutes(routes: any) {
            const same = sameJson(routeData, routes);

            if (same) {
                return;
            }

            setRouteData(routes);

            routeIds.forEach(routeId => {
                if (map.current.getSource(routeId)) {
                    map.current.removeLayer(routeId);
                    map.current.removeSource(routeId);
                }
            });

            routes.forEach((route: any, index: number) => {
                if (route.geometry !== null) {
                    map.current.addSource(route.id, {
                        'type': 'geojson',
                        'data': {
                            'type': 'Feature',
                            'properties': {},
                            'geometry': polyline.toGeoJSON(route.geometry)
                        }
                    });
                    map.current.addLayer({
                        'id': route.id,
                        'type': 'line',
                        'source': route.id,
                        'layout': {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        'paint': {
                            'line-color': mapColor(route.colorIndex),
                            'line-width': 3
                        }
                    })
                }
            })

            setRouteIds(routes.map(r => r.id));
        },
        setDrivers(drivers: any) {

            const same = sameJson(driverData, drivers);

            if (same) {
                return;
            }

            setDriverData(drivers);

            driverMarkers.forEach((marker: any) => {
                marker.remove();
            })

            let _markers = [];

            drivers.forEach((marker: any, key: number) => {

                const markerElement = document.createElement('div');
                markerElement.classList.add(styles.marker);
                markerElement.classList.add(styles.driverMarker);

                if (marker.colorIndex) {
                    markerElement.classList.add(styles[`b${mapColor(marker.colorIndex, false)}`]);
                }

                const _marker = new mapboxgl.Marker(markerElement)
                    .setLngLat([marker.longitude, marker.latitude])
                    .addTo(map.current);

                _markers.push(_marker);
            })

            setDriverMarkers(_markers);
        },
        loaded() {
            return loaded;
        }
    }))

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'https://api.maptiler.com/maps/ab5efeec-0edb-4a21-8698-2aa7d44508cd/style.json?key=as655Sc4TpwS024zFCfw',
            center: [lng, lat],
            zoom: zoom
        });

        map.current.on('load', () => {
            initDraggableMarker()
            setLoaded(true);
        });

    }, [map.current]);

    useEffect(() => {
        if (draggableMarker && loaded && !initedDraggableMarker) {
            initDraggableMarker()
        }
    }, [draggableMarker, loaded, initedDraggableMarker])

    useEffect(() => {
        if (draggableMarker && map.current && loaded) {
            onMoveDraggable({ lngLat: { lng: draggableMarker.longitude, lat: draggableMarker.latitude } })
        }
    }, [draggableMarker, loaded, map.current])

    useEffect(() => {
        if (flyTo === undefined || flyTo === null || flyTo.length === 0) {
            return;
        } else if (flyTo === 'bounds') {

            if (markers.length < 1) {
                return;
            }

            const bounds = new mapboxgl.LngLatBounds([
                markers[0].longitude,
                markers[0].latitude
            ], [
                markers[0].longitude,
                markers[0].latitude
            ]);

            markers.forEach((marker: any) => {
                bounds.extend([
                    marker.longitude,
                    marker.latitude
                ]);
            })

            map.current.fitBounds(bounds, {
                padding: 100
            });

            return;
        }

        map.current.flyTo({
            center: [
                flyTo[0],
                flyTo[1]
            ],
            zoom: 15,
            speed: 3,
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });

    }, [flyTo]);

    const initDraggableMarker = () => {
        if (draggableMarker) {

            setInitedDraggableMarker(true);
            draggableGeojson.features[0].geometry.coordinates = [draggableMarker.longitude, draggableMarker.latitude];

            const dragCanvas = map.current.getCanvasContainer();

            map.current.flyTo({
                center: [
                    draggableMarker.longitude,
                    draggableMarker.latitude
                ],
                zoom: 18,
                speed: 3,
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });

            map.current.addSource('draggableMarker', {
                'type': 'geojson',
                'data': draggableGeojson
            });

            map.current.addLayer({
                'id': 'draggableMarker',
                'type': 'circle',
                'source': 'draggableMarker',
                'paint': {
                    'circle-radius': 10,
                    'circle-color': '#1C9DE5' // red color
                }
            });

            // When the cursor enters a feature in
            // the point layer, prepare for dragging.
            map.current.on('mouseenter', 'draggableMarker', () => {
                map.current.setPaintProperty('draggableMarker', 'circle-color', '#3bb2d0');
                dragCanvas.style.cursor = 'move';
            });

            map.current.on('mouseleave', 'draggableMarker', () => {
                map.current.setPaintProperty('draggableMarker', 'circle-color', '#1C9DE5');
                dragCanvas.style.cursor = '';
            });

            map.current.on('mousedown', 'draggableMarker', (e) => {
                // Prevent the default map drag behavior.
                e.preventDefault();

                dragCanvas.style.cursor = 'grab';

                map.current.on('mousemove', onMoveDraggable);
                map.current.once('mouseup', onDraggableUp);
            });

            map.current.on('touchstart', 'draggableMarker', (e) => {
                if (e.points.length !== 1) return;

                // Prevent the default map drag behavior.
                e.preventDefault();

                map.current.on('touchmove', onMoveDraggable);
                map.current.once('touchend', onDraggableUp);
            });
        }

    };


    const onMoveDraggable = (e: any) => {
        const coords = e.lngLat;
        // Update the Point feature in `geojson` coordinates
        // and call setData to the source layer `point` on it.
        draggableGeojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
        map.current.getSource('draggableMarker').setData(draggableGeojson);
    }

    const onDraggableUp = (e: any) => {
        const coords = e.lngLat;

        onDragged({ longitude: coords.lng, latitude: coords.lat });

        // Unbind mouse/touch events
        map.current.off('mousemove', onMoveDraggable);
        map.current.off('touchmove', onMoveDraggable);
    }


    return <div>
        <div ref={mapContainer} style={style} className={styles.mapContainer} />
    </div>

})

export default Map;


export const mapColor = (colorIndex: number, returnColor = true) => {

    const colors = [
        '#0C9489',
        '#48D3EA',
        '#03A4E5',
        '#1B75BB',
        '#65A324',
        '#AAB927',
        '#9334E6',
        '#DB1680',
        '#EA5814',
        '#EAD114',
        '#E11D47',
        '#62718C',
        '#910000',
    ];

    if ((colorIndex + 1) > colors.length) {
        let divisor = Math.floor((colorIndex - 1) / colors.length);
        let jump = (colorIndex - 1) - ((colors.length) * divisor);
        colorIndex = jump + 1;
    }

    if (returnColor) {
        return colors[colorIndex - 1];
    } else {
        return colorIndex;
    }
}

export { calculateClusterBounds };