import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { Grid, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import UserUnit from '../../../Services/Http/User';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../Services/Http/Company';
import wrlog from '../../../Helper/functions/wrlog';
import CustomSwitch from '../../../Components/Form/Switch';
import RoleUnit from '../../../Services/Http/Role';
import Storage from '../../../Services/Storage';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';

import DeliveryGuyImage from '../../../Assets/placeholder_images/delivery_guy.jpg';
import WarehouseUnit from '../../../Services/Http/Warehouse';

interface Props {
    reload: any;
    setReload: any;
    selectedWarehouse?: any;
}

/**
 * 
 * @returns Login Page
 */
const WarehouseDetail = (props: Props) => {

    const { reload, setReload, selectedWarehouse } = props;

    const [warehouse, setWarehouse] = useState({} as { title: string, address: string, streetNumber: string, postcode: string, city: string, country: string, id: string });
    const [roles, setRoles] = useState(null);
    const [currentWarehouse, setCurrentWarehouse] = useState(null) as any;
    const [loading, setLoading] = useState(true);
    const [askDeletePopupOpen, setAskDeletePopupOpen] = useState(false);
    const buttonRef = useRef() as { current: any };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        setLoading(true);
        if (params.id !== undefined) {
            setCurrentWarehouse(params?.id)
        } else {
            setLoading(false);
            setCurrentWarehouse(null);
        }
    }, [params])

    useEffect(() => {
        wrlog("currentUser", currentWarehouse);
        loadWarehouse();
    }, [currentWarehouse])

    const loadWarehouse = async () => {

        if (currentWarehouse === null) {
            setLoading(false);
            return;
        }

        try {
            const data = await WarehouseUnit.get(currentWarehouse);
            setWarehouse(data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            wrlog(err);
            if (err.code !== 404) {
                dispatch(addMessage({
                    type: 'error',
                    title: err.title,
                    body: err.description,
                }))
            }
            navigate(`/settings/warehouses`);
        }
    }

    const update = async () => {
        buttonRef.current.changeState('loading');
        try {
            await WarehouseUnit.update(currentWarehouse, warehouse);
            setReload(true);
            setTimeout(() => {
                setReload(false);
            }, 100)
            buttonRef.current.changeState('success');
        } catch (err) {
            console.log(err)
            buttonRef.current.changeState('error');
        }
    }

    const confirmDelete = async () => {

        try {
            await WarehouseUnit.update(currentWarehouse, { status: 'deleted' });

            dispatch(addMessage({
                type: 'success',
                title: translate('delete_warehouse_success_title'),
                body: '',
            }))

            navigate(`/settings/warehouses/`);
            setTimeout(() => {
                setAskDeletePopupOpen(false);
            }, 500)

            setReload(true);
            setTimeout(() => {
                setReload(false);
            }, 100)
        } catch (err) {
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
            setAskDeletePopupOpen(false);
        }
    }

    const changeWarehouse = (key: string, value: string) => {
        let _warehouse = { ...warehouse };
        _warehouse[key] = value;
        setWarehouse(_warehouse);
    }

    if (currentWarehouse === null) {
        return <Grid container spacing={2} className={styles.detailContainer}>
            <Grid item md={12}>
                <img src={DeliveryGuyImage} style={{ maxHeight: '30vh', maxWidth: '50%' }} />
                <h3>{translate('choose_a_warehouse')}</h3>
                <p>{translate('choose_a_warehouse_text')}</p>
            </Grid>
        </Grid>
    }

    if (loading || !currentWarehouse) {
        return <>
            <Grid container spacing={2} className={styles.detailContainer}>
                <Grid item md={6}>
                    <strong>{translate('title')}</strong><br />
                    <Skeleton variant='rectangular' animation="wave" height={14} width={170} />
                </Grid>
                <Grid item md={6}>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <strong>{translate('address')}</strong><br />
                    <Skeleton variant='rectangular' animation="wave" height={14} width={290} />
                </Grid>
                <Grid item md={6}>
                    <strong>{translate('streetNumber')}</strong><br />
                    <Skeleton variant='rectangular' animation="wave" height={14} width={180} />
                </Grid>
            </Grid>
        </>
    }

    return (
        <>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
                <Grid item md={12}>
                    <h3>{translate('warehouse')}</h3>
                </Grid>
            </Grid>
            <Grid container spacing={2} className={styles.detailContainer}>
                <Grid item md={12}>
                <Grid container spacing={2}>
                        <Grid item md={6}>
                            <TextField
                                value={warehouse.title}
                                onChange={e => changeWarehouse('title', e.target.value)}
                                id="standard-basic"
                                label={translate('title')}
                                type="text"
                                className='dark'
                            />
                        </Grid>
                        <Grid item md={6}>
                            
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <TextField
                                value={warehouse.address}
                                onChange={e => changeWarehouse('address', e.target.value)}
                                id="standard-basic"
                                label={translate('address')}
                                type="text"
                                className='dark'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                value={warehouse.streetNumber}
                                onChange={e => changeWarehouse('streetNumber', e.target.value)}
                                id="standard-basic"
                                label={translate('streetNumber')}
                                type="text"
                                className='dark'
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item md={4}>
                            <TextField
                                value={warehouse.postcode}
                                onChange={e => changeWarehouse('postcode', e.target.value)}
                                id="standard-basic"
                                label={translate('postcode')}
                                type="text"
                                className='dark'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                value={warehouse.city}
                                onChange={e => changeWarehouse('city', e.target.value)}
                                id="standard-basic"
                                label={translate('city')}
                                type="text"
                                className='dark'
                            />
                        </Grid>
                        <Grid item md={2}>
                            <TextField
                                value={warehouse.country}
                                onChange={e => changeWarehouse('country', e.target.value)}
                                inputProps={{ maxLength: 2 }}
                                id="standard-basic"
                                label={translate('country')}
                                type="text"
                                className='dark'
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: 50 }}>
                        <Grid item md={6}>
                            <Button
                                type={"tertiary"}
                                onClick={() => setAskDeletePopupOpen(true)}
                                label={translate('delete_warehouse')}
                                className={'delete'}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Button
                                type={"primary"}
                                ref={buttonRef}
                                onClick={() => update()}
                                label={translate('save')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Popup
                    open={askDeletePopupOpen}
                    close={() => setAskDeletePopupOpen(false)}
                    style={{ width: 400 }}
                >
                    <h3>{translate('are_you_sure?')}</h3>
                    <p>{translate('are_you_sure_delete_warehouse')}</p>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            type={"primary"}
                            onClick={confirmDelete}
                            label={translate('yes_delete')}
                            className={'delete'}
                        />
                        <br />
                        <Button
                            type={"tertiary"}
                            onClick={() => setAskDeletePopupOpen(false)}
                            label={translate('cancel')}
                        />
                    </div>
                </Popup>
            </Grid>
        </>
    );
}

export default WarehouseDetail;