import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useLocation, useParams } from 'react-router-dom';
import Logo from '../../../Assets/Logo.png';

import styles from './styles.module.scss';

import { ReactComponent as SettingsIcon } from '../../../Assets/navigationIcons/settings.svg';
import { ReactComponent as UsersIcon } from '../../../Assets/navigationIcons/users.svg';
import { ReactComponent as NotificationsIcon } from '../../../Assets/navigationIcons/notifications_grey.svg';
import { ReactComponent as RouteIcon } from '../../../Assets/navigationIcons/route.svg';
import ArrowIcon from '../../../Assets/navigationIcons/arrow_down.svg';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';

interface SidebarProps {
    children: any;
}

/**
 * 
 * @returns Login Page
 */
const Sidebar = (props: SidebarProps) => {

    return (
        <div className={styles.container}>
            {props.children}
        </div>
    );
}

export default Sidebar;