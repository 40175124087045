import wrlog from '../../Helper/functions/wrlog';
import Storage from '../Storage';
import Unit from './index';

export default class UserUnit extends Unit {

    static login = async (email: string, password: string) => {

        let body = {
            email: email,
            password: password
        }

        return await Unit.post('/user/signin', body);
    }

    static validateToken = async () => {
        return await Unit.post('/verifytoken');
    }

    static forgotPassword = async (email: string) => {
        return await Unit.post('/user/forgotpassword', { email: email });
    }

    static resetPassword = async (password: string, key: string, secret: string) => {

        let body = { password: password, key: key, secret: secret };
        return await Unit.post('/user/resetpassword', body);
    }

    static changePassword = async (password: string) => {
        let body = { password: password };
        return await Unit.post('/user/profile/password', body);
    }

    static editRole = async (userId: string, roleId: string, enabled: boolean) => {

        let body = {
            userId: userId,
            roleId: roleId,
            enabled: enabled
        };
        return await Unit.post('/user/role', body);
    }

    static searchByEmail = async (search: string) => {
        return await Unit.post('/user/searchemail', { search: search });
    }

    static invite = async (email: string, roleId: string) => {
        const body = {
            companyId: Storage.getValue('currentCompany'),
            email: email,
            roleId: roleId
        }
        return await Unit.post('/user/invite', body);
    }

    static resendInvite = async (invitationId: string) => {
        return await Unit.post('/user/invite/resend', { id: invitationId });
    }

    static getInvitation = async (invitationId: string) => {
        return await Unit.get(`/invite/${invitationId}`);
    }

    static acceptInvitation = async (invitationId: string) => {
        return await Unit.get(`/invite/${invitationId}/accept`);
    }

    static declineInvitation = async (invitationId: string) => {
        return await Unit.get(`/invite/${invitationId}/decline`);
    }

    static acceptInitialInvitation = async (invitationId: string, firstName: string, lastName: string, email: string, password: string) => {
        let body = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password
        }
        return await Unit.post(`/invite/${invitationId}/accept`, body);
    }

    static getProfile = async () => {
        return await Unit.get(`/user/profile`);
    }

    static updateProfile = async (key: string, value: string) => {

        let body = {}
        body[key] = value;

        return await Unit.post(`/user/profile`, body);
    }



}



