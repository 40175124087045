import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { translate } from '../../Helper/multilingual';
import Button from '../Button';
import RouteStopUnit from '../../Services/Http/RouteStop';
import LocationUnit from '../../Services/Http/Location';
import Map from '../Map';
import { useDispatch } from 'react-redux';
import { setRouteStopData, updateRouteStopData } from '../../Services/Redux/features/routeStop/routeStopSlice';

mapboxgl.accessToken = 'pk.eyJ1IjoiYWZyZXNoZWQiLCJhIjoiY2w0Y214aDB2MDAzZjNicGE5b2RhMXl1cSJ9.Hj4CU2_lKV7E52qb_dTQ7A';

interface Props {
    routeStop: any;
    open: Boolean;
    setOpen: any;
}

const ChangeAddressLocation = (props: Props) => {

    const { routeStop, open, setOpen, setRouteStop } = props;

    const [originalLocation, setOriginalLocation] = useState({});
    const [location, setLocation] = useState({});
    const [locationId, setLocationId] = useState(null);
    const [loading, setLoading] = useState(true);

    const mapRef = useRef();
    const buttonRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        if (routeStop !== null) {
            load();
        }
    }, [routeStop])


    const load = async () => {
        let locationData = await LocationUnit.getLocation(routeStop.id);
        const { longitude, latitude, id } = locationData;
        setLocationId(id);
        setOriginalLocation({ longitude, latitude });
        setLocation({ longitude, latitude });
        setLoading(false);
        buttonRef.current.changeState('default');
    }

    const update = async () => {

        buttonRef.current.changeState('loading');

        try {
            await LocationUnit.updateLocation(locationId, location);
            dispatch(updateRouteStopData({ id: routeStop.id, longitude: location.longitude, latitude: location.latitude }));
            buttonRef.current.changeState('success');
        } catch (err) {
            buttonRef.current.changeState('error');
        }

        setOpen(false);
    }

    useEffect(() => {
        if (buttonRef.current && !location.longitude) {
            buttonRef.current.changeState('loading');
        }
    }, [buttonRef])

    const onChange = (locationData: object) => {
        setLocation(locationData);
    }

    return (
        <div className={styles.container} style={{}}>
            <Map
                markers={[]}
                routes={[]}
                drivers={[]}
                ref={mapRef}
                draggableMarker={location?.longitude ? location : null}
                style={{ height: 400 }}
                onDragged={(l: object) => onChange(l)}
            />
            <Button
                ref={buttonRef}
                type={'primary'}
                style={{ position: 'absolute', bottom: 20, right: 20, width: 'auto', zIndex: 2 }}
                preventLoading
                label={loading ? translate('loading_location') : translate('save_change')}
                onClick={() => update()}
            />{
                (
                    originalLocation.longitude !== location.longitude
                    || originalLocation.latitude !== location.latitude
                )
                && <Button
                    type={'secondary'}
                    style={{ position: 'absolute', bottom: 20, left: 20, width: 'auto', zIndex: 2 }}
                    preventLoading
                    label={translate('reset')}
                    onClick={() => {
                        setLocation({ ...originalLocation });
                    }}
                />
            }

        </div>
    )

}

export default ChangeAddressLocation;