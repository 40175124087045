import { createSlice } from '@reduxjs/toolkit'
import wrlog from '../../../../Helper/functions/wrlog';
import { db } from '../../../Database';

interface AddRoutesProps {
    payload: any;
}

export const routesSlice = createSlice({
    name: 'stop',
    initialState: [],
    reducers: {
        initRoutes: (state) => {
            db.routes.toArray().then((res: any) => {
                state.push(res);
            }).catch(() => {

            })
        },
        setRoutesData: (state, action: AddRoutesProps) => {
            let index = state.findIndex((e: any) => e.id === action.payload.id);

            if (action.payload?.status !== 'deleted') {
                if (index < 0) { // Add
                    db.routes.add(action.payload);
                    state.push(action.payload);
                } else { // Update
                    // merge changes to let the old data and only change new data that is incoming
                    let merged = { ...state[index], ...action.payload };
                    if (action.payload?.stopLocations) {

                        let mergedLocations = state[index].stopLocations.map((location: any, key: number) => {

                            const index = action.payload?.stopLocations?.findIndex(l => l.id === location.id);
                            const newLocation = action.payload.stopLocations[index];
                            return { ...location, ...newLocation };
                        })
                        merged['stopLocations'] = mergedLocations;
                    }

                    db.routes.update(action.payload.id, merged)
                    state[index] = merged;
                }
            } else {
                state.splice(index, 1);
            }

        }
    },
})

// Action creators are generated for each case reducer function
export const { setRoutesData, initRoutes } = routesSlice.actions

export default routesSlice.reducer
