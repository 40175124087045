export const possibleProofOfDeliveryOptionsPersonally = [
    'scan',
    'signature',
    'photo',
    'photo_of_printed_delivery_note'
];

export const possibleProofOfDeliveryOptionsDropoffPermit = [
    'scan',
    'photo',
];

export const possibleProofOfDeliveryOptionsDefault = {
    personally: [],
    dropoff: [],
    dropoffPermit: false,
}