import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { FileUploader } from "react-drag-drop-files";
import Papa from 'papaparse';
import * as XLSX from 'xlsx/xlsx.mjs';

import { translate } from '../../Helper/multilingual';
import Pagination from '../Pagination';
import Button from '../Button';

interface Props {
    setPage: any;
    setJson: any;
    json: any;
    upload: any;
}


const Mapping = (props: Props) => {

    const possibleFields = [
        {
            id: 'externalId',
            title: translate('reference_id'),
            required: true,
            prefilled: ['id', 'reference_id', 'customerid', 'customer_id', 'kundennummer'],
            mapped: null,
        },
        {
            id: 'name',
            title: translate('name'),
            required: true,
            prefilled: ['name'],
            mapped: null,
        },
        {
            id: 'address',
            title: translate('address'),
            required: true,
            prefilled: ['adresse', 'adress_1', 'adress1', 'address', 'strasse', 'straße'],
            mapped: null,
        },
        {
            id: 'streetNumber',
            title: translate('street_number'),
            required: true,
            prefilled: ['streetnumber', 'number', 'hausnummer', 'nummer'],
            mapped: null,
        },
        {
            id: 'addressAddition',
            title: translate('address_addition'),
            required: false,
            prefilled: ['zusatz', 'address_addition', 'adresszusatz', 'address_2'],
            mapped: null,
        },
        {
            id: 'postcode',
            title: translate('postcode'),
            required: true,
            prefilled: ['postcode', 'plz', 'post code', 'postalcode', 'postleitzahl', 'zip', 'zipcode', 'zip code'],
            mapped: null,
        },
        {
            id: 'city',
            title: translate('city'),
            required: true,
            prefilled: ['city', 'ort', 'place', 'bezirk'],
            mapped: null,
        },
        {
            id: 'country',
            title: translate('country'),
            required: false,
            prefilled: ['country', 'land'],
            mapped: null,
        },
        {
            id: 'productTitle',
            title: translate('product_title'),
            required: false,
            prefilled: ['produkt', 'product_title', 'produkt titel'],
            mapped: null,
        },
        {
            id: 'productSku',
            title: translate('product_sku'),
            required: true,
            prefilled: ['sku', 'product_sku', 'product_id', 'produkt sku'],
            mapped: null,
        },
        {
            id: 'productExternalId',
            title: translate('productExternalId'),
            required: true,
            prefilled: ['productid', 'produktid', 'produkt id', 'produkt external id', 'order id'],
            mapped: null,
        },
        {
            id: 'email',
            title: translate('email'),
            required: false,
            prefilled: ['email', 'e-mail', 'mail'],
            mapped: null,
        },
        {
            id: 'phone',
            title: translate('phone'),
            required: false,
            prefilled: ['phone', 'mobile', 'phone number', 'phonenumber', 'telefonnummer', 'handy nummer', 'telefon nummer', 'handynummer'],
            mapped: null,
        },
        {
            id: 'note',
            title: translate('note'),
            required: false,
            prefilled: ['note', 'notiz', 'bemerkung', 'bemerkungen'],
            mapped: null,
        }
    ];

    const { setJson, json, setPage, upload } = props;

    const [usedKeys, setUsedKeys] = useState([]);
    const [mappedFields, setMappedFields] = useState([...possibleFields]);
    const [valid, setValid] = useState(false);

    useEffect(() => {

        let _mappedFields = JSON.parse(JSON.stringify(mappedFields));
        let _usedKeys = [];

        Object.keys(json[0]).map(k => {
            let index = _mappedFields.findIndex(f => {
                if (f.prefilled.includes(k.toLowerCase()))
                return f.prefilled.includes(k.toLowerCase());
            })

            if (index > -1) {
                _mappedFields[index].mapped = k;
                _usedKeys.push(k);
            }
        })

        setMappedFields(_mappedFields);
        setUsedKeys(_usedKeys);

    }, [json])

    useEffect(() => {
        checkValidity();
    }, mappedFields)

    const checkValidity = () => {
        let _valid = true;

        mappedFields.forEach((v) => {
            if (v.required && v.mapped === null) {
                _valid = false;
            }
        })

        setValid(_valid)
    }

    const map = () => {
        let _json = [];

        json.map((r: any) => {

            let out = {};

            mappedFields.map(f => {
                if (f.mapped !== null) {
                    out[f.id] = r[f.mapped].toString();
                }
            })

            _json.push(out);
        })

        formatJson(_json);

    }

    const formatJson = (_json) => {

        _json = _json.map((
            {
                externalId,
                name,
                address,
                streetNumber,
                postcode,
                city,
                country,
                productSku,
                productExternalId,
                addressAddition,
                productTitle,
                email,
                phone,
                note
            }) => {
            let out = {
                externalId,
                name,
                address,
                streetNumber,
                postcode,
                city,
                parcels: [{
                    productSku,
                    externalId: productExternalId,
                }]
            };

            if (addressAddition) {
                out['addressAddition'] = addressAddition;
            }
            if (email) {
                out['email'] = email;
            }
            if (phone) {
                out['phone'] = phone;
            }
            if (note) {
                out['note'] = note;
            }
            if (country) {
                out['country'] = country;
            }else{
                out['country'] = 'AT';
            }
            if (productTitle) {
                out.parcels[0]['productTitle'] = productTitle;
            }

            return out;
        })

        upload(_json);

    }

    const changeSelect = (key: string, jsonKey: string) => {
        let _mappedFields = JSON.parse(JSON.stringify(mappedFields));

        let orIndex = mappedFields.findIndex(k => k.id === key)
        let _usedKeys = [...usedKeys];

        if (jsonKey === '') {
            let index = usedKeys.findIndex(k => mappedFields[orIndex].mapped === k)
            _usedKeys.splice(index, 1);
            setUsedKeys(_usedKeys);
            jsonKey = null;
        } else {
            _usedKeys.push(jsonKey)
            setUsedKeys(_usedKeys)
        }

        _mappedFields[orIndex].mapped = jsonKey;

        setMappedFields(_mappedFields);
    }

    return <>
        <table>
            <tbody>
                {
                    mappedFields.map(field => (
                        <tr>
                            <td>{field.title}{field.required && '*'}</td>
                            <td>
                                <select
                                    value={field.mapped !== null ? field.mapped : 'a'}
                                    onChange={(e) => changeSelect(field.id, e.target.value)}
                                >
                                    <option value={''}>-----</option>
                                    {Object.keys(json[0]).map(k => {
                                        return <option value={k} disabled={usedKeys.includes(k)}>{k}</option>
                                    })}
                                </select>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        <Button
            type={'secondary'}
            style={{ width: 'max-content' }}
            textStyle={{ width: 'max-content' }}
            preventLoading
            label={translate('upload_finish')}
            className={!valid ? 'disabled' : undefined}
            onClick={map}
        />
    </>

}

export default Mapping;