import { db } from "../../Services/Database"
import RoleUnit from "../../Services/Http/Role"
import { initJobs } from "../../Services/Redux/features/job/jobSlice"
import Storage from "../../Services/Storage"
import { hasPermission } from "./auth"
import wrlog from "./wrlog"

const initialLoadData = async (dispatch) => {

    // Roles
    if (hasPermission('user', 'canCreate')) {
        RoleUnit.listRoles().then(roles => {
            Storage.setValue('roles', roles);
        })
    }
    db.jobs.toArray().then(res => {
        dispatch(initJobs(res));
    })

}

export default initialLoadData;