import Clustering from '../../Helper/functions/dbscan';
import wrlog from './wrlog';

const createClusters = (_coordinates: any, amountRoutes: number) => {

    let coordinates = _coordinates;
    // if (coordinates[0].coordinates !== undefined) {
    //     coordinates = _coordinates.map((stop: any) => stop.coordinates);
    // }

    const { radius, matrix } = calculateRadius(coordinates);

    let markers = [];
    let { clusters, noise, clusterIndexes } = recursiveDbscan(coordinates, radius, amountRoutes);

    let noiseClusterPoints = [];

    noise.forEach(noisePointIndex => {
        let _noiseMatrix = [...matrix[noisePointIndex]]

        let minNoiseIndex = 0;
        if (_noiseMatrix[minNoiseIndex] === 0) {
            minNoiseIndex = 1;
        }

        _noiseMatrix.forEach((distance, distanceIndex: number) => {
            if (distance < _noiseMatrix[minNoiseIndex] && distance !== 0 && noise.findIndex(e => parseInt(e) === parseInt(distanceIndex)) < 0) {
                minNoiseIndex = distanceIndex;
            }
        })

        let clusterIndex = -1;

        clusterIndexes.forEach((cPointIndexes, clusterIndexIndex) => {
            if (cPointIndexes.findIndex(e => e === minNoiseIndex) > -1) {
                clusterIndex = clusterIndexIndex;
                clusters[clusterIndex].push(coordinates[noisePointIndex]);
            }
        })

        noiseClusterPoints.push({
            noiseIndex: noisePointIndex,
            clusterPointIndex: minNoiseIndex,
            clusterIndex: clusterIndex
        })

    })

    if (clusterIndexes.length > amountRoutes) {
        clusters = clusters.sort((a, b) => a.length - b.length);
        let count = 0;

        while (clusters.length > amountRoutes && count < 10) {

            let nearestClusterIndex = findNearestCluster(clusters[0], clusters, count);

            if (nearestClusterIndex > -1) {
                clusters[nearestClusterIndex] = [...cloneArray(clusters[nearestClusterIndex]), ...cloneArray(clusters[0])];

                clusterIndexes.splice(0, 1);
                clusters.splice(0, 1);
            }

            count++;
        }

    }

    coordinates.forEach((c: any) => {
        markers.push({
            longitude: c.coordinates[0],
            latitude: c.coordinates[1],
        })
    })

    return {
        clusters: clusters,
        markers: markers
    }
}


export const recursiveDbscan = (orders, radius, fixedAmountRoutes) => {

    let avClusterSize = 10;
    let numClusters = 0;

    let bestRes = [];

    let currRadius = radius;
    let works = true;

    while (numClusters < fixedAmountRoutes && works) {

        var clustering = Clustering(orders, currRadius, 'km', avClusterSize);
        clustering.fit(function (e, clusters) {
            if (clusters === null) {
                works = false;
            } else {
                numClusters = clusters.length;
                if (numClusters < fixedAmountRoutes) { // not enough clusters (routes) -> defined by vehicles for the route
                    currRadius = currRadius - 0.1
                }

                bestRes = clusters
            }

        })

    }

    works = true;

    // while (numClusters > fixedAmountRoutes && works) {

    //     var clustering = Clustering(orders, currRadius, 'km', avClusterSize);
    //     clustering.fit(function (e, clusters) {
    //         if (clusters === null) {
    //             works = false;
    //         } else {
    //             numClusters = clusters.length;
    //             if (numClusters > fixedAmountRoutes) { // not enough clusters (routes) -> defined by vehicles for the route
    //                 currRadius = currRadius + 0.1
    //             }

    //             bestRes = clusters
    //         }

    //     })

    // }

    // Build back cluster with coordinates
    let noise = Object.keys(cloneArray(orders))
    let realClusters = [];
    bestRes.forEach((cluster, index) => {
        let realCluster = [];
        cluster.forEach(waypointIndex => {
            let index = noise.findIndex(e => parseInt(e) === parseInt(waypointIndex));
            noise.splice(index, 1);
            realCluster.push(orders[waypointIndex]);
        });
        realClusters.push(realCluster);
    })

    let noiseCluster = [];
    noise.forEach((n) => {
        noiseCluster.push(orders[n]);
    })

    return { clusters: realClusters, clusterIndexes: bestRes, noise }
}

export const createMatrix = (points1: any, points2: any) => {

    let matrix = [];
    let distances = [];

    points1.forEach((coordionate1: any) => {
        let _distances: any = [];
        points2.forEach((coordionate2: any) => {
            _distances.push(calcCrow(parseFloat(coordionate1.coordinates[0]), parseFloat(coordionate1.coordinates[1]), parseFloat(coordionate2.coordinates[0]), parseFloat(coordionate2.coordinates[1])));
        })

        matrix.push(cloneArray(_distances));

        _distances = _distances.sort((a, b) => a - b);
        distances.push(_distances[2]);
    })

    return { matrix, distances };
}

export const findNearestCluster = (cluster, clusters, number) => {

    let points: any = [];
    clusters.forEach(cluster => {
        points = [...points, ...cluster];
    });

    let { matrix } = createMatrix([cluster[0]], points);
    matrix = matrix[0]

    let minNoiseIndex: number;
    let minNoisePoint: any = null;

    matrix.forEach((distance, distanceIndex) => {

        if ((minNoiseIndex === null || (distance < matrix[minNoiseIndex] && distance !== 0)) && cluster.findIndex(e => e[0] === points[distanceIndex][0] && e[1] === points[distanceIndex][1]) < 0) {
            minNoiseIndex = distanceIndex;
            minNoisePoint = points[distanceIndex];
        }

    })

    let _clusterIndex = -1;

    clusters.forEach((cPointIndexes: any, clusterIndexIndex: number) => {
        if (minNoisePoint !== null && cPointIndexes.findIndex((e: any) => e[0] === minNoisePoint[0] && e[1] === minNoisePoint[1]) > -1) {
            _clusterIndex = clusterIndexIndex;
        }
    })

    return _clusterIndex;
}



export const calcCrow = (lat1: number, lon1: number, lat2: number, lon2: number) => {

    // wrlog("points123", lat1, lat2, lon1, lon2)

    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}

export const toRad = (value) => {
    return value * Math.PI / 180;
}

export const calculateRadius = (coordinates: any) => {

    const { distances, matrix } = createMatrix(coordinates, coordinates)

    let sortedDistances = distances.sort((a, b) => a - b);

    let maxDifference = 0;
    let optimalThreshold = 0;
    for (let i = 0; i < sortedDistances.length - 2; i++) {
        let x = sortedDistances[i + 2] - (2 * sortedDistances[i + 1]) + sortedDistances[i];
        if (maxDifference < x) {
            maxDifference = x;
            optimalThreshold = i;
        }
    }


    const radius = sortedDistances[sortedDistances.length - 1];

    return { radius, matrix };
}

export const cloneArray = (array: any) => {
    return JSON.parse(JSON.stringify(array));
}


export default createClusters;