import { translate } from "../multilingual";

const translatePriority = (priorityNumber: number) => {
    switch (parseInt(priorityNumber)) {
        case 1:
            return translate('low');
        case 2:
            return translate('middle');
        case 3:
            return translate('high');
        case 4:
            return translate('asap');
    }

    return translate('no_priority');
}

export default translatePriority;