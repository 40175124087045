import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import styles from './styles.module.scss';
import { translate } from '../../../Helper/multilingual';


const StatusSelect = ({ options, value, onChange, color, style }) => {
    return <FormControl style={style} className={`${styles.select} ${color && styles[`colored-${color}`] ? styles[`colored-${color}`] : ''}`} variant="filled">
        <Select
            defaultValue={value}
            onChange={e => onChange(e.target.value)}
        >
            {
                options?.map((option: any, key: number) => {
                    return <MenuItem disabled={option?.disabled || false} key={key} value={option.value}>{option.label}</MenuItem>
                })
            }
        </Select>
    </FormControl>
}

export default StatusSelect;