import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';

import styles from '../styles.module.scss';

import { Card, Checkbox, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import wrlog from '../../../Helper/functions/wrlog';
import RouteUnit from '../../../Services/Http/Route';
import moment from 'moment';
import GeoUnit from '../../../Services/Http/Geo';
import formatTime from '../../../Helper/functions/formatTime';
import WarehouseSelect from '../../../Components/Form/WarehouseSelect';
import Button from '../../../Components/Button';
import AddressAutocomplete from '../../../Components/AddressAutocomplete';
import StartLocationInput from '../../../Components/Form/StartLocationInput';

interface StopGroupDetailProps {
    currentRoute: any;
    setCurrentRoute: any;
    reload: any;
    setSaveState: any;
    closeSidebar: any
}

/**
 * 
 * @returns Login Page
 */
const RouteDetail = (props: StopGroupDetailProps) => {

    const { currentRoute, setCurrentRoute, reload, setSaveState, closeSidebar } = props;

    useEffect(() => {
        wrlog("currentRoute", currentRoute);
    }, [currentRoute])

    const formChanged = async (key: string, value: string) => {

        let _currentRoute = { ...currentRoute };
        _currentRoute[key] = value;

        if (currentRoute.id === undefined || currentRoute.id === null) {
            return;
        }

        setSaveState('saving');

        const body = {}

        if (key === 'startDateTime' || key === 'endDateTime') {
            let _value = new Date(_currentRoute.startDate);
            let split = value.split(':');

            _value.setHours(parseInt(split[0]));
            _value.setMinutes(parseInt(split[1]));
            _value.setSeconds(0);
            _value.setMilliseconds(0);
            value = moment(_value).format();
        }

        body[key] = value;

        RouteUnit.update(currentRoute.id, body)
            .then(() => {
                setSaveState('saved');
                setTimeout(() => {
                    setSaveState('default');
                }, 2000)
            }).catch(err => {

            })
    }

    const formChangedValue = (value: string, key: string) => {
        let _currentRoute = { ...currentRoute };
        _currentRoute[key] = value;
        setCurrentRoute(_currentRoute);
    }

    return (
        <>
            <Grid container style={{ marginTop: 30 }}>
                <Grid item md={12} className={styles.center}>
                    <h4 style={{ margin: 0, display: 'inline-block', marginRight: 10 }}>{currentRoute?.title}</h4>
                </Grid>
            </Grid>
            <hr style={{ width: '100%', marginTop: 20, marginBottom: 10 }} />
            <Grid container spacing={2}>
                <Grid item md={6} padding={0} className='align_right'>
                    <TextField
                        value={formatTime(currentRoute.startDateTime)}
                        onChange={e => formChangedValue(e.target.value, 'startDateTime')}
                        onBlur={e => formChanged('startDateTime', e.target.value)}
                        id="standard-basic"
                        label={translate('start_time')}
                        type="text"
                        className='dark'
                    />
                </Grid>
                <Grid item md={6} padding={0} className='align_right'>
                    <TextField
                        value={currentRoute.pauseTime}
                        onChange={e => formChangedValue(e.target.value, 'pauseTime')}
                        onBlur={e => formChanged('pauseTime', e.target.value)}
                        id="standard-basic"
                        label={translate('pause_time')}
                        type="text"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">{translate('minutes_short')}</InputAdornment>,
                        }}
                        className='dark'
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={12} padding={0}>
                    <strong>{translate('start_address')}</strong>
                </Grid>
            </Grid>

            <StartLocationInput
                onChange={async ({ title, location }) => {

                    let _currentRoute = { ...currentRoute };
                    _currentRoute.startLocationTitle = title;
                    _currentRoute.startLocation = location;
                    setCurrentRoute(_currentRoute);

                    await formChanged('startLocationTitle', title);
                    await formChanged('startLocation', location);
                    await reload();
                }}
                value={currentRoute.startLocationTitle}
            />

            <h4>{translate('default_end_location')}</h4>

            <FormControl variant="filled">
                <InputLabel>{translate('return_to_start_location')}</InputLabel>
                <Select
                    defaultValue={currentRoute.returnToStartLocation}
                    onChange={e => {
                        formChangedValue(e.target.value, 'returnToStartLocation')
                        formChanged('returnToStartLocation', e.target.value)
                    }}
                    label={translate('return_to_start_location')}
                >
                    <MenuItem value={'returnToStartLocation'}>{translate('return_to_start_location')}</MenuItem>
                    <MenuItem value={'none'}>{translate('no_end_location')}</MenuItem>
                </Select>
            </FormControl>

            <TextField
                value={formatTime(currentRoute.endDateTime)}
                onChange={e => formChangedValue(e.target.value, 'endDateTime')}
                onBlur={e => formChanged('endDateTime', e.target.value)}
                id="standard-basic"
                label={`${translate('end_time')} (${translate('optional')})`}
                type="text"
                className='dark'
            />

            <h4>{translate('optimizing_for')}</h4>
            <FormControl variant="filled">
                <InputLabel>{translate('vehicle')}</InputLabel>
                <Select
                    value={currentRoute.vehicle ?? currentRoute.vehicle}
                    onChange={e => {
                        formChangedValue(e.target.value, 'vehicle')
                        formChanged('vehicle', e.target.value)
                    }}
                    label={translate('vehicle')}
                >
                    <MenuItem value={'delivery_van'}>{translate('delivery_van')}</MenuItem>
                    <MenuItem value={'car'}>{translate('car')}</MenuItem>
                    <MenuItem value={'truck'}>{translate('truck')}</MenuItem>
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                <Grid item md={6} padding={0}>
                    <TextField
                        value={currentRoute.timeForStop}
                        onChange={e => formChangedValue(e.target.value, 'timeForStop')}
                        onBlur={e => formChanged('timeForStop', e.target.value)}
                        id="standard-basic"
                        label={translate('time_for_stop')}
                        type="text"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">{translate('minutes_short')}</InputAdornment>,
                        }}
                        className='dark'
                    />
                </Grid>
                <Grid item md={6} padding={0}>
                    <FormControl variant="filled">
                        <InputLabel>{translate('distance_unit')}</InputLabel>
                        <Select
                            value={currentRoute.distanceUnit}
                            label={translate('distance_unit')}
                            onChange={e => {
                                formChangedValue(e.target.value, 'distanceUnit')
                                formChanged('distanceUnit', e.target.value)
                            }}
                        >
                            <MenuItem value={'metric'}>{translate('metric')}</MenuItem>
                            <MenuItem value={'imperial'}>{translate('imperial')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}

export default RouteDetail;