import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../Helper/multilingual';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../Assets/Logo.png';

import styles from './styles.module.scss';

import { Card, Grid, Skeleton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import Map from '../../Components/Map';
import Button from '../../Components/Button';
import CsvImporter from '../../Components/CsvImporter';
import RouteStopUnit from '../../Services/Http/RouteStop';
import wrlog from '../../Helper/functions/wrlog';
import AddressAutocomplete from '../../Components/AddressAutocomplete/index';
import DetailContainer from '../../Components/DetailContainer';
import RouteStopDetail from '../Logistics/RouteStopDetail';
import { setRouteStops, updateRouteStop } from '../../Services/Redux/features/routeStops/routeStopsSlice';
import { setRouteStopData } from '../../Services/Redux/features/routeStop/routeStopSlice';
import ParcelDetail from '../../Components/ParcelDetailContainer';

interface DashbardProps {
}

/**
 * 
 * @returns Login Page
 */
const Dashboard = (props: DashbardProps) => {

    const [flyToDestination, setFlyToDestination] = useState(null);
    const [currentStop, setCurrentStop] = useState(null);
    const [detailContainerVisible, setDetailContainerVisible] = useState(false);
    const [detailContainerLoading, setDetailContainerLoading] = useState(true);
    const [currentParcelId, setCurrentParcelId] = useState(null);
    const [saveState, setSaveState] = useState('default');

    const dispatch = useDispatch();
    const routeStops = useSelector((state) => state.routeStops);

    useEffect(() => {
        loadSoloStops();
    }, [])

    useEffect(() => {
        wrlog("currentParcelId", currentParcelId, currentStop?.id)
    }, [currentParcelId])

    const loadSoloStops = async () => {
        try {
            const res = await RouteStopUnit.listSolo();
            dispatch(setRouteStops(res))
            return res;
        } catch (err) {
            wrlog(err)
        }
    }

    const loadStopDetail = async (routeStopId: string) => {
        setDetailContainerLoading(true);
        try {
            const data = await RouteStopUnit.getStop(routeStopId);
            dispatch(setRouteStopData(data));
            setCurrentStop(data);
            setDetailContainerLoading(false);
        } catch (err) {
            wrlog(err);
        }
    }


    const selectSoloStop = (routeStop: any) => {
        setFlyToDestination([routeStop.longitude, routeStop.latitude]);
        loadStopDetail(routeStop.id);
        setDetailContainerVisible(true);
        setDetailContainerLoading(true);
    }

    const addRouteStop = async (data: any) => {
        let body = {
            id: null,
            address: data.address,
            streetNumber: data.streetNumber,
            addressAddition: null,
            postcode: data.postcode,
            city: data.city,
            country: data.country || null,
            longitude: data.longitude,
            latitude: data.latitude,
            note: null,
            name: null,
            email: null,
            phone: null,
            productTitle: null,
            productSku: null
        };

        try {
            const res = await RouteStopUnit.create(body);
            const _routeStops = await loadSoloStops();
            const solo = _routeStops.filter((r: any) => r.id === res.id);
            selectSoloStop(solo);
        } catch (err) {
            console.log(err);
        }
    }

    const detailLoading = () => (
        <>
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={'calc(100% - 200px)'} width={'100%'} style={{ marginTop: 20 }} />
        </>
    )

    return (
        <>
            <Sidebar>
                <Card>
                    <h4 style={{ marginBottom: 10, marginLeft: 5 }}>{translate('stops')}</h4>
                    <Grid container padding={0}>
                        <Grid item md={12} padding={0}>
                            {/* <TextField
                                id="standard-basic"
                                placeholder={translate('add_stop')}
                                type="search"
                                className='extrasmall dark'
                            /> */}
                            <AddressAutocomplete
                                reload={loadSoloStops}
                                save={addRouteStop}
                                id={'addAddress'}
                            />
                            <CsvImporter
                                reload={loadSoloStops}
                            />
                        </Grid>
                    </Grid>
                    <hr style={{ width: '100%' }} />
                    <div className='smallListContainer'>
                        {
                            routeStops !== undefined && routeStops.map((routeStop: any, key: number) => {
                                return <div
                                    key={key}
                                    className='smallListItem cuttext'
                                    onClick={() => selectSoloStop(routeStop)}
                                >
                                    <strong>{routeStop.address} {routeStop.streetNumber}</strong><br />
                                    {routeStop.postcode} {routeStop.city}<br />
                                    <div className='badge'>ASAP</div>
                                </div>
                            })
                        }
                    </div>
                </Card>
            </Sidebar>
            <div className='mapContainer'>
                <Map
                    markers={routeStops !== undefined ? [...routeStops] : []}
                    routes={[]}
                    drivers={[]}
                    flyTo={flyToDestination}
                />

                <DetailContainer
                    visible={detailContainerVisible}
                    close={() => setDetailContainerVisible(false)}
                >
                    {saveState !== 'default' && <div
                        style={{ top: 20, right: 0 }}
                        className={`saveBadge ${saveState === 'saving' ? 'animate-flicker' : undefined}`}>{translate(saveState)}</div>}
                    {
                        detailContainerLoading ? detailLoading() :
                            <RouteStopDetail
                                selectedRouteStopId={currentStop?.id}
                                reload={loadSoloStops}
                                setSaveState={setSaveState}
                                reloadSingle={() => loadStopDetail(currentStop?.id)}
                                closeSidebar={() => setDetailContainerVisible(false)}
                                currentParcelId={currentParcelId}
                                setCurrentParcelId={setCurrentParcelId}
                            />
                    }
                </DetailContainer>
                {currentStop?.id !== undefined && currentParcelId !== null && <DetailContainer
                    visible={true}
                    disableClose={false}
                    close={() => setCurrentParcelId(null)}
                    level={2}
                >
                    <ParcelDetail
                        showStatus={false}
                        currentStopId={currentStop?.id}
                        setSaveState={setSaveState}
                        reloadSingle={() => loadStopDetail(currentStop?.id)}
                        closeSidebar={() => setDetailContainerVisible(false)}
                        currentParcelId={currentParcelId}
                        setCurrentParcelId={setCurrentParcelId}
                    />
                </DetailContainer>}
            </div>

        </>
    );
}

export default Dashboard;