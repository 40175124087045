import wrlog from '../../Helper/functions/wrlog';
import Storage from '../Storage';
import Unit from './index';

export default class ImportsUnit extends Unit {

    static listImports = async (page: number, take: number) => {
        return await Unit.get(`/imports?p=${page}&t=${take}`);
    }

    static listImportData = async (importId: string, status: any, page: number, take: number) => {
        return await Unit.get(`/imports/${importId}?p=${page}&t=${take}${status ? `&s=${status}` : ''}`);
    }

    static getData = async (importId: string, dataId: string) => {
        return await Unit.get(`/imports/${importId}/${dataId}`);
    }

    static updateData = async (importId: string, dataId: string, data: any) => {
        return await Unit.put(`/imports/${importId}/${dataId}`, data);
    }

    static addData = async (stopGroupId: string, title: string) => {
        return await Unit.post(`/stop/group`, { id: stopGroupId, title: title });
    }

    static rerunWorkflow = async (importId: string) => {
        return await Unit.post(`/import/${importId}/workflows`);
    }

    // Clustering
    static runClustering = async (importId: string, settings: object) => {
        return await Unit.post(`/import/${importId}/clustering`, settings);
    }

    static getClustering = async (importId: string) => {
        return await Unit.get(`/import/${importId}/clustering`);
    }

    static createRoutes = async (importId: string, settings: object) => {
        return await Unit.post(`/import/${importId}/clustering/routes`, settings);
    }

}



