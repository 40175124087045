import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../../Assets/Logo.png';

import { Card, Checkbox, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../Sidebar';
import Map from '../../../Components/Map';
import Button from '../../../Components/Button';
import CsvImporter from '../../../Components/CsvImporter';
import RouteStopUnit from '../../../Services/Http/RouteStop';
import wrlog from '../../../Helper/functions/wrlog';
import AddressAutocomplete from '../../../Components/AddressAutocomplete/index-2';
import DetailContainer from '../../../Components/DetailContainer';
import RouteStopDetail from '../RouteStopDetail';
import StopGroupUnit from '../../../Services/Http/StopGroup';

import Popup from '../../../Components/Popup';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import RouteDetail from './RouteDetail';
import { setRouteStopData } from '../../../Services/Redux/features/routeStop/routeStopSlice';
import { setStopGroup } from '../../../Services/Redux/features/stopGroups/stopGroupsSlice';

import moment from 'moment';
import RouteUnit from '../../../Services/Http/Route';
import RouteListSidebar from './routeListSidebar';
import AddRouteSidebar from './addRouteSidebar';
import CompanyUnit from '../../../Services/Http/Company';
import GeoUnit from '../../../Services/Http/Geo';
import { loadNamespaces } from 'i18next';
import { setRoutesData } from '../../../Services/Redux/features/routes/routesSlice';
import Calendar from '../../../Components/Calendar';
import { arrayMoveImmutable } from 'array-move';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ParcelDetail from '../../../Components/ParcelDetailContainer';
import { reduceData, setRouteStops } from '../../../Services/Redux/features/routeStops/routeStopsSlice';
import sameJson from '../../../Helper/functions/compareJson';
import { cloneArray } from '../../../Helper/functions/cluster';
import createDetailUrl from '../../../Helper/functions/createDetailUrl';
import { Buffer } from "buffer";

interface propState {
    routeId?: string;
    routeDate?: string;
    routeStopId?: string;
}

/**
 * 
 * @returns Login Page
 */
const Routes = (props: any) => {

    const dispatch = useDispatch();

    const [flyToDestination, setFlyToDestination] = useState([]);
    const [currentStop, setCurrentStop] = useState(null);
    const [detailContainerVisible, setDetailContainerVisible] = useState(false);
    const [detailContainerLoading, setDetailContainerLoading] = useState(false);
    const [routeLoading, setRouteLoading] = useState(false);

    const [routeStopLoading, setRouteStopLoading] = useState(false);
    const [detailContainerType, setDetailContainerType] = useState('route');
    const [saveState, setSaveState] = useState('default');
    const [addStopGroupPopupOpen, setAddStopGroupPopupOpen] = useState(false);
    const [assignStopsToGroupPopupOpen, setAssignStopsToGroupPopupOpen] = useState(false);
    const [clusters, setClusters] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [driverLocations, setDriverLocations] = useState([]);
    const [mapRoutes, setMapRoutes] = useState([]);
    const [isAddRoute, setIsAddRoute] = useState(false);

    const [drivers, setDrivers] = useState([]);
    const [driversForRoute, setDriversForRoute] = useState([]);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [changedDate, setChangedDate] = useState(new Date());
    const [showStopIds, setShowStopIds] = useState([]);
    const [filterStatus, setFilterStatus] = useState('all');
    const [filteredRoutes, setFilteredRoutes] = useState([]);
    const [allRoutes, setAllRoutes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [selectDriverPopupOpen, setSelectDriverPopupOpen] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState('')
    const [expandRouteStops, setExpandRouteStops] = useState(false);
    const [changeDatePopupOpen, setChangeDatePopupOpen] = useState(false);
    const [assignToDifferentRoutePopupOpen, setAssignToDifferentRoutePopupOpen] = useState(false)
    const [removeRouteStopPopupOpen, setRemoveRouteStopPopupOpen] = useState(false)
    const [removeRoutePopupOpen, setRemoveRoutePopupOpen] = useState(false)
    const [selectedRouteId, setSelectedRouteId] = useState(null);
    const [routingErrorPopupOpen, setRoutingErrorPopupOpen] = useState(false);
    const [routingErrorType, setRoutingErrorType] = useState('first');
    const [optimizationErrorDescription, setOptimizationErrorDescription] = useState('');
    const [stopsForRoute, setStopsForRoute] = useState([]);
    const [amountRoutes, setAmountRoutes] = useState(1);
    const [currentParcelId, setCurrentParcelId] = useState(null);
    const [routeStopsToChange, setRouteStopsToChange] = useState([]);
    const [changingRoute, setChangingRoute] = useState(false);

    const navLocation = useLocation();
    const _navState = navLocation.state as propState;
    const [navState, setNavState] = useState(null);

    const selectDriverButtonRef = useRef();
    const changeDateButtonRef = useRef();
    const removeRouteStopButtonRef = useRef();
    const removeRouteButtonRef = useRef();
    const changeStatusButtonRef = useRef();
    const mapRef = useRef();

    const routes = useSelector(state => state.routes);
    const routeStops = useSelector(state => state.routeStops);
    const searchTerm = useSelector(state => state.search);

    const [currentRoute, setCurrentRoute] = useState({});
    const [currentRouteDefault, setCurrentRouteDefault] = useState({});

    const [urlRouteStopId, setUrlRouteStopId] = useState(null) as any;
    const [init, setInit] = useState(false);
    const [currentQ, setCurrentQ] = useState(null);

    useEffect(() => {
        console.log("routes changed", routes[0]);

    }, [routes])

    const navigate = useNavigate();

    useEffect(() => {
        console.log(init, navLocation);
        selectRouteFromUrl();
        setInit(true);

    }, [navLocation])

    useEffect(() => {
        CompanyUnit.getCompanySettings().then(settings => {

            let _defaultCurrentRoute = {};

            if (settings.routeStartLocation !== undefined) {
                _defaultCurrentRoute['startLocationTitle'] = settings.routeStartLocationTitle;
                _defaultCurrentRoute['startLocation'] = settings.routeStartLocation;

                console.log("_defaultCurrentRoute", _defaultCurrentRoute)
            }
            if (settings.routeReturnToStartLocation !== undefined) {
                _defaultCurrentRoute['returnToStartLocation'] = settings.routeReturnToStartLocation;
            }
            if (settings.routeStartTime !== undefined) {
                _defaultCurrentRoute['startDateTime'] = settings.routeStartTime;
            }
            if (settings.routeEndTime !== undefined) {
                _defaultCurrentRoute['endDateTime'] = settings.routeEndTime;
            }
            if (settings.routePauseTime !== undefined) {
                _defaultCurrentRoute['pauseTime'] = settings.routePauseTime;
            }
            if (settings.routeTimeForStop !== undefined) {
                _defaultCurrentRoute['timeForStop'] = settings.routeTimeForStop;
            }
            if (settings.routeVehicle !== undefined) {
                _defaultCurrentRoute['vehicleType'] = settings.routeVehicle;
            }
            if (settings.routeDistanceUnit !== undefined) {
                _defaultCurrentRoute['distanceUnit'] = settings.routeDistanceUnit;
            }

            setCurrentRouteDefault(_defaultCurrentRoute);
        })

        loadDrivers();
    }, [])

    useEffect(() => {
        setRouteLoading(true);
        initialLoadRoutes();
    }, [selectedDate]);

    useEffect(() => {
        const interval = setInterval(() => {
            loadRoutes(true);
        }, 20 * 1000);


        const bigInterval = setInterval(() => {
            loadRoutes(false);
        }, 60 * 1000);

        return () => {
            clearInterval(interval)
            clearInterval(bigInterval)
        }
    }, [])

    useEffect(() => {
        if (mapRef.current && currentStop?.attemptLocation && mapRef.current.loaded()) {
            mapRef.current.setDropoffMarker(currentStop?.attemptLocation[1], currentStop?.attemptLocation[0], currentRoute?.id, [])
        } else {
            mapRef.current.removeDropoffMarker()
        }

        const interval = setInterval(() => {

            if (currentRoute?.id) {
                loadRouteStops(currentRoute?.id)
            }

            if (currentStop?.id) {
                RouteStopUnit.getStop(currentStop?.id).then((res: any) => {
                    if (currentStop?.id === res.id && !sameJson(currentStop, res)) {
                        setCurrentStop(res);
                        dispatch(setRouteStopData(res));
                    }
                }).catch((err: any) => {
                    wrlog(err);
                })
            }

        }, 20 * 1000);

        return () => clearInterval(interval)
    }, [currentRoute, currentStop])

    useEffect(() => {
        if (currentRoute?.id) {
            dispatch(setRouteStops([]));
            loadRouteStops(currentRoute?.id);
        }
    }, [currentRoute?.id])

    useEffect(() => {
        let formatedDate = moment(selectedDate).format('YYYY-MM-DD');

        let _allRoutes = routes.filter((route: any) => {
            return route?.startDateTime?.includes(formatedDate);
        })

        setAllRoutes(_allRoutes)

        let _filteredRoutes = _allRoutes.filter((route: any) => {
            return (filterStatus === 'all' || filterStatus === route.status);
        })

        if (searchTerm?.route && searchTerm?.route !== '') {

            let splittedSearchTerm = searchTerm?.route.split(' ').map((t: string) => t.toLowerCase()).filter((t: string) => t.length > 0);

            _filteredRoutes = _filteredRoutes.filter((r: any) => {
                let valid = false;

                if (r?.stopLocations?.find((s: any) => {
                    return (
                        splittedSearchTerm.some((t: string) => s?.address?.toString().toLowerCase().includes(t))
                        || splittedSearchTerm.some((t: string) => s?.streetNumber?.toString().toLowerCase().includes(t))
                        || splittedSearchTerm.some((t: string) => s?.postcode?.toString().toLowerCase().includes(t))
                        || splittedSearchTerm.some((t: string) => s?.city?.toString().toLowerCase().includes(t))
                        || splittedSearchTerm.some((t: string) => s?.name?.toString().toLowerCase().includes(t))
                        || splittedSearchTerm.some((t: string) => s?.tags?.includes(t))
                    )
                })) {
                    valid = true;
                }

                if (
                    splittedSearchTerm.some((t: string) => r?.title?.toString().toLowerCase().includes(t))
                    || splittedSearchTerm.some((t: string) => r?.driver?.name?.toString().toLowerCase().includes(t))
                ) {
                    valid = true;
                }

                return valid;
            })
        }

        if (currentRoute?.id) {
            let index = _filteredRoutes.findIndex((e: any) => e.id === currentRoute.id)
            if (index >= 0) {
                setCurrentRoute({ ..._filteredRoutes[index] });
            }
        }

        setFilteredRoutes(_filteredRoutes);
    }, [selectedDate, filterStatus, routes, searchTerm])

    useEffect(() => {
        console.log(searchTerm);
    }, [searchTerm])

    useEffect(() => {
        let _markers = [];
        let _driverLocations = [];

        filteredRoutes.forEach((route: any, key: number) => {
            if (route.startLocation !== undefined) {
                _markers.push({
                    icon: 'start',
                    colorIndex: key + 1,
                    id: 'start-' + route.id,
                    longitude: route.startLocation[0],
                    latitude: route.startLocation[1]
                })
            }

            if (route?.stopLocations) {
                route.stopLocations.forEach((stop: any) => {
                    _markers.push({
                        number: stop.order,
                        colorIndex: key + 1,
                        id: stop.id,
                        status: stop.status,
                        address: stop.address,
                        streetNumber: stop.streetNumber,
                        postcode: stop.postcode,
                        city: stop.city,
                        longitude: stop.longitude,
                        latitude: stop.latitude
                    })

                    return stop.id;
                });
            }

            if (route.driverLocation) {
                _driverLocations.push({
                    colorIndex: key + 1,
                    latitude: route.driverLocation.latitude,
                    longitude: route.driverLocation.longitude
                })
            }

        });

        const setOnMap = (timeout: number) => {
            setTimeout(() => {
                if (mapRef.current && mapRef.current.loaded()) {
                    mapRef.current.setRoutes(filteredRoutes.map((r, k) => ({ geometry: r.geometry, id: r.id, colorIndex: k + 1 })))
                    mapRef.current.setMarkers(_markers)
                    mapRef.current.setDrivers(_driverLocations)
                } else {
                    setOnMap(100);
                }
            }, timeout)
        }

        setOnMap(0);

    }, [filteredRoutes])

    useEffect(() => {
        if (isAddRoute) {
            let _default = { ...currentRouteDefault };
            setCurrentRoute(_default)
        }
    }, [isAddRoute])

    const selectRouteFromUrl = async () => {
        const params = new URLSearchParams(navLocation.search);
        let query = params.get('q');

        let routeId: any = null;
        let routeStopId: any = null;
        if (query) {

            // Load raw
            const raw = Buffer.from(query, 'base64').toString();

            const preventReload = raw?.includes('--preventreload');

            console.log("QUERY", preventReload, init, raw);

            if (init && preventReload) {
                return;
            }

            routeId = raw;
            if (routeId.includes(':')) {
                let split = routeId.split(':');
                routeId = split[0];
                routeStopId = split[1];
            }

            // Load route for date
            try {
                const { startDate } = await RouteUnit.getRoute(routeId);

                console.log("QUERY startDate", startDate, routeStopId);

                if (startDate) {
                    setSelectedDate(new Date(startDate));
                    selectRoute(null, { id: routeId }, false);
                    if (routeStopId) {
                        setUrlRouteStopId(routeStopId);
                        loadRouteStops(routeId, routeStopId);
                    }
                } else {
                    throw new Error();
                }
            } catch (err) {
                console.warn("QUERY 1", err);
                navigate('/logistics/routes', { replace: true });
            }


        }
    }

    const loadStopGroups = () => {
        StopGroupUnit.listStopGroups().then((res: any) => {
            res.forEach((stopGroup: any) => {
                dispatch(setStopGroup(stopGroup));
            });
        }).catch((err: any) => {
            wrlog(err)
        })
    }

    const loadDrivers = async () => {
        const drivers = await CompanyUnit.listUsers('', '', 'driver', 0, 10000)
        setDrivers(drivers);
    }

    const initialLoadRoutes = async () => {
        setRouteLoading(true);
        await loadRoutes();

        setRouteLoading(false);
        setCreateLoading(false);
        setLoading(false)
    }

    const loadRoutes = async (update = false) => {
        let body = {
            date: moment(selectedDate).format('YYYY-MM-DD'),
            status: "",
            update: update
        }

        try {
            const res = await RouteUnit.list(body);
            if (!update || !sameJson(routes, res)) {
                res.forEach((route: any) => {
                    dispatch(setRoutesData(route));
                });
            }
        } catch (err) {
            wrlog(err)
            return false;
        }
    }

    const loadRouteStops = async (routeId: string, routeStopId: string = '') => {
        try {
            const res = await RouteUnit.getStops(routeId);

            if (urlRouteStopId || routeStopId !== '') {

                if(!routeStopId){
                    routeStopId = urlRouteStopId
                }
                const index = res.findIndex((rs: any) => rs.id === routeStopId);
                if (index > -1) {
                    selectRouteStop(res[index], null, false);
                }
            }

            if (Array.isArray(res)) {
                dispatch(setRouteStops(res));
            }

        } catch (err) {
            wrlog(err)
            return false;
        }
    }

    const loadStopDetail = (routeStopId: string) => {
        setDetailContainerLoading(true);
        RouteStopUnit.getStop(routeStopId).then((res: any) => {
            setCurrentStop(res);
            dispatch(setRouteStopData(res));
            setDetailContainerLoading(false);
        }).catch((err: any) => {
            wrlog(err);
        })
    }

    const selectRoute = (e: any, route: any, setUrl = true) => {
        if (e === null && route === null) {
            setCurrentRoute({})
            return;
        }

        if (e !== null && (e.target.closest('.button') !== null || e.target.closest('.MuiButtonBase-root') !== null || e.target.closest('.MuiBackdrop-root') !== null)) {
            setCurrentRoute(route)
            return;
        }

        let urlAddition = '';

        if (route.id === currentRoute?.id && e !== null) {
            setCurrentRoute(null);
            setDetailContainerType(null);
            setExpandRouteStops(false);
            setDetailContainerVisible(false);
            setDetailContainerLoading(false);
            urlAddition = createDetailUrl('/logistics/routes', null, null, true);
        } else {
            setCurrentRoute(route);
            // loadRouteStops(route.id);
            setDetailContainerType('route');
            setExpandRouteStops(true);
            setDetailContainerVisible(true);
            setDetailContainerLoading(false);
            urlAddition = createDetailUrl('/logistics/routes', route.id, null, true);
        }


        if (setUrl) {
            console.log("urlAddition", urlAddition);
            navigate(urlAddition, {
                replace: true
            });
        }
    }

    const selectDriver = () => {
        if (currentRoute.id === undefined) {
            return;
        }
        RouteUnit.update(currentRoute.id, { driver: selectedDriver }).then(res => {
            selectDriverButtonRef.current.changeState('success');
            setTimeout(() => {
                loadRoutes();
                setSelectDriverPopupOpen(false);
            }, 500)
        }).catch(err => {
            selectDriverButtonRef.current.changeState('error');
        })
    }

    const changeDate = () => {
        if (currentRoute.id === undefined) {
            return;
        }

        let _changedDate = changedDate;
        if (typeof _changedDate !== 'object') {
            _changedDate = new Date(_changedDate);
        }

        if (currentRoute.startDateTime instanceof Date) {
            _changedDate.setHours(currentRoute.startDateTime.getHours());
            _changedDate.setMinutes(currentRoute.startDateTime.getMinutes());
        } else {

            const splittedStart = currentRoute.startDateTime.split('T')[1].split(':');

            _changedDate.setHours(parseInt(splittedStart[0]));
            _changedDate.setMinutes(parseInt(splittedStart[1]));
        }

        let body = {
            startDateTime: _changedDate
        }

        if (currentRoute.endDateTime !== null) {
            let newEndDateTime = new Date(_changedDate);

            if (currentRoute.endDateTime instanceof Date) {
                newEndDateTime.setHours(currentRoute.endDateTime.getHours());
                newEndDateTime.setMinutes(currentRoute.endDateTime.getMinutes());
            } else {
                const splittedStart = currentRoute.endDateTime.split('T')[1].split(':');

                newEndDateTime.setHours(parseInt(splittedStart[0]));
                newEndDateTime.setMinutes(parseInt(splittedStart[1]));
            }

            body['endDateTime'] = newEndDateTime;
        }

        RouteUnit.update(currentRoute.id, body).then(res => {
            changeDateButtonRef.current.changeState('success');
            dispatch(setRoutesData(res))
            setTimeout(() => {
                setChangeDatePopupOpen(false);
            }, 500)
        }).catch(err => {
            console.log(err);
            changeDateButtonRef.current.changeState('error');
        })
    }

    const assignToDifferentRoute = () => {
        if (currentRoute.id === undefined) {
            return;
        }

        let body = {
            routeStopIds: routeStopsToChange,
            newRouteId: selectedRouteId
        }

        RouteUnit.assignToDifferentRoute(currentRoute.id, body).then(res => {
            changeStatusButtonRef.current.changeState('success');

            res.forEach((route: any) => {
                dispatch(setRoutesData(route));
            });

            setChangingRoute(false);

            setTimeout(() => {
                setAssignToDifferentRoutePopupOpen(false);
                loadRoutes();
                loadRouteStops(currentRoute.id);
            }, 500)
        }).catch(err => {
            changeStatusButtonRef.current.changeState('error');
        })
    }

    const removeRoute = () => {
        if (currentRoute.id === undefined) {
            return;
        }

        let body = {
            status: 'deleted'
        }

        RouteUnit.update(currentRoute.id, body).then(res => {
            removeRouteButtonRef.current.changeState('success');
            loadRoutes();
            setTimeout(() => {
                setRemoveRoutePopupOpen(false);
            }, 500)
        }).catch(err => {
            removeRouteButtonRef.current.changeState('error');
        })
    }

    const removeRouteStop = () => {

        if (currentRoute.id === undefined) {
            return;
        }

        RouteUnit.detachRouteStop(currentRoute.id, currentStop.id).then(res => {
            wrlog(res)
            removeRouteStopButtonRef.current.changeState('success');
            loadRoutes();
            setTimeout(() => {
                setRemoveRouteStopPopupOpen(false);
            }, 500)
        }).catch(err => {
            wrlog(err)
            removeRouteStopButtonRef.current.changeState('error');
        })
    }

    const selectRouteStop = (routeStop: any, e: any, setUrl = true) => {
        mapRef.current.flyTo([routeStop.longitude, routeStop.latitude])

        if (e !== null && e.target.closest('.MuiButtonBase-root') !== null) {
            return;
        }

        setCurrentStop(routeStop);

        if (routeStop || e !== null) {
            setCurrentStop(routeStop);
            loadStopDetail(routeStop.id);
            setDetailContainerType('routeStop');
            setDetailContainerVisible(true);
            // setDetailContainerLoading(true);

            wrlog('/logistics/routes', currentRoute.id, routeStop.id)

            const urlAddition = createDetailUrl('/logistics/routes', currentRoute.id, routeStop.id, true);

            if (setUrl) {
                console.log("urlAddition", urlAddition);
                navigate(urlAddition, {
                    replace: true
                });
            }

        }
    }

    const setShowDetail = (show: boolean) => {
        setDetailContainerType('route');
        setDetailContainerVisible(show);
        setDetailContainerLoading(false);
    }

    const onRouteStopSort = async ({ oldIndex, newIndex }) => {

        let _routeStops = [...routeStops];

        let _currentRoute = cloneArray(currentRoute);

        _routeStops = arrayMoveImmutable([..._routeStops], oldIndex, newIndex);

        let arrayToReSort = _routeStops.map((s, k) => ({
            ...s,
            order: k + 1
        }))

        _currentRoute.stopLocations = _currentRoute.stopLocations.map((sl: any) => {
            const index = arrayToReSort.findIndex(s => s.id === sl.id);
            if (index > -1) {
                return {
                    ...sl,
                    order: arrayToReSort[index].order
                }
            }
        })

        RouteUnit.resort(_currentRoute.id, arrayToReSort).then(geometry => {
            const index = routes.findIndex(r => r.id === _currentRoute.id);
            let route = { ...routes[index] };
            route.geometry = geometry
            route.stopLocations = _currentRoute.stopLocations

            dispatch(setRoutesData(route));
        }).catch(err => wrlog(err))

        dispatch(setRouteStops(_routeStops));
        setCurrentRoute(_currentRoute);
    }

    const calculateRoutes = async (manually = false, allowFreeRouteAmount = false) => {

        setRoutingErrorPopupOpen(false);
        setCreateLoading(true);
        setLoading(true);

        let startDateTime = new Date(selectedDate);
        let endDateTime: any = null;

        if (currentRoute.endDateTime !== undefined && currentRoute.endDateTime !== null && currentRoute.endDateTime !== '') {
            endDateTime = new Date(selectedDate);
            endDateTime.setHours(currentRoute.endDateTime.split(':')[0])
            endDateTime.setMinutes(currentRoute.endDateTime.split(':')[1])
        }
        startDateTime.setHours(currentRoute.startDateTime.split(':')[0])
        startDateTime.setMinutes(currentRoute.startDateTime.split(':')[1])

        let settings = {
            startLocation: currentRoute.startLocation,
            startLocationTitle: currentRoute.startLocationTitle,
            date: selectedDate,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            pauseTime: parseInt(currentRoute.pauseTime),
            returnToStartLocation: currentRoute.returnToStartLocation === 'returnToStartLocation',
            vehicle: currentRoute.vehicleType,
            timeForStop: parseInt(currentRoute.timeForStop),
            distanceUnit: currentRoute.distanceUnit,
            freeRouteAmount: allowFreeRouteAmount
        }

        let driverIds = driversForRoute.map(d => d.id);


        if (amountRoutes > driversForRoute.length) {
            for (let i = driversForRoute.length; i < amountRoutes; i++) {
                driverIds.push(driverIds[driverIds.length - 1]);
            }
        }

        const body = {
            drivers: driverIds,
            settings,
            stops: stopsForRoute.map(rs => rs.id)
        }

        if (manually) {
            RouteUnit.createRoutes(body).then(_routes => {
                setMarkers([]);
                setMapRoutes([]);
                setIsAddRoute(false);
                loadRoutes();
            })
        } else {
            RouteUnit.calculateRoutes(body).then(_routes => {

                setCreateLoading(false);
                setLoading(false);

                setMarkers([]);
                setMapRoutes([]);
                setIsAddRoute(false);
                loadRoutes();
            }).catch(err => {
                setRoutingErrorPopupOpen(true);
                setOptimizationErrorDescription(err.description)

                setCreateLoading(false);
                setLoading(false);

                if (err.description.includes('still')) {
                    setRoutingErrorType('second');
                } else {
                    setRoutingErrorType('first');
                }
            })
        }


    }

    const createRoutesManually = async () => {
        await calculateRoutes(true);
    }

    const optimize = async (routeId: string) => {
        setCreateLoading(true);
        setLoading(true);

        try {
            await RouteUnit.optimizeRoute(routeId);
            setCreateLoading(false);
            setLoading(false);
            loadRoutes();
            loadRouteStops(routeId);
        } catch (err) {
            console.log("error")
            setCreateLoading(false);
            setLoading(false);
        }
    }

    const detailLoading = () => (
        <>
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={'calc(100% - 200px)'} width={'100%'} style={{ marginTop: 20 }} />
        </>
    )

    return (
        <>
            <Sidebar>
                {isAddRoute ?
                    <AddRouteSidebar
                        setIsAddRoute={setIsAddRoute}
                        setSelectedDate={setSelectedDate}
                        routeStopLoading={routeStopLoading}
                        detailLoading={detailLoading}
                        loadRoutes={loadRoutes}
                        setClusters={setClusters}
                        clusters={clusters}
                        setShowDetail={setShowDetail}
                        setMarkers={setMarkers}
                        calculateRoutes={calculateRoutes}
                        createRoutesManually={createRoutesManually}
                        drivers={drivers}
                        driversForRoute={driversForRoute}
                        setDriversForRoute={setDriversForRoute}
                        stopsForRoute={stopsForRoute}
                        setStopsForRoute={setStopsForRoute}
                        amountRoutes={amountRoutes}
                        setAmountRoutes={setAmountRoutes}
                    />
                    :
                    <RouteListSidebar
                        setIsAddRoute={setIsAddRoute}
                        filterStatus={filterStatus}
                        setFilterStatus={setFilterStatus}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        filteredRoutes={filteredRoutes}
                        allRoutes={allRoutes}
                        currentRoute={currentRoute}
                        currentStop={currentStop}
                        selectRoute={selectRoute}
                        routeStopLoading={routeStopLoading}
                        expandRouteStops={expandRouteStops}
                        detailLoading={detailLoading}
                        routeLoading={routeLoading}
                        routeStops={routeStops}
                        showStopIds={showStopIds}
                        selectRouteStop={selectRouteStop}
                        setSelectDriverPopupOpen={setSelectDriverPopupOpen}
                        setChangeDatePopupOpen={setChangeDatePopupOpen}
                        setChangedDate={setChangedDate}
                        setAssignToDifferentRoutePopupOpen={setAssignToDifferentRoutePopupOpen}
                        setRemoveRouteStopPopupOpen={setRemoveRouteStopPopupOpen}
                        onRouteStopSort={onRouteStopSort}
                        setRemoveRoutePopupOpen={setRemoveRoutePopupOpen}
                        setRouteStopsToChange={setRouteStopsToChange}
                        changingRoute={changingRoute}
                        setChangingRoute={setChangingRoute}
                        optimize={optimize}
                    />
                }
            </Sidebar>

            <div className='mapContainer'>
                {loading &&
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        zIndex: 9999999,
                        backgroundColor: 'rgba(255,255,255,0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%'

                    }}>
                        <CircularProgress />
                        {createLoading && <>
                            <h4 style={{ marginBottom: 0 }}>{translate('creating_routes')}</h4>
                            <p style={{ marginTop: 0 }}>{translate('this_can_take_a_while')}</p>
                        </>
                        }
                    </div>
                }
                <Map
                    ref={mapRef}
                    // markers={markers}
                    // routes={mapRoutes}
                    // drivers={driverLocations}
                    flyTo={flyToDestination}
                />

                <DetailContainer
                    visible={detailContainerVisible}
                    disableClose={detailContainerType === 'route'}
                    close={() => setDetailContainerVisible(false)}
                >
                    {saveState !== 'default' && <div
                        style={{ top: 20, right: 0 }}
                        className={`saveBadge ${saveState === 'saving' ? 'animate-flicker' : undefined}`}>{translate(saveState)}</div>}


                    {
                        detailContainerLoading ? detailLoading() : <>
                            {detailContainerType === 'routeStop' && <>
                                <Button
                                    type='secondary'
                                    preventLoading
                                    label={translate('back')}
                                    style={{ top: 15, left: 15, position: 'absolute' }}
                                    onClick={() => setDetailContainerType('route')}
                                />
                                <RouteStopDetail
                                    selectedRouteStopId={currentStop?.id}
                                    currentRouteId={currentRoute?.id}
                                    reload={loadStopGroups}
                                    setSaveState={setSaveState}
                                    reloadSingle={() => loadStopDetail(currentStop?.id)}
                                    closeSidebar={() => setDetailContainerVisible(false)}
                                    currentParcelId={currentParcelId}
                                    setCurrentParcelId={setCurrentParcelId}
                                />
                            </>
                            }
                            {detailContainerType === 'route' && <RouteDetail
                                currentRoute={currentRoute}
                                setCurrentRoute={setCurrentRoute}
                                reload={loadRoutes}
                                setSaveState={setSaveState}
                                closeSidebar={() => setDetailContainerVisible(false)}
                            />
                            }
                        </>
                    }
                </DetailContainer>
                {currentStop?.id !== undefined && currentParcelId !== null && <DetailContainer
                    visible={currentParcelId !== null}
                    disableClose={false}
                    close={() => setCurrentParcelId(null)}
                    level={2}
                >
                    <ParcelDetail
                        currentStopId={currentStop?.id}
                        currentRouteId={currentRoute?.id}
                        reload={loadStopGroups}
                        setSaveState={setSaveState}
                        reloadSingle={() => loadStopDetail(currentStop?.id)}
                        closeSidebar={() => setDetailContainerVisible(false)}
                        currentParcelId={currentParcelId}
                        setCurrentParcelId={setCurrentParcelId}
                    />
                </DetailContainer>}
            </div>
            <Popup
                close={() => setSelectDriverPopupOpen(false)}
                open={selectDriverPopupOpen}
                style={{ maxWidth: 400 }}
            >
                <h4 style={{ marginTop: 0 }}>{translate('select_driver')}</h4>
                <FormControl variant="filled">
                    <InputLabel>{translate('driver')}</InputLabel>
                    <Select
                        label={translate('driver')}
                        onChange={e => setSelectedDriver(e.target.value)}
                    >
                        {
                            drivers.map((d, k) => <MenuItem key={k} value={d.id}>{d.firstName === '' && d.lastName === '' ? d.email : `${d.firstName} ${d.lastName}`}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <Button
                    type='primary'
                    onClick={selectDriver}
                    label={translate('select_driver')}
                    ref={selectDriverButtonRef}
                />
            </Popup>

            <Popup
                close={() => setAssignToDifferentRoutePopupOpen(false)}
                open={assignToDifferentRoutePopupOpen}
                style={{ maxWidth: 400 }}
            >
                <h4 style={{ marginTop: 0 }}>{translate('assign_to_different_route')}</h4>
                <FormControl variant="filled">
                    <InputLabel>{translate('routes')}</InputLabel>
                    <Select
                        label={translate('select_status')}
                        onChange={e => setSelectedRouteId(e.target.value)}
                    >
                        {filteredRoutes.filter(r => r.id !== currentRoute?.id).map((route, key) => (
                            <MenuItem key={key} value={route.id}>{route?.title !== null ? route?.title : translate('no_title')} <small style={{ marginLeft: 3 }}> {route?.driver?.name !== null ? ` (${route?.driver?.name})` : ''} {!route?.title ? route?.count.sum + ' ' + translate('stops') : ''}</small></MenuItem>
                        ))}

                    </Select>
                </FormControl>
                <Button
                    type='primary'
                    onClick={assignToDifferentRoute}
                    label={translate('assign_to_different_route')}
                    ref={changeStatusButtonRef}
                />
            </Popup>

            <Popup
                open={removeRoutePopupOpen}
                close={() => setRemoveRoutePopupOpen(false)}
                style={{ width: 400 }}
            >
                <h3>{translate('are_you_sure?')}</h3>
                <p>{translate('are_you_sure_delete_route')}</p>
                <div style={{ textAlign: 'center' }}>
                    <Button
                        type={"primary"}
                        onClick={removeRoute}
                        label={translate('yes_delete')}
                        className={'delete'}
                        ref={removeRouteButtonRef}
                    />
                    <br />
                    <Button
                        type={"tertiary"}
                        onClick={() => setRemoveRoutePopupOpen(false)}
                        label={translate('cancel')}
                    />
                </div>
            </Popup>

            <Popup
                open={removeRouteStopPopupOpen}
                close={() => setRemoveRouteStopPopupOpen(false)}
                style={{ width: 400 }}
            >
                <h3>{translate('are_you_sure?')}</h3>
                <p>{translate('are_you_sure_delete_route_stop')}</p>
                <div style={{ textAlign: 'center' }}>
                    <Button
                        type={"primary"}
                        onClick={removeRouteStop}
                        label={translate('yes_delete')}
                        className={'delete'}
                        ref={removeRouteStopButtonRef}
                    />
                    <br />
                    <Button
                        type={"tertiary"}
                        onClick={() => setRemoveRouteStopPopupOpen(false)}
                        label={translate('cancel')}
                    />
                </div>
            </Popup>

            <Popup
                close={() => setChangeDatePopupOpen(false)}
                open={changeDatePopupOpen}
                style={{ maxWidth: 400 }}
            >
                <h4 style={{ marginTop: 0 }}>{translate('change_date')}</h4>
                <Calendar
                    onChange={(date: Date) => {
                        setChangedDate(date);
                        sessionStorage.setItem('savedDate', date.toDateString());
                    }}
                    initialValue={changedDate}
                    onlyFuture={true}
                    opened={true}
                    useSavedDate={true}
                />
                <Button
                    type='primary'
                    onClick={changeDate}
                    label={translate('change_date')}
                    ref={changeDateButtonRef}
                    style={{ marginTop: 20 }}
                />
            </Popup>

            <Popup
                close={() => setRoutingErrorPopupOpen(false)}
                open={routingErrorPopupOpen}
                style={{ maxWidth: 400 }}
            >
                <h4 style={{ marginTop: 0 }}>{translate('problem_with_optimization')}</h4>
                <p>
                    {translate(optimizationErrorDescription)}
                </p>
                {
                    routingErrorType === 'first' &&
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            type='primary'
                            onClick={() => calculateRoutes(false, true)}
                            label={translate('calculate_with_free_route_amount')}
                            style={{ marginTop: 20 }}
                        />
                        <Button
                            type='secondary'
                            onClick={() => setRoutingErrorPopupOpen(false)}
                            label={translate('change_route_settings')}
                            style={{ marginTop: 20 }}
                        />
                    </div>
                }

            </Popup>

        </>
    );
}

export default Routes;