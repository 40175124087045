import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { Grid, Skeleton, Stack, TextField, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import UserUnit from '../../../../Services/Http/User';
import { addMessage } from '../../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../../Services/Http/Company';
import wrlog from '../../../../Helper/functions/wrlog';
import CustomSwitch from '../../../../Components/Form/Switch';
import RoleUnit from '../../../../Services/Http/Role';
import Storage from '../../../../Services/Storage';
import Button from '../../../../Components/Button';
import Popup from '../../../../Components/Popup';

import DeliveryGuyImage from '../../../../Assets/placeholder_images/delivery_guy.jpg';
import StopGroupUnit from '../../../../Services/Http/StopGroup';
import QueryBuilder from '../../../../Components/QueryBuilder';
import MuiQueryBuilder from "mui-querybuilder";
import JobUnit from '../../../../Services/Http/Job';

interface Props {
    currentGroup: string;
    reload: any;
    setReload: any;
}

/**
 * 
 * @returns Login Page
 */
const WorkflowDetail = (props: Props) => {

    const { reload, setReload } = props;

    const setFilterButtonRef = useRef();
    const setContractorButtonRef = useRef();

    const [stopGroupData, setStopGroupData] = useState(null);
    const [contractors, setContractors] = useState([]);
    const [availableContractors, setAvailableContractors] = useState([]);

    const [filters, setFilters] = useState([]);
    const [currentGroup, setCurrentGroup] = useState(null);
    const [loading, setLoading] = useState(true);
    const [askDeletePopupOpen, setAskDeletePopupOpen] = useState(false);
    const [askDeleteFilterPopupOpen, setAskDeleteFilterPopupOpen] = useState(false);
    const [addFilterPopupOpen, setAddFilterPopupOpen] = useState(false);
    const [addContractorPopupOpen, setAddContractorPopupOpen] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({});
    const [disabledFilterIds, setDisabledFilterIds] = useState([]);
    const [disabledContractorIds, setDisabledContractorIds] = useState([]);

    const [newContractor, setNewContractor] = useState('new');
    const [companyKey, setCompanyKey] = useState('');

    const { handleSubmit } = useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        console.log(params.stopGroupId);
        setLoading(true);
        if (params.stopGroupId !== undefined) {
            setLoading(false);
            setCurrentGroup(params.stopGroupId)
            loadAvailableContractors(params.stopGroupId);
        } else {
            setLoading(false);
            setCurrentGroup(null);
        }
    }, [params])

    useEffect(() => {
        loadStopGroupSettings();
    }, [currentGroup])

    const loadAvailableContractors = (id) => {
        StopGroupUnit.getAvailableContractors(id).then((res: any) => {
            setAvailableContractors(res);
        }).catch((err: any) => {
            wrlog(err);
        })
    }

    const loadStopGroupSettings = () => {
        if (!currentGroup) {
            setLoading(false);
            return;
        }
        setLoading(true);

        StopGroupUnit.getSetting(currentGroup).then(res => {
            setStopGroupData(res);
            setContractors(res.contractors);
            setLoading(false);
        }).catch((err: any) => {
            wrlog(err);
            if (err.code !== 404) {
                dispatch(addMessage({
                    type: 'error',
                    title: err.title,
                    body: err.description,
                }))
            }
        })
    }

    const updateSetting = (key: string, value: any) => {

        let body = {
            id: currentGroup,
        }

        body[key] = value;

        StopGroupUnit.updateSetting(body)
            .then(res => {
            })
            .catch((err: any) => {
                dispatch(addMessage({
                    type: 'error',
                    title: err.title,
                    body: err.description,
                }))
            })
    }

    const changeFilter = () => {

        const { id, key, value } = currentFilter;

        setFilterButtonRef.current.changeState('loading');

        wrlog(currentFilter)

        if (id === undefined || id === null) {
            StopGroupUnit.createFilter(currentGroup, key, value).then(res => {
                loadStopGroupSettings();
                setFilterButtonRef.current.changeState('success');
                setTimeout(() => {
                    setAddFilterPopupOpen(false);
                }, 500)
            }).catch((err: any) => {
                setFilterButtonRef.current.changeState('error');
                dispatch(addMessage({
                    type: 'error',
                    title: err.title,
                    body: err.description,
                }))
            })
        } else {
            addToDisabledFilter(id)
            StopGroupUnit.updateFilter(
                currentGroup,
                id,
                key,
                value
            ).then(res => {
                loadStopGroupSettings();
                setFilterButtonRef.current.changeState('success');
                setTimeout(() => {
                    setAddFilterPopupOpen(false);
                }, 500)
            }).catch((err: any) => {
                setFilterButtonRef.current.changeState('error');
                dispatch(addMessage({
                    type: 'error',
                    title: err.title,
                    body: err.description,
                }))
            }).finally(() => {

            })
        }
    }

    const addContractor = () => {

        if (newContractor === 'new' && companyKey.length !== 6) {
            return;
        }

        setContractorButtonRef.current.changeState('loading');

        let body = {
            id: currentGroup,
            type: '',
            value: '',
        }

        if (newContractor !== 'new') {
            body.type = 'existing';
            body.value = newContractor;
        } else {
            body.type = 'new';
            body.value = companyKey;
        }

        StopGroupUnit.createContrator(body).then((res: any) => {
            setContractorButtonRef.current.changeState('success');
            loadAvailableContractors(currentGroup);
            loadStopGroupSettings();

            setTimeout(() => {
                setAddContractorPopupOpen(false);
                setNewContractor('new');
                setCompanyKey('');
            }, 500)

        }).catch((err: any) => {
            setContractorButtonRef.current.changeState('error');
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description
            }))
        })

    }

    const confirmDelete = () => {
        StopGroupUnit.updateSetting({
            id: currentGroup,
            status: 'deleted',
        }).then((res) => {
            dispatch(addMessage({
                type: 'success',
                title: translate('delete_stopgroup_success_title'),
                body: translate('delete_stopgroup_success_body'),
            }))
            navigate(`/settings/logistics/stopgroups/`);
            setTimeout(() => {
                setAskDeletePopupOpen(false);
            }, 500)

            setReload(true);
            setTimeout(() => {
                setReload(false);
            }, 100)
        }).catch((err) => {
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
            setAskDeletePopupOpen(false);
        })
    }

    const addToDisabledFilter = (filterId: string) => {
        let _disabledFilterIds = [...disabledFilterIds];
        _disabledFilterIds.push(filterId);
        setDisabledFilterIds(_disabledFilterIds);
    }

    const addToDisabledContractorIds = (contractorId: string) => {
        let _disabledContractorIds = [...disabledContractorIds];
        _disabledContractorIds.push(contractorId);
        setDisabledContractorIds(_disabledContractorIds);
    }

    const confirmDeleteFilter = (filterId: string) => {
        addToDisabledFilter(filterId);

        StopGroupUnit.deleteFilter(currentGroup, filterId).then((res) => {
            loadStopGroupSettings();
        }).catch((err) => {
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
        })
    }

    const confirmDeleteContractor = (contractorId: string) => {
        addToDisabledContractorIds(contractorId)
        StopGroupUnit.deleteContractor(currentGroup, contractorId).then((res) => {
            loadStopGroupSettings();
        }).catch((err) => {
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
        })
    }


    const addBlankFilter = () => {
        const _filters = [...filters];
        _filters.push({
            id: null,
            key: null,
            value: null,
        })
        setCurrentFilter(_filters);
        setAddFilterPopupOpen(true)
    }

    if (currentGroup === null) {
        return <Grid container spacing={2} className={styles.detailContainer}>
            <Grid item md={12}>
                <img src={DeliveryGuyImage} style={{ maxHeight: '30vh', maxWidth: '50%' }} />
                <h3>{translate('choose_a_workflow')}</h3>
            </Grid>
        </Grid>
    }

    if (loading || stopGroupData === null) {
        return <>
            <Grid container spacing={2} className={styles.detailContainer}>
                <Grid item md={12}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <strong>{translate('title')}</strong><br />
                            <Skeleton variant='rectangular' animation="wave" height={14} width={170} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    }

    const setFilterData = (key: string, value: string) => {
        let _currentFilter = { ...currentFilter };
        _currentFilter[key] = value;
        setCurrentFilter(_currentFilter);
    }

    return (
        <>
            <Grid container spacing={2} className={styles.detailContainer}>
                <Grid item md={12}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <TextField
                                onBlur={(e) => updateSetting('title', e.target.value)}
                                id="standard-basic"
                                label={translate('title')}
                                type="text"
                                className='dark'
                                defaultValue={stopGroupData.title}
                            />
                        </Grid>
                        <Grid item md={6} style={{textAlign: 'right'}}>
                            <Button
                                type={"tertiary"}
                                onClick={() => setAskDeletePopupOpen(true)}
                                label={translate('delete')}
                                className={'delete'}
                            />
                        </Grid>
                    </Grid>
                    <hr style={{ width: '100%' }} />
                    <Grid container spacing={2}>
                        <Grid item md={12} spacing={2}>
                            <h4 style={{ marginBottom: 10 }}>{translate('filters')}</h4>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item md={12} spacing={2}>
                            <QueryBuilder
                                onChange={res => {
                                    updateSetting('filters', res)
                                }}
                                values={stopGroupData.filters}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: 0 }}>
                        <Grid item md={12}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    {filters.map((filter: any, key: number) => {
                                        return <tr key={key} className={disabledFilterIds.includes(filter.id) ? 'disabled' : undefined}>
                                            <td>
                                                {translate(filter.key)}
                                            </td>
                                            <td>
                                                {filter.value}
                                            </td>
                                            <td width={100}>
                                                <Button
                                                    type={"tertiary"}
                                                    onClick={() => {
                                                        setCurrentFilter({ ...filter })
                                                        setAddFilterPopupOpen(true);
                                                    }}
                                                    label={translate('edit')}
                                                />
                                            </td>
                                            <td width={100} className='align_right'>
                                                <Button
                                                    type={"tertiary"}
                                                    onClick={() => confirmDeleteFilter(filter.id)}
                                                    label={translate('delete')}
                                                    className={'delete'}
                                                />
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                    <hr style={{ width: '100%' }} />
                    <Grid container spacing={2} style={{ marginTop: 30 }}>
                        <Grid item md={6} spacing={2}>
                            <h4 style={{ margin: 0 }}>{translate('contractors')}</h4>
                        </Grid>
                        <Grid item md={6} spacing={2} className='align_right'>
                            <Button
                                type={"tertiary"}
                                onClick={() => setAddContractorPopupOpen(true)}
                                label={translate('add_contractor')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: 0 }}>
                        <Grid item md={12}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    {contractors.map((contractor: any, key: number) => {
                                        return <tr key={key} className={disabledContractorIds.includes(contractor.id) ? 'disabled' : undefined}>
                                            <td>
                                                {translate(contractor.title)}
                                            </td>
                                            <td width={100} className='align_right'>
                                                <Button
                                                    type={"tertiary"}
                                                    onClick={() => confirmDeleteContractor(contractor.id)}
                                                    label={translate('delete')}
                                                    className={'delete'}
                                                />
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </Grid>
                <Popup
                    open={askDeletePopupOpen}
                    close={() => setAskDeletePopupOpen(false)}
                    style={{ width: 400 }}
                >
                    <h3>{translate('are_you_sure?')}</h3>
                    <p>{translate('are_you_sure_delete_user')}</p>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            type={"primary"}
                            onClick={confirmDelete}
                            label={translate('yes_delete')}
                            className={'delete'}
                        />
                        <br />
                        <Button
                            type={"tertiary"}
                            onClick={() => setAskDeletePopupOpen(false)}
                            label={translate('cancel')}
                        />
                    </div>
                </Popup>
                <Popup
                    open={addContractorPopupOpen}
                    close={() => setAddContractorPopupOpen(false)}
                    style={{ width: 400 }}
                >
                    <h3>{translate('add_contractor')}</h3>
                    <FormControl variant="filled" style={{ marginBottom: 20 }}>
                        <InputLabel>{translate('contractor')}</InputLabel>
                        <Select
                            label={translate('contractor')}
                            onChange={e => setNewContractor(e.target.value)}
                            style={{ marginBottom: '20px !important' }}
                            defaultValue={'new'}
                        >
                            <MenuItem value={'new'}>{translate('add_new_contractor')}</MenuItem>
                            {
                                availableContractors.map(contractor => <MenuItem value={contractor.id}>{contractor.title}</MenuItem>)
                            }

                        </Select>
                    </FormControl>
                    {
                        newContractor === 'new' && <TextField
                            onChange={e => setCompanyKey(e.target.value)}
                            id="standard-basic"
                            label={translate('company_key')}
                            type="text"
                            className='dark'
                            value={companyKey}
                        />
                    }

                    <Button
                        type={"primary"}
                        onClick={addContractor}
                        label={translate('save')}
                        ref={setContractorButtonRef}
                    />
                </Popup>
            </Grid>
        </>
    );
}

export default WorkflowDetail;