import React, { useEffect, useRef, useState } from 'react';
import { translate } from "../../../Helper/multilingual";
import Calendar from '../../../Components/Calendar';

import { ReactComponent as TruckIcon } from '../../../Assets/navigationIcons/logistics.svg';
import { ReactComponent as OntimeIcon } from '../../../Assets/navigationIcons/ontime.svg';
import { ReactComponent as DelayedIcon } from '../../../Assets/navigationIcons/delayed.svg';
import { ReactComponent as FailedIcon } from '../../../Assets/navigationIcons/failed.svg';
import AddIcon from '../../../Assets/add.svg';
import { Card, Checkbox, Grid, TextField } from "@mui/material";
import Button from "../../../Components/Button";
import RouteUnit from "../../../Services/Http/Route";
import RouteStopUnit from "../../../Services/Http/RouteStop";
import { useDispatch, useSelector } from 'react-redux';
import StopGroupUnit from '../../../Services/Http/StopGroup';
import { setStopGroup } from '../../../Services/Redux/features/stopGroups/stopGroupsSlice';
import wrlog from '../../../Helper/functions/wrlog';

interface Props {
    selectGroup: any,
    selectStop: any,
    availableGroups: any,
    availableStops: any,
    showStopIds: any,
    routeStopLoading: any,
    loadRouteStops: any,
    loadRouteStops: any,
    detailLoading: any,
    setCurrentPage: any,
    stopsSelected: boolean
}

const AddRouteChooseStops = (
    props: Props
) => {

    const {
        selectGroup,
        selectStop,
        availableGroups,
        availableStops,
        showStopIds,
        routeStopLoading,
        loadRouteStops,
        detailLoading,
        setCurrentPage,
        stopsSelected
    } = props;

    const [currentGroup, setCurrentGroup] = useState({});

    const dispatch = useDispatch();

    const routeStops = useSelector(state => state.routeStop);

    const selectStopGroup = (stopGroup: any) => {
        if (stopGroup.id !== currentGroup?.id) {
            setCurrentGroup(stopGroup)
        } else {
            setCurrentGroup({})
        }
    }

    useEffect(() => {
        wrlog("stopsSelected", stopsSelected);
    }, [stopsSelected])

    return (
        <div className='smallListContainer fullHeight' style={{ marginBottom: 100 }}>
            <h4>{translate('stop_groups')}</h4>
            {availableGroups.map((group: any, key: number) => {
                return <div
                    key={key}
                    className={`smallListItem cuttext expandable white ${currentGroup?.id === group.id ? 'open' : undefined}`}
                >
                    <div className='header'
                        onClick={(e) => {
                            if (e.target.closest('.MuiCheckbox-root')) {
                                return;
                            }
                            selectStopGroup(group);
                        }}
                    >
                        <h4 style={{ margin: 0 }}>{group.title}</h4>
                        {group.count} {group.count == 1 ? translate('stop') : translate('stops')}<br />
                        <div className='expendableIcon'></div>
                        <Checkbox
                            onChange={e => { selectGroup(group.id, e.target.checked) }}
                        />
                    </div>
                    {
                        currentGroup?.id === group.id && <div className='innerWrapper'>
                            {routeStopLoading ? detailLoading() :
                                routeStops.length > 0 ? routeStops.map((routeStop: any) => {
                                    if (!showStopIds.includes(routeStop.id)) {
                                        return null;
                                    }
                                    return <div
                                        className='smallListItem cuttext'
                                    >
                                        <strong>{routeStop.address} {routeStop.streetNumber}</strong><br />
                                        {routeStop.postcode} {routeStop.city}<br />
                                        <div className='badge'>ASAP</div>
                                    </div>
                                })
                                    : <strong>{translate('no_route_stops_found')}</strong>
                            }
                        </div>
                    }

                </div>
            })
            }
            <h4>{translate('stops')}</h4>
            <table>
                <tbody>
                    {
                        availableStops.map(stop => {
                            return <tr>
                                <td>
                                    <Checkbox
                                        onChange={e => selectStop(stop, e.target.checked)}
                                    />
                                </td>
                                <td>{stop.address} {stop.streetNumber}, {stop.postcode} {stop.city}</td>
                                <td></td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
            <div style={{ width: '100%', height: 100, display: 'block' }}></div>
            <Button
                type={'primary'}
                label={translate('continue')}
                onClick={() => setCurrentPage(1)}
                // className={stopsSelected ? undefined : 'disabled'}
                preventLoading
                style={{ position: 'fixed', bottom: 20, left: 20, width: 460 }}
            />
        </div>
    )
}

export default AddRouteChooseStops;