import wrlog from '../../Helper/functions/wrlog';
import Storage from '../Storage';
import Unit from './index';

export default class StopGroupUnit extends Unit {

    static listStopGroups = async () => {
        return await Unit.get(`/stop/group`);
    }

    static listStops = async (stopGroupId: string) => {
        return await Unit.get(`/stop/group/${stopGroupId}`);
    }

    static listAvailableStops = async (stopGroupId: string) => {
        return await Unit.get(`/stop/group/${stopGroupId}/available`);
    }

    static createGroup = async (title: string) => {
        return await Unit.post(`/stop/group`, { title: title });
    }

    static updateGroup = async (stopGroupId: string, title: string) => {
        return await Unit.put(`/stop/group`, { id: stopGroupId, title: title });
    }

    static deleteGroup = async (stopGroupId: string) => {
        return await Unit.delete(`/stop/group/${stopGroupId}`, {});
    }

    static assignStopsToGroup = async (stopGroupId: string, routeStopIds: any) => {
        return await Unit.post(`/stop/group/${stopGroupId}/assign`, { routeStopIds: routeStopIds });
    }

    static detachStopFromGroup = async (stopGroupId: string, routeStopId: string) => {
        return await Unit.delete(`/stop/group/${stopGroupId}/assign`, { routeStopId: routeStopId });
    }


    /** Settings */
    static listSettings = async () => {
        return await Unit.get('/stop/group/settings');
    }

    static createSetting = async (body: any) => {
        return await Unit.post('/stop/group/settings', body);
    }

    static updateSetting = async (body: any) => {
        return await Unit.put('/stop/group/settings', body);
    }

    static getSetting = async (id: string) => {
        return await Unit.get(`/stop/group/settings/${id}`);
    }

    static setMeta = async (id: string, key: string, value: string) => {
        const body = {
            id: id,
            key: key,
            value: value
        }
        return await Unit.post(`/stop/group/settings/meta`, body);
    }

    static createFilter = async (id: string, key: string, value: string) => {
        const body = {
            id: id,
            key: key,
            value: value
        }
        return await Unit.post('/stop/group/settings/filter', body);
    }

    static updateFilter = async (id: string, filterId: string, key: string, value: string) => {
        const body = {
            id: id,
            filterId: filterId,
            key: key,
            value: value
        }
        return await Unit.put('/stop/group/settings/filter', body);
    }

    static deleteFilter = async (id: string, filterId: string) => {
        const body = {
            id: id,
            filterId: filterId
        }
        return await Unit.delete('/stop/group/settings/filter', body);
    }

    static createContrator = async (body: any) => {
        return await Unit.post('/stop/group/settings/contractor', body);
    }

    static deleteContractor = async (id: string, contractorId: string) => {
        const body = {
            id: id,
            contractorId: contractorId,
            status: 'deleted'
        }
        return await Unit.put('/stop/group/settings/contractor', body);
    }

    static getAvailableContractors = async (id: string) => {
        return await Unit.get(`/stop/group/settings/${id}/contractors`);
    }





}



