import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import {
    Link, Route, Routes, useParams, BrowserRouter as Router, Navigate, useNavigate, useLocation
} from 'react-router-dom';
import Logo from '../../../Assets/Logo.png';

import styles from './styles.module.scss';

import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import CompanySettings from './CompanySettings';
import ListItem from '../../../Components/ListItem';
import SettingsSidebar from './Sidebar';
import wrlog from '../../../Helper/functions/wrlog';
import CompanyUnit from '../../../Services/Http/Company';
import Storage from '../../../Services/Storage';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import Profile from './Profile';
import ChangePassword from './ChangePassword';

interface GeneralSettingsProps {
}

/**
 * 
 * @returns Login Page
 */
const GeneralSettings = (props: GeneralSettingsProps) => {

    const LoginButtonRef = useRef();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [settings, setSettings] = useState(null);

    const dispatch = useDispatch();
    const location = useLocation();

    return (
        <>
            <Sidebar />
            <SettingsSidebar />
            <div className='rightContainer'>
                <Grid container spacing={0}>
                    <Grid item md={12} padding={4}>
                        {location.pathname === '/settings' && <CompanySettings/>}
                        {location.pathname === '/settings/profile' && <Profile />}
                        {location.pathname === '/settings/password' && <ChangePassword />}
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default GeneralSettings;