import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../../Assets/Logo.png';

import styles from '../styles.module.scss';

import { Card, Checkbox, Grid, Skeleton, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from './Sidebar';
import Map from '../../../Components/Map';
import Button from '../../../Components/Button';
import CsvImporter from '../../../Components/CsvImporter';
import RouteStopUnit from '../../../Services/Http/RouteStop';
import wrlog from '../../../Helper/functions/wrlog';
import AddressAutocomplete from '../../../Components/AddressAutocomplete';
import DetailContainer from '../../../Components/DetailContainer';
import StopGroupUnit from '../../../Services/Http/StopGroup';
import Popup from '../../../Components/Popup';

interface StopGroupDetailProps {
    currentStopGroup: any;
    reload: any;
    setSaveState: any;
    reloadSingle: any;
    closeSidebar: any;
}

/**
 * 
 * @returns Login Page
 */
const StopGroupDetail = (props: StopGroupDetailProps) => {

    const { currentStopGroup, reload, setSaveState, reloadSingle, closeSidebar } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();

    const editTitleButtonRef = useRef();
    const assignStopGroupButtonRef = useRef();

    const [editTitle, setEditTitle] = useState(false);
    const [assignStopsToGroupPopupOpen, setAssignStopsToGroupPopupOpen] = useState(false);
    const [soloRouteStops, setSoloRouteStops] = useState([]);
    const [selectedRouteStops, setSelectedRouteStops] = useState([]);

    useEffect(() => {
        wrlog(currentStopGroup);
    }, [])

    const loadSoloStops = () => {
        RouteStopUnit.listSolo().then((res: any) => {
            setSoloRouteStops(res)
        }).catch((err: any) => {
            wrlog(err)
        })
    }

    const updateTitle = (data: any) => {
        editTitleButtonRef.current.changeState('loading');
        StopGroupUnit.updateGroup(currentStopGroup.id, data.title).then(res => {
            editTitleButtonRef.current.changeState('success');
            reload();
            setSaveState('saved');
            setEditTitle(false);
            setTimeout(() => {
                setSaveState('default');
            }, 2000)
        }).catch(err => {

        })
    }

    const deleteStopGroup = () => {
        StopGroupUnit.deleteGroup(currentStopGroup.id).then(res => {
            reload();
            setSaveState('saved');
            closeSidebar();
            setTimeout(() => {
                setSaveState('default');
            }, 2000)
        }).catch(err => {

        })
    }

    const assignStopsToGroup = () => {
        StopGroupUnit.assignStopsToGroup(currentStopGroup.id, selectedRouteStops).then(res => {
            reload();
            setSaveState('saved');
            closeSidebar();
            setTimeout(() => {
                setSaveState('default');
            }, 2000)
        }).catch(err => {

        })
    }

    const setSoloRouteSelected = (routeStopId: string, checked: boolean) => {
        let _selectedRouteStops = [...selectedRouteStops];

        if (checked) {
            _selectedRouteStops.push(routeStopId)
        } else {
            let index = _selectedRouteStops.findIndex((e) => e === routeStopId);
            _selectedRouteStops.splice(index, 1);
        }

        setSelectedRouteStops(_selectedRouteStops)
    }

    const selectAll = () => {
        if (soloRouteStops.length === selectedRouteStops.length) {
            setSelectedRouteStops([]);
        } else {
            setSelectedRouteStops(soloRouteStops.map(s => s.id))
        }
    }

    if (editTitle) {
        return <Grid container style={{ marginTop: 30 }}>
            <Grid item md={12} className={styles.center}>
                <Button
                    type="secondary"
                    preventLoading
                    label={translate('back')}
                    onClick={() => setEditTitle(false)}
                />
                <form onSubmit={handleSubmit(updateTitle)}>

                    <TextField
                        {...register('title', { required: true })}
                        error={errors.title}
                        id="standard-basic"
                        type="text"
                        label={translate('title')}
                        className='dark'
                        defaultValue={currentStopGroup.title}
                    />
                    <Button
                        type='primary'
                        isInput
                        label={translate('save')}
                        ref={editTitleButtonRef}
                    />
                </form>
            </Grid>
        </Grid>

    }

    return (
        <>
            <Grid container style={{ marginTop: 30 }}>
                <Grid item md={12} className={styles.center}>
                    <h4 style={{ margin: 0, display: 'inline-block', marginRight: 10 }}>{currentStopGroup?.title}</h4><Button
                        type="tertiary"
                        preventLoading
                        label={translate('edit')}
                        style={{ marginRight: 10 }}
                        onClick={() => setEditTitle(true)}
                    />
                </Grid>
            </Grid>
            <hr style={{ width: '100%', marginTop: 20, marginBottom: 10 }} />
            <Grid container style={{ marginTop: 10 }}>
                <Grid item md={6}>
                    <Button
                        type="secondary"
                        preventLoading
                        label={translate('add_route_stops')}
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            loadSoloStops();
                            setAssignStopsToGroupPopupOpen(true)
                        }}
                    />
                </Grid>
                <Grid item md={6} className='align_right'>
                    <Button
                        type="tertiary"
                        preventLoading
                        className='delete'
                        label={translate('delete')}
                        onClick={() => deleteStopGroup()}
                    />
                </Grid>
            </Grid>
            <hr style={{ width: '100%', marginTop: 10, marginBottom: 20 }} />
            <Grid container style={{ marginTop: 10 }}>
                <Grid item md={12}>
                    <p style={{ margin: 0 }}>, </p>
                </Grid>
            </Grid>
            <Popup
                close={() => setAssignStopsToGroupPopupOpen(false)}
                open={assignStopsToGroupPopupOpen}
            >
                <h4 style={{ marginTop: 0 }}>{translate('add_stop_group')}</h4>
                <Button
                    type='tertiary'
                    onClick={() => selectAll()}
                    label={soloRouteStops.length === selectedRouteStops.length ? translate('unselect_all') : translate('select_all')}
                    ref={assignStopGroupButtonRef}
                />
                <table>
                    <tbody>
                        {
                            soloRouteStops.map(soloRouteStop => {
                                return <tr>
                                    <td>
                                        <Checkbox
                                            onChange={e => setSoloRouteSelected(soloRouteStop.id, e.target.checked)}
                                            checked={selectedRouteStops.includes(soloRouteStop.id)}
                                        />
                                    </td>
                                    <td>{soloRouteStop.address} {soloRouteStop.streetNumber}</td>
                                    <td></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <Button
                    type='primary'
                    onClick={() => assignStopsToGroup()}
                    label={translate('save')}
                    ref={assignStopGroupButtonRef}
                />
            </Popup>
        </>
    );
}

export default StopGroupDetail;