import React, { useEffect, useRef, useState } from 'react';
import { set, useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';

import styles from './styles.module.scss';
import DeliveryGuyImage from '../../../Assets/placeholder_images/delivery_guy.jpg';

import { Card, Checkbox, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import UserUnit from '../../../Services/Http/User';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../Services/Http/Company';
import wrlog from '../../../Helper/functions/wrlog';
import CustomSwitch from '../../../Components/Form/Switch';
import RoleUnit from '../../../Services/Http/Role';
import Storage from '../../../Services/Storage';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';
import { possibleProofOfDeliveryOptionsDefault, possibleProofOfDeliveryOptionsDropoffPermit, possibleProofOfDeliveryOptionsPersonally } from '../../../Helper/Data/proofOfDelivery';
import { possibleReasonOfFailure, possibleReasonOfFailureActions, reasonOfFailureOptionsDefault } from '../../../Helper/Data/reasonOfFailure';
import WarehouseSelect from '../../../Components/Form/WarehouseSelect';
import WarehouseUnit from '../../../Services/Http/Warehouse';
import StartLocationInput from '../../../Components/Form/StartLocationInput';

interface RouteSettingsProps {
}

/**
 * 
 * @returns Login Page
 */
const RouteSettings = (props: RouteSettingsProps) => {
    const [loading, setLoading] = useState(true);

    const [routeReturnToStartLocation, setRouteReturnToStartLocation] = useState(null);
    const [routeVehicle, setRouteVehicle] = useState(null);
    const [routeDistanceUnit, setRouteDistanceUnit] = useState(null);
    const [routeWarehouse, setRouteWarehouse] = useState(null);

    const [proofOfDeliveryOptionsValue, setProofOfDeliveryOptionsValue] = useState(possibleProofOfDeliveryOptionsDefault);
    const [reasonOfFailureOptionsValue, setReasonOfFailureOptionsValue] = useState(reasonOfFailureOptionsDefault);

    const [startRouteTitle, setStartRouteTitle] = useState('');

    const { register, handleSubmit, setValue, getValues, formState: { errors, isValid } } = useForm();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        CompanyUnit.getCompanySettings().then(async settings => {
            if (settings !== undefined && settings !== null) {
                setValue('routeStartTime', settings.routeStartTime)
                setValue('routeEndTime', settings.routeEndTime)
                setValue('routePauseTime', settings.routePauseTime)
                setValue('routeReturnToStartLocation', settings.routeReturnToStartLocation)
                setRouteReturnToStartLocation(settings.routeReturnToStartLocation)
                setValue('routeReturnToStartLocationLocation', settings.routeReturnToStartLocationLocation)
                setValue('routeVehicle', settings.routeVehicle)
                setRouteVehicle(settings.routeVehicle)
                setValue('routeTimeForStop', settings.routeTimeForStop)
                setValue('routeDistanceUnit', settings.routeDistanceUnit)
                setRouteDistanceUnit(settings.routeDistanceUnit)
                setStartRouteTitle(settings.routeStartLocationTitle)
                // try {
                //     const { id } = await WarehouseUnit.get(settings.routeStartLocation);
                //     setRouteWarehouse(id);
                // } catch (err) {
                // }

                // SET DEFAULT

                if (settings.proofOfDeliveryOptions?.personally === undefined) {
                    settings.proofOfDeliveryOptions = possibleProofOfDeliveryOptionsDefault
                }

                setProofOfDeliveryOptionsValue(settings.proofOfDeliveryOptions)


                if (!settings.reasonOfFailureOptions) {
                    settings.reasonOfFailureOptions = reasonOfFailureOptionsDefault;
                }

                console.log(settings.reasonOfFailureOptions)

                let validArray = true;
                Object.keys(settings.reasonOfFailureOptions).forEach(key => {
                    console.log(Array.isArray(settings.reasonOfFailureOptions[key]), settings.reasonOfFailureOptions[key])
                    if (!Array.isArray(settings.reasonOfFailureOptions[key])) {
                        validArray = false;
                    }
                })

                if (!validArray) {
                    settings.reasonOfFailureOptions = reasonOfFailureOptionsDefault;
                }

                setReasonOfFailureOptionsValue(settings.reasonOfFailureOptions)

                setLoading(false);
            }
        })
    }, [])

    const formChanged = (key: string, value?: any) => {

        let formValue = getValues(key);

        if (value !== undefined) {
            setValue(key, value);
            formValue = value
        }

        let body = [{
            key: key,
            value: formValue
        }];
        CompanyUnit.setCompanySettings(body)
            .then(() => {

            }).catch(err => {
                dispatch(addMessage({
                    type: 'error',
                    title: err.title,
                    body: err.description,
                }))
            })

    }

    const listLoadingContainer = () => {
        return <Grid container spacing={0}>
            <Grid item md={12} padding={4}>
                <Skeleton variant='rectangular' animation="wave" height={60} width={'100%'} />
                <Skeleton variant='rectangular' animation="wave" height={60} width={'100%'} style={{ marginBottom: 40 }} />
                <Skeleton variant='rectangular' animation="wave" height={30} width={'100%'} />
                <Skeleton variant='rectangular' animation="wave" height={30} width={'100%'} />
            </Grid>
        </Grid>

    }

    return (
        <>
            <Sidebar />
            <div className='middleContainer'>
                {
                    loading ? listLoadingContainer() :
                        <Grid container spacing={0}>
                            <Grid item md={12} padding={4}>
                                <h3>{translate('route_setting')}</h3>
                                <p>{translate('route_setting_body')}</p>
                                <Grid container spacing={2}>
                                    <Grid item md={6} padding={0} className='align_right'>
                                        <TextField
                                            {...register('routeStartTime', { required: true })}
                                            onBlur={e => formChanged('routeStartTime', e.target.value)}
                                            error={errors.email}
                                            id="standard-basic"
                                            label={translate('start_time')}
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item md={6} padding={0} className='align_right'>
                                        <TextField
                                            {...register('routePauseTime', { required: true })}
                                            onBlur={e => formChanged('routePauseTime', e.target.value)}
                                            error={errors.email}
                                            id="standard-basic"
                                            label={translate('pause_time')}
                                            type="text"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">{translate('minutes_short')}</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {/* <TextField
                                    {...register('routeStartLocation', { required: true })}
                                    onBlur={e => formChanged('routeStartLocation', e.target.value)}
                                    error={errors.email}
                                    id="standard-basic"
                                    label={translate('start_address')}
                                    type="text"
                                /> */}

                                {/* <WarehouseSelect
                                    value={routeWarehouse}
                                    onChange={({ id }) => {
                                        setRouteWarehouse(id);
                                        formChanged('routeStartLocation', id);
                                    }}
                                /> */}

                                <StartLocationInput
                                    onChange={({ title, location }) => {
                                        formChanged('routeStartLocationTitle', title)
                                        formChanged('routeStartLocation', location)
                                        setStartRouteTitle(title);
                                    }}
                                    value={startRouteTitle}
                                />

                                <h4>{translate('default_end_location')}</h4>

                                <FormControl variant="filled" className='white'>
                                    <InputLabel>{translate('return_to_start_location')}</InputLabel>
                                    <Select
                                        {...register('routeReturnToStartLocation', { required: true })}
                                        label={translate('return_to_start_location')}
                                        onChange={e => formChanged('routeReturnToStartLocation', e.target.value)}
                                        defaultValue={routeReturnToStartLocation}
                                    >
                                        <MenuItem value={'returnToStartLocation'}>{translate('return_to_start_location')}</MenuItem>
                                        <MenuItem value={'none'}>{translate('no_end_location')}</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField
                                    {...register('routeEndTime', { required: true })}
                                    onBlur={e => formChanged('routeEndTime', e.target.value)}
                                    id="standard-basic"
                                    label={`${translate('end_time')} (${translate('optional')})`}
                                    type="text"
                                />

                                <h4>{translate('optimizing_for')}</h4>
                                <FormControl variant="filled" className='white'>
                                    <InputLabel>{translate('vehicle')}</InputLabel>
                                    <Select
                                        {...register('routeVehicle', { required: true })}
                                        label={translate('vehicle')}
                                        onChange={e => formChanged('routeVehicle', e.target.value)}
                                        defaultValue={routeVehicle}
                                    >
                                        <MenuItem value={'delivery_van'}>{translate('delivery_van')}</MenuItem>
                                        <MenuItem value={'car'}>{translate('car')}</MenuItem>
                                        <MenuItem value={'truck'}>{translate('truck')}</MenuItem>
                                    </Select>
                                </FormControl>

                                <Grid container spacing={2}>
                                    <Grid item md={6} padding={0}>
                                        <TextField
                                            {...register('routeTimeForStop', { required: true })}
                                            onBlur={e => formChanged('routeTimeForStop', e.target.value)}
                                            error={errors.email}
                                            id="standard-basic"
                                            label={translate('time_for_stop')}
                                            type="text"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">{translate('minutes_short')}</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6} padding={0}>
                                        <FormControl variant="filled" className='white'>
                                            <InputLabel>{translate('distance_unit')}</InputLabel>
                                            <Select
                                                {...register('routeDistanceUnit', { required: true })}
                                                label={translate('distance_unit')}
                                                onChange={e => formChanged('routeDistanceUnit', e.target.value)}
                                                defaultValue={routeDistanceUnit}
                                            >
                                                <MenuItem value={'metric'}>{translate('metric')}</MenuItem>
                                                <MenuItem value={'imperial'}>{translate('imperial')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>



                                <h4>{translate('proof_of_deliveries')}</h4>

                                <Grid container style={{ marginTop: 20 }}>
                                    <Grid item xs={12}>
                                        <strong>{translate('personally')}</strong>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginBottom: 40 }}>
                                    <Grid item xs={12}>
                                        <div style={{
                                            width: '100%',
                                            overflow: 'scroll',
                                        }}>
                                            {possibleProofOfDeliveryOptionsPersonally.map((option: string, key: number) => {
                                                return <Button
                                                    key={key}
                                                    type='secondary'
                                                    preventLoading
                                                    label={translate(option)}
                                                    selected={proofOfDeliveryOptionsValue.personally.includes(option)}
                                                    onClick={() => {
                                                        let proofOfDeliveryOptions = { ...proofOfDeliveryOptionsValue };
                                                        let proofOfDeliveryOptionsPersonally = [...proofOfDeliveryOptions.personally];
                                                        if (typeof proofOfDeliveryOptionsValue.personally !== 'object') {
                                                            proofOfDeliveryOptionsPersonally = [];
                                                        }
                                                        if (proofOfDeliveryOptionsPersonally.includes(option)) {
                                                            let index = proofOfDeliveryOptionsPersonally.findIndex(o => o === option);
                                                            proofOfDeliveryOptionsPersonally.splice(index, 1);
                                                        } else {
                                                            proofOfDeliveryOptionsPersonally.push(option);
                                                        }

                                                        proofOfDeliveryOptions.personally = proofOfDeliveryOptionsPersonally
                                                        setProofOfDeliveryOptionsValue(proofOfDeliveryOptions);
                                                        formChanged('proofOfDeliveryOptions', proofOfDeliveryOptions)
                                                    }}
                                                />
                                            })}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
                                    <Grid item xs={8}>
                                        <strong>{translate('dropoff_permit')}</strong>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                                        <CustomSwitch onChange={(e) => {
                                            let proofOfDeliveryOptions = { ...proofOfDeliveryOptionsValue };

                                            proofOfDeliveryOptions.dropoffPermit = e.target.checked
                                            setProofOfDeliveryOptionsValue(proofOfDeliveryOptions);
                                            formChanged('proofOfDeliveryOptions', proofOfDeliveryOptions)
                                        }} defaultChecked={proofOfDeliveryOptionsValue.dropoffPermit} />
                                    </Grid>
                                </Grid>
                                {proofOfDeliveryOptionsValue.dropoffPermit && <Grid container style={{ marginBottom: 40 }}>
                                    <Grid item xs={12}>
                                        <div style={{
                                            width: '100%',
                                            overflow: 'scroll',
                                        }}>
                                            {possibleProofOfDeliveryOptionsDropoffPermit.map(option => {
                                                return <Button
                                                    type='secondary'
                                                    preventLoading
                                                    label={translate(option)}
                                                    selected={proofOfDeliveryOptionsValue.dropoff.includes(option)}
                                                    onClick={() => {
                                                        let proofOfDeliveryOptions = { ...proofOfDeliveryOptionsValue };
                                                        let proofOfDeliveryOptionsdropoff = [...proofOfDeliveryOptions.dropoff];
                                                        if (typeof proofOfDeliveryOptionsValue.dropoff !== 'object') {
                                                            proofOfDeliveryOptionsdropoff = [];
                                                        }
                                                        if (proofOfDeliveryOptionsdropoff.includes(option)) {
                                                            let index = proofOfDeliveryOptionsdropoff.findIndex(o => o === option);
                                                            proofOfDeliveryOptionsdropoff.splice(index, 1);
                                                        } else {
                                                            proofOfDeliveryOptionsdropoff.push(option);
                                                        }

                                                        proofOfDeliveryOptions.dropoff = proofOfDeliveryOptionsdropoff
                                                        setProofOfDeliveryOptionsValue(proofOfDeliveryOptions);
                                                        formChanged('proofOfDeliveryOptions', proofOfDeliveryOptions)
                                                    }}
                                                />
                                            })}
                                        </div>
                                    </Grid>
                                </Grid>}


                                <Grid container style={{ marginTop: 20 }}>
                                    <Grid item xs={12}>
                                        <strong>{translate('dropoff_not_possible')}</strong>
                                    </Grid>
                                </Grid>

                                {possibleReasonOfFailure.map(reason => {
                                    return <><Grid container style={{ marginTop: 20 }}>
                                        <Grid item xs={12}>
                                            <strong>{translate(reason)}</strong>
                                        </Grid>
                                    </Grid>
                                        {
                                            possibleReasonOfFailureActions.map(action => {
                                                return <Grid container style={{ marginTop: 10 }}>
                                                    <Grid item xs={7}>
                                                        {translate(action)}
                                                    </Grid>
                                                    <Grid item xs={5} style={{ textAlign: 'right' }}>
                                                        <CustomSwitch
                                                            onChange={(e) => {
                                                                let reasonOfFailureOptions = { ...reasonOfFailureOptionsValue };

                                                                let reasonOfFailureOptionsOption = reasonOfFailureOptions[reason] ? [...reasonOfFailureOptions[reason]] : [];

                                                                if (e.target.checked) {
                                                                    reasonOfFailureOptionsOption.push(action);
                                                                } else {
                                                                    const index = reasonOfFailureOptionsOption.findIndex((o: string) => o === action);
                                                                    reasonOfFailureOptionsOption.splice(index, 1);
                                                                }
                                                                reasonOfFailureOptions[reason] = reasonOfFailureOptionsOption;

                                                                setReasonOfFailureOptionsValue({ ...reasonOfFailureOptions });
                                                                formChanged('reasonOfFailureOptions', reasonOfFailureOptions)
                                                            }}
                                                            defaultChecked={
                                                                reasonOfFailureOptionsValue && reasonOfFailureOptionsValue[reason] && typeof reasonOfFailureOptionsValue[reason] === 'object' && reasonOfFailureOptionsValue[reason]?.includes(action)
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            })
                                        }


                                    </>
                                })}
                            </Grid>
                        </Grid>
                }
            </div>
            <div className='rightContainer'>
                <Grid container spacing={0}>
                    <Grid item md={12} padding={4} style={{ textAlign: 'center' }}>
                        <img src={DeliveryGuyImage} style={{ width: '70%' }} />
                        <Grid container spacing={0} style={{ textAlign: 'left' }}>
                            <Grid item md={12} style={{ padding: 80, paddingTop: 20 }}>
                                <h3>{translate('modern_route_planning_headline')}</h3>
                                <p>
                                    {translate('modern_route_planning_text')}
                                </p>
                                <Button
                                    onClick={() => navigate('/logistics/routeplanning')}
                                    label={translate('plan_route')}
                                    type={'primary'}
                                    className='smallWidth'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

        </>
    );
}

export default RouteSettings;