import wrlog from '../../Helper/functions/wrlog';
import Storage from '../Storage';
import Unit from './index';

export default class JobUnit extends Unit {

    static listJobs = async () => {
        return await Unit.get(`/job`);
    }

    static getJobDetails = async (jobId: string) => {
        return await Unit.get(`/job/${jobId}`);
    }

    static getJobLocations = async (jobId: string) => {
        return await Unit.get(`/job/${jobId}/locations`);
    }

    static createJob = async (body: any) => {
        return await Unit.post(`/job`, body);
    }

    static updateJob = async (body: any) => {
        return await Unit.put(`/job`, body);
    }

    static deleteJob = async (jobId: string) => {
        return await Unit.delete(`/job/${jobId}`, {});
    }

    static acceptJob = async (jobId: string) => {
        return await Unit.get(`/job/${jobId}/accept`);
    }

    static rejectJob = async (jobId: string) => {
        return await Unit.get(`/job/${jobId}/reject`);
    }

    static assignStopsToGroup = async (stopGroupId: string, routeStopIds: any) => {
        return await Unit.post(`/job/${stopGroupId}/assign`, { routeStopIds: routeStopIds });
    }

    static detachStopFromGroup = async (stopGroupId: string, routeStopId: string) => {
        return await Unit.delete(`/job/${stopGroupId}/assign`, { routeStopId: routeStopId });
    }

    static listAvailableGroups = async () => {
        return await Unit.get(`/job/groups`);
    }

    static listAvailableRoutes = async () => {
        return await Unit.get(`/job/routes`);
    }

    static getAvailableContractors = async (jobId: string) => {
        return await Unit.get(`/job/${jobId}/availablecontractors`);
    }

    static addContractor = async (jobId: string, body: any) => {
        return await Unit.post(`/job/${jobId}/contractor`, body);
    }

    static removeContractor = async (jobId: string, contractorId: string) => {
        return await Unit.delete(`/job/${jobId}/contractor/${contractorId}`);
    }

}



