import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Logo from '../../Assets/Logo.png';

import { ReactComponent as DashboardIcon } from '../../Assets/navigationIcons/dashboard.svg';
import { ReactComponent as WarehouseIcon } from '../../Assets/navigationIcons/warehouse.svg';
import { ReactComponent as LogisticsIcon } from '../../Assets/navigationIcons/logistics.svg';
import { ReactComponent as SettignsIcon } from '../../Assets/navigationIcons/settings.svg';
import { ReactComponent as SearchIcon } from '../../Assets/navigationIcons/search.svg';

import styles from './styles.module.scss';
import { TextField } from '@mui/material';
import { translate } from '../../Helper/multilingual';
import Storage from '../../Services/Storage';
import wrlog from '../../Helper/functions/wrlog';
import Company from '../../Helper/Classes/Company';
import CompaniesSelect from './CompaniesSelect';
import { logout } from '../../Helper/functions/auth';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import { setSearch } from '../../Services/Redux/features/search/searchSlice';
import moment from 'moment';
import Badge from '../Badge';
import getStatusColor from '../../Helper/functions/getStatusColor';
import SearchUnit from '../../Services/Http/Search';
import { useNavigate } from 'react-router-dom';
import createDetailUrl from '../../Helper/functions/createDetailUrl';

const HeaderBar = () => {

    const location = useLocation();

    const [openProfile, setOpenProfile] = useState(false);
    const [nameInitials, setNameInititals] = useState('');
    const [jobs, setJobs] = useState('');
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState({});
    const [searching, setSearching] = useState(true)
    const [hasResults, setHasResults] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getName();
        getJobs();
        window.addEventListener('click', detectClickOutsideProfile);

        return () => {
            window.removeEventListener('click', detectClickOutsideProfile);
        }
    }, [location])

    useEffect(() => {
        setSearching(true);
        const delayDebounceFn = setTimeout(() => {
            if (search && search.length > 2) {
                SearchUnit.search(search).then(res => {
                    setSearchResults(res);
                    setSearching(false);
                    setHasResults(res.routes.length > 0 || res.stops.length > 0)
                })
            } else {
                setSearchResults({});
                setSearching(true);
                setHasResults(false);
            }
        }, 500)

        if (search.length === 0) {
            setSearchResults({});
            setSearching(true);
            setHasResults(false);
        }

        return () => clearTimeout(delayDebounceFn)

    }, [search])

    const handleSearchClick = (type: string, data: any) => {

        let url = '';

        if (type === 'route') {
            url = createDetailUrl('/logistics/routes', data.id);
        } else if (type === 'routeStop') {
            if (data.status === 'to_assign') {

            } else if (data.routeId) {
                url = createDetailUrl('/logistics/routes', data.routeId, data.id);
            }
        }

        setSearch('');

        if (url !== '') {
            navigate(url);
        }
    }

    const getName = () => {
        let name = Storage.getValue('user', 'name');
        let initials = '';
        if (name.split(' ').length > 1) {
            name.split(' ').forEach((namePart: string) => {
                initials += namePart.substring(0, 1);
            });
        }
        setNameInititals(initials);
    }

    const getJobs = () => {
        const company = new Company(Storage.getValue('currentCompany'));
        setJobs(company.getUsersJobs());
    }

    const detectClickOutsideProfile = (e: any) => {
        if (!document.getElementById('profileContainer').contains(e.target)) {
            setOpenProfile(false);
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <img src={Logo} />
                <div className={`${styles.searchContainer} ${(!hasResults && !searching) || hasResults ? styles.hasResults : undefined}`}>
                    <TextField
                        id="standard-basic"
                        placeholder={translate('find_stops')}
                        type="search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className={`dark small search`}
                    />
                    {(searchResults?.routes?.length > 0 || searchResults?.stops?.length > 0 || (!hasResults && !searching))
                        && <div className={styles.results}>
                            {!hasResults

                                && <strong>{translate('no_results_found')}</strong>}
                            {searchResults?.routes?.length > 0 &&
                                <h5 style={{ marginTop: 0, marginBottom: 0 }}>{translate('routes')}</h5>
                            }
                            {
                                searchResults?.routes?.map((route: any, key: number) => {
                                    return <div
                                        className={styles.result}
                                        onClick={() => handleSearchClick('route', route)}
                                    >
                                        <strong>{route.title}</strong>
                                        <Badge
                                            state={getStatusColor(route?.status)}
                                            small
                                            label={translate(route?.status)}
                                            style={{ position: 'absolute', top: 5, right: 5 }}
                                        />
                                        <div className={styles.resultRow}>
                                            <div>{route.driver}</div>
                                            <div>{moment(new Date(route.start)).format('DD.MM.YYYY')}</div>
                                        </div>
                                    </div>
                                })
                            }
                            {searchResults?.routes?.length && searchResults?.stops?.length > 0 ?
                                <hr style={{ backgroundColor: 'white', width: '100%' }} />
                                : null
                            }
                            {searchResults?.stops?.length > 0 &&
                                <h5 style={{ marginTop: 0, marginBottom: 0 }}>{translate('stops')}</h5>
                            }
                            {
                                searchResults?.stops?.map((routeStop: any, key: number) => {
                                    return <div
                                        className={styles.result}
                                        onClick={() => handleSearchClick('routeStop', routeStop)}
                                    >
                                        <strong>{routeStop.name}</strong><br />
                                        <small>{routeStop.address}</small> <small>{routeStop.postcode}</small> <small>{routeStop.city}</small><br />
                                        {routeStop.estArrival && <small>{moment(new Date(routeStop.estArrival)).format('DD.MM.YYYY HH:mm')}</small>}
                                        <Badge
                                            state={getStatusColor(routeStop?.status)}
                                            small
                                            label={translate(routeStop?.status)}
                                            style={{ position: 'absolute', top: 5, right: 5 }}
                                        />
                                    </div>
                                })
                            }
                        </div>
                    }
                </div>
                <nav>
                    <Link to="/" className={location.pathname === '/' ? styles.current : undefined}><DashboardIcon /><span>{translate('dashboard')}</span></Link>
                    {/* <Link to="/warehouse" className={location.pathname.includes('/warehouse') ? styles.current : undefined}><WarehouseIcon /><span>{translate('warehouse')}</span></Link> */}
                    <div className={styles.subnavWrapper}>
                        <Link to="/logistics/routes" className={`${location.pathname.includes('/logistics') && !location.pathname.includes('/settings') ? styles.current : undefined} hasSubnav`}><LogisticsIcon /><span>{translate('logistics')}</span></Link>
                        <div className={styles.subnav}>
                            {/* <Link to="/logistics/stopgroups" className={location.pathname === '/logistics/stopgroups' ? styles.current : undefined}><DashboardIcon /><span>{translate('stop_groups')}</span></Link> */}
                            {/* <Link to="/logistics/routes" className={location.pathname === '/logistics/routes' ? styles.current : undefined}><DashboardIcon /><span>{translate('routes')}</span></Link> */}
                            {/* <Link to="/logistics/jobs" className={location.pathname === '/logistics/jobs' ? styles.current : undefined}><DashboardIcon /><span>{translate('jobs')}</span></Link> */}
                        </div>
                    </div>
                    <Link to="/settings" className={location.pathname.includes('/settings') ? styles.current : undefined}><SettignsIcon /><span>{translate('settings')}</span></Link>
                </nav>
                <div className={styles.verticalLine}></div>
                {/* <div className={styles.notifications}>
                    <div className={styles.badge}>2</div>
                </div> */}

                <div id="profileContainer" className={`${styles.profileContainer} ${openProfile ? styles.open : undefined}`}>
                    <div className={styles.inner} onClick={() => setOpenProfile(prev => !prev)}>
                        <div className={styles.icon}>{nameInitials}</div>
                        <div className={styles.contentContainer}>
                            <div className={`${styles.name} cuttext`}>{Storage.getValue('user', 'name')}</div>
                            <div className={`${styles.positions} cuttext`}>{jobs}</div>
                        </div>
                        <div className={styles.arrow}></div>
                    </div>
                    <nav>
                        <strong style={{ marginLeft: 20, marginTop: 10, display: 'block' }}>{translate('companies')}</strong>
                        <CompaniesSelect />
                        <Button
                            type="tertiary"
                            label={translate('logout')}
                            onClick={() => logout()}
                        />
                    </nav>
                </div>
            </div >
        </div >
    )
};

export default HeaderBar