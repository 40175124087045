import { createSlice, current } from '@reduxjs/toolkit'
import wrlog from '../../../../Helper/functions/wrlog';
import { db } from '../../../Database';

interface AddStopGroupsProps {
    payload: any;
}

export const stopGroupSlice = createSlice({
    name: 'stopgroup',
    initialState: { stopGroup: [] },
    reducers: {
        init: (state) => {
            // db.routeStops.toArray().then(res => {
            //     state.routeStop.push(res);
            // }).catch(() => {

            // })

        },
        setStopGroup: (state, action: AddStopGroupsProps) => {
            let index = current(state.stopGroup).findIndex((e: any) => e.id === action.payload.id);

            if (action.payload.status !== 'deleted') {
                if (index < 0) {
                    state.stopGroup.push(action.payload);
                } else {
                    // db.routeStops.update(action.payload.id, action.payload)



                    let newOb = { ...action.payload }
                    delete newOb.id;

                    state.stopGroup[index] = { ...current(state.stopGroup)[index], ...newOb };

                    // action.payload.id = current(state.stopGroup)[index].id;
                }
            } else {
                state.stopGroup.splice(index, 1);
            }

        }
    },
})

// Action creators are generated for each case reducer function
export const { setStopGroup } = stopGroupSlice.actions

export default stopGroupSlice.reducer
