// import { TextField } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

interface BadgeProps {
    label: string;
    state: string;
    icon?: any;
    small?: any;
    style?: any;
}
/**
 * 
 * @returns Badge
 */
const Badge = (props: BadgeProps) => {
    const {
        label,
        state,
        icon,
        small,
        style
    } = props;

    return (
        <div
            className={`${styles.container} ${styles[state]} ${small !== undefined && small !== false ? styles.small : undefined}`}
            style={{ ...style }}
        >
            {icon
                && <img className={styles.icon} src={icon} />}
            {label}
        </div>
    );
}

export default Badge;