import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../../Assets/Logo.png';

import { Card, Checkbox, Grid, Skeleton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../Sidebar';
import Map from '../../../Components/Map';
import Button from '../../../Components/Button';
import CsvImporter from '../../../Components/CsvImporter';
import RouteStopUnit from '../../../Services/Http/RouteStop';
import wrlog from '../../../Helper/functions/wrlog';
import AddressAutocomplete from '../../../Components/AddressAutocomplete';
import DetailContainer from '../../../Components/DetailContainer';
import RouteStopDetail from '../RouteStopDetail';
import StopGroupUnit from '../../../Services/Http/StopGroup';

import AddIcon from '../../../Assets/add.svg';
import Popup from '../../../Components/Popup';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import StopGroupDetail from './StopGroupDetail';
import { setRouteStopData } from '../../../Services/Redux/features/routeStop/routeStopSlice';
import { setStopGroup } from '../../../Services/Redux/features/stopGroups/stopGroupsSlice';
import translatePriority from '../../../Helper/functions/translatePriority';

interface StopGroupProps {
}

/**
 * 
 * @returns Login Page
 */
const StopGroup = (props: StopGroupProps) => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const addStopGroupButtonRef = useRef();

    const dispatch = useDispatch();

    // const [routeStops, setRouteStops] = useState([]);
    // const [stopGroups, setStopGroups] = useState([]);
    const [flyToDestination, setFlyToDestination] = useState(null);
    const [currentStop, setCurrentStop] = useState(null);
    const [currentStopGroup, setCurrentStopGroup] = useState(null);
    const [detailContainerVisible, setDetailContainerVisible] = useState(false);
    const [detailContainerLoading, setDetailContainerLoading] = useState(true);
    const [routeStopLoading, setRouteStopLoading] = useState(false);
    const [detailContainerType, setDetailContainerType] = useState(null);
    const [saveState, setSaveState] = useState('default');
    const [addStopGroupPopupOpen, setAddStopGroupPopupOpen] = useState(false);
    const [assignStopsToGroupPopupOpen, setAssignStopsToGroupPopupOpen] = useState(false);
    const [showStopIds, setShowStopIds] = useState([]);

    const stopGroups = useSelector(state => state.stopGroup.stopGroup);
    const routeStops = useSelector(state => state.routeStop);

    useEffect(() => {
        loadStopGroups();
    }, [])

    useEffect(() => {
        stopGroups.forEach((stopGroup: any) => {
            if (stopGroup.id === currentStopGroup?.id && stopGroup.stops !== undefined) {
                const stopIds = stopGroup.stops.map((stop: any) => stop.id);
                wrlog("stopGroup", stopGroup.stops);
                setShowStopIds(stopIds);
            }
        });
    }, [stopGroups])


    const loadStopGroups = () => {
        StopGroupUnit.listStopGroups().then((res: any) => {
            res.forEach((stopGroup: any) => {
                dispatch(setStopGroup(stopGroup));
            });
        }).catch((err: any) => {
            wrlog(err)
        })
    }

    const loadRouteStops = (id: string) => {
        setRouteStopLoading(true);
        StopGroupUnit.listStops(id).then((res: any) => {
            setRouteStopLoading(false);
            dispatch(setStopGroup(res));
            let stopIds = res.stops.map((stop: any) => {
                dispatch(setRouteStopData(stop));

                return stop.id;
            });
            setShowStopIds(stopIds)
            dispatch(setStopGroup(res));
        }).catch((err: any) => {
            wrlog(err)
        })
    }

    const loadStopDetail = (routeStopId: string) => {
        setDetailContainerLoading(true);
        RouteStopUnit.getStop(routeStopId).then((res: any) => {
            setCurrentStop(res);
            dispatch(setRouteStopData(res));
            setDetailContainerLoading(false);
        }).catch((err: any) => {
            wrlog(err);
        })
    }


    const selectStopGroup = (stopGroup: any) => {
        if (stopGroup.id === currentStopGroup?.id) {
            setCurrentStopGroup(null);
            setDetailContainerType(null);
            setDetailContainerVisible(false);
            setDetailContainerLoading(false);
        } else {
            wrlog("stopGroup", stopGroup)
            setCurrentStopGroup(stopGroup)
            loadRouteStops(stopGroup.id);
            setDetailContainerType('stopGroup');
            setDetailContainerVisible(true);
            setDetailContainerLoading(false);
        }
    }

    const createStopGroup = (data: any) => {
        addStopGroupButtonRef.current.changeState('loading');
        StopGroupUnit.createGroup(data.title).then(res => {
            loadStopGroups();
            addStopGroupButtonRef.current.changeState('success');
            setTimeout(() => {
                setAddStopGroupPopupOpen(false);
            }, 500)
        }).catch((err: any) => {
            addStopGroupButtonRef.current.changeState('error');
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
        })
    }

    const selectRouteStop = (routeStop: any) => {
        setFlyToDestination([routeStop.longitude, routeStop.latitude]);
        if (routeStop.canReadFull) {
            setCurrentStop(routeStop);
            loadStopDetail(routeStop.id);
            setDetailContainerType('routeStop');
            setDetailContainerVisible(true);
            setDetailContainerLoading(true);
        }
    }

    const detailLoading = () => (
        <>
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={'calc(100% - 200px)'} width={'100%'} style={{ marginTop: 20 }} />
        </>
    )

    return (
        <>
            <Sidebar>
                <Card style={{ marginBottom: 30 }}>
                    <Grid container padding={0}>
                        <Grid item md={6} padding={0}>
                            <h4 style={{ marginBottom: 0, marginLeft: 5 }}>{translate('stop_groups')}</h4>
                        </Grid>
                        <Grid item md={6} padding={0} className='align_right'>
                            <Button
                                type='tertiary'
                                icon={<img src={AddIcon} />}
                                label={translate('add')}
                                onClick={() => setAddStopGroupPopupOpen(true)}
                                preventLoading={true}
                            />
                        </Grid>
                    </Grid>
                </Card>
                <div className='smallListContainer fullHeight'>
                    {
                        stopGroups.map((stopGroup: any, key: number) => {
                            return <div
                                key={key}
                                className={`smallListItem cuttext expandable white ${currentStopGroup?.id === stopGroup.id ? 'open' : undefined}`}
                            >
                                <div className='header'
                                    onClick={() => selectStopGroup(stopGroup)}
                                >
                                    <h4 style={{ margin: 0 }}>{stopGroup.title}</h4>
                                    {stopGroup.count} {stopGroup.count == 1 ? translate('stop') : translate('stops')}<br />
                                    <div className='expendableIcon'></div>
                                </div>
                                {
                                    currentStopGroup?.id === stopGroup.id && <div className='innerWrapper'>
                                        {routeStopLoading ? detailLoading() :
                                            routeStops.length > 0 ? routeStops.map((routeStop: any) => {
                                                if (!showStopIds.includes(routeStop.id)) {
                                                    return null;
                                                }
                                                return <div
                                                    className='smallListItem cuttext'
                                                    onClick={() => selectRouteStop(routeStop)}
                                                >
                                                    <strong>{routeStop.address} {routeStop.streetNumber}</strong><br />
                                                    {routeStop.postcode} {routeStop.city}<br />
                                                    {routeStop.priority > 0 && <div className='badge'>{translatePriority(routeStop.priority)}</div>}
                                                </div>
                                            })
                                                : <strong>{translate('no_route_stops_found')}</strong>
                                        }
                                    </div>
                                }

                            </div>
                        })
                    }
                </div>
            </Sidebar>
            <div className='mapContainer'>
                <Map
                    markers={[...routeStops]}
                    routes={[]}
                    drivers={[]}
                    flyTo={flyToDestination}
                />

                <DetailContainer
                    visible={detailContainerVisible}
                    close={() => setDetailContainerVisible(false)}
                >
                    {saveState !== 'default' && <div
                        style={{ top: 20, right: 0 }}
                        className={`saveBadge ${saveState === 'saving' ? 'animate-flicker' : undefined}`}>{translate(saveState)}</div>}


                    {
                        detailContainerLoading ? detailLoading() : <>
                            {detailContainerType === 'routeStop' && <>
                                <Button
                                    type='secondary'
                                    preventLoading
                                    label={translate('back')}
                                    style={{ top: 15, left: 15, position: 'absolute' }}
                                    onClick={() => setDetailContainerType('stopGroup')}
                                />
                                <RouteStopDetail
                                    selectedRouteStopId={currentStop.id}
                                    reload={loadStopGroups}
                                    setSaveState={setSaveState}
                                    reloadSingle={() => loadStopDetail(currentStop.id)}
                                    closeSidebar={() => setDetailContainerVisible(false)}
                                /></>
                            }
                            {detailContainerType === 'stopGroup' && <StopGroupDetail
                                currentStopGroup={currentStopGroup}
                                reload={loadStopGroups}
                                setSaveState={setSaveState}
                                reloadSingle={() => loadStopDetail(currentStop.id)}
                                closeSidebar={() => setDetailContainerVisible(false)}
                            />
                            }
                        </>
                    }
                </DetailContainer>
            </div>
            <Popup
                close={() => setAddStopGroupPopupOpen(false)}
                open={addStopGroupPopupOpen}
                style={{ maxWidth: 400 }}
            >
                <h4 style={{ marginTop: 0 }}>{translate('add_stop_group')}</h4>
                <form onSubmit={handleSubmit(createStopGroup)}>
                    <TextField
                        {...register('title', { required: true })}
                        error={errors.title}
                        id="standard-basic"
                        label={translate('title')}
                        type="text"
                        className='dark'
                    />
                    <Button
                        type='primary'
                        isInput
                        label={translate('add_stop_group')}
                        ref={addStopGroupButtonRef}
                    />
                </form>
            </Popup>
        </>
    );
}

export default StopGroup;