import { Buffer } from "buffer";
import wrlog from "./wrlog";

const createDetailUrl = (base, routeId, routeStopId = null, preventReload = false) => {
    if (!routeId) {
        return base;
    }

    let raw = `${routeId}`;
    if (routeStopId) {
        raw += `:${routeStopId}`;
    }
    if(preventReload){
        raw += `:--preventreload`;
    }
    return `${base}?q=${Buffer.from(raw).toString('base64')}`;
}

export default createDetailUrl;