import wrlog from '../../Helper/functions/wrlog';
import Storage from '../Storage';
import Unit from './index';

export default class RouteUnit extends Unit {

    static list = async (body: any) => {
        return await Unit.post('/route/list', body);
    }

    static getRoute = async (routeId: string) => {
        return await Unit.get(`/route/${routeId}`);
    }

    static listStops = async (routeId: string) => {
        return await Unit.get(`/route/${routeId}`);
    }

    static getStops = async (routeId: string) => {
        return await Unit.get(`/route/${routeId}/stops`);
    }

    static create = async (body: any) => {
        return await Unit.post('/route', body);
    }

    static update = async (routeId: string, body: any) => {
        return await Unit.put(`/route/${routeId}`, body);
    }

    static getAvailableGroups = async () => {
        return await Unit.get('/route/groups');
    }

    static getAvailableStops = async () => {
        return await Unit.get('/route/stops');
    }

    static calculateRoutes = async (body: any) => {
        return await Unit.post('/route/calculate', body);
    }

    static createRoutes = async (body: any) => {
        return await Unit.post('/route', body);
    }

    static resort = async (routeId: string, body: any) => {
        return await Unit.put(`/route/${routeId}/resort`, body);
    }

    static detachRouteStop = async (routeId: string, routeStopId: string) => {
        return await Unit.delete(`/route/${routeId}/detach/${routeStopId}`, {});
    }

    static attachRouteStops = async (routeId: string, routeStopIds: string) => {
        return await Unit.put(`/route/${routeId}/attach`, { ids: routeStopIds });
    }

    static assignToDifferentRoute = async (routeId: string, body: any) => {
        return await Unit.put(`/route/${routeId}/reassign`, body);
    }

    static downloadPackingList = async (routeId: string) => {
        return await Unit.get(`/route/${routeId}/packinglist`);
    }

    static getCsvData = async (body: any) => {
        return await Unit.post('/route/csv', body);
    }

    static optimizeRoute = async (routeId: any) => {
        return await Unit.post(`/route/${routeId}/optimize`, {});
    }
}



