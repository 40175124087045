import { Avatar, IconButton, Menu, Tooltip } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import translatePriority from '../../Helper/functions/translatePriority';
import wrlog from '../../Helper/functions/wrlog';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { translate } from '../../Helper/multilingual';
import { MenuItem } from '@mui/material';
import { ListItemIcon } from '@mui/material';

interface Props {
    contextMenu?: any,
    style?: any,
}

const ContextMenu = (
    props: Props
) => {

    const {
        contextMenu,
        style
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
    }, [anchorEl])
    const handleContectMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleContectMenuClose = () => {
        setAnchorEl(null);
    };


    return <>
        <Tooltip title={translate('settings')}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={anchorEl !== null ? 'long-menu' : undefined}
                aria-expanded={anchorEl !== null ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleContectMenuClick}
                style={{ position: 'absolute', top: 'calc(50% - 20px)', right: 10, ...style }}
            >
                <MoreVertIcon />
            </IconButton>
        </Tooltip>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={anchorEl !== null}
            onClose={handleContectMenuClose}
            onClick={handleContectMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {
                contextMenu.map(menuItem => {
                    return <MenuItem
                        onClick={() => { wrlog("MENU CL"); menuItem.onClick() }}
                    >
                        {
                            menuItem.icon && <ListItemIcon>{menuItem.icon()}</ListItemIcon>
                        }
                        {menuItem.label}

                    </MenuItem>
                })
            }
        </Menu>
    </>
}

export default memo(ContextMenu)