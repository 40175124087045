import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useLocation, useParams } from 'react-router-dom';
import Logo from '../../../Assets/Logo.png';

import styles from './styles.module.scss';

import { ReactComponent as SettingsIcon } from '../../../Assets/navigationIcons/settings.svg';
import { ReactComponent as UsersIcon } from '../../../Assets/navigationIcons/users.svg';
import { ReactComponent as NotificationsIcon } from '../../../Assets/navigationIcons/notifications_grey.svg';
import { ReactComponent as RouteIcon } from '../../../Assets/navigationIcons/route.svg';

import { ReactComponent as ApiKeyIcon } from '../../../Assets/navigationIcons/api-keys.svg';
import { ReactComponent as WorkflowIcon } from '../../../Assets/navigationIcons/workflows.svg';
import { ReactComponent as ImportIcon } from '../../../Assets/navigationIcons/import.svg';
import { ReactComponent as WarehouseIcon } from '../../../Assets/navigationIcons/warehouse.svg';

import ArrowIcon from '../../../Assets/navigationIcons/arrow_down.svg';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';

interface Props {
}

/**
 * 
 * @returns Login Page
 */
const Sidebar = (props: Props) => {

    const location = useLocation();

    return (
        <div className={styles.container}>
            <Accordion defaultExpanded={true} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary>{translate('settings')}<img src={ArrowIcon} /></AccordionSummary>
                <AccordionDetails>
                    <Link to="/settings" className={location.pathname === '/settings' || location.pathname === '/settings/profile' || location.pathname === '/settings/password' ? styles.current : undefined}><SettingsIcon /><span>{translate('general_settings')}</span></Link>
                    <Link to="/settings/users" className={location.pathname.includes('/settings/users') ? styles.current : undefined}><UsersIcon /><span>{translate('users')}</span></Link>
                    <Link to="/settings/api" className={location.pathname.includes('/settings/api') ? styles.current : undefined}><ApiKeyIcon /><span>{translate('api_clients')}</span></Link>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary>{translate('logistics')}<img src={ArrowIcon} /></AccordionSummary>
                <AccordionDetails>
                    <Link to="/settings/logistics/notifications" className={location.pathname === '/settings/logistics/notifications' ? styles.current : undefined}><NotificationsIcon /><span>{translate('notifications')}</span></Link>
                    <Link to="/settings/logistics/route" className={location.pathname === '/settings/logistics/route' ? styles.current : undefined}><RouteIcon /><span>{translate('route_set_up')}</span></Link>
                    <Link to="/settings/warehouses" className={location.pathname.includes('/settings/warehouses') ? styles.current : undefined}><WarehouseIcon /><span>{translate('warehouses')}</span></Link>
                    <Link to="/settings/logistics/stopgroups" className={location.pathname === '/settings/logistics/stopgroups' ? styles.current : undefined}><WorkflowIcon /><span>{translate('workflows')}</span></Link>
                    <Link to="/settings/logistics/imports" className={location.pathname.includes('/settings/logistics/imports') ? styles.current : undefined}><ImportIcon /><span>{translate('imports')}</span></Link>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default Sidebar;