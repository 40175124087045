import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import Popup from '../Popup';
import 'mapbox-gl/dist/mapbox-gl.css';
import { CSVBoxButton } from '@csvbox/react'
import { getUserToken } from '../../Helper/functions/auth';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { translate } from '../../Helper/multilingual';
import Button from '../Button';
import Storage from '../../Services/Storage';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../Services/Redux/features/messages/messageSlice';
import FileUpload from './fileUpload';
import CheckData from './checkData';
import Mapping from './mapping';
import Progress from './progress';
import RouteStopUnit from '../../Services/Http/RouteStop';
import uuid from 'react-uuid'
import { API_ENDPOINT } from '../../config';

mapboxgl.accessToken = 'pk.eyJ1IjoiYWZyZXNoZWQiLCJhIjoiY2w0Y214aDB2MDAzZjNicGE5b2RhMXl1cSJ9.Hj4CU2_lKV7E52qb_dTQ7A';

interface CsvImporterProps {
    reload: any;
}

const CsvImporter = (props: CsvImporterProps) => {

    const { reload } = props;
    const dispatch = useDispatch();

    const [importerOpen, setImporterOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [json, setJson] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        setCurrentPage(1)
    }, [importerOpen])

    const upload = async (stops: any) => {
        setCurrentPage(4);
        setUploadProgress(1);

        const fileData = JSON.stringify(stops);
        const blob = new Blob([fileData], { type: "text/plain" });

        const formData = new FormData();
        formData.append("file", blob);

        await RouteStopUnit.import(formData);
        setUploadProgress(100);

        setImporterOpen(false);
        reload();
    }

    return <><Button
        type={'secondary'}
        style={{ height: 50, borderRadius: 15, padding: '5px 15px' }}
        preventLoading
        label={translate('import')}
        onClick={() => setImporterOpen(true)}
    />

        <Popup
            open={importerOpen}
            close={() => setImporterOpen(false)}
            style={{ maxWidth: 1100 }}
        >
            {currentPage === 1 && <FileUpload
                setPage={setCurrentPage}
                setJson={setJson}
                closePopup={() => setImporterOpen(false)}
            />}
            {currentPage === 2 && <CheckData
                setPage={setCurrentPage}
                json={json}
                setJson={setJson}
            />}
            {currentPage === 3 && <Mapping
                setPage={setCurrentPage}
                json={json}
                setJson={setJson}
                upload={upload}
            />}
            {currentPage === 4 && <Progress
                progress={uploadProgress}
            />}
        </Popup>

    </>

}

export default CsvImporter;