import React, { useEffect, useRef, useState } from 'react';
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';

import DeliveryGuyImage from '../../../Assets/placeholder_images/delivery_guy.jpg';

import { Card, Checkbox, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Sidebar from '../Sidebar';
import CompanyUnit from '../../../Services/Http/Company';
import CustomSwitch from '../../../Components/Form/Switch';
import Button from '../../../Components/Button';
import Popup from '../../../Components/Popup';

interface RouteSettingsProps {
}

/**
 * 
 * @returns Page for API Client settings
 */
const ApiClientSettings = (props: RouteSettingsProps) => {
    const [loading, setLoading] = useState(true);

    const [clients, setClients] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [createPopupOpen, setCreatePopupOpen] = useState(false);
    const [createSuccessResponse, setCreateSuccessResponse] = useState({});
    const [createSuccessPopupOpen, setCreateSuccessPopupOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        load();
    }, [])

    const load = () => {
        CompanyUnit.getApiClients().then(res => {
            setClients(res);
            setLoading(false);
        }).catch(() => {
            setLoading(false)
        })
    }

    const revoke = (key: string) => {
        setLoading(true);
        CompanyUnit.revokeApiClient(key).then(res => {
            load();
        }).catch(() => {
            setLoading(false)
        })
    }

    const create = () => {
        CompanyUnit.createApiClient(permissions).then(res => {
            setCreateSuccessResponse(res);
            setCreatePopupOpen(false);
            setCreateSuccessPopupOpen(true);
            load();
        }).catch(() => {
            setLoading(false)
        })
    }

    const listLoadingContainer = () => {
        return <Grid container spacing={0}>
            <Grid item md={12} padding={4}>
                <Skeleton variant='rectangular' animation="wave" height={60} width={'100%'} />
                <Skeleton variant='rectangular' animation="wave" height={60} width={'100%'} style={{ marginBottom: 40 }} />
                <Skeleton variant='rectangular' animation="wave" height={30} width={'100%'} />
                <Skeleton variant='rectangular' animation="wave" height={30} width={'100%'} />
            </Grid>
        </Grid>
    }

    return (
        <>
            <Sidebar />
            <div className='middleContainer'>
                {
                    loading ? listLoadingContainer() :
                        <Grid container spacing={0}>
                            <Grid item md={12} padding={4}>
                                <h3>{translate('api_clients')}</h3>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>{translate('api_key')}</strong></TableCell>
                                            <TableCell><strong>{translate('permissions')}</strong></TableCell>
                                            <TableCell><strong>{translate('status')}</strong></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {
                                            clients.map(({ key, permissions, status }, rowKey) => (
                                                <TableRow key={rowKey}>
                                                    <TableCell>{key}</TableCell>
                                                    <TableCell>{Object.keys(permissions).filter(pkey => permissions[pkey]).join(', ')}</TableCell>
                                                    <TableCell>{translate(status)}</TableCell>
                                                    <TableCell>
                                                        {status === 'active'
                                                            && <Button
                                                                onClick={() => revoke(key)}
                                                                label={translate('revoke')}
                                                                type={'tertiary'}
                                                                className={'delete'}
                                                            />
                                                        }
                                                    </TableCell>
                                                </TableRow>

                                            ))
                                        }
                                    </TableBody>
                                </Table>
                                <Button
                                    onClick={() => {
                                        setCreatePopupOpen(true);
                                        setPermissions({})
                                    }}
                                    label={translate('create_api_client')}
                                    type={'primary'}
                                    style={{ marginTop: 30 }}
                                    preventLoading
                                />
                            </Grid>
                        </Grid>
                }
            </div>
            <div className='rightContainer'>
                <Grid container spacing={0}>
                    <Grid item md={12} padding={4} style={{ textAlign: 'center' }}>
                        <img src={DeliveryGuyImage} style={{ width: '70%' }} />
                        <Grid container spacing={0} style={{ textAlign: 'left' }}>
                            <Grid item md={12} style={{ padding: 80, paddingTop: 20 }}>
                                <h3>{translate('modern_route_planning_headline')}</h3>
                                <p>
                                    {translate('modern_route_planning_text')}
                                </p>
                                <Button
                                    onClick={() => navigate('/logistics/routeplanning')}
                                    label={translate('plan_route')}
                                    type={'primary'}
                                    className='smallWidth'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Popup
                open={createPopupOpen}
                close={() => setCreatePopupOpen(false)}
                style={{ maxWidth: 500 }}
            >
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item md={9} padding={0}>
                        {translate('api_client_import')}
                    </Grid>
                    <Grid item md={3} padding={0} className='align_right'>
                        <CustomSwitch onChange={(e) => {
                            let _permissions = permissions;
                            _permissions['import'] = e.target.checked;
                            setPermissions(_permissions);
                        }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item md={9} padding={0}>
                        {translate('api_client_geocoding')}
                    </Grid>
                    <Grid item md={3} padding={0} className='align_right'>
                        <CustomSwitch onChange={(e) => {
                            let _permissions = permissions;
                            _permissions['geocoding'] = e.target.checked;
                            setPermissions(_permissions);
                        }}
                        />
                    </Grid>
                </Grid>
                {/* <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item md={9} padding={0}>
                        {translate('api_client_route_read')}
                    </Grid>
                    <Grid item md={3} padding={0} className='align_right'>
                        <CustomSwitch onChange={(e) => {
                            let _permissions = permissions;
                            _permissions['route.read'] = e.target.checked;
                            setPermissions(_permissions);
                        }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item md={9} padding={0}>
                        {translate('api_client_stop_read')}
                    </Grid>
                    <Grid item md={3} padding={0} className='align_right'>
                        <CustomSwitch onChange={(e) => {
                            let _permissions = permissions;
                            _permissions['stop.read'] = e.target.checked;
                            setPermissions(_permissions);
                        }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item md={9} padding={0}>
                        {translate('api_client_parcel_read')}
                    </Grid>
                    <Grid item md={3} padding={0} className='align_right'>
                        <CustomSwitch onChange={(e) => {
                            let _permissions = permissions;
                            _permissions['parcel.read'] = e.target.checked;
                            setPermissions(_permissions);
                        }}
                        />
                    </Grid>
                </Grid> */}

                <Button
                    onClick={() => create()}
                    label={translate('create_api_client')}
                    type={'primary'}
                    style={{ marginTop: 30 }}
                />

            </Popup>

            <Popup
                open={createSuccessPopupOpen}
                close={() => setCreateSuccessPopupOpen(false)}
                style={{ maxWidth: 500 }}
            >
                <h4>{translate('api_client_created')}</h4>
                <p>{translate('api_client_created_body')}</p>

                <br />
                <strong>{createSuccessResponse?.key}</strong><br />
                <small>API-Key</small>

                <br />
                <br />
                <strong>{createSuccessResponse?.secret}</strong><br />
                <small>API-Secret</small>
            </Popup>

        </>
    );
}

export default ApiClientSettings;