import wrlog from '../../Helper/functions/wrlog';
import Storage from '../Storage';
import Unit from './index';

export default class NotificationUnit extends Unit {

    static listNotifications = async () => {
        return await Unit.get('/notification');
    }

    static listNotificationTriggers = async () => {
        return await Unit.get('/notification/triggers');
    }

    static createNotification = async (trigger: string, headline: string, textBody: string) => {
        const body = {
            trigger: trigger,
            headline: headline,
            body: textBody,
        }
        return await Unit.post('/notification', body);
    }

    static updateNotification = async (id: string, trigger: string, headline: string, textBody: string) => {
        const body = {
            id: id,
            data: {
                trigger: trigger,
                headline: headline,
                body: textBody,
            }
        }

        return await Unit.put('/notification', body);
    }

}



