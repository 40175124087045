import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import AddIcon from '../../Assets/add.svg';
import { translate } from '../../Helper/multilingual';
import InputTags from '../Form/Tags';
import styles from './styles.module.scss'
import uuid from 'react-uuid'
import Popup from '../Popup';
import wrlog from '../../Helper/functions/wrlog';

interface Props {
    values: any;
    onChange: any;
}

const defaultQuery = {
    combinator: 'and',
    isGroundLevel: true,
    type: 'group',
    id: uuid(),
    filters: []
}

const defaultElements = {
    group: {
        isGroundLevel: false,
        combinator: 'and',
        type: 'group',
        id: '',
        filters: []
    },
    postcodes: {
        id: '',
        type: 'postcode',
        value: []
    },
    tags: {
        id: '',
        type: 'tags',
        value: []
    },
};


/**
 * 
 * @returns Login Page
 */
const QueryBuilder = (props: Props) => {

    const { values, onChange } = props;

    const [query, setQuery] = useState(null);
    const [isInit, setIsInit] = useState(true);

    useEffect(() => {
        if (!isInit) {
            onChange(query);
        }
    }, [query])

    useEffect(() => {
        if (isInit) {
            if (!values || !values.filters) {
                setQuery({ ...defaultQuery });
            } else {
                setQuery(values);
            }
            setIsInit(false)
        }
    }, [values])

    if (query === null || query === undefined) {
        return <></>
    }

    const renderPostcode = ({ value, id }) => {
        return <div className={styles.element}>
            <InputTags
                value={value}
                title={'postcodes'}
                onChange={res => { updateValue(id, res) }}
            />
            <Button
                label={translate('delete')}
                type={'tertiary'}
                onClick={() => removeElement(id)}
                className={'delete'}
                style={{ position: 'absolute', top: 'calc(50% - 6px)', transform: 'translateY(-50%)', right: 10 }}
            />
        </div>
    }

    const renderTag = ({ value, id }) => {
        return <div className={styles.element}>
            <InputTags
                value={value}
                title={'tags'}
                onChange={res => { updateValue(id, res) }}
            />
            <Button
                label={translate('delete')}
                type={'tertiary'}
                onClick={() => removeElement(id)}
                className={'delete'}
                style={{ position: 'absolute', top: 'calc(50% - 6px)', transform: 'translateY(-50%)', right: 10 }}
            />
        </div>
    }

    const addElement = (containerId, elementType) => {

        let queryCopy = JSON.parse(JSON.stringify(query))
        console.log(queryCopy);

        if (queryCopy?.id?.toString() == containerId?.toString()) {
            let newElement = defaultElements[elementType];
            newElement.id = uuid();
            queryCopy.filters.push(newElement)
        }

        queryCopy.filters.forEach((element, key) => {
            if (element.type === 'group' && element.id === containerId) {
                let newElement = defaultElements[elementType];
                newElement.id = uuid();
                queryCopy.filters[key].filters.push(newElement)
            }
        });

        setQuery(queryCopy);
    }

    const removeElement = (containerId: string) => {

        let queryCopy = JSON.parse(JSON.stringify(query))

        queryCopy.filters.forEach((element: any, key: string) => {
            if (element.id === containerId) {
                queryCopy.filters.splice(key, 1);
            } else if (element.type === 'group') {
                element.filters.forEach((element1: any, key1: string) => {
                    if (element1.id === containerId) {
                        queryCopy.filters[key].filters.splice(key1, 1);
                    }
                })
            }
        });

        setQuery(queryCopy);
    }

    const updateCombinator = (containerId: string) => {
        let queryCopy = JSON.parse(JSON.stringify(query))
        console.log(queryCopy)

        if (queryCopy?.id?.toString() == containerId?.toString()) {
            queryCopy.combinator = queryCopy.combinator === 'and' ? 'or' : 'and'
        }

        queryCopy.filters.forEach((element, key) => {
            if (element.id === containerId) {
                queryCopy.filters[key].combinator = queryCopy.filters[key].combinator === 'and' ? 'or' : 'and'
            }
        });

        setQuery(queryCopy);
    }

    const updateValue = (containerId: string, value: any) => {
        let queryCopy = JSON.parse(JSON.stringify(query))

        queryCopy.filters.forEach((element: any, key: string) => {
            if (element.id === containerId) {
                queryCopy.filters[key].value = value;
            } else if (element.type === 'group') {
                element.filters.forEach((element1: any, key1: string) => {
                    if (element1.id === containerId) {
                        queryCopy.filters[key].filters[key1].value = value;
                    }
                })
            }
        });

        setQuery(queryCopy);
    }

    const renderGroup = ({ combinator, filters, id, isGroundLevel }) => {
        return <div className={styles.groupContainer}>

            {
                filters?.length > 1 && <div className={`${styles.combinatorWrapper} ${styles[combinator]}`}>
                    <div
                        onClick={() => updateCombinator(id)}
                        className={styles.combinatorLabel}>{translate(combinator)}</div>
                </div>
            }

            <div className={styles.contentContainer}>
                {
                    filters?.map(filter => {
                        switch (filter.type) {
                            case 'postcode':
                                return renderPostcode(filter)
                            case 'tags':
                                return renderTag(filter)
                            case 'group':
                                return renderGroup(filter)
                        }
                    })
                }
            </div>
            <div className={styles.buttonContainer} style={filters?.length > 0 ? { marginTop: 20 } : {}}>
                <Button
                    label={translate('postcode')}
                    icon={<img src={AddIcon} />}
                    type={'tertiary'}
                    onClick={() => addElement(id, 'postcodes')}
                />
                <Button
                    label={translate('tag')}
                    icon={<img src={AddIcon} />}
                    type={'tertiary'}
                    onClick={() => addElement(id, 'tags')}
                />
                {isGroundLevel && <Button
                    label={translate('add_inner_group')}
                    icon={<img src={AddIcon} />}
                    type={'tertiary'}
                    onClick={() => addElement(id, 'group')}
                />}
                {!isGroundLevel && <Button
                    label={translate('delete')}
                    type={'tertiary'}
                    onClick={() => removeElement(id)}
                    className={'delete'}
                    style={{ float: 'right' }}
                />}
            </div>
        </div>
    }

    return (<>
        {renderGroup(query)}
    </>
    );
}

export default QueryBuilder;