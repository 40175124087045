import Storage from "../../Services/Storage";
import { translate } from "../multilingual";

export default class Company {
    id: string;
    jobs: any;
    title: string;

    constructor(id: string) {
        this.id = id;
        this.jobs = [];
        this.title = '';
    }

    getData = () => {
        Storage.getValue('user', 'companies').forEach(company => {
            if (company.id === this.id) {
                this.title = company.title;
                this.jobs = company.roles;
            }
        });
    }

    getUsersJobs = () => {
        this.getData();
        let jobTitles = [];
        this.jobs.forEach((job: any) => {
            jobTitles.push(translate(job.title))
        })
        return jobTitles.join(', ');
    }
}