import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../../../Assets/Logo.png';

import { Card, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Table, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../Sidebar';
import Map from '../../../../Components/Map';
import Button from '../../../../Components/Button';
import CsvImporter from '../../../../Components/CsvImporter';
import RouteStopUnit from '../../../../Services/Http/RouteStop';
import wrlog from '../../../../Helper/functions/wrlog';
import AddressAutocomplete from '../../../../Components/AddressAutocomplete';
import DetailContainer from '../../../../Components/DetailContainer';
import RouteStopDetail from '../../RouteStopDetail';
import StopGroupUnit from '../../../../Services/Http/StopGroup';

import AddIcon from '../../../../Assets/add.svg';
import Popup from '../../../../Components/Popup';
import { addMessage } from '../../../../Services/Redux/features/messages/messageSlice';
// import StopGroupDetail from './StopGroupDetail';
import { setRouteStopData } from '../../../../Services/Redux/features/routeStop/routeStopSlice';
import { setStopGroup } from '../../../../Services/Redux/features/stopGroups/stopGroupsSlice';
import JobUnit from '../../../../Services/Http/Job';
import { setJob } from '../../../../Services/Redux/features/job/jobSlice';
import Badge from '../../../../Components/Badge';
import Storage from '../../../../Services/Storage';

interface StopGroupProps {
}

/**
 * 
 * @returns Login Page
 */
const JobSingle = (props: StopGroupProps) => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const addStopGroupButtonRef = useRef();
    const addContractorButtonRef = useRef();
    const mapRef = useRef();


    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const [currentRouteStops, setCurrentRouteStops] = useState([]);
    const [flyToDestination, setFlyToDestination] = useState(null);
    const [currentStop, setCurrentStop] = useState(null);
    const [currentStopGroup, setCurrentStopGroup] = useState(null);
    const [currentJob, setCurrentJob] = useState({});
    const [detailContainerVisible, setDetailContainerVisible] = useState(false);
    const [detailContainerLoading, setDetailContainerLoading] = useState(true);
    const [routeStopLoading, setRouteStopLoading] = useState(false);
    const [detailContainerType, setDetailContainerType] = useState(null);
    const [saveState, setSaveState] = useState('default');
    const [addStopGroupPopupOpen, setAddStopGroupPopupOpen] = useState(false);
    const [assignStopsToGroupPopupOpen, setAssignStopsToGroupPopupOpen] = useState(false);
    const [showStopIds, setShowStopIds] = useState([]);
    const [showStopIdsForGroup, setShowStopIdsForGroup] = useState([]);
    const [addContractorFormOpen, setAddContractorFormOpen] = useState(true);
    const [availableContractors, setAvailableContractors] = useState([]);
    const [newContractor, setNewContractor] = useState('new');
    const [newContractorCode, setNewContractorCode] = useState('');
    const [locations, setLocations] = useState([]);


    const jobs = useSelector(state => state.job.job);
    const routeStops = useSelector(state => state.routeStop);

    useEffect(() => {
        loadJobDetail();
    }, [])

    useEffect(() => {
        jobs.forEach(job => {
            if (job.id === params.id) {
                setCurrentJob(job);
                if (job.stops !== undefined) {
                    setShowStopIds(job.stops.map((stop: any) => {
                        dispatch(setRouteStopData(stop));
                        return stop.id
                    }))
                }

            }
        });
    }, [params.id, jobs])

    useEffect(() => {
        if (currentJob?.type === 'own') {
            loadAvailableContractors();
        }
        loadLocations();
    }, [currentJob])

    const loadJobDetail = () => {
        JobUnit.getJobDetails(params.id).then((res: any) => {

            if (res.companyId === Storage.getValue('currentCompany')) {
                res.type = 'own';
            } else {
                if (res.status === 'pending') {
                    res.type = 'new';
                } else if (res.status === 'active') {
                    res.type = 'active';
                } else if (res.status === 'done') {
                    res.type = 'done';
                }
            }

            dispatch(setJob(res));
            setFlyToDestination('bounds');
            res.stopGroups.forEach((stopGroup: any) => {
                dispatch(setStopGroup(stopGroup));
            });
        }).catch((err: any) => {
            wrlog(err)
        })
    }

    const loadLocations = () => {
        JobUnit.getJobLocations(params.id).then((locations: any) => {
            setLocations(locations);
            mapRef.current.flyTo('bounds')
        })
    }

    const loadRouteStops = (id: string) => {
        setRouteStopLoading(true);
        StopGroupUnit.listStops(id).then((res: any) => {
            setRouteStopLoading(false);
            dispatch(setStopGroup(res));
            let stopIds = res.stops.map((stop: any) => {
                dispatch(setRouteStopData(stop));

                return stop.id;
            });
            dispatch(setStopGroup(res));
            setShowStopIdsForGroup(stopIds)
        }).catch((err: any) => {
            wrlog(err)
        })
    }

    const loadStopDetail = (routeStopId: string) => {
        setDetailContainerLoading(true);
        RouteStopUnit.getStop(routeStopId).then((res: any) => {
            setCurrentStop(res);
            dispatch(setRouteStopData(res));
            setDetailContainerLoading(false);
        }).catch((err: any) => {
            wrlog(err);
        })
    }

    const loadAvailableContractors = () => {
        JobUnit.getAvailableContractors(params.id).then((res: any) => {
            setAvailableContractors(res);
        }).catch((err: any) => {
            wrlog(err);
        })

    }

    const selectStopGroup = (stopGroup: any) => {
        if (stopGroup.id === currentStopGroup?.id) {
            setCurrentStopGroup(null);
            setDetailContainerType(null);
            setDetailContainerVisible(false);
            setDetailContainerLoading(false);
            setShowStopIdsForGroup([]);
            setFlyToDestination('bounds');
        } else {
            wrlog("stopGroup", stopGroup)
            setCurrentStopGroup(stopGroup)
            loadRouteStops(stopGroup.package_id);
            setDetailContainerType('stopGroup');
            setDetailContainerVisible(true);
            setDetailContainerLoading(false);
        }
    }

    const selectRouteStop = (routeStop: any) => {
        setFlyToDestination([routeStop.longitude, routeStop.latitude]);
        if (currentStopGroup.visibility === 'full') {
            setCurrentStop(routeStop);
            loadStopDetail(routeStop.id);
            setDetailContainerType('routeStop');
            setDetailContainerVisible(true);
            setDetailContainerLoading(true);
        }
    }

    const addContractor = () => {

        wrlog(newContractorCode, newContractorCode.length)

        if (newContractor === 'new' && newContractorCode.length !== 6) {
            return;
        }

        addContractorButtonRef.current.changeState('loading');

        let body = {
            id: params.id,
            type: '',
            value: '',
        }

        if (newContractor !== 'new') {
            body.type = 'existing';
            body.value = newContractor;
        } else {
            body.type = 'new';
            body.value = newContractorCode;
        }

        JobUnit.addContractor(params.id, body).then((res: any) => {
            addContractorButtonRef.current.changeState('success');
            loadAvailableContractors();
            loadJobDetail();
            setNewContractor('new');
            setNewContractorCode('');
        }).catch((err: any) => {
            addContractorButtonRef.current.changeState('error');
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description
            }))
        })

    }

    const deleteContractor = (contractId: string) => {
        let contractors = []
        let updatedJob = { ...currentJob }

        currentJob.contractors.forEach(contractor => {

            if (contractor.id !== contractId) {
                contractors.push(contractor);
            }
        })
        updatedJob.contractors = contractors;

        dispatch(setJob(updatedJob));
        JobUnit.removeContractor(params.id, contractId).then((res: any) => {
            loadAvailableContractors()
        }).catch((err: any) => {

            wrlog(err);
        })
    }

    const detailLoading = () => (
        <>
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 40 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
        </>
    )


    return (
        <>
            <Sidebar>
                <Button
                    type={'secondary'}
                    label={translate('back')}
                    preventLoading
                    onClick={() => { navigate('/logistics/jobs') }}
                />
                {
                    currentJob.title === undefined ?
                        <Skeleton variant='rectangular' animation="wave" height={200} width={'100%'} style={{ marginTop: 0 }} />
                        : <>
                            <Card style={{ marginBottom: 30, position: 'relative' }}>
                                <Grid container padding={0}>
                                    <Grid item md={12} padding={0}>
                                        <div>
                                            <h4 style={{}}>{translate('job')}</h4>
                                            <Badge
                                                label={translate(`job_status_${currentJob?.status}`)}
                                                state={currentJob?.status}
                                                small
                                                style={{ position: 'absolute', top: 10, right: 10 }}
                                            />
                                            <br />
                                            <h4 style={{}}>{currentJob?.title}</h4>
                                            {currentJob.address}, {currentJob.postcode} {currentJob.city}
                                            <br />
                                            <br />
                                            {currentJob.count} {translate('stops')}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card>
                        </>
                }

                {
                    currentJob?.type === 'own' &&
                    <Card style={{ marginBottom: 30 }}>
                        <Grid container padding={0}>
                            <Grid item md={12} padding={0}>
                                <div style={{ width: '100%', position: 'relative' }}>
                                    <h4 style={{}}>{translate('contractors')}</h4>
                                    {currentJob.status === 'pending' && <Button
                                        type='tertiary'
                                        icon={<img src={AddIcon} />}
                                        label={translate('add')}
                                        onClick={() => {
                                            setAddContractorFormOpen(true);
                                        }}
                                        style={{ position: 'absolute', top: -7, right: -7 }}
                                        preventLoading={true}
                                    />}
                                    <br />
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            {
                                                currentJob.contractors !== undefined && currentJob.contractors.map(contractor => {
                                                    return <tr>
                                                        <td>
                                                            {contractor.title}
                                                        </td>
                                                        <td>
                                                            <Badge
                                                                label={translate(`job_contractor_status_${contractor.status}`)}
                                                                state={contractor.status}
                                                                small
                                                            />
                                                        </td>
                                                        <td style={{ textAlign: 'right' }}>
                                                            {currentJob.status === 'pending' && <Button
                                                                type={'tertiary'}
                                                                label={translate('delete')}
                                                                className='delete'
                                                                onClick={() => { deleteContractor(contractor.id) }}
                                                                style={{ marginRight: -5 }}
                                                            />}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>

                                    {
                                        addContractorFormOpen && currentJob.status === 'pending' && <>
                                            <hr style={{ width: '100%' }} />
                                            <FormControl variant="filled" style={{ marginBottom: 20 }}>
                                                <InputLabel>{translate('contractor')}</InputLabel>
                                                <Select
                                                    label={translate('contractor')}
                                                    onChange={e => setNewContractor(e.target.value)}
                                                    style={{ marginBottom: '20px !important' }}
                                                    defaultValue={'new'}
                                                >
                                                    <MenuItem value={'new'}>{translate('add_new_contractor')}</MenuItem>
                                                    {
                                                        availableContractors.map(contractor => <MenuItem value={contractor.id}>{contractor.title}</MenuItem>)
                                                    }

                                                </Select>
                                            </FormControl>
                                            {
                                                newContractor === 'new' && <TextField
                                                    id="standard-basic"
                                                    label={translate('enter_company_code')}
                                                    type="text"
                                                    value={newContractorCode}
                                                    onChange={e => setNewContractorCode(e.target.value)}
                                                    className={`dark ${newContractor !== 'new' ? 'disabled' : undefined}`}
                                                    inputProps={{ maxLength: 6 }}
                                                />
                                            }

                                            <Button
                                                label={translate('add')}
                                                preventLoading
                                                ref={addContractorButtonRef}
                                                type={'primary'}
                                                onClick={() => addContractor()}
                                            />
                                        </>
                                    }

                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                }
                {currentJob.type === 'own' ? <>
                    {currentJob.stops === undefined ?
                        detailLoading() : <>
                            <div className='smallListContainer fullHeight'>
                                <strong>{translate('stop_groups')}</strong>
                                {
                                    currentJob.packages.map((stopGroup: any, key: number) => {
                                        return <div
                                            key={key}
                                            className={`smallListItem cuttext expandable white ${currentStopGroup?.id === stopGroup.id ? 'open' : undefined}`}
                                        >
                                            <div className='header'
                                                onClick={() => selectStopGroup(stopGroup)}
                                            >
                                                <h4 style={{ margin: 0 }}>{stopGroup.title}</h4>
                                                {stopGroup.count} {stopGroup.count == 1 ? translate('stop') : translate('stops')}<br />
                                                <div className='expendableIcon'></div>
                                            </div>
                                            {
                                                currentStopGroup?.id === stopGroup.id && <div className='innerWrapper'>
                                                    {routeStopLoading ? detailLoading() :
                                                        routeStops.length > 0 ? routeStops.map((routeStop: any) => {
                                                            if (!showStopIdsForGroup.includes(routeStop.id)) {
                                                                return null;
                                                            }
                                                            return <div
                                                                className='smallListItem cuttext'
                                                                onClick={() => selectRouteStop(routeStop)}
                                                            >
                                                                <strong>{routeStop.address} {routeStop.streetNumber}</strong><br />
                                                                {routeStop.postcode} {routeStop.city}<br />
                                                                <div className='badge'>ASAP</div>
                                                            </div>
                                                        })
                                                            : <strong>{translate('no_route_stops_found')}</strong>
                                                    }
                                                </div>
                                            }

                                        </div>
                                    })
                                }
                            </div>
                        </>
                    }
                </>

                    : <>
                        <div className='smallListContainer fullHeight'>
                            <strong>{translate('stops')}</strong>
                            {
                                locations.map((location: any, key: number) => {
                                    return <div
                                        className='smallListItem cuttext'
                                        onClick={() => mapRef.current.flyTo([location.longitude, location.latitude])}
                                    >
                                        <strong>{location.address} {location.streetNumber}</strong><br />
                                        {location.postcode} {location.city}<br />
                                    </div>
                                })
                            }
                        </div>
                    </>}
            </Sidebar>
            <div className='mapContainer'>
                <Map
                    markers={locations}
                    highlightedRouteStopIds={[...showStopIdsForGroup]}
                    routes={[]}
                    drivers={[]}
                    ref={mapRef}
                    flyTo={flyToDestination}
                />

                <DetailContainer
                    visible={detailContainerVisible}
                    close={() => setDetailContainerVisible(false)}
                >
                    {saveState !== 'default' && <div
                        style={{ top: 20, right: 0 }}
                        className={`saveBadge ${saveState === 'saving' ? 'animate-flicker' : undefined}`}>{translate(saveState)}</div>}


                    {
                        detailContainerLoading ? detailLoading() : <>
                            {detailContainerType === 'routeStop' && <>
                                <Button
                                    type='secondary'
                                    preventLoading
                                    label={translate('back')}
                                    style={{ top: 15, left: 15, position: 'absolute' }}
                                    onClick={() => setDetailContainerType('stopGroup')}
                                />
                                <RouteStopDetail
                                    selectedRouteStopId={currentStop.id}
                                    setSaveState={setSaveState}
                                    reloadSingle={() => loadStopDetail(currentStop.id)}
                                    closeSidebar={() => setDetailContainerVisible(false)}
                                /></>
                            }
                            {/* {detailContainerType === 'stopGroup' && <StopGroupDetail
                                currentStopGroup={currentStopGroup}
                                reload={loadStopGroups}
                                setSaveState={setSaveState}
                                reloadSingle={() => loadStopDetail(currentStop.id)}
                                closeSidebar={() => setDetailContainerVisible(false)}
                            />
                            } */}
                        </>
                    }
                </DetailContainer>
            </div>
        </>
    );
}

export default JobSingle;