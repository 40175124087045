import React, { useEffect, useState } from 'react'
import wrlog from '../../Helper/functions/wrlog'
import styles from './styles.module.scss';

import ErrorIcon from '../../Assets/messageIcons/error.svg'
import SuccessIcon from '../../Assets/messageIcons/success.svg'

interface MessageProps {
    type: string;
    title: string;
    body: string;
    created: number;
}


const Message = (props: MessageProps) => {
    const start = 0;
    const end = 10000;
    const step = 10;

    const [remainingSeconds, setRemainingSeconds] = useState(start);
    const [show, setShow] = useState(true);
    const { type, title, body } = props;

    useEffect(() => {

        if (remainingSeconds > end) {
            setShow(false);
        } else {
            setTimeout(() => {
                setRemainingSeconds(prev => prev + step);
            }, step)
        }

    }, [remainingSeconds])

    if (!show) {
        return null;
    }

    return (
        <div className={`${styles.wrapper} ${styles[type]}`}>
            <div className={styles.counter} style={{ width: `${(remainingSeconds / end) * 100}%` }}></div>
            <div className={`${styles.container}`}>

                <div className={styles.leftContainer}>
                    {type === 'error' && <img src={ErrorIcon} />}
                    {type === 'success' && <img src={SuccessIcon} />}
                </div>
                <div className={styles.rightContainer}>
                    <h4 className={styles.title}>{title}</h4>
                    <div className={styles.body}>{body}</div>
                </div>
            </div>
        </div>
    )
}

export default Message;