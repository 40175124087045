import { FormControl, Input, InputAdornment, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../Helper/multilingual';
import { Link, useParams } from 'react-router-dom';
import BackgroundImage from '../../Assets/LogoistikApp_Cover.jpg';
import Logo from '../../Assets/Logo.png';
import EmailIcon from '../../Assets/formIcons/email.svg';
import PasswordIcon from '../../Assets/formIcons/password.svg';

import styles from './styles.module.scss';

import { Grid } from '@mui/material';
import Button from '../../Components/Button';
import UserUnit from '../../Services/Http/User';
import wrlog from '../../Helper/functions/wrlog';
import Popup from '../../Components/Popup';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../Services/Redux/features/messages/messageSlice';
import Storage from '../../Services/Storage';
import InvitationForm from './InvitationForm';
import { getUserToken } from '../../Helper/functions/auth';

import Video from '../../Assets/videos/backgroundVideoPronto.mp4';

interface LandingProps {
    isForgotPassword?: any;
    isResetPassword?: any;
    isInvitation?: any;
}

/**
 * 
 * @returns Login Page
 */
const Landing = (props: LandingProps) => {

    const LoginButtonRef = useRef();

    const { isForgotPassword, isResetPassword, isInvitation } = props;

    const [forgotPasswordPopupOpen, setForgotPasswordPopupOpen] = useState(isForgotPassword || false);
    const [resetPasswordPopupOpen, setResetPasswordPopupOpen] = useState(isResetPassword || false);
    const [invitationPopupOpen, setInvitationPopupOpen] = useState(isInvitation || false);
    const [isLoggedIn, setIsLogegdIn] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const dispatch = useDispatch();

    const params = useParams();

    useEffect(() => {
        setIsLogegdIn(getUserToken());
    }, []);

    useEffect(() => {
        if (isResetPassword && params['key'] !== undefined && params.secret !== undefined) {
            setResetPasswordPopupOpen(true);
        }
    }, [isResetPassword])

    useEffect(() => {
        if (isInvitation && params.invitationId !== undefined) {
            setInvitationPopupOpen(true);
        }
    }, [isInvitation])

    /**
     * Logs in the User
     * @param {object} data containig email and password as props
     * 
     * @returns void
     */
    const doLogin = (data: any) => {
        let buttonRef = LoginButtonRef.current || null;
        if (buttonRef !== null) {
            buttonRef.changeState('loading');
        }

        UserUnit.login(data.email, data.password).then(res => {
            buttonRef.changeState('success');

            // set token
            localStorage.setItem('token', res.token);

            // set default/current company (first in array) --> if not set
            let currentCompany = Storage.getValue('currentCompany');
            if (currentCompany !== null) {
                let included = false;
                res.companies.forEach((company: any) => {
                    if (company.id === currentCompany) {
                        included = true;
                    }
                });
                if (!included) {
                    currentCompany = null;
                }
            }

            if (currentCompany === null) {
                Storage.setValue('currentCompany', res.companies[0].id);
            }

            window.location.reload();
        }).catch(err => {
            buttonRef.changeState('error');

            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description
            }))
        })

    }

    return (
        <Grid container spacing={0}>
            {!isLoggedIn && <Grid item md={3} padding={4}>
                <div className={styles.container}>
                    <img src={Logo} className={styles.logo} />
                    <form onSubmit={handleSubmit(doLogin)}>
                        <TextField
                            {...register('email', { required: true })}
                            error={errors.email}
                            id="standard-basic"
                            label={<><img src={EmailIcon} /><div>{translate('email')}</div></>}
                            type="email"
                            className='hasIcon'
                        />
                        <TextField
                            {...register('password', { required: true })}
                            error={errors.password}
                            id="standard-basic"
                            label={<><img src={PasswordIcon} /><div>{translate('password')}</div></>}
                            type="password"
                            className='hasIcon'
                        />
                        <Button
                            type='primary'
                            isInput
                            label={translate('signin')}
                            ref={LoginButtonRef}
                        />
                    </form>
                    <hr />
                    <Button
                        type='tertiary'
                        label={translate('forgot_password')}
                        onClick={() => setForgotPasswordPopupOpen(true)}
                    />
                </div>
            </Grid>
            }
            <Grid item md={isLoggedIn ? 12 : 9} style={{ height: '100vh' }}>
                {/* <img src={BackgroundImage} style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                <video autoPlay muted style={{ width: '100%', height: '100%', objectFit: 'cover' }} >
                    <source
                        src={Video}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            </Grid>

            <Popup
                open={forgotPasswordPopupOpen}
                close={() => setForgotPasswordPopupOpen(false)}
                style={{ maxWidth: 400 }}
            >
                <ForgotPasswordForm
                    closePopup={() => setForgotPasswordPopupOpen(false)}
                />
            </Popup>

            <Popup
                open={resetPasswordPopupOpen}
                close={() => setResetPasswordPopupOpen(false)}
                style={{ maxWidth: 400 }}
            >
                <ResetPasswordForm
                    resetKey={params['key'] || null}
                    secret={params.secret || null}
                    closePopup={() => setResetPasswordPopupOpen(false)}
                />
            </Popup>

            <Popup
                open={invitationPopupOpen}
                close={() => setInvitationPopupOpen(false)}
                style={{ maxWidth: 400 }}
            >
                <InvitationForm
                    invitationId={params['invitationId'] || null}
                    closePopup={() => setInvitationPopupOpen(false)}
                />
            </Popup>

        </Grid>);
}

export default Landing;