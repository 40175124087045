import wrlog from "../../Helper/functions/wrlog";

export default class Storage {
    key: string;

    constructor(key: string) {
        this.key = key;
    }

    store = (value: any) => {
        if (typeof value !== 'string') {
            value = JSON.stringify(value);
        }

        localStorage.setItem(this.key, value);
    }

    get = () => {
        let storage = localStorage.getItem(this.key);
        if (storage === null) {
            return null;
        }

        try {
            return JSON.parse(storage);
        } catch (err) {
            return storage;
        }

    }

    static getValue = (storageKey: string, itemKey?: string) => {
        let storage = localStorage.getItem(storageKey);
        if (storage === null) {
            return null;
        }

        try {
            storage = JSON.parse(storage);
        } catch (err) {
            return storage;
        }

        if (itemKey !== undefined) {
            if (storage[itemKey] === undefined) {
                return null;
            }
            return storage[itemKey];
        }
        return storage;
    }

    static setValue = (storageKey: string, value: any) => {
        if (typeof value !== 'string') {
            value = JSON.stringify(value);
        }

        localStorage.setItem(storageKey, value);
    }

    static removeValue = (storageKey: string) => {
        localStorage.removeItem(storageKey);
    }

}

