// import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import './App.scss';
import { Routes, Route, Link, BrowserRouter as Router } from 'react-router-dom';
import Landing from './Pages/Landing';
import { changeLanguageHandler } from './Helper/multilingual';
import { isLoggedIn } from './Helper/functions/auth';
import wrlog from './Helper/functions/wrlog';
import Messages from './Components/Messages';
import Dashboard from './Pages/Dashboard';
import HeaderBar from './Components/HeaderBar';
import Logo from './Assets/Logo.png';
import Storage from './Services/Storage';
import GeneralSettings from './Pages/Settings/GeneralSettings';
import Users from './Pages/Settings/Users';
import initialLoadData from './Helper/functions/initialLoadData';
import NotificationSettings from './Pages/Settings/NotificationSettings';
import RouteSettings from './Pages/Settings/RouteSettings';
import WorkflowSettings from './Pages/Settings/RouteSettings/Workflows';
import StopGroup from './Pages/Logistics/StopGroups';
import Jobs from './Pages/Logistics/Jobs';
import JobSingle from './Pages/Logistics/Jobs/single';
import RoutesPage from './Pages/Logistics/Routes';
import { useDispatch } from 'react-redux';
import { db } from './Services/Database';
import Try from './Pages/Logistics/Try';
import ApiClientSettings from './Pages/Settings/Api';
import ImportsSettingsList from './Pages/Settings/Imports';
import Warehouses from './Pages/Settings/Warehouses';
// import WsTest from './Pages/Testing/ws';
import 'react-tooltip/dist/react-tooltip.css'
import Clustering from './Pages/Settings/Imports/Clustering';

const App = () => {

    const [loggedIn, setLoggedIn] = useState(null) as any;
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    changeLanguageHandler();

    useEffect(() => {

        isLoggedIn()
            .then((res) => {
                let storage = new Storage('user');
                storage.store(res);
                initialLoadData(dispatch);
                setLoggedIn(true);
            })
            .catch(err => {
                wrlog("NOT LOGGED IN", err)
                setLoggedIn(false);
                Storage.removeValue('token');
                Storage.removeValue('jobTypeShow');
                Storage.removeValue('currentCompany');
                Storage.removeValue('roles');
            })
            .finally(() => {
                setLoading(false);
            })


        window.onbeforeunload = () => {
            // db.delete();
        }

    }, [])

    if (loading) {
        return <div className='loadingWrapper'>
            <div className='loadingContainer'>
                <img src={Logo} />
                <div className={'loader-ellipsis'}><div></div><div></div><div></div><div></div></div>
            </div>
            <div>Building your dashboard...</div>
        </div>
    }

    if (loggedIn === false) {
        return (
            <Router>
                <Messages />
                <Routes>
                    <Route path="/invite/:invitationId" element={<Landing isInvitation={true} />} />
                    <Route path="/resetpassword/:key/:secret" element={<Landing isResetPassword={true} />} />
                    <Route path="*" element={<Landing />} />
                </Routes>
            </Router>
        )
    } else if (loggedIn === true) {
        return (
            <Router>
                <Messages />
                <HeaderBar />
                <Routes>
                    <Route index element={<Dashboard />} />
                    <Route path="/invite/:invitationId" element={<Landing isInvitation={true} />} />
                    <Route path="/settings/" element={<GeneralSettings />} />
                    <Route path="/settings/profile" element={<GeneralSettings />} />
                    <Route path="/settings/password" element={<GeneralSettings />} />
                    <Route path="/settings/users" element={<Users />} />
                    <Route path="/settings/users/:userId" element={<Users />} />
                    <Route path="/settings/api" element={<ApiClientSettings />} />
                    <Route path="/settings/logistics/notifications" element={<NotificationSettings />} />
                    <Route path="/settings/logistics/route" element={<RouteSettings />} />
                    <Route path="/settings/logistics/stopgroups" element={<WorkflowSettings />} />
                    <Route path="/settings/logistics/stopgroups/:stopGroupId" element={<WorkflowSettings />} />
                    <Route path="/settings/logistics/imports" element={<ImportsSettingsList />} />
                    <Route path="/settings/logistics/imports/clustering/:importId" element={<Clustering />} />

                    <Route path="/settings/warehouses" element={<Warehouses />} />
                    <Route path="/settings/warehouses/:id" element={<Warehouses />} />


                    <Route path="/logistics/stopgroups" element={<StopGroup />} />
                    <Route path="/logistics/jobs" element={<Jobs />} />
                    <Route path="/logistics/jobs/:id" element={<JobSingle />} />
                    <Route path="/logistics/routes" element={<RoutesPage />} />
                    <Route path="/tests/routing" element={<Try />} />


                </Routes>
            </Router>
        );
    }


};

export default App;