import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import CompanyIcon from '../../../Assets/navigationIcons/company_settings.svg';
import ProfileIcon from '../../../Assets/navigationIcons/profile.svg';
import PasswordIcon from '../../../Assets/navigationIcons/password.svg';

import styles from './styles.module.scss';

import UserUnit from '../../../Services/Http/User';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import CompanyUnit from '../../../Services/Http/Company';
import AddUserForm from './AddUserForm';
import FilterForm from './FilterForm';
import ListItem from '../../../Components/ListItem';
import { Grid } from '@mui/material';


/**
 * 
 * @returns Login Page
 */
const SettingsSidebar = () => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='middleContainer'>
            <Grid container spacing={0}>
                <Grid item md={12} padding={4}>
                    <h3>{translate('general_settings')}</h3>
                    <ListItem
                        title={translate('company_details')}
                        detail=""
                        badges={[]}
                        selected={location.pathname === '/settings'}
                        onClick={() => navigate('/settings')}
                        loading={false}
                        icon={<img src={CompanyIcon} />}
                    />
                    <ListItem
                        title={translate('profile')}
                        detail=""
                        badges={[]}
                        selected={location.pathname === '/settings/profile'}
                        onClick={() => navigate('/settings/profile')}
                        loading={false}
                        icon={<img src={ProfileIcon} />}
                    />
                    <ListItem
                        title={translate('change_password')}
                        detail=""
                        badges={[]}
                        selected={location.pathname === '/settings/password'}
                        onClick={() => navigate('/settings/password')}
                        loading={false}
                        icon={<img src={PasswordIcon} />}
                    />

                </Grid>
            </Grid>
        </div>
    );
}


export default SettingsSidebar;