import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../../Assets/Logo.png';

import { Card, Checkbox, Grid, Skeleton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../Sidebar';
import Map from '../../../Components/Map';
import Button from '../../../Components/Button';
import CsvImporter from '../../../Components/CsvImporter';
import RouteStopUnit from '../../../Services/Http/RouteStop';
import wrlog from '../../../Helper/functions/wrlog';
import AddressAutocomplete from '../../../Components/AddressAutocomplete';
import DetailContainer from '../../../Components/DetailContainer';
import RouteStopDetail from '../RouteStopDetail';
import StopGroupUnit from '../../../Services/Http/StopGroup';
import JobUnit from '../../../Services/Http/Job';

import AddIcon from '../../../Assets/add.svg';
import Popup from '../../../Components/Popup';
import { addMessage } from '../../../Services/Redux/features/messages/messageSlice';
import StopGroupDetail from './JobDetail';
import { setRouteStopData } from '../../../Services/Redux/features/routeStop/routeStopSlice';
import { setStopGroup } from '../../../Services/Redux/features/stopGroups/stopGroupsSlice';
import { initJobs, removeJob, setJob } from '../../../Services/Redux/features/job/jobSlice';
import { Box } from '@mui/system';
import Badge from '../../../Components/Badge';
import Storage from '../../../Services/Storage';

interface StopGroupProps {
}

/**
 * 
 * @returns Login Page
 */
const Jobs = (props: StopGroupProps) => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const addJobButtonRef = useRef();
    const acceptJobButtonRef = useRef([]);
    const rejectJobButtonRef = useRef([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [routes, setRoutes] = useState([]);
    const [stopGroups, setStopGroups] = useState([]);
    const [flyToDestination, setFlyToDestination] = useState(null);
    const [routeStopLoading, setRouteStopLoading] = useState(false);
    const [detailContainerType, setDetailContainerType] = useState(null);
    const [saveState, setSaveState] = useState('default');
    const [addJobPopupOpen, setAddJobPopupOpen] = useState(false);
    const [stopGroupsSelected, setStopGroupsSelected] = useState([]);
    const [routesSelected, setRoutesSelected] = useState([]);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [jobTypeShow, setJobTypeShow] = useState('');


    const jobs = useSelector(state => state.job.job);

    useEffect(() => {
        let typeShow = Storage.getValue('jobTypeShow');
        if(typeShow === null){
            setJobTypeShow('new')
        }else{
            setJobTypeShow(typeShow)
        }

        loadJobs();
    }, [])
    const changeJobTypeShow = typeShow => {
        Storage.setValue('jobTypeShow', typeShow)
        setJobTypeShow(typeShow)
    }

    const loadJobs = () => {
        JobUnit.listJobs().then((res: any) => {
            dispatch(initJobs([]));
            res.own.forEach((job: any) => {
                job.type = 'own';
                dispatch(setJob(job));
            });
            res.contractorJobs.forEach((job: any) => {
                if (job.status === 'pending') {
                    job.type = 'new';
                } else if (job.status === 'active') {
                    job.type = 'active';
                } else if (job.status === 'done') {
                    job.type = 'done';
                }
                dispatch(setJob(job));
            });
        }).catch((err: any) => {
            wrlog(err)
        })
    }



    const loadGroups = () => {
        JobUnit.listAvailableGroups().then((res: any) => {
            setStopGroups(res);
        }).catch((err: any) => {
            wrlog(err)
        })
    }

    const loadRoutes = () => {
        JobUnit.listAvailableRoutes().then((res: any) => {
            setRoutes(res);
        }).catch((err: any) => {
            wrlog(err)
        })
    }


    const createJob = () => {
        addJobButtonRef.current.changeState('loading');

        const body = {
            routes: routesSelected,
            stopGroups: stopGroupsSelected,
            start: start,
            end: end,
        }

        JobUnit.createJob(body).then(res => {
            // loadJobs();
            addJobButtonRef.current.changeState('success');
            setTimeout(() => {
                setAddJobPopupOpen(false);
            }, 500)
        }).catch((err: any) => {
            addJobButtonRef.current.changeState('error');
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
        })
    }

    const acceptJob = (jobId: any) => {
        let buttonRef = acceptJobButtonRef.current[jobId]
        buttonRef.changeState('loading');

        JobUnit.acceptJob(jobId).then(res => {
            buttonRef.changeState('success');
        }).catch((err: any) => {
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
            buttonRef.changeState('error');
        })
    }

    const rejectJob = (jobId: any) => {
        let buttonRef = rejectJobButtonRef.current[jobId]
        buttonRef.changeState('loading');

        JobUnit.rejectJob(jobId).then(res => {
            setTimeout(() => {
                dispatch(removeJob(jobId))
            }, 2100)
            buttonRef.changeState('success');
        }).catch((err: any) => {
            buttonRef.changeState('error');
            dispatch(addMessage({
                type: 'error',
                title: err.title,
                body: err.description,
            }))
        })
    }

    const detailLoading = () => (
        <>
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={20} width={'100%'} style={{ marginTop: 20 }} />
            <Skeleton variant='rectangular' animation="wave" height={'calc(100% - 200px)'} width={'100%'} style={{ marginTop: 20 }} />
        </>
    )

    const selectStopGroup = (stopGroupId: string, checked: boolean) => {
        let _selectedStopGroups = [...stopGroupsSelected];

        if (checked) {
            _selectedStopGroups.push(stopGroupId)
        } else {
            let index = _selectedStopGroups.findIndex((e) => e === stopGroupId);
            _selectedStopGroups.splice(index, 1);
        }

        setStopGroupsSelected(_selectedStopGroups)
    }

    const jobRow = (job) => {
        return <Card style={{ width: '100%', marginBottom: 20 }}
            className='clickable noBorder'
            onClick={(e) => {
                if (e.target.closest('.button') !== null) {
                    return;
                }

                navigate(`/logistics/jobs/${job.id}`);
            }}>
            <Grid container
            >
                <Grid item md={4}>
                    <div>
                        {job.type === 'own' && job.status === 'pending' ? <h4 style={{ margin: 0, opacity: 0.4 }}>{translate('no_contractor')}</h4> : <>
                            <h4 style={{ margin: 0 }}>{job.title}</h4>
                            {job.address}, {job.postcode} {job.city}</>}

                    </div>
                </Grid>
                <Grid item md={3} style={{ textAlign: 'center' }}>
                    <div><strong>{job.start === null && job.end === null ? translate('not_set') : ''}</strong><br />{translate('job_timeslot')}</div>
                </Grid>
                <Grid item md={2} style={{ textAlign: 'center' }}>
                    <div><strong>{job.count}</strong><br />{job.count == 1 ? translate('stop') : translate('stops')}</div>
                </Grid>
                <Grid item md={3} style={{ justifyContent: 'flex-end' }}>
                    {
                        job.type !== 'own' && job.status === 'pending' ?
                            <>
                                <Button
                                    style={{}}
                                    type={'tertiary'}
                                    label={translate('decline')}
                                    className={'delete large'}
                                    onClick={() => rejectJob(job.id)}
                                    ref={el => rejectJobButtonRef.current[job.id] = el}
                                />
                                <Button
                                    style={{}}
                                    type={'secondary'}
                                    className='large green'
                                    label={translate('accept')}
                                    preventLoading
                                    onClick={() => acceptJob(job.id)}
                                    ref={el => acceptJobButtonRef.current[job.id] = el}
                                />
                            </>
                            :
                            <>
                                <Badge
                                    label={translate(`job_status_${job.status}`)}
                                    state={job.status}
                                />
                            </>
                    }
                </Grid>
            </Grid>
        </Card>
    }


    return (
        <>
            <Box maxWidth={'1400px'} style={{ margin: '0px auto' }}>
                <Grid container style={{ marginTop: 100 }}>
                    <Grid item md={6} padding={0}>
                        <h4 style={{ marginBottom: 0, marginLeft: 5 }}>{translate('my_jobs')}</h4>
                    </Grid>
                    <Grid item md={6} padding={0} className='align_right'>
                        <Button
                            type='tertiary'
                            icon={<img src={AddIcon} />}
                            label={translate('add')}
                            onClick={() => {
                                loadGroups();
                                loadRoutes();
                                setAddJobPopupOpen(true);
                            }}
                            preventLoading={true}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: 30 }}>
                    <Grid item md={12} padding={0}>
                        <Button
                            type='tertiary'
                            label={`${translate('new_jobs')} (${jobs.filter((job: any) => job.type === 'new').length})`}
                            onClick={() => changeJobTypeShow('new')}
                            className={jobTypeShow === 'new' ? 'hovered' : undefined}
                            style={{ marginLeft: -5 }}
                            preventLoading={true}
                        />
                        <Button
                            type='tertiary'
                            label={`${translate('current_jobs')} (${jobs.filter((job: any) => job.type === 'active').length})`}
                            onClick={() => changeJobTypeShow('active')}
                            className={jobTypeShow === 'active' ? 'hovered' : undefined}
                            preventLoading={true}
                        />
                        <Button
                            type='tertiary'
                            label={`${translate('done_jobs')} (${jobs.filter((job: any) => job.type === 'done').length})`}
                            onClick={() => changeJobTypeShow('done')}
                            className={jobTypeShow === 'done' ? 'hovered' : undefined}
                            preventLoading={true}
                        />
                        <Button
                            type='tertiary'
                            label={`${translate('created_jobs')} (${jobs.filter((job: any) => job.type === 'own').length})`}
                            onClick={() => changeJobTypeShow('own')}
                            className={jobTypeShow === 'own' ? 'hovered' : undefined}
                            preventLoading={true}
                        />

                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: 30 }}>
                    {
                        jobs.filter((job) => job.type === jobTypeShow).sort((a, b) => a.created > b.created).map((job: any, key: number) => {
                            return jobRow(job)
                        })
                    }
                </Grid>
            </Box>
            <Popup
                close={() => setAddJobPopupOpen(false)}
                open={addJobPopupOpen}
                style={{ maxWidth: 700 }}
            >
                <h4 style={{ marginTop: 0 }}>{translate('stop_groups')}</h4>
                <table>
                    <tbody>
                        {
                            stopGroups.map(stopGroup => {
                                return <tr>
                                    <td>
                                        <Checkbox
                                            onChange={e => selectStopGroup(stopGroup.id, e.target.checked)}
                                        />
                                    </td>
                                    <td>{stopGroup.title}</td>
                                    <td>{stopGroup.count}</td>
                                    <td></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <Button
                    type='primary'
                    onClick={() => createJob()}
                    label={translate('save')}
                    ref={addJobButtonRef}
                />
            </Popup>
        </>
    );
}

export default Jobs;