import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { FileUploader } from "react-drag-drop-files";
import Papa from 'papaparse';
import * as XLSX from 'xlsx/xlsx.mjs';

import { translate } from '../../Helper/multilingual';

interface Props {
    setPage: any;
    setJson: any;
    closePopup: any;
}

const FileUpload = (props: Props) => {

    const { setJson, setPage, closePopup } = props;

    const handleChange = (_file: any) => {
        const handleSuccess = (data) => {
            setJson(data);
            setPage(2);
        }

        if (_file.type.includes('csv')) {
            Papa.parse(_file, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    handleSuccess(results.data)
                },
            });
        } else {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                let json = XLSX.utils.sheet_to_json(worksheet, { header: "A" });

                let keys: any = {};
                Object.keys(json[0]).map(k => keys[json[0][k]] = k);

                let jsonOut = [];

                json.map((r, k) => {
                    if (k < 1) {
                        return;
                    }
                    // get keys

                    let out: any = {};

                    Object.keys(keys).map(key => {
                        let orKey = keys[key];
                        let val = '';

                        if (r[orKey] !== undefined) {
                            val = r[orKey]
                        }

                        out[key] = val;
                    })

                    jsonOut.push(out);
                })

                handleSuccess(jsonOut);
            };
            reader.readAsArrayBuffer(_file);
        }

        // setPage(2)
    };

    return <>
        <FileUploader
            handleChange={handleChange}
            name="file"
            label={translate('drag_drop_here')}
            hoverTitle={translate('drop_here')}
            types={['CSV', 'XLSX']}
            classes={styles.dragDropContainer}
        />
    </>

}

export default FileUpload;