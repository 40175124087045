import Unit from './index';

export default class RoleUnit extends Unit {

    static listRoles = async () => {
        return await Unit.get('/role');
    }

}



