import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserToken } from "../../Helper/functions/auth";
import wrlog from "../../Helper/functions/wrlog";
import doUpdateData from "./doUpdateData";

const { io } = require("socket.io-client");

const URL = process.env.SOCKET_URL || "http://localhost:3040";

const WebSocketProvider = ({ children }) => {


    return children;

    // const dispatch = useDispatch();
    // const [connected, setConnected] = useState(false);

    // useEffect(() => {
    //     createClient();
    // }, [])
    // const createClient = () => {
    //     const socket = io(URL);

    //     wrlog("WS: connected");
    //     setConnected(true);

    //     socket.emit("init", { token: getUserToken() });

    //     socket.on('apiEvent', (res) => {
    //         sessionStorage.setItem('currentSession', socket.id)
    //         console.log("WS: Incomming", res);
    //         if (res.action === 'requestNewToken') {
    //             socket.emit("refreshToken", { token: getUserToken() });
    //         } else if (res.action === 'checkConnection') {
    //             socket.emit("confirmConnection", { connected: true });
    //         } else if (res.action === 'updateData') {
    //             console.log("WS: DATA", res.payload);
    //             doUpdateData(dispatch, res.payload.dataType, res.payload.dataId, res.payload.data)
    //         }
    //         console.log(res);
    //     })

    //     socket.on("disconnect", (reason) => {
    //         setConnected(false);
    //         wrlog("WS: disconnected", reason);
    //         if (!connected) {
    //             createClient();
    //         }
    //     });

    // };

    // return children;

}

export default WebSocketProvider;