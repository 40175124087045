import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../../Helper/multilingual';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../../Assets/Logo.png';

import styles from './styles.module.scss';

import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import Sidebar from '../Sidebar';
import UsersList from './UsersList';
import UserDetail from './UserDetail';

interface UsersProps {
}

/**
 * 
 * @returns Login Page
 */
const Users = (props: UsersProps) => {

    const LoginButtonRef = useRef();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [currentUser, setCurrentUser] = useState(null);
    const [reload, setReload] = useState(null);

    const dispatch = useDispatch();

    const params = useParams();

    return (
        <>
            <Sidebar />
            <div className='middleContainer'>
                <Grid container spacing={0}>
                    <Grid item md={12} padding={4}>
                        <h3>{translate('users')}</h3>
                        <UsersList
                            currentUser={currentUser}
                            selectUser={setCurrentUser}
                            reload={reload}
                            setReload={setReload}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className='rightContainer'>
                <Grid container spacing={0}>
                    <Grid item md={12} padding={4}>
                        <UserDetail
                            currentUser={currentUser}
                            reload={reload}
                            setReload={setReload}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default Users;