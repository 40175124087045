import { useEffect, useState } from "react"
import { translate } from "../../Helper/multilingual";
import Storage from "../../Services/Storage"
import styles from './styles.module.scss';


const CompaniesSelect = () => {

    const [currentCompany, setCurrentCompany] = useState(null);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        setCurrentCompany(Storage.getValue('currentCompany'));
        setCompanies(Storage.getValue('user', 'companies'));
    }, [])

    const changeCompany = (companyId: string) => {
        Storage.setValue('currentCompany', companyId);
        window.location.reload();
    }

    return (
        <div className={styles.companyList}>
            {
                companies.map((company: any, key: any) => {

                    return <div
                        key={key}
                        onClick={() => changeCompany(company.id)}
                        className={`${styles.container} ${currentCompany === company.id ? styles.current : undefined}`}
                    >
                        <div className={styles.leftContainer}>
                            <div className={styles.icon}>{company.title.substring(0, 2).toUpperCase()}</div>
                        </div>
                        <div className={styles.rightContainer}>
                            <div className={styles.title}>{company.title}</div>
                            <div className={styles.positions}>{company.roles.map((role: any) => translate(role.title)).join(', ')}</div>
                        </div>
                    </div>
                })
            }
        </div>
    )
}

export default CompaniesSelect