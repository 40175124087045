import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { translate } from '../../Helper/multilingual';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../Assets/Logo.png';

import styles from '../../Pages/Logistics/styles.module.scss';

import { Autocomplete, Card, Grid, InputAdornment, Skeleton, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Map from '../../Components/Map';
import Button from '../../Components/Button';
import CsvImporter from '../../Components/CsvImporter';
import RouteStopUnit from '../../Services/Http/RouteStop';
import wrlog from '../../Helper/functions/wrlog';
import AddressAutocomplete from '../../Components/AddressAutocomplete/index';
import DetailContainer from '../../Components/DetailContainer';
import { db } from '../../Services/Database';
import ParcelUnit from '../../Services/Http/Parcel';
import { setRouteStopData } from '../../Services/Redux/features/routeStop/routeStopSlice';
import CustomSwitch from '../Form/Switch';
import { possibleReasonOfFailure, possibleReasonOfFailureActions } from '../../Helper/Data/reasonOfFailure';
import { possibleProofOfDeliveryOptionsDropoffPermit, possibleProofOfDeliveryOptionsPersonally } from '../../Helper/Data/proofOfDelivery';
import StatusSelect from '../Form/StatusSelect';
import getStatusColor from '../../Helper/functions/getStatusColor';
import { setRoutesData } from '../../Services/Redux/features/routes/routesSlice';
import { cloneArray } from '../../Helper/functions/cluster';
import ParcelProof from './Proofs';

interface Props {
    setSaveState: any;
    reloadSingle: any;
    closeSidebar: any;
    currentStopId: any;
    currentParcelId: any;
    setCurrentParcelId: any;
    showStatus?: boolean;
}

/**
 * 
 * @returns Login Page
 */
const ParcelDetail = (props: Props) => {

    const { setSaveState, currentStopId, currentParcelId, setCurrentParcelId, reloadSingle, closeSidebar, showStatus } = props;

    const [parcelData, setParcelData] = useState({} as any);
    const [parcelDataCheck, setParcelDataCheck] = useState({} as any);
    const [currentStop, setCurrentStop] = useState({} as any);
    const [askDelete, setAskDelete] = useState(false);


    const routeStop = useSelector(state => state.routeStop);
    const routes = useSelector(state => state.routes);

    const buttonRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        setCurrentStop({ ...routeStop })
        routeStop.parcels.forEach((parcel: any) => {
            if (parcel.id === currentParcelId) {

                let pData = { ...parcel };
                let proofOfDeliveryOptions = { ...pData.proofOfDeliveryOptions };
                if (proofOfDeliveryOptions['personally'] === undefined) {
                    proofOfDeliveryOptions['personally'] = [];
                }
                if (proofOfDeliveryOptions['dropoff'] === undefined) {
                    proofOfDeliveryOptions['dropoff'] = [];
                }
                if (proofOfDeliveryOptions['dropoffPermit'] === undefined) {
                    proofOfDeliveryOptions['dropoffPermit'] = false;
                }

                pData.proofOfDeliveryOptions = proofOfDeliveryOptions;
                console.log(pData)
                setParcelData({ ...pData });
                setParcelDataCheck({ ...pData });
            }
        });

    }, [routeStop, currentParcelId])

    const deleteParcel = async () => {
        await ParcelUnit.update({ id: currentParcelId, status: 'deleted' });
        setAskDelete(false);
        buttonRef?.current?.changeState('success')
        setCurrentParcelId(null);
        reloadSingle();
    }


    const formChanged = (value: string, key: string) => {
        const body = {
            id: parcelData.id,
            stopId: currentStopId
        }

        if (value == parcelDataCheck[key] || (value === '' && parcelDataCheck[key] === null)) {
            return;
        }

        setSaveState('saving');

        body[key] = value;

        setParcelDataCheck({ ...parcelData })

        ParcelUnit.update(body)
            .then(() => {
                let _currentStop = { ...currentStop };
                let _parcels = [...currentStop.parcels];

                let index = _currentStop.parcels.findIndex(p => p.id === currentParcelId);
                let _parcel = { ..._currentStop.parcels[index] };
                _parcel[key] = value;
                _parcels[index] = _parcel;
                _currentStop.parcels = _parcels;

                dispatch(setRouteStopData(_currentStop));

                setTimeout(() => {
                    setSaveState('default');
                }, 2000)
            }).catch(err => {

            })
    }

    const formChangedValue = (value: string, key: string) => {
        let _parcelData = { ...parcelData };
        _parcelData[key] = value;
        setParcelData(_parcelData)
    }

    if (parcelData.id === undefined) {
        return <></>
    }

    return (
        <>
            <div className={styles.header} style={{ marginTop: 5 }}>
                <Button
                    type="tertiary"
                    preventLoading
                    className=''
                    label={translate('back')}
                    onClick={() => setCurrentParcelId(null)}
                    style={{ margin: 0, float: 'left', marginBottom: 15, marginTop: 15 }}
                />
                {parcelData?.routeStopStatus === 'created'
                    && <>
                        <Button
                            type="tertiary"
                            ref={buttonRef}
                            // preventLoading={!askDelete}
                            className='delete'
                            label={askDelete ? translate('deleteConfirm') : translate('delete')}
                            onClick={() => {
                                if (!askDelete) {
                                    setAskDelete(true)
                                } else {
                                    buttonRef?.current?.changeState('loading')
                                    deleteParcel();
                                }
                            }}
                            style={{ margin: 0, marginBottom: 15, marginTop: 15 }}
                        />
                    </>
                }
                {showStatus === false
                    ? null
                    : <StatusSelect
                        value={parcelData?.routeStopStatus}
                        onChange={(status: string) => {
                            // formChanged(status, 'status');
                            // formChangedValue(status, 'status');
                            formChanged(status, 'routeStopStatus');
                            formChangedValue(status, 'routeStopStatus');
                        }}
                        color={getStatusColor(parcelData?.routeStopStatus)}
                        style={{ position: 'absolute', top: 20, right: 0 }}
                        options={[
                            {
                                value: 'created',
                                label: translate('delivery_status_todo'),
                            },
                            {
                                value: 'delivered',
                                label: translate('delivery_status_success'),
                            },
                            {
                                value: 'failed',
                                label: translate('delivery_status_failed'),
                            },
                        ]}
                    />
                }
                <hr style={{ width: '100%', marginTop: 10, marginBottom: 20 }} />
            </div>
            <Grid container style={{ marginTop: 10 }}>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <strong>{translate('product_information')}</strong>
                        </Grid>
                    </Grid>
                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'title')}
                        onChange={e => formChangedValue(e.target.value, 'title')}
                        label={translate('title')}
                        type="text"
                        className='dark'
                        value={parcelData.title}
                        disabled={parcelData.routeStopStatus !== 'created'}
                    />
                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'sku')}
                        onChange={e => formChangedValue(e.target.value, 'sku')}
                        label={translate('productSku')}
                        type="text"
                        className='dark'
                        value={parcelData.sku}
                        disabled={parcelData.routeStopStatus !== 'created'}
                    />
                    {
                        parcelData?.routeStopStatus === 'created'
                            ? <>

                                <Grid container style={{ marginTop: 20 }}>
                                    <Grid item xs={12}>
                                        <strong>{translate('proof_of_deliveries')}</strong>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: 20 }}>
                                    <Grid item xs={12}>
                                        <strong>{translate('personally')}</strong>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginBottom: 40 }}>
                                    <Grid item xs={12}>
                                        <div style={{
                                            width: '100%',
                                            overflow: 'scroll',
                                        }}>
                                            {possibleProofOfDeliveryOptionsPersonally.map((option, key) => {
                                                return <Button
                                                    key={key}
                                                    type='secondary'
                                                    preventLoading
                                                    label={translate(option)}
                                                    selected={parcelData.proofOfDeliveryOptions.personally.includes(option)}
                                                    onClick={() => {
                                                        let proofOfDeliveryOptions = { ...parcelData.proofOfDeliveryOptions };
                                                        let proofOfDeliveryOptionsPersonally = [...parcelData.proofOfDeliveryOptions.personally];
                                                        if (typeof proofOfDeliveryOptionsPersonally !== 'object') {
                                                            proofOfDeliveryOptionsPersonally = [];
                                                        }
                                                        if (proofOfDeliveryOptionsPersonally.includes(option)) {
                                                            let index = proofOfDeliveryOptionsPersonally.findIndex(o => o === option);
                                                            proofOfDeliveryOptionsPersonally.splice(index, 1);
                                                        } else {
                                                            proofOfDeliveryOptionsPersonally.push(option);
                                                        }

                                                        proofOfDeliveryOptions.personally = proofOfDeliveryOptionsPersonally
                                                        formChanged(proofOfDeliveryOptions, 'proofOfDeliveryOptions')
                                                        formChangedValue(proofOfDeliveryOptions, 'proofOfDeliveryOptions')
                                                    }}
                                                />
                                            })}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
                                    <Grid item xs={8}>
                                        <strong>{translate('dropoff_permit')}</strong>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                                        <CustomSwitch onChange={(e) => {
                                            let proofOfDeliveryOptions = { ...parcelData.proofOfDeliveryOptions };

                                            proofOfDeliveryOptions.dropoffPermit = e.target.checked
                                            formChanged(proofOfDeliveryOptions, 'proofOfDeliveryOptions')
                                            formChangedValue(proofOfDeliveryOptions, 'proofOfDeliveryOptions')
                                        }} defaultChecked={parcelData.proofOfDeliveryOptions.dropoffPermit} />
                                    </Grid>
                                </Grid>
                                {parcelData.proofOfDeliveryOptions.dropoffPermit && <Grid container style={{ marginBottom: 40 }}>
                                    <Grid item xs={12}>
                                        <div style={{
                                            width: '100%',
                                            overflow: 'scroll',
                                        }}>
                                            {possibleProofOfDeliveryOptionsDropoffPermit.map((option, key) => {
                                                return <Button
                                                    type='secondary'
                                                    key={key}
                                                    preventLoading
                                                    label={translate(option)}
                                                    selected={parcelData.proofOfDeliveryOptions.dropoff.includes(option)}
                                                    onClick={() => {
                                                        let proofOfDeliveryOptions = { ...parcelData.proofOfDeliveryOptions };
                                                        let proofOfDeliveryOptionsdropoff = [...parcelData.proofOfDeliveryOptions.dropoff];
                                                        if (typeof proofOfDeliveryOptionsdropoff !== 'object') {
                                                            proofOfDeliveryOptionsdropoff = [];
                                                        }
                                                        if (proofOfDeliveryOptionsdropoff.includes(option)) {
                                                            let index = proofOfDeliveryOptionsdropoff.findIndex(o => o === option);
                                                            proofOfDeliveryOptionsdropoff.splice(index, 1);
                                                        } else {
                                                            proofOfDeliveryOptionsdropoff.push(option);
                                                        }

                                                        proofOfDeliveryOptions.dropoff = proofOfDeliveryOptionsdropoff
                                                        formChanged(proofOfDeliveryOptions, 'proofOfDeliveryOptions')
                                                        formChangedValue(proofOfDeliveryOptions, 'proofOfDeliveryOptions')
                                                    }}
                                                />
                                            })}
                                        </div>
                                    </Grid>
                                </Grid>
                                }
                                <Grid container style={{ marginTop: 20 }}>
                                    <Grid item xs={12}>
                                        <strong>{translate('dropoff_not_possible')}</strong>
                                    </Grid>
                                </Grid>

                                {possibleReasonOfFailure.map((reason, key) => {
                                    return <div key={key}><Grid container style={{ marginTop: 20 }}>
                                        <Grid item xs={12}>
                                            <strong>{translate(reason)}</strong>
                                        </Grid>
                                    </Grid>
                                        {
                                            possibleReasonOfFailureActions.map((action, _key) => {
                                                return <Grid container key={_key}>
                                                    <Grid item xs={7}>
                                                        {translate(action)}
                                                    </Grid>
                                                    <Grid item xs={5} style={{ textAlign: 'right' }}><CustomSwitch onChange={(e) => {
                                                        let reasonOfFailureOptions = { ...parcelData?.reasonOfFailureOptions };
                                                        let reasonOfFailureOptionsOption = { ...reasonOfFailureOptions[reason] };

                                                        reasonOfFailureOptionsOption[action] = e.target.checked;
                                                        reasonOfFailureOptions[reason] = reasonOfFailureOptionsOption;

                                                        formChanged(reasonOfFailureOptions, 'reasonOfFailureOptions')
                                                        formChangedValue(reasonOfFailureOptions, 'reasonOfFailureOptions')
                                                    }} defaultChecked={
                                                        parcelData?.reasonOfFailureOptions && parcelData?.reasonOfFailureOptions[reason] && parcelData?.reasonOfFailureOptions[reason][action] ? parcelData?.reasonOfFailureOptions[reason][action] : false
                                                    } />
                                                    </Grid>
                                                </Grid>
                                            })
                                        }
                                    </div>
                                })}

                                <hr style={{ width: '100%', marginTop: 30, marginBottom: 20 }} />
                            </>
                            : <>
                                <ParcelProof
                                    proofOfDelivery={parcelData.proofOfDelivery}
                                    reasonOfFailure={parcelData.reasonOfFailure}
                                    status={parcelData.routeStopStatus}
                                />
                            </>
                    }
                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'scanCode')}
                        onChange={e => formChangedValue(e.target.value, 'scanCode')}
                        label={translate('scan_code')}
                        type="text"
                        className='dark'
                        value={parcelData.scanCode}
                        disabled={parcelData.routeStopStatus !== 'created'}
                    />
                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'trackingCode')}
                        onChange={e => formChangedValue(e.target.value, 'trackingCode')}
                        label={translate('tracking_code')}
                        type="text"
                        className='dark'
                        value={parcelData.trackingCode}
                        disabled={parcelData.routeStopStatus !== 'created'}
                    />
                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'companyTitle')}
                        onChange={e => formChangedValue(e.target.value, 'companyTitle')}
                        label={translate('seller_name')}
                        type="text"
                        className='dark'
                        value={parcelData.companyTitle}
                        disabled={parcelData.routeStopStatus !== 'created'}
                    />
                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'externalId')}
                        onChange={e => formChangedValue(e.target.value, 'externalId')}
                        label={translate('seller_order_id')}
                        type="text"
                        className='dark'
                        value={parcelData.externalId}
                        disabled={parcelData.routeStopStatus !== 'created'}
                    />
                    <Grid container>
                        <Grid item xs={12}>
                            <strong>{translate('measurements')}</strong>
                        </Grid>
                    </Grid>
                    <TextField
                        id="standard-basic"
                        onBlur={e => formChanged(e.target.value, 'weight')}
                        onChange={e => formChangedValue(e.target.value, 'weight')}
                        label={translate('weight')}
                        type="text"
                        className='dark'
                        value={parcelData.weight}
                        disabled={parcelData.routeStopStatus !== 'created'}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                id="standard-basic"
                                onBlur={e => formChanged(e.target.value, 'width')}
                                onChange={e => formChangedValue(e.target.value, 'width')}
                                label={translate('width')}
                                type="text"
                                className='dark'
                                value={parcelData.width}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">{translate('cm')}</InputAdornment>,
                                }}
                                disabled={parcelData.routeStopStatus !== 'created'}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="standard-basic"
                                onBlur={e => formChanged(e.target.value, 'height')}
                                onChange={e => formChangedValue(e.target.value, 'height')}
                                label={translate('height')}
                                type="text"
                                className='dark'
                                value={parcelData.height}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">{translate('cm')}</InputAdornment>,
                                }}
                                disabled={parcelData.routeStopStatus !== 'created'}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="standard-basic"
                                onBlur={e => formChanged(e.target.value, 'length')}
                                onChange={e => formChangedValue(e.target.value, 'length')}
                                label={translate('length')}
                                type="text"
                                className='dark'
                                value={parcelData.length}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">{translate('cm')}</InputAdornment>,
                                }}
                                disabled={parcelData.routeStopStatus !== 'created'}
                            />
                        </Grid>
                    </Grid>
                    <p style={{ margin: 0 }}></p>
                </Grid>
            </Grid>
        </>
    );
}

export default ParcelDetail;