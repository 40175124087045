const getStatusColor = (status: string) => {
    switch (status) {
        case 'running':
            return 'yellow';
        case 'to_assign':
            return 'grey';
        case 'success':
            return 'green';
        case 'delivered':
            return 'green';
        case 'created':
            return 'grey';
        case 'late':
            return 'yellow';
        case 'open':
            return '';
        case 'failed':
            return 'red';
        case 'problem':
            return 'red';
        case 'stopped_early':
            return 'red';
        case 'done':
            return 'green';
        case 'arrived':
            return 'yellow';
        case 'driving':
            return 'yellow';
        case 'assigned_to_route':
            return 'grey';
    }

    return ''
}

export default getStatusColor;