import wrlog from '../../Helper/functions/wrlog';
import Storage from '../Storage';
import Unit from './index';

export default class RouteStopUnit extends Unit {

    static import = async (formData: any) => {
        return await Unit.postMultipart('/stop/import', formData);
    }

    static listSolo = async () => {
        return await Unit.get('/stops/solo');
    }

    static create = async (body: any) => {
        return await Unit.post('/stop', body);
    }

    static update = async (body: any) => {
        return await Unit.put('/stop', body);
    }

    static getStop = async (id: string) => {
        return await Unit.get(`/stop/${id}`);
    }

    static createNotification = async (trigger: string, headline: string, textBody: string) => {
        const body = {
            trigger: trigger,
            headline: headline,
            body: textBody,
        }
        return await Unit.post('/notification', body);
    }

    static updateNotification = async (id: string, trigger: string, headline: string, textBody: string) => {
        const body = {
            id: id,
            data: {
                trigger: trigger,
                headline: headline,
                body: textBody,
            }
        }

        return await Unit.put('/notification', body);
    }

}



