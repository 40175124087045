import i18n, { t } from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files

import translationEN from "./languages/en.json";
import translationDE from "./languages/de.json";

export const changeLanguageHandler = (e) => {
    const languageValue = navigator.language.split('-')[0];
    i18n.changeLanguage(languageValue);
}

export const translate = (val, vars = null) => {

    let text = t(val);

    if (vars !== null) {
        Object.keys(vars).forEach(key => {
            let _var = vars[key];
            text = text.replace(`{{${key}}}`, _var);
        })
    }

    return text;
}


const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
};

//i18N Initialization

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en", //default language
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;